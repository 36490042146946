import * as S from "./styles";

export default function ViewOrEdit({
  label,
  value,
  read,
  onChange,
  type,
  name,
  func,
  isBrandSelected,
  ...rest
}) {
  // console.log(isBrandSelected)
  return (
    <div style={{ margin: "10px 0px", width: "100%" }}>
      <h5 style={{ marginBottom: "5px" }}>{label}</h5>
      <div style={{ display: "flex", alignContent: "center", gap: 10 }}>
        <S.Input
          name={name}
          value={value}
          readOnly={read}
          onChange={onChange}
          type={type ?? "text"}
          {...rest}
        />
        {func && (
          <button
            onClick={func}
            style={{ padding: 10 }}
            disabled={isBrandSelected}
          >
            Add
          </button>
        )}
      </div>
    </div>
  );
}
