import { gql } from "@apollo/client";

export const CREATEDBY_IDS = gql`
  query {
    staffUsers(first: 100) {
      edges {
        node {
          id
          isStaff
          email
        }
      }
    }
  }
`;

export const GET_BRAND_SOURCING_REQUESTS = gql`
  query GetBrandSources(
    $state: BrandSourceRequestEnum
    $storeBucket: StoreStatusEnum
    $stores: [ID]
    $brands: [ID]
    $createdBy: [ID]
    $brandCollab: BrandCollabEnum
    $direction: OrderDirection!
    $field: BrandSourceRequestSortField!
    $endCursor: String
  ) {
    brandSourceRequests(
      first: 100
      after: $endCursor
      filter: {
        state: $state
        storeBucket: $storeBucket
        stores: $stores
        brands: $brands
        createdBy: $createdBy
        brandCollab: $brandCollab
      }
      sortBy: { field: $field, direction: $direction }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          notification
          campaignName
          store {
            id
            storeUrl
            storeName
          }
          brand {
            id
            brandName
            active
          }
          termsAndConditions
          content
          brandCollab
          brandManagers
          state
          storeBucket
          storeManagers
          createdAt
          createdBy {
            email
          }
        }
      }
    }
  }
`;
