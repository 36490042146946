import { useLazyQuery, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import Table from "../../components/Table";
import Filters from "./Filters";
import { GET_STORES } from "./queries";
import ReactModal from "react-modal";
import * as S from "../BrandSourcing/styles";
import EditStaff from "./EditStaff";
import EditStore from "./EditStore";
import EditActions from "./EditActions";

export default function StoresDashboard() {
  const [page, setPage] = useState(1);
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState({});
  const [editStaff, setEditStaff] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [filters, setFilters] = useState({
    dir: { label: "Desc", value: "DESC" },
    field: { label: "GMV Last 30 Days", value: "GMV_LAST_30_DAYS" },
  });
  const { data, loading, error, fetchMore } = useQuery(GET_STORES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      barter: filters?.barter?.value,
      dir: filters.dir?.value,
      field: filters.field?.value,
      search: filters?.search,
    },
  });

  const changeFilter = (value, type) => {
    setPage(1);
    if (type === "storeBarter")
      return setFilters({
        ...filters,
        barter: value,
        storeBarter: value,
        stopSource: null,
      });
    if (type === "stopSource")
      return setFilters({
        ...filters,
        barter: value,
        stopSource: value,
        storeBarter: null,
      });
    setFilters({ ...filters, [type]: value });
  };

  useEffect(() => {
    if (data) {
      const storesData = data.stores.edges.map(({ node }) => ({
        storeId: window.atob(node?.id).replace("Store:", "") ?? "--",
        actions: {
          function: () => {
            setEditStore(true);
            setCurrentStore(node);
          },
          name: "Edit",
        },
        storeName: node?.storeName ?? "--",
        storeUrl: {
          link: {
            name: "View Store",
            url: node?.storeUrl ? node?.storeUrl : "--",
          },
        },
        slug: node?.slug ?? "--",
        state: node?.state ?? "--",
        storeManagers: {
          function: () => {
            setEditStaff(true);
            setCurrentStore({
              id: node?.id,
              staff: node?.storeManagers?.map((mem) => ({
                label: mem.email,
                value: mem.id,
              })),
            });
          },
          data: node?.storeManagers ?? [],
        },
        storeType: node?.storeType ?? "--",
        storeCategoryPageLevel: node?.storeCategoryPageLevel ?? "--",
        status: node?.actions?.status ?? "--",
        nextActions: node?.actions?.nextActions ?? "--",
        storeActions: {
          function: () => {
            setCurrentStore(node);
            setEditStatus(true);
          },
          name: "Edit Actions",
          disable: node?.actions ? false : true,
        },
        streakLive: node?.streakLive ? "True" : "False",
        instagram: {
          link: {
            name: node?.instagramLink ? node?.instagramLink : "--",
            url: node?.instagramLink ? node?.instagramLink : "--",
          },
        },
        stopSourceWithZaamo: node?.stopSourceWithZaamo ? "True" : "False",
        barterGuidelines: JSON.parse(node?.metadata)?.barter_guidelines ?? "--",
        influencerNotes: JSON.parse(node?.metadata)?.influencer_notes ?? "--",
      }));
      setStores(storesData);
    }
  }, [data, page]);

  if (loading) return <PacmanLoader />;
  if (error) return <ErrorPage />;

  return (
    <Layout
      pagination={{
        hasNext:
          data?.stores?.pageInfo?.hasNextPage ||
          page * 100 < data?.stores?.edges?.length,
        current: page,
        onNextClick: () => {
          setPage(page + 1);
          fetchMore({
            variables: {
              endCursor: data?.stores?.pageInfo?.endCursor,
            },
          });
        },
        onPrevClick: () => setPage(page - 1),
      }}
      desktop
    >
      <Filters
        onChange={changeFilter}
        defaultValue={{
          ...filters,
        }}
      />
      <Table
        currentPage={page}
        from="stores"
        data={stores}
        displayPageSize={100}
      />
      <ReactModal
        isOpen={editStaff}
        onRequestClose={() => setEditStaff(false)}
        style={S.Modal}
      >
        <EditStaff
          id={currentStore?.id}
          staff={currentStore?.staff}
          close={() => setEditStaff(false)}
        />
      </ReactModal>
      <ReactModal
        isOpen={editStore}
        onRequestClose={() => setEditStore(false)}
        style={S.Modal}
      >
        <EditStore showInfluencerNotes store={currentStore} close={() => setEditStore(false)} />
      </ReactModal>
      <ReactModal
        isOpen={editStatus}
        onRequestClose={() => setEditStatus(false)}
        style={{
          ...S.Modal,
          content: {
            ...S.Modal.content,
            maxWidth: "900px",
          },
        }}
      >
        <EditActions
          storeData={currentStore?.actions}
          close={() => setEditStatus(false)}
        />
      </ReactModal>
    </Layout>
  );
}
