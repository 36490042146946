import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Dropdown from "../../../../components/Dropdown";
import * as S from "./styles";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { ORDER_STATUS, UPDATE_METADATA } from "./mutation";
import { client } from "../../../../apollo-client";
import { GET_ORDER_DETAILS } from "./queries";
import { GET_MASTER_DASHBOARD_DATA } from "../../queries";

const ProductCard = (props) => {
  const [updateOrderStatusMutation, { loading }] = useMutation(ORDER_STATUS);
  // const [updateMetaDataMutation, { loading2 }] = useMutation(UPDATE_METADATA);
  const [orderStatus, setOrderStatus] = useState(undefined);
  const [data, setData] = useState([]);
  const [shippingId, setShippingId] = useState(undefined);
  const [shippingProvider, setShippingProvider] = useState(undefined);
  // const [metaData, setMetaData] = useState(undefined);
  // const [metaDataType, setMetaDataType] = useState(metaDataOptions[0]);

  const updateOrderStatus = async () => {
    if (
      orderStatus.value === "SHIPPED" &&
      (!shippingId || shippingId.trim() == "" || !shippingProvider || shippingProvider.trim() == "")
    ) {
      toast.warn("Please enter shipping details!");
      return;
    }

    const { data: orderStatusData } = await updateOrderStatusMutation({
      variables: {
        id: props?.fulfillmentIds[props?.id].fulfillmentId,
        warehouseId: props.warehouseId,
        fulfillmentStatus: orderStatus?.value,
        shippingId: shippingId,
        shippingProvider: shippingProvider,
      },
    });
    if (orderStatusData?.updateFulfillment?.fulfillment) {
      toast.success("Status updated successfully!");
      client.refetchQueries({
        include: [GET_ORDER_DETAILS],
      });
      const temp = { ...props.statusDict };
      temp[props.id] = orderStatus.value;
      props.setStatusDict(temp);
      props.setModalShow(false);
    } else {
      orderStatusData?.updateFulfillment?.orderErrors?.map((e) => {
        toast.error(e?.message);
      });
    }
    console.log(orderStatusData, "orderstatusdata");
  };

  // const updateMetaData = async () => {
  //   const { data: updatedMetaData } = await updateMetaDataMutation({
  //     variables: {
  //       id: props?.fulfillmentIds[props?.id].fulfillmentId,
  //       key: metaDataType?.value,
  //       value: metaData,
  //     },
  //   });
  //   if (updatedMetaData?.updateMetadata?.item) {
  //     toast.success("Metadata updated successfully!");
  //     props.setModalShow(false);
  //   } else {
  //     updatedMetaData?.updateMetadata?.metadataErrors?.map((e) => {
  //       toast.error(e?.message);
  //     });
  //   }
  //   console.log(updatedMetaData, "updatedMetaData");
  // };

  useEffect(() => {
    if (
      shippingId?.includes("https://") ||
      shippingId?.includes("http://") ||
      shippingId?.includes("www.")
    ) {
      setShippingProvider("From Link");
    } else {
      setShippingProvider(props?.fulfillments[0]?.shippingFulfillment?.shippingProvider);
    }
  }, [shippingId]);

  useEffect(() => {
    if (props?.fulfillmentIds[props?.id]?.status) {
      let temp = [...fulfillmentDataItems];
      for (let i in temp) {
        if (
          temp[i].value == props?.fulfillmentIds[props?.id]?.status &&
          !["EXCHANGE_REQUESTED", "EXCHANGE_INITIATED", "EXCHANGE_COMPLETED"].includes(
            props?.fulfillmentIds[props?.id]?.status
          )
        ) {
          temp.splice(0, i);
          break;
        }
      }
      setData(temp);
    }
  }, [props?.fulfillmentIds[props?.id]?.status]);

  useEffect(() => {
    if (props?.fulfillmentIds[props?.id]?.status) {
      setOrderStatus({
        label: fulfilmentStatusDict[props?.fulfillmentIds[props?.id]?.status],
        value: props?.fulfillmentIds[props?.id]?.status,
      });
    }
  }, [props?.fulfillmentIds[props?.id]?.status]);

  useEffect(() => {
    if (props?.fulfillments?.length) {
      setShippingId(props?.fulfillments[0]?.shippingFulfillment?.shippingId);
      setShippingProvider(props?.fulfillments[0]?.shippingFulfillment?.shippingProvider);
      // setMetaData(props?.fulfillments[0]?.metadata[0]?.value);
    }
  }, [props?.fulfillments]);

  return (
    <>
      <S.ProductCard>
        <S.Image>
          <img src={props?.thumbnail?.url} alt="img" />
        </S.Image>
        <S.Description>
          <p>{props?.brandName}</p>
          <p>
            {props?.productName} | <b>{props?.variant?.name}</b>
          </p>
          <S.ProdFlex>
            <span>QTY: {props?.quantity}</span>
            <span>Rs.{props?.variant?.pricing?.price?.net?.amount}</span>
          </S.ProdFlex>
          <S.StatusChange>
            <div>
              <div style={{ transform: "Scale(0.8)", marginLeft: "-1.2rem" }}>
                <label>Change Status:</label>
                <Dropdown
                  data={[...data]}
                  value="value"
                  label="label"
                  onChange={(e) => setOrderStatus(e)}
                  placeholder="Order Status"
                  defaultValue={
                    orderStatus ?? {
                      label: fulfilmentStatusDict[props?.fulfillmentIds[props?.id]?.status],
                      value: props?.fulfillmentIds[props?.id]?.status,
                    }
                  }
                  // menuPortalTarget={document.getElementById("order-edit-container")}
                />
              </div>
              {orderStatus?.value === "SHIPPED" && (
                <>
                  <S.ShippingId>
                    <input
                      onChange={(e) => setShippingId(e.target.value)}
                      placeholder="Shipping Link"
                      value={shippingId ?? ""}
                    />
                  </S.ShippingId>
                  <S.ShippingId>
                    <input
                      onChange={(e) => setShippingProvider(e.target.value)}
                      placeholder="Shipping Provider"
                      value={shippingProvider ?? ""}
                      disabled={
                        shippingId?.includes("https://") ||
                        shippingId?.includes("http://") ||
                        shippingId?.includes("www.")
                          ? true
                          : false
                      }
                    />
                  </S.ShippingId>
                </>
              )}
            </div>
            <Button
              style={{
                maxWidth: "100px",
                margin: "auto auto 8px 0px",
                padding: "0px 0",
                lineHeight: "28px",
              }}
              disabled={loading}
              onClick={
                orderStatus?.value
                  ? () => updateOrderStatus()
                  : () => toast.warn("Please select order fulfillment status!")
              }
            >
              {loading ? "Up    ) {dating..." : "Update"}
            </Button>
          </S.StatusChange>
        </S.Description>
      </S.ProductCard>
      {/* <S.MetaData>
        <Dropdown
          data={[...metaDataOptions]}
          value="value"
          label="label"
          onChange={(e) => setMetaDataType(e)}
          placeholder="Type"
          defaultValue={metaDataType}
          isDisabled
        />
        <textarea
          placeholder="Type here..."
          value={metaData}
          onChange={(e) => setMetaData(e.target.value)}
        />
        <Button
          style={{
            margin: "auto auto 8px 0px",
            padding: "0px 0",
            lineHeight: "28px",
          }}
          disabled={loading2}
          onClick={
            metaData && metaData.trim() !== "" && metaDataType?.value
              ? () => updateMetaData()
              : () => toast.warn("Please add metadata!")
          }
        >
          {loading2 ? "Updating Metadata..." : "Update Metadata"}
        </Button>
      </S.MetaData> */}
    </>
  );
};

const metaDataOptions = [{ label: "Status", value: "STATUS" }];

const fulfillmentDataItems = [
  { value: "PLACED", label: "Placed" },
  { value: "IN_PROCESS", label: "In Process" },
  { value: "SHIPPED", label: "Shipped" },
  { value: "DELIVERED", label: "Delivered" },
  { value: "CANCELLATION_INITIATED", label: "Cancellation Initiated" },
  { value: "CANCELLATION_PROCESSED", label: "Cancellation Processed" },
  { value: "RETURN_REQUESTED", label: "Return Requested" },
  { value: "RETURN_INITIATED", label: "Return Initiated" },
  { value: "RETURN_COMPLETED", label: "Return Completed" },
  { value: "EXCHANGE_REQUESTED", label: "Exchange Requested" },
  { value: "EXCHANGE_INITIATED", label: "Exchange Initiated" },
  { value: "EXCHANGE_COMPLETED", label: "Exchange Completed" },
];

const fulfilmentStatusDict = {
  PLACED: "Placed",
  IN_PROCESS: "In Process",
  SHIPPED: "Shipped",
  DELIVERED: "Delivered",
  CANCELLATION_INITIATED: "Cancellation Initiated",
  CANCELLATION_PROCESSED: "Cancellation Processed",
  RETURN_REQUESTED: "Return Requested",
  RETURN_INITIATED: "Return Initiated",
  RETURN_COMPLETED: "Return Completed",
  FULFILLED: "Fulfilled",
  PENDING: "Pending",
  EXCHANGE_REQUESTED: "Exchange Requested",
  EXCHANGE_INITIATED: "Exchange Initiated",
  EXCHANGE_COMPLETED: "Exchange Completed",
};

export default ProductCard;
