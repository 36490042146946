import * as S from "./styles";
import ReactModal from "react-modal";
import { useState } from "react";
import OrderModal from "./OrderModal";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import moment from "moment";

const OrderCard = ({
  storeName,
  storeUrl,
  imageUrl,
  quantity,
  brandName,
  price,
  productName,
  variantName,
  id,
  createdAt,
  updatedAt,
  fulfilmentStatus,
  shippingId,
  shippingProvider,
  voucherName,
  cod,
  refundCreationTime,
}) => {
  const [showModal, setShowModal] = useState(false);

  const refundCreationFormatted =
    moment(refundCreationTime).format("DD/MM/YYYY hh:mm");

  return (
    <S.Card>
      <S.CardMain>
        <S.Left>
          <S.Image src={imageUrl} />
          <S.NoOfItems>{quantity}</S.NoOfItems>
        </S.Left>

        <S.Right>
          <S.CardTop>
            <S.CardHeading>{brandName}</S.CardHeading>
            <S.Price>₹{price}</S.Price>
          </S.CardTop>
          <S.CardMid>
            <S.CardText>{productName}</S.CardText>
          </S.CardMid>
          <S.CardBottom>
            <S.BottomDetails>
              <S.BottomDetailsCard>
                <S.PropName>Size: </S.PropName>
                <S.PropValue>{variantName}</S.PropValue>
              </S.BottomDetailsCard>
              <CopyToClipboard
                onCopy={() => toast.success("URL Copied")}
                text={storeUrl}
              >
                <S.BottomDetailsCard>
                  <S.PropName>{storeName}</S.PropName>
                </S.BottomDetailsCard>
              </CopyToClipboard>
              {cod ? (
                <S.BottomDetailsCard>
                  <S.PropName>COD</S.PropName>
                </S.BottomDetailsCard>
              ) : null}
              {!!refundCreationTime ? (
                <S.BottomDetailsCard>
                  <S.PropName>
                    Refund Creation Time: {refundCreationFormatted}
                  </S.PropName>
                </S.BottomDetailsCard>
              ) : null}
              {voucherName && (
                <S.BottomDetailsCard>
                  <S.PropName>Voucher: {voucherName}</S.PropName>
                </S.BottomDetailsCard>
              )}
            </S.BottomDetails>
            <S.ViewDetailsButton onClick={() => setShowModal(true)}>
              View Details
            </S.ViewDetailsButton>
          </S.CardBottom>
        </S.Right>
      </S.CardMain>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
        style={S.Modal}
      >
        <OrderModal
          setShowModal={setShowModal}
          id={id}
          brandName={brandName}
          productName={productName}
          createdAt={createdAt}
          updatedAt={updatedAt}
          fulfilmentStatus={fulfilmentStatus}
          shippingId={shippingId}
          shippingProvider={shippingProvider}
        />
      </ReactModal>
    </S.Card>
  );
};

export default OrderCard;
