import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #f5f5f5;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 1fr 2fr;
`;
export const Left = styled.div`
  padding: 0.5rem;
  display: grid;
  place-items: center;
`;

export const Image = styled.img`
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
  height: 6rem;
  width: 6rem;
  margin: auto;
`;

export const Right = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 0;
`;
export const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding-left: 1.5rem;
`;
export const Details = styled.div`
  /* display: grid; */
  padding: 0.5rem 0;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  align-items: start;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px 5px;
`;
export const Cell = styled.div`
  text-align: center;
  flex: 1;
`;
export const Value = styled.div`
  font-size: 14px;
`;
export const Small = styled.div`
  font-size: 9px;
`;
