import styled from "styled-components";

export const Heading = styled.h2`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0 7px;
  border-bottom: 1.5px solid #eee;
`;

export const Transaction = styled.div`
  display: flex;
  margin: 20px 10px;
`;

export const SubHeading = styled.h3`
  font-weight: 300;
`;

export const TData = styled.h4`
  font-weight: 300;
  margin: 5px 0px;
`;

export const TDate = styled.h4`
  margin-top: 5px;
  text-align: right;
  font-weight: 300;
`;

export const TCost = styled.h4`
  text-align: right;
  font-weight: 400;
  margin-bottom: 10px;
`;
