import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

import Button from "../../../components/Button";
import { UPDATE_CONTENT } from "./mutation";
import * as S from "../../StoresDashboard/EditStore/styles";

export const EditContent = ({ prevContent = "", storeId, close }) => {
  const [content, setContent] = useState(prevContent ?? "");

  const [updateContent] = useMutation(UPDATE_CONTENT);

  const handleUpdate = async () => {
    const res = await updateContent({
      variables: {
        storeId: storeId,
        content: content,
      },
    });
    if (res?.data?.storeUpdate?.store) {
      toast.success("Content updated sucessfully");
      close();
    } else {
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      <S.Label>Edit Content</S.Label>
      <S.TextArea
        value={content}
        placeholder="Your content"
        onChange={(e) => setContent(e.target.value)}
      />
      <Button
        disabled={JSON.stringify(content) === JSON.stringify(prevContent)}
        onClick={handleUpdate}
        style={{ margin: "10px 0px" }}
      >
        Save
      </Button>
    </div>
  );
};
