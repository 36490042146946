import * as S from "./styles";

import Layout from "../../components/Layout";

const Home = () => {
  return (
    <Layout desktop hideBack>
      <S.Container>
        <ul>
          {/* <li>
            <a href="/prod/dashboard/overall">Overall Dashboard</a>
          </li> */}
          <li>
            <a href="/prod/dashboard/master">Master Dashboard</a>
          </li>
          <li>
            <a href="/prod/analytics/store">Store Analytics</a>
          </li>
          <li>
            <a href="/prod/support/orders">Customer Support</a>
          </li>
          <li>
            <a href="/prod/support/refund">Refund</a>
          </li>
          <li>
            <a href="/products">PDP 2</a>
          </li>
          <li>
            <a href="/sourcing">Source with Zaamo</a>
          </li>
          <li>
            <a href="/prod/analytics/content">Content Analytics</a>
          </li>
          <li>
            <a href="/prod/influencer/signup">Activate Influencer Account</a>
          </li>
          <li>
            <a href="/prod/brand/sourcing">Brand Sourcing Requests</a>
          </li>
          <li>
            <a href="/prod/dashboard/brands">Brands Dashboard</a>
          </li>
          <li>
            <a href="/prod/dashboard/botd">Botd Dashboard</a>
          </li>
          <li>
            <a href="/prod/coupon/create">Coupons</a>
          </li>
          <li>
            <a href="/prod/dashboard/stores">Stores Dashboard</a>
          </li>
          <li>
            <a href="/prod/dashboard/influencer">Influencer Dashboard</a>
          </li>
          <li>
            <a href="/prod/dashboard/meetup">Meetup Dashboard</a>
          </li>
          <li>
            <a href="/prod/analytics/shopify">Shopify Analytics</a>
          </li>
        </ul>
      </S.Container>
    </Layout>
  );
};

export default Home;
