import { gql } from "@apollo/client";

export const RESTRICT_ORDER = gql`
  mutation RestrictOrder($id: ID!, $private: JSONString) {
    brandUpdate(input: { id: $id, privateMetadata: $private }) {
      brand {
        brandName
        id
        companyName
        address {
          firstName
        }
      }
    }
  }
`;
