import { gql } from "@apollo/client";

export const GENERATE_OTP = gql`
  mutation GenerateOtp($mobileNo: String!) {
    generateOtp(mobileNo: $mobileNo) {
      success
      accountErrors {
        message
      }
    }
  }
`;

export const VALIDATE_OTP = gql`
  mutation VerifyOtp($mobileNo: String!, $otp: Int!) {
    verifyOtp(mobileNo: $mobileNo, otp: $otp) {
      success
      accountErrors {
        message
      }
    }
  }
`;
export const TOKEN_CREATE = gql`
  mutation TokenCreate($mobileNo: String!) {
    tokenCreate(mobileNo: $mobileNo) {
      token
      refreshToken
      user {
        id
        isActive
        userId
        influencer {
          id
        }
      }
    }
  }
`;

export const ACTIVATE_ACCOUNT = gql`
  mutation ActivateAccount($mobileNo: String!, $username: String!) {
    activateAccount(
      input: { mobileNo: $mobileNo, instagramUsername: $username }
    ) {
      user {
        id
        userId
        isActive
        influencer {
          instagramUsername
        }
      }
    }
  }
`;

export const CREATE_STORE_MANAGER = gql`
  mutation CreateStoreManager($store: ID!, $staff: [ID]!) {
    storeManagerCreate(input: { store: $store, users: $staff }) {
      store {
        id
        storeName
      }
    }
  }
`;
