import { gql } from "@apollo/client";

export const UPDATE_COMMISSION = gql`
  mutation UpdateCommission($id: ID!, $commission: Float!, $zaamoCommission: Float) {
    brandCommissionCreateUpdate(input: { brand: $id, commissionPercentage: $commission, zaamoCommission: $zaamoCommission }) {
      brandCommission {
        id
        brand {
          brandName
          id
        }
        commissionPercentage
        zaamoCommission
        createdAt
        updatedAt
      }
    }
  }
`;
