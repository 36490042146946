import PageButton from "./PageButton";
import * as S from "./styles";

function Pagination({ hasNext, current, onNextClick, onPrevClick, isMobile }) {
  return (
    <S.Wrapper style={{ maxWidth: isMobile ? "500px" : "100%" }}>
      <S.Center>
        {current !== 1 && (
          <S.BackIcon onClick={onPrevClick} src="/assets/images/back.svg" />
        )}
        <PageButton active>{current}</PageButton>
        {hasNext ? <PageButton>{current + 1}</PageButton> : null}
        {hasNext ? <PageButton>...</PageButton> : null}
        {hasNext ? (
          <S.NextIcon onClick={onNextClick} src="/assets/images/back.svg" />
        ) : null}
      </S.Center>
    </S.Wrapper>
  );
}

export default Pagination;
