import styled from "styled-components";

export const Div = styled.div`
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const Button = styled.button`
  background: #000000;
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 30px;
  color: #ffffff;
  margin: 21px 6px 5px 0px;
  width: fit-content;
  padding: 2px 10px;
  cursor: pointer;
  `;

