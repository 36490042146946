import styled from "styled-components";
import { keyframes } from "styled-components";

export const SpinContainer = styled.div`
  display: flex;
  margin: auto;
  justify-content: center;
  padding: 55px 0px 10px 40px;
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid #000;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const Table = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
`;
export const TableRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 4px;
`;
export const InputCell = styled.input`
  border-radius: 2px;
  width: 23%;
  margin-right: 1.75%;
  padding: 10px 5px;
  border: 1px solid grey;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const HeaderCell = styled.h2`
  font-size: 1.1rem;
  font-weight: 600;
  flex: 1;
`;
export const Cell = styled.p`
  font-size: 0.9rem;
  font-weight: 400;
  flex: 1;
`;
export const ButtonCell = styled.button`
  font-size: 0.9rem;
  font-weight: 500;
  flex: 1;
  cursor: pointer;
  border: none;
  background-color: ${(props) => props.bg};
  color: ${(props) => props.color ?? "white"};
  padding: 4px;
`;
