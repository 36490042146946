import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";

import { onError } from "@apollo/client/link/error";
import { relayStylePagination } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("token");
  const storeId = localStorage.getItem("store-id");
  //normal headers required for every request
  const authLinkHeaders = {
    authorization: token ? `JWT ${token}` : "",
    "x-platform-code": "AN",
  };
  //if storeId is present -> if its present then send the header or else don't send the header
  //x-store-id header is to be set only for brand management dashboard
  const customHeaders = Object.assign(
    authLinkHeaders,
    storeId && { "x-store-id": storeId }
  );
  return {
    headers: {
      ...headers,
      ...customHeaders,
    },
  };
});
const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_BASE_URL,
});

//remove tokens and redirect to login page
const clearCredentials = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  window.location.href = "/";
};

//check if error is related to authentication
const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message }) => {
      console.log(message);
      if (message === "You do not have permission to perform this action")
        return clearCredentials();
    });
});

export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, httpLink]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        merge: true,
        fields: {
          masterDashboard: relayStylePagination([
            "$stores",
            "$timePeriod",
            "$brands",
            "$brandOrderStatus",
            "$voucher",
            "$search",
            "$direction",
            "$sortBy",
            "$status",
          ]),
          masterDashboardKpi: relayStylePagination([
            "$timePeriod",
            "$brandIds",
            "$orderStatus",
          ]),
          collectionAnalytics: relayStylePagination(),
          vouchers: relayStylePagination([
            "$brands",
            "$collections",
            "$stores",
            "$search",
          ]),
          productsAnalytics: relayStylePagination(),
          storeAnalytics: relayStylePagination([
            "$stores",
            "$timePeriod",
            "$direction",
            "$field",
            "$storeStatus",
            "$storeAction",
          ]),
          brands: relayStylePagination([
            "$search",
            "$barter",
            "$orders",
            "$commission",
            "$field",
            "$direction",
            "$status",
            "$importance",
          ]),
          sourcingRequest: relayStylePagination([
            "$email",
            "$stores",
            "$brands",
            "$direction",
            "$sortBy",
            "$status",
            "$time",
            "$collab",
            "$storeStatus",
            "$barter",
          ]),
          storePayout: relayStylePagination(),
          brandSourceRequests: relayStylePagination([
            "$state",
            "$storeBucket",
            "$stores",
            "$brands",
            "$createdBy",
            "$brandCollab",
            "$direction",
            "$field",
          ]),
          stores: relayStylePagination([
            "$barter",
            "$dir",
            "$field",
            "$search",
            "$status",
            "$managerEmail",
          ]),
          influencers: relayStylePagination([
            "$search",
            "$field",
            "$direction",
            "$status",
          ]),
          products: relayStylePagination(),
          pdpAnalytics: relayStylePagination([
            "$brandId",
            "$page",
            "$brandCollection",
            "$brandCategory",
            "$brandSubCategory",
            "$productType",
            "$isPublished",
            "$dataSource",
          ]),
        },
      },
    },
  }),
});
//
