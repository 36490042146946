import Dropdown from "../../components/Dropdown";
import * as S from "./styles";

const Filters = (props) => {
console.log(props, "proprs")
  return (
    <S.Filters>
      <div>
        <label>Select Stores</label>
        <Dropdown
          style={{ width: "100%", padding: "2px 0" }}
          data={[...props?.stores]}
          label="storeName"
          value="id"
          onChange={props.onStoreChange}
          defaultValue={props.storesValue}
          isMulti
          placeholder="Select stores"
        />
      </div>
      <div>
        <label>Select Collections</label>
        <Dropdown
          style={{ width: "100%", padding: "2px 0" }}
          data={[...props.collectionFilter]}
          value="collectionId"
          label="name"
          onChange={props.onCollectionChange}
          defaultValue={props.collectionsValue}
          isMulti
          placeholder="Select Collections"
        />
      </div>
      <S.Flex>
        <div style={{ width: "48%" }}>
          <label>Sort Direction</label>
          <Dropdown
            style={{ width: "100%", padding: "2px 0" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={props.onDirectionChange}
            defaultValue={props.directionValue}
            placeholder="Sort Direction"
          />
        </div>
        <div style={{ width: "48%" }}>
          <label>Sort By</label>
          <Dropdown
            style={{ width: "100%", padding: "2px 0" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={props.onSortChange}
            defaultValue={props.sortByValue}
            placeholder="Sort By"
            isClearable
          />
        </div>
      </S.Flex>
    </S.Filters>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Name",
    value: "NAME",
  },
  {
    label: "Price",
    value: "PRICE",
  },
  {
    label: "Minimal Price",
    value: "MINIMAL_PRICE",
  },
  {
    label: "Date",
    value: "DATE",
  },
  {
    label: "Type",
    value: "TYPE",
  },
  {
    label: "Published",
    value: "PUBLISHED",
  },
  {
    label: "Publication Date",
    value: "PUBLICATION_DATE",
  },
  {
    label: "Collection",
    value: "COLLECTION",
  },
  {
    label: "Popularity",
    value: "POPULARITY",
  },
  {
    label: "Sales this month",
    value: "SALES_THIS_MONTH",
  },
  {
    label: "Weekly visits",
    value: "WEEKLY_VISITS",
  },
];

export default Filters;
