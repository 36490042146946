import React from "react";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import TableWithSelection from "../../../components/TableWithSelection";
import * as S from ".././styles";

export const SelectRowsModal = ({
  allSelectedRows = [],
  createBulkDealHandler,
  close,
}) => {
  const [stores, setStores] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    if (allSelectedRows.length > 0) {
      const storesData = allSelectedRows.map(({ original }) => ({
        storeName: original?.storeName ?? "--",
        storeUrl: {
          link: {
            name: "View Store",
            url: original?.storeUrl ? original?.storeUrl : "--",
          },
        },
        slug: original?.slug ?? "--",
        state: original?.state ?? "--",
        storeType: original?.storeType ?? "--",
        storeCategoryPageLevel: original?.storeCategoryPageLevel ?? "--",
        status: original?.actions?.status ?? "--",
        nextActions: original?.actions?.nextActions ?? "--",
        streakLive: original?.streakLive ? "True" : "False",
        instagram: {
          link: {
            name: original?.instagramLink ? original?.instagramLink : "--",
            url: original?.instagramLink ? original?.instagramLink : "--",
          },
        },
        stopSourceWithZaamo: original?.stopSourceWithZaamo ? "True" : "False",
        storeId: original?.storeId,
      }));
      setStores(storesData);
    }
  }, []);

  const handleCreateBulkDeal = async () => {
    if (selectedRows.length > 0) {
      //   alert("creating bulk deal");
      createBulkDealHandler(selectedRows);
      close();
    } else {
      toast.error("Select atleast one store");
    }
  };
  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <h5>Selected items</h5>
        <S.Button onClick={handleCreateBulkDeal}>Create Bulk Deal</S.Button>
      </div>
      <TableWithSelection
        currentPage={1}
        from="stores"
        data={stores}
        displayPageSize={100}
        setSelectedRows={setSelectedRows}
        allRowsSelected={true}
      />
    </div>
  );
};
