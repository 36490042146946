import Dropdown from "../../components/Dropdown";
import * as S from "./styles";

const Filters = (props) => {
  //   console.log(props);

  return (
    <S.Filters>
      <label>Select Stores</label>
      <Dropdown
        style={{ width: "100%", padding: "2px 0" }}
        data={[...props?.stores]}
        label="storeName"
        value="id"
        onChange={props.onStoreChange}
        defaultValue={props.storesValue}
        isMulti
        placeholder="Select stores"
      />
      <S.Flex>
        <div style={{ width: "48%" }}>
          <label>Sort Direction</label>
          <Dropdown
            style={{ width: "100%", padding: "2px 0" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={props.onDirectionChange}
            defaultValue={props.directionValue}
            placeholder="Sort Direction"
          />
        </div>
        <div style={{ width: "48%" }}>
          <label>Sort By</label>
          <Dropdown
            style={{ width: "100%", padding: "2px 0" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={props.onSortChange}
            defaultValue={props.sortByValue}
            placeholder="Sort By"
          />
        </div>
      </S.Flex>
    </S.Filters>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Name",
    value: "NAME",
  },
  {
    label: "Availability",
    value: "AVAILABILITY",
  },
  {
    label: "Product Count",
    value: "PRODUCT_COUNT",
  },
  {
    label: "Publication date",
    value: "PUBLICATION_DATE",
  },
  {
    label: "Updated At",
    value: "UPDATED_AT",
  },
];

export default Filters;
