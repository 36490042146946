import styled from "styled-components";

export const Form = styled.div`
  position: relative;
  .css-qc6sy-singleValue {
    font-size: 0.9rem;
  }
  /* .css-6j8wv5-Input {
    margin: 0;
  } */
  /* span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    padding: 0 4px;
  } */
`;
