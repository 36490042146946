import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import PacmanLoader from "../../components/PackmanLoader";
import { CREATEDBY_IDS } from "../BrandSourcing/queries";
import { GET_USER_DATA } from "../MasterDashboard/queries";
import { ACTIVATE_ACCOUNT, CREATE_STORE_MANAGER } from "./mutations";
import * as S from "./styles";

const SignOut = () => {
  const [searchParams] = useSearchParams();
  //values for the store to be edited
  const [selectedValue, setSelectedValue] = useState({
    store: null,
    staff: null,
  });
  //for activating the account
  const [activateAccount, { data, error }] = useMutation(ACTIVATE_ACCOUNT);

  //create store manager
  const [createManager, { error: createManagerError }] = useMutation(CREATE_STORE_MANAGER);

  //stores
  const {
    data: storeData,
    loading,
    error: storeError,
  } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });
  //staff members
  const { data: staffData, loading: staffLoading, error: staffError } = useQuery(CREATEDBY_IDS);

  const noRegex = /^91[0-9]{10}$/g;

  //details for the user
  const [details, setDetails] = useState({
    phone: "",
    username: "",
  });

  //handle the change of the dropdowns
  const onChange = (e, type) => {
    return setSelectedValue((prev) => ({ ...prev, [type]: e }));
  };

  //calling the mutation
  const handleStaff = async () => {
    try {
      if (selectedValue.staff && selectedValue.store) {
        const res = await createManager({
          variables: {
            store: selectedValue.store?.value,
            staff: selectedValue.staff.map((staff) => staff?.value),
          },
        });
        if (res.data?.storeManagerCreate) toast.success("Staff Saved!");
      } else toast.warning("Fields Empty!");
    } catch (error) {
      console.log(error);
    }
  };

  //input handler
  const handleChange = (e) => {
    setDetails((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (noRegex.test(details.phone)) {
      activateAccount({
        variables: {
          mobileNo: details.phone,
          username: details.username,
        },
      });
    } else {
      toast.warning("Number should begin with 91 and have 10 digits!");
    }
  };

  useEffect(() => {
    if (error) toast.error("Something went wrong, Try checking the Input fields!");
  }, [error]);

  useEffect(() => {
    if (data) {
      toast.success(
        `${data?.activateAccount?.user?.influencer?.instagramUsername} activated successfully!`
      );
    }
  }, [data]);

  useEffect(() => {
    const mobileNo = searchParams.get("mobile");
    if (mobileNo) {
      setDetails({ ...details, phone: mobileNo });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  if (loading || staffLoading) return <PacmanLoader />;
  if (storeError || staffError || createManagerError) return <ErrorPage />;

  return (
    <S.Wrapper>
      <S.PhoneNumber>Activate Influencer Account</S.PhoneNumber>
      <S.FormStyled>
        <form onSubmit={handleSubmit}>
          <S.TakeInput
            autoFocus
            type="number"
            name="phone"
            placeholder="Enter your phone No"
            value={details.phone}
            onChange={handleChange}
            className="form-field"
            required
          />
          <S.TakeInput
            name="username"
            placeholder="Enter Instagram Username"
            value={details.username}
            onChange={handleChange}
            className="form-field"
            required
          />
          <Button style={{ width: "25%" }} type="submit">
            Sign Up
          </Button>
        </form>
      </S.FormStyled>
      <S.PhoneNumber style={{ margin: "30px 0px 20px" }}>Set Staff Members</S.PhoneNumber>
      <div style={{ display: "flex", gap: "20px" }}>
        <div style={{ display: "grid" }}>
          <S.Label>Select Store</S.Label>
          <Dropdown
            style={{ width: "300px" }}
            data={storeData?.userByMobile?.authorisedStores}
            value="id"
            label="storeName"
            onChange={(e) => onChange(e, "store")}
            placeholder="Select Store"
            defaultValue={selectedValue.store}
          />
        </div>
        <div style={{ display: "grid" }}>
          <S.Label>Select Staff Members</S.Label>
          <Dropdown
            style={{ width: "300px" }}
            data={staffData?.staffUsers?.edges?.map(({ node }) => ({
              id: node?.id,
              email: node?.email,
            }))}
            value="id"
            label="email"
            onChange={(e) => onChange(e, "staff")}
            placeholder="Select Staff"
            isMulti
          />
        </div>
      </div>
      <Button onClick={handleStaff} style={{ width: "160px", margin: "0px" }}>
        Save
      </Button>
    </S.Wrapper>
  );
};

export default SignOut;
