import { gql } from "@apollo/client";

export const TOKEN_VERIFY = gql`
  mutation TokenVerify($token: String!) {
    tokenVerify(token: $token) {
      isValid
      user {
        id
        isStaff
        isSuperuser
        mobileNo
      }
    }
  }
`;
