import { gql } from "@apollo/client";

export const GET_USER_DATA = gql`
  query UserData($mobileNo: String!) {
    userByMobile(mobileNo: $mobileNo) {
      id
      isActive
      authorisedBrands {
        id
        brandName
      }
      authorisedStores {
        id
        storeName
      }
    }
  }
`;
//

export const GET_MASTER_DASHBOARD_DATA = gql`
  query MasterDashboardCountableConnection(
    $stores: [ID]
    $timePeriod: TimePeriod
    $brands: [ID]
    $endCursor: String
    $direction: OrderDirection!
    $sortBy: OrderSortField!
    $status: [FulfillmentStatusEnum]
    $brandOrderStatus: [BrandOrderStatusEnum]
    $voucher: String
    $cod: Boolean
    $search: String
  ) {
    masterDashboard(
      first: 10
      after: $endCursor
      filter: {
        stores: $stores
        timePeriod: $timePeriod
        brands: $brands
        fulfillmentStatus: $status
        brandOrderStatus: $brandOrderStatus
        voucherStartswith: $voucher
        cod: $cod
        search: $search
      }
      sortBy: { direction: $direction, field: $sortBy }
    ) {
      pageInfo {
        startCursor
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          appCode
          source
          created
          status
          store {
            storeUrl
            id
            storeName
          }
          discount {
            amount
          }
          fulfillments {
            status
            updatedAt
            updatedBy {
              email
            }
            lines {
              id
              note
              orderLine {
                id
                trackingUrl
                productName
                commission
                metadata {
                  key
                  value
                }
              }
            }
          }
          lines {
            id
            cod
            productName
            quantity
            discountedPrice
            brandOrderStatus
            shippingPrice
            thumbnail {
              url
              alt
            }
            totalPrice {
              currency
              net {
                amount
              }
            }
            variant {
              costPrice {
                amount
              }
              price {
                amount
              }
              pricing {
                price {
                  net {
                    amount
                  }
                  gross {
                    amount
                  }
                }
              }

              product {
                brand {
                  id
                  brandName
                }
              }
            }
          }
          voucher {
            name
            code
          }
          billingAddress {
            firstName
            streetAddress1
            city
            postalCode
          }
        }
      }
    }
  }
`;

export const GET_MASTER_DASHBOARD_KPIS = gql`
  query MasterDashboardKpi(
    $timePeriod: TimePeriod
    $brandIds: [ID]
    $orderStatus: [FulfillmentStatusEnum]
  ) {
    masterDashboardKpi(
      timePeriod: $timePeriod
      brandIds: $brandIds
      orderStatus: $orderStatus
    ) {
      gmv
      productsSold
      influencerEarning
      completedSourcingRequests
      numberOfBotdtruebrands
      delayedOrderCount
      storeGmv
    }
  }
`;
