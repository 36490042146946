import { gql } from "@apollo/client";

export const STAFF_MANAGER_EDIT = gql`
  mutation StaffManagerEdit($id: ID!, $staff: [ID]!) {
    brandManagerCreate(
      input: { brand: $id, users: $staff }
    ) {
      brand {
        staffMembers(first: 10) {
          edges {
            node {
              id
              email
              mobileNo
            }
          }
        }
      }
    }
  }
`;
