import styled, { keyframes } from "styled-components";

export const Label = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 1rem;
`;

export const TextLabel = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.8rem;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const TextField = styled.textarea`
  max-width: 100%;
  width: 80%;
  padding: 8px;
  margin-top: 10px;
`;

export const SpinContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 45px 0px 10px 40px;
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid #000;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;