import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  /* border: 1px solid black; */
  display: flex;
  background-color: #fafafa;
  border-radius: 10px;
  border: 1px solid #ddd;
  max-width: 100%;
`;

export const Left = styled.div`
  display: grid;
  place-items: center;
  padding: 1rem;
`;
export const Circle = styled.div`
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  background-color: black;
  color: white;
  display: grid;
  place-items: center;

  p {
    font-size: 10px;
  }
`;
export const Value = styled.div`
  display: grid;
  place-items: center;
`;
export const Divide = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
export const Cut = styled.div`
  height: 2rem;
  width: 2rem;
  border-radius: 1000px;
  background-color: white;
  top: ${({ bottom }) => bottom && "-1rem"};
  bottom: ${({ bottom }) => !bottom && "-1rem"};
  border-bottom: 1px solid ${(props) => (props.bottom ? "#ddd" : "transparent")};
  border-top: 1px solid ${(props) => (!props.bottom ? "#ddd" : "transparent")};
  /* border-top: 1px solid #f1f1f1; */
  position: absolute;
`;
export const DivideLine = styled.div`
  height: 100%;
  width: 0.1px;
  border-left: 2px dashed #aaa;
`;
export const Right = styled.div`
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 0.5rem 1rem 0.5rem 1.5rem;
`;
export const Details = styled.div`
  display: grid;
  gap: 0.2rem;
`;
export const Name = styled.div`
  font-size: 14px;
  font-weight: 600;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export const Table = styled.table`
  /* padding: 0.5rem 0; */
`;
export const Row = styled.tr``;
export const RowHeading = styled.td`
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
  color: gray;
`;
export const RowValue = styled.td`
  padding-right: 0.5rem;
  padding-bottom: 0.2rem;
  font-weight: 600;
  overflow: hidden;
  max-width: 130px;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const Status = styled.p`
  background-color: ${({ active }) => (active ? "#00a362" : "#FC0000")};
  border-radius: 5px;
  color: white;
  padding: 0.25rem 0.5rem;
  font-weight: 700;
  width: fit-content;
  margin-left: auto;
`;

export const StatusDetails = styled.div`
  display: flex;
  span {
    font-size: 0.8rem;
    line-height: 30px;
    font-weight: 500;
  }
`;
