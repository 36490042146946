import * as S from "./styles";

import CopyToClipboard from "react-copy-to-clipboard";
import { INITIATE_REFUND } from "./mutations";
import OrderModal from "./OrderModal";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import moment from "moment";

const OrderCard = ({
  storeName,
  storeUrl,
  imageUrl,
  quantity,
  brandName,
  price,
  productName,
  variantName,
  id,
  createdAt,
  fulfilmentStatus,
  shippingId,
  shippingProvider,
  voucherName,
  refundStatus,
  refundAmount,
  lineId,
  cod,
  refundCreationTime,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [initiateRefund] = useMutation(INITIATE_REFUND);
  const [refundButtonName, setRefundButtonName] = useState("Initiate Refund");
  const refundHandler = async () => {
    const res = await initiateRefund({
      variables: {
        id: lineId,
      },
    });
    if (res.data.refundOrderline.success === null) {
      toast.error("Refund initiation failed!");
    } else if (res.data.refundOrderline.success === true) {
      toast.success("Refund initation successful!");
      window.location.reload();
      setRefundButtonName("Refund Initiated");
    } else if (res.data.refundOrderline.success === false) {
      toast.error("Refund initiation failed!");
    }
  };
  const refundCreationFormatted =
    moment(refundCreationTime).format("DD/MM/YYYY hh:mm");
  return (
    <S.Card>
      <S.CardMain>
        <S.Left>
          <S.Image src={imageUrl} />
          <S.NoOfItems>{quantity}</S.NoOfItems>
        </S.Left>

        <S.Right>
          <S.CardTop>
            <S.CardHeading>{brandName}</S.CardHeading>
            <S.Price>₹{price}</S.Price>
          </S.CardTop>
          <S.CardMid>
            <S.CardText>{productName}</S.CardText>
          </S.CardMid>
          <S.CardBottom>
            <S.BottomDetails>
              <S.BottomDetailsCard>
                <S.PropName>Size: </S.PropName>
                <S.PropValue>{variantName}</S.PropValue>
              </S.BottomDetailsCard>
              <CopyToClipboard
                onCopy={() => toast.success("URL Copied")}
                text={storeUrl}
              >
                <S.BottomDetailsCard>
                  <S.PropName>{storeName}</S.PropName>
                </S.BottomDetailsCard>
              </CopyToClipboard>
              {cod ? (
                <S.BottomDetailsCard>
                  <S.PropName>COD</S.PropName>
                </S.BottomDetailsCard>
              ) : null}
              {!!refundCreationTime ? (
                <S.BottomDetailsCard>
                  <S.PropName>
                    Refund Creation Time: {refundCreationFormatted}
                  </S.PropName>
                </S.BottomDetailsCard>
              ) : null}
              {voucherName && (
                <S.BottomDetailsCard>
                  <S.PropName>Voucher: {voucherName}</S.PropName>
                </S.BottomDetailsCard>
              )}

              {/* //Different RefundStatus having different button names */}
              {refundStatus === null ? (
                (fulfilmentStatus === "CANCELLATION_INITIATED" && (
                  <S.InitiateRefund onClick={refundHandler}>
                    {refundButtonName}
                  </S.InitiateRefund>
                )) ||
                (fulfilmentStatus === "RETURN_INITIATED" && (
                  <S.InitiateRefund onClick={refundHandler}>
                    {refundButtonName}
                  </S.InitiateRefund>
                ))
              ) : (
                <S.RefundInitiated>
                  {refundStatus[0].toUpperCase() + refundStatus.slice(1)}
                </S.RefundInitiated>
              )}
              {refundAmount && (
                <S.RefundAmount>Refund Amount: ₹{refundAmount}</S.RefundAmount>
              )}
            </S.BottomDetails>
            <S.ViewDetailsButton onClick={() => setShowModal(true)}>
              View Details
            </S.ViewDetailsButton>
          </S.CardBottom>
        </S.Right>
      </S.CardMain>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => {
          setShowModal(false);
        }}
        style={S.Modal}
      >
        <OrderModal
          setShowModal={setShowModal}
          id={id}
          brandName={brandName}
          productName={productName}
          createdAt={createdAt}
          fulfilmentStatus={fulfilmentStatus}
          shippingId={shippingId}
          shippingProvider={shippingProvider}
        />
      </ReactModal>
    </S.Card>
  );
};

export default OrderCard;
