import * as S from "./styles";

import { useEffect, useState } from "react";

import { GET_ORDERS } from "./queries";
import Layout from "../../components/Layout";
import OrderCard from "./OrderCard";
import PacmanLoader from "../../components/PackmanLoader";
import Search from "./Search";
import { useLazyQuery } from "@apollo/client";
import { useSearchParams } from "react-router-dom";

const Refund = () => {
  const [searchParams] = useSearchParams();
  //search input from user
  const [searchString, setSearchString] = useState("");
  //check for seeing if previous string is changed or not
  const [checkString, setCheckString] = useState("");
  //current page - pagination
  const [page, setPage] = useState(1);
  //array of data recieved from gql query for pagination
  const [parsedData, setParsedData] = useState([]);
  //fetching orders
  const [getOrders, { data, loading, fetchMore }] = useLazyQuery(GET_ORDERS, {
    notifyOnNetworkStatusChange: true,
  });

  //on search button click query the orders
  const searchData = () => {
    //check so that 2 different orders arent merged
    if (checkString !== searchString) {
      setParsedData([]);
      setPage(1);
      setCheckString(searchString);
    }
    getOrders({
      variables: {
        searchString: searchString === "" ? "null" : searchString,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  //checking if data object is already inside the parsedData array
  const isPresent = (value, data) => {
    return data?.orders?.pageInfo?.startCursor !== parsedData[value]?.orders?.pageInfo?.startCursor;
  };

  //adding data to parsedData
  const setData = (data) => {
    setParsedData((prev) => {
      return [...prev, data];
    });
  };

  useEffect(() => {
    if (data?.orders?.edges?.length > 0) {
      if (isPresent(page - 1, data)) setData(data);
    } else {
      setParsedData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const keyPress = (e) => {
    if (e.key === "Enter") searchData();
  };

  useEffect(() => {
    document.addEventListener("keydown", keyPress);
    return () => {
      document.removeEventListener("keydown", keyPress);
    };
  }, [keyPress]);

  useEffect(() => {
    const orderId = searchParams.get("order_id");
    if (orderId)
      getOrders({
        variables: {
          searchString: orderId,
        },
      });
  }, []);

  if (loading) {
    return <PacmanLoader />;
  }

  return (
    <Layout
      hideBack
      title="Customer Support"
      pagination={{
        hasNext: parsedData[page - 1]?.orders?.pageInfo?.hasNextPage,
        current: page,
        onNextClick: async () => {
          const res = await fetchMore({
            variables: {
              endCursor: parsedData[page - 1]?.orders?.pageInfo?.endCursor,
            },
          });
          if (isPresent(page, res?.data)) {
            setData(res?.data);
            setPage(page + 1);
          } else {
            setPage(page + 1);
          }
        },
        onPrevClick: () => setPage(page - 1),
      }}
    >
      <div
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
        }}
      >
        <S.SearchInput
          value={searchString}
          onChange={(e) => {
            setSearchString(e.target.value);
          }}
          placeholder="91XXXXXXXXXX or mail@example.com"
        />
        <div onClick={() => searchData()} style={{ position: "absolute", right: "15px" }}>
          <Search />
        </div>
      </div>
      {loading ? null : parsedData.length === 0 ? (
        <S.NoResult>No Results Found</S.NoResult>
      ) : (
        parsedData[page - 1]?.orders?.edges.map((order, index) => {
          // console.log(order)
          return (
            <S.Order key={index}>
              <S.Top>
                <S.Dot />
                <S.Orderid>
                  Order ID:{" "}
                  <span
                    style={{
                      color:
                        (searchParams.get("order_id") === order?.node?.id ||
                          searchString === order?.node?.id) &&
                        "#3EC70B",
                    }}
                  >
                    {order?.node?.id}
                  </span>
                </S.Orderid>
              </S.Top>
              <S.DetailsBorder>
                <S.OrderInfo>
                  Name:&nbsp;{order?.node?.shippingAddress?.firstName ?? "--"}
                  {", "}
                  Email:&nbsp;{order?.node?.shippingAddress?.email ?? "--"}, No:&nbsp;
                  {order?.node?.shippingAddress?.phone ?? "--"}
                  {", "}
                  Address:&nbsp;
                  {order?.node?.shippingAddress?.streetAddress1 ?? "--"}
                  {", "}
                  PC:&nbsp;{order?.node?.shippingAddress?.postalCode ?? "--"}
                  {", "}
                  City:&nbsp;{order?.node?.shippingAddress?.city ?? "--"}&nbsp;
                  {order?.node?.shippingAddress?.cityArea ?? "--"}
                </S.OrderInfo>
              </S.DetailsBorder>
              {order?.node?.lines?.map((item, i) => {
                // console.log(item, 'item')
                return (
                  <OrderCard
                    key={i}
                    storeName={order?.node?.store?.storeName}
                    storeUrl={order?.node?.store?.storeUrl}
                    id={order?.node?.id}
                    imageUrl={item?.thumbnail?.url}
                    price={item?.totalPrice?.net?.amount}
                    variantName={item?.variantName}
                    quantity={item?.quantity}
                    productName={item?.productName}
                    brandName={item?.brandName}
                    createdAt={order?.node?.created}
                    fulfilmentStatus={item?.fulfilment?.status}
                    shippingId={
                      item?.fulfilment?.shippingFulfillment?.shippingId
                    }
                    shippingProvider={
                      item?.fulfilment?.shippingFulfillment?.shippingProvider
                    }
                    voucherName={order?.node?.voucher?.name}
                    refundStatus={item?.refundStatus}
                    refundAmount={item?.refundAmount}
                    lineId={item?.id}
                    cod={item?.cod}
                    refundCreationTime={item?.refundCreationTime}
                  />
                );
              })}
            </S.Order>
          );
        })
      )}
    </Layout>
  );
};

export default Refund;
