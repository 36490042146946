import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { COD_UPDATE } from "./queries";
import { Input, Wrapper } from "./style";

const codData = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

export const CodModal = ({ id, setModal, cod, price, refetch }) => {
  const [codValue, setCod] = useState(cod ? codData[0] : codData[1]);
  const [codPrice, setCodPrice] = useState(price ?? 0);

  const [updateCod, { data, loading, error }] = useMutation(COD_UPDATE);

  const handleSubmit = async () => {
    const res = await updateCod({
      variables: {
        id: id,
        cod: codValue.value,
        codBasePrice: codPrice,
      },
    });
    if (res?.data?.brandUpdate) {
      toast.success("Update successful");
      setModal(false);
      refetch();
    } else {
      toast.error("Something went wrong");
    }
  };
  return (
    <Wrapper>
      <h2>Add COD</h2>
      <Dropdown
        value="value"
        label="label"
        defaultValue={codValue}
        data={codData}
        style={{ margin: "10px 0px 10px" }}
        onChange={(e) => setCod(e)}
      />
      <h2>COD Price</h2>
      <Input
        type="number"
        value={codPrice}
        onChange={(e) => setCodPrice(e.target.value)}
      />
      <Button
        disabled={codValue.value === cod && codPrice == price}
        onClick={handleSubmit}
      >
        Update
      </Button>
    </Wrapper>
  );
};
