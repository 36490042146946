import styled, { css } from "styled-components";

export const Wrapper = styled.div`
  padding: 1px;
  box-sizing: content-box;
  width: 20px;
  background-color: ${(props) => (props.checked ? "#02C2D5" : "gray")};
  border-radius: 100px;
  /* display: flex;
  align-items: center; */
  justify-content: ${(props) => (props.checked ? "flex-end" : "flex-start")};
`;

export const Thumb = styled.div`
  border-radius: 100;
  background-color: white;
  border-radius: 100px;
  height: 10px;
  width: 10px;
  box-sizing: content-box;
  border: 1px solid gray;
  transition: 400ms ease-in-out;
  transform: translateY(-0.5px);
  margin-left: 0;

  ${(props) => {
    if (props.checked) {
      return css`
        margin-left: 100%;
        transform: translateY(-0.5px) translateX(-100%);
      `;
    }
  }}

  &:hover {
    border-color: #02c2d5;
  }
`;
