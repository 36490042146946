import { gql } from "@apollo/client";

export const CHANGE_VISIBILITY = gql`
  mutation pdpPublishProduct(
    $isPublished: Boolean
    $productIdBrand: String
    $variantIdBrands: [String]
    $brandId: ID
    $categoryId: ID
    $commission: Float
    $valueDeal: Boolean
  ) {
    pdpPublishProduct(
      productIdBrand: $productIdBrand
      variantIdBrands: $variantIdBrands
      brandId: $brandId
      toPublish: $isPublished
      categoryId: $categoryId
      commission: $commission
      valueDeal: $valueDeal
    ) {
      success
      message
    }
  }
`;

export const UPDATE_INVENTORY = gql`
  mutation updateInventory($id: ID!, $stock: Int!) {
    variantInventoryUpdate(input: [{ variantId: $id, stock: $stock }]) {
      success
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation updateCategory($productId: ID!, $brandId: ID!, $category: ID!) {
    productUpdate(id: $productId, input: { brand: $brandId, category: $category }) {
      product {
        name
        category {
          name
        }
      }
    }
  }
`;

export const UPDATE_VALUE_DEAL = gql`
  mutation updateValueDeal($productId: ID!, $brandId: ID!, $valueDeal: Boolean) {
    productUpdate(id: $productId, input: { brand: $brandId, valueDeal: $valueDeal }) {
      product {
        name
      }
    }
  }
`;

export const UPDATE_COMMISSION = gql`
  mutation updateCommission($productId: ID!, $brandId: ID!, $commission: Float!) {
    productUpdate(
      id: $productId
      input: { brand: $brandId, customCommissionPercentage: $commission }
    ) {
      product {
        name
        commissionPercentage
        category {
          name
        }
      }
    }
  }
`;

export const TOGGLE_BARTER = gql`
  mutation toggleBarter($productId: ID!, $brandId: ID!, $barter: BarterTypeEnum) {
    productUpdate(id: $productId, input: { brand: $brandId, brandBarter: $barter }) {
      product {
        name
        brandBarter
        category {
          name
        }
      }
    }
  }
`;

export const TAG_UPDATE = gql`
  mutation UpdateProductTags($productId: ID!, $tags: [ID]!) {
    productTagUpdate(productId: $productId, tags: $tags) {
      errors {
        message
        field
      }
      productTagMapping {
        productTag {
          id
          name
        }
      }
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($id: ID!) {
    productImageDelete(id: $id) {
      product {
        id
        name
      }
      image {
        url
      }
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($id: ID!, $brand: ID!, $name: String) {
    productUpdate(id: $id, input: { brand: $brand, name: $name }) {
      product {
        name
      }
    }
  }
`;