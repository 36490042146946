import { useLazyQuery } from "@apollo/client";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import * as S from "../EditBrand/styles";
import { GET_USER_DATA } from "../queries";

export const DownloadCsv = ({ setModal }) => {
  const [weekDay, setWeekDay] = useState(null);

  const [tokenVerify, { loading, error }] = useLazyQuery(GET_USER_DATA);

  const days = [
    { label: "1", value: 1 },
    { label: "2", value: 2 },
    { label: "3", value: 3 },
    { label: "4", value: 4 },
    { label: "5", value: 5 },
    { label: "6", value: 6 },
    { label: "7", value: 7 },
  ];
  const handleClick = async () => {
    const mobileNo = localStorage.getItem("analytics_mobileNo");
    if (mobileNo) {
      const { data } = await tokenVerify({
        variables: {
          mobileNo: mobileNo,
        },
      });
      const id = data?.userByMobile?.id;
      const res = await fetch(
        `${process.env.REACT_APP_GRAPHQL_BASE_URL.replace(
          "/graphql/",
          ""
        )}/csv/export_csv_for_brand_owners?user_id=${id}&days=${weekDay}`
      );
      const jsonRes = await res.json();
      if (jsonRes?.Success) {
        toast.success("An email will be sent to you shortly");
      } else {
        toast.error("Something went wrong");
      }
      setModal(false);
    }
  };
  return (
    <div>
      <S.Label>Create Brand</S.Label>
      <div>
        <S.TextLabel>Select Day</S.TextLabel>
        <Dropdown
          style={{ margin: "5px 0px", border: "1px solid" }}
          data={days}
          value="value"
          label="label"
          onChange={(e) => setWeekDay(e.value)}
        />
      </div>
      <Button
        disabled={weekDay === null}
        onClick={() => handleClick()}
        style={{ marginTop: "12px" }}
      >
        Save
      </Button>
    </div>
  );
};
