import { gql } from "@apollo/client";

export const GET_USER_STORES = gql`
  query UserByMobile($mobileNo: String!) {
    userByMobile(mobileNo: $mobileNo) {
      id
      isActive
      authorisedStores(storeType: INFLUENCER) {
        id
        storeName
      }
    }
  }
`;

export const GET_STORE_ANALYTICS = gql`
  query StoreAnalytics(
    $stores: [ID]
    $timePeriod: TimePeriod
    $endCursor: String
    $direction: OrderDirection!
    $field: StoreAnalyticsSortField!
    $storeStatus: [StoreStatusEnum]
    $storeAction: [StoreNextActionsEnum]
  ) {
    storeAnalytics(
      first: 5
      filter: {
        stores: $stores
        storeStatus: $storeStatus
        storeNextActions: $storeAction
      }
      timePeriod: $timePeriod
      after: $endCursor
      sortBy: { direction: $direction, field: $field }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          actions {
            id
            nextActions
            status
          }
          cities
          lastLoginTime
          noOfSourcingRequestsInitiated
          noOfSourcingRequestsBrandFullfilled
          last7DaysTotalOrders
          last7DaysTotalVisitors
          last7DaysPdpViews
          last7DaysA2c
          last30DaysTotalStoreSales
          last30DaysPdpViews
          storeId
          createdAt
          storeName
          storeUrl
          totalVisitors
          totalProductPageViews
          totalCollectionViews
          wishlistOfProducts
          totalOrders
          averageOrderValue
          averageNumberOfItemsInOrder
          averageAbandonedCart
          noOfCouponsUsed
          totalStoreSales
          totalEarnings
          lastPayout
          netPayoutDue
          totalMspSales
          numberOfProductsSold
          totalBrandClicks
          totalCategoryClicks
          myOrders
          productLastAdded
          appLastLaunchedAt
        }
      }
    }
  }
`;
