import * as S from "./styles";

function Details(props) {
  return (
    <S.Details>
      <S.Col>
        <S.Cell>
          <S.Value>{props.visits ?? "--"}</S.Value>
          <S.Small>visits</S.Small>
        </S.Cell>
        <S.Cell>
          <S.Value>₹{props.msp}</S.Value>
          <S.Small>MSP</S.Small>
        </S.Cell>
      </S.Col>
      <S.Col style={{ flex: 1 }}>
        <S.Cell>
          <S.Value>{props.wishlistCount}</S.Value>
          <S.Small>Whishlists</S.Small>
        </S.Cell>
        <S.Cell>
          <S.Value>{props.latestPurchaseDate}</S.Value>
          <S.Small>
            Last purchase <br /> date
          </S.Small>
        </S.Cell>
      </S.Col>
      <S.Col>
        <S.Cell>
          <S.Value>{props.purchaseCount}</S.Value>
          <S.Small>Purchase count</S.Small>
        </S.Cell>

        <S.Cell>
          <S.Value>21-Nov</S.Value>
          <S.Small>
            Addition of <br /> product date
          </S.Small>
        </S.Cell>
      </S.Col>
    </S.Details>
  );
}

export default Details;
