import { gql } from "@apollo/client";

export const ORDER_STATUS = gql`
  mutation OrderStatus(
    $id: ID!
    $warehouseId: ID!
    $fulfillmentStatus: FulfillmentStatusEnum!
    $shippingId: String
    $shippingProvider: String
  ) {
    updateFulfillment(
      id: $id
      input: {
        warehouseId: $warehouseId
        fulfillmentStatus: $fulfillmentStatus
        shippingId: $shippingId
        shippingProvider: $shippingProvider
      }
    ) {
      fulfillment {
        status
        trackingNumber
      }
      orderErrors {
        code
        field
        message
      }
    }
  }
`;

export const UPDATE_METADATA = gql`
  mutation UpdateMetadata($id: ID!, $key: String!, $value: String!) {
    updateMetadata(id: $id, input: { key: $key, value: $value }) {
      metadataErrors {
        field
        message
      }
      item {
        metadata {
          key
          value
        }
      }
    }
  }
`;
