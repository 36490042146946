import { gql } from "@apollo/client";

export const GET_STORES = gql`
  query GetStores(
    $barter: Boolean
    $dir: OrderDirection!
    $field: StoreSortField!
    $search: String
    $endCursor: String
    $status: [StoreStatusEnum]
    $managerEmail: [String]
    $nextActions: [StoreNextActionsEnum]
  ) {
    stores(
      first: 100
      after: $endCursor
      sortBy: { direction: $dir, field: $field }
      filter: {
        storeBarter: $barter
        search: $search
        managerEmail: $managerEmail
        status: $status
        nextActions: $nextActions
      }
    ) {
      edges {
        node {
          id
          storeName
          content
          storeUrl
          description
          state
          slug
          metadata
          storeType
          storeCategoryPageLevel
          storeManagers {
            id
            email
          }
          actions {
            id
            status
            nextActions
          }
          streakLive
          instagramLink
          stopSourceWithZaamo
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
