import Dropdown from "../../../components/Dropdown";

export default function SelectValues({ data, type, val, onChange }) {
  return (
    <Dropdown
      style={{ margin: "5px 0px", border: "1px solid" }}
      data={data}
      value="value"
      label="label"
      onChange={(e) => onChange(e, type)}
      defaultValue={val}
    />
  );
}
