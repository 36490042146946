import { gql } from "@apollo/client";

export const USER = gql`
  query USER($brandId: ID!) {
    brand(id: $brandId) {
      mobiles {
        mobileNo
        type
        active
        userName
        id
      }
    }
  }
`;
export const USER_BY_MOBILE = gql`
  query USER_BY_MOBILE($mobile: String!){
    userByMobile(mobileNo: $mobile) {
      firstName
      lastName
      userId
      isActive
    }
  }
`;
// export const USER = gql`
//   query User($no: String!) {
//     userByMobile(mobileNo: $no) {
//       isActive
//       authorisedBrands {
//         id
//         brandName
//       }
//     }
//   }
// `;
