import { useMutation } from "@apollo/client";
import { includes } from "lodash";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";
import Dropdown from "../../components/Dropdown";
import { UPDATE_CONTENT_AND_STATUS } from "./mutations";
import { GET_SOURCING_REQUESTS } from "./queries";
import * as S from "./styles";

const UpdateContentStatus = ({ modalData }) => {
  const [content, setContent] = useState("");
  const [status, setStatus] = useState({});
  const [updateContentStatus] = useMutation(UPDATE_CONTENT_AND_STATUS);

  const getContent = () => {
    const date = new Date();
    const dateString = `[${String(date)
      .split(" ")
      .slice(0, 5)
      .join(" ")} ]: zaamo_dashboard_update:`;

    const totalContent = content
      ? `${
          modalData?.content ? modalData?.content : ""
        }\n\n${dateString} ${content}`
      : modalData?.content
      ? modalData?.content
      : "";
    return totalContent;
  };

  useEffect(() => {
    if (modalData) {
      setStatus({
        value: modalData?.status,
        label: STATUS_ENUM[modalData?.status],
      });
    }
  }, [modalData]);

  const handleSave = async () => {
    if (!content && status?.value === modalData?.status) return;
    const totalContent = getContent();
    const { data: updateData } = await updateContentStatus({
      variables: {
        id: modalData.id,
        status: status?.value ?? "",
        content: totalContent,
      },
    });
    if (updateData?.sourcingRequestUpdate?.sourcingRequest) {
      let temp = { ...modalData.statusContentDict };
      temp[modalData.id] = {
        status: updateData?.sourcingRequestUpdate?.sourcingRequest.status,
        content: updateData?.sourcingRequestUpdate?.sourcingRequest.content,
      };
      modalData.setStatusContentDict(temp);
      modalData.setModalShow(false);
      toast.success("Update successful");
    } else {
      toast.error("Something went wrong");
    }
    client.refetchQueries({
      include: [GET_SOURCING_REQUESTS],
    });
  };

  return (
    <>
      <S.Label>Select Status</S.Label>
      <Dropdown
        onChange={(e) => setStatus(e)}
        data={STATUS_ARRAY}
        label="label"
        value="value"
        defaultValue={status}
      />
      <S.Label>Content</S.Label>
      <S.TextFieldReadOnly readOnly value={modalData?.content ?? ""} />
      <S.Label>Add Content</S.Label>
      <S.TextField
        onChange={(e) => setContent(e.target.value)}
        value={content ?? ""}
      />
      <br />
      <S.Button
        style={{
          backgroundColor:
            content || status?.value !== modalData?.status ? "#111" : "#555",
        }}
        onClick={handleSave}
      >
        Save
      </S.Button>
    </>
  );
};

export const STATUS_ARRAY = [
  {
    value: "REQUEST_RECEIVED",
    label: "Request received",
  },
  {
    value: "BRAND_SHARED_DELIVERABLES",
    label: "Brand Shared Deliverables",
  },
  {
    value: "BRAND_WILL_CONTACT_THE_INFLUENCER",
    label: "Brand will contact the influencer",
  },
  {
    value: "BRAND_COLLABORATION_APPROVED",
    label: "Brand collaboration approved",
  },
  {
    value: "BRAND_COUPON_CREATED",
    label: "Brand coupon created",
  },
  {
    value: "BRAND_NOT_INTERESTED",
    label: "Brand not interested",
  },
  {
    value: "ZAAMO_WILL_FULFILL_REQUEST",
    label: "Zaamo will fulfill request",
  },
  {
    value: "ZAAMO_COUPON_CREATED",
    label: "Zaamo coupon created",
  },
  {
    value: "ZAAMO_NOT_INTERESTED",
    label: "Zaamo not interested",
  },
  {
    value: "INFLUENCER_CONTENT_CREATED_FOR_ZAAMO",
    label: "Influencer content created for Zaamo",
  },
  {
    value: "INFLUENCER_CONTENT_CREATED_FOR_BRAND",
    label: "Influencer content created for Brand",
  },
  {
    value: "INFLUENCER_HAS_SHARED_THE_DELIVERABLES",
    label: "Influencer has shared the deliverables",
  },
  {
    value: "PRODUCT_EXCHANGE_OR_RETURN_REQUESTED",
    label: "Product exchange or return requested",
  },
  {
    value: "REQUEST_CANCELLED_BY_INFLUENCER",
    label: "Request cancelled by influencer",
  },
  { label: "Brand Coupon Clubbed", value: "BRAND_COUPON_CLUBBED" },
  { label: "Influencer Hold", value: "INFLUENCER_HOLD" },
  { label: "Brand Hold", value: "BRAND_HOLD" },
  { label: "Request Clubbed", value: "REQUEST_CLUBBED" },
  {
    label: "Coupon cancelled by influencer",
    value: "COUPON_CANCELLED_BY_INFLUENCER",
  },
  { label: "Coupon cancelled by brand", value: "COUPON_CANCELLED_BY_BRAND" },
  { label: "Request Expired", value: "REQUEST_EXPIRED" },
];

const STATUS_ENUM = {
  REQUEST_RECEIVED: "request received",
  BRAND_CONTACT_FOR_SOURCING: "brand contact for sourcing",
  BRAND_WILL_CONTACT_THE_INFLUENCER: "brand will contact the influencer",
  BRAND_COLLABORATION_APPROVED: "brand collaboration approved",
  BRAND_COUPON_CREATED: "brand coupon created",
  BRAND_NOT_INTERESTED: "brand not interested",
  ZAAMO_WILL_FULFILL_REQUEST: "zaamo will fulfill request",
  ZAAMO_COUPON_CREATED: "zaamo coupon created",
  ZAAMO_NOT_INTERESTED: "zaamo not interested",
  INFLUENCER_CONTENT_CREATED: "influencer content created",
  PRODUCT_EXCHANGE_OR_RETURN_REQUESTED: "product exchange or return requested",
  REQUEST_CANCELLED_BY_INFLUENCER: "request cancelled by influencer",
};

export default UpdateContentStatus;
