import Dropdown from "../../../../components/Dropdown";
import * as S from "./styles";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";
import { useRef, useState } from "react";
import { toast } from "react-toastify";

const Filters = (props) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const dateRef = useRef(null);

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Select Brand</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={props?.data?.authorisedBrands}
            value="id"
            label="brandName"
            onChange={(e) => props.onChange(e, "BRAND")}
            placeholder="Select Brand"
            defaultValue={props.defaultValue.brand}
            isClearable
          />
        </div>
        <div>
          <label>Type of Content</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={contents}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "CONT")}
            placeholder="Type of Content"
            defaultValue={props.defaultValue.typeOfContent}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "DIR")}
            placeholder="Direction"
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "SORT")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.sortBy}
          />
        </div>
        <div>
          <label>Time Period</label>
          <br />
          <div>
            <Flatpickr
              options={{ mode: "range" }}
              style={{ padding: "8px", margin: "10px 12px 12px 5px" }}
              ref={dateRef}
              placeholder="Select Range"
              onChange={(e) => {
                setStartDate(moment(e[0]).format("YYYY-MM-DD"));
                setEndDate(moment(e[1]).format("YYYY-MM-DD"));
              }}
            />
            <span
              onClick={() => {
                if (startDate !== "" && endDate !== "") {
                  props.onChange({ startDate, endDate }, "TIME");
                } else {
                  toast.warning("Please select a date range");
                }
              }}
              style={{
                position: "relative",
                bottom: "7px",
                marginRight: "10px",
              }}
            >
              Apply
            </span>
          </div>
        </div>
        <span
          className="clear"
          onClick={() => {
            props.clearAll();
            dateRef.current?.flatpickr.clear();
          }}
        >
          Clear All
        </span>
      </S.Flex>
    </S.Div>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Total Views",
    value: "total_views",
  },
  {
    label: "Avg Watch Time",
    value: "avg_watch_time",
  },
  {
    label: "Total Stream Time",
    value: "total_stream_time",
  },
  {
    label: "Total View Button Clicks",
    value: "total_view_button_clicks",
  },
];

const contents = [
  { label: "Brand Reel", value: "brand_reel" },
  { label: "PDP Video", value: "pdp_video" },
  { label: "Collection Content", value: "collection_content" },
];

export default Filters;
