import styled from "styled-components";

export const Label = styled.h5`
  color: grey;
  padding-bottom: 5px;
`;

export const Contain = styled.div`
  display: grid;
  width: 18%;
  margin-right: 20px;
`;

export const Input = styled.input`
  padding: 10px;
`;

export const BtnStyle = { width: "fit-content", padding: "5px 30px", position: "relative", top: 7 };
