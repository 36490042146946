import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { UPDATE_PRODUCT } from "./mutations";
import * as S from "./styles";

export default function EditProduct({ product, close }) {
  const [productInfo, setProductInfo] = useState({
    name: product?.name,
  });

  const handleEdit = async () => {
    const res = await editProduct({
      variables: {
        id: product?.productZaamoId,
        brand: product?.brandId,
        name: productInfo?.name,
      },
    });
    if (res.data?.productUpdate?.product?.name) {
      toast.success("Product Edited Successfully!");
      close();
    }
  };

  const [editProduct, { error }] = useMutation(UPDATE_PRODUCT, {
    refetchQueries: "active"
  });

  if (error) return <p>Something went wrong...</p>;

  return (
    <div>
      <h3 style={{ marginBottom: "20px" }}>Edit Product</h3>
      <h5 style={{ marginBottom: 5 }}>Product Name</h5>
      <input
        value={productInfo?.name}
        style={S.Input}
        onChange={(e) => setProductInfo({ ...productInfo, name: e.target.value })}
      />
      <button
        style={{ margin: "10px 0px", padding: "5px 10px", width: "100%" }}
        disabled={product?.name === productInfo?.name}
        onClick={handleEdit}
      >
        Save
      </button>
    </div>
  );
}
