import { gql } from "@apollo/client";

export const CREATE_BRAND = gql`
  mutation CreateBrand(
    $companyName: String!
    $brandName: String!
    $brandContactName: String!
    $brandContactNumber: String!
    $shortDescription: String
    $sizeFitNote: String
    $preferedPaymentMode: PreferedPaymentModeEnum
    $brandSource: BrandSourceEnum
    $address: AddressInput!
    $returnAddress: AddressInput!
    $shippingReturnPolicy: GenericScalar
    $panNumber: String!
    $botd: Boolean
    $commission: Float
    $tooManyOrders: Boolean
    $brandOrderInfo: String
    $orderProcessingDays: Int
    $orderShippingDays: Int
    $brandBarterGuidelines: String
    $brandBarter: Boolean
  ) {
    brandCreate(
      input: {
        companyName: $companyName
        brandName: $brandName
        brandContactName: $brandContactName
        brandContactNumber: $brandContactNumber
        shortDescription: $shortDescription
        sizeFitNote: $sizeFitNote
        preferedPaymentMode: $preferedPaymentMode
        brandSource: $brandSource
        address: $address
        returnAddress: $returnAddress
        shippingReturnPolicy: $shippingReturnPolicy
        panNumber: $panNumber
        botd: $botd
        commission: $commission
        tooManyOrders: $tooManyOrders
        brandOrderInfo: $brandOrderInfo
        orderProcessingDays: $orderProcessingDays
        orderShippingDays: $orderShippingDays
        brandBarterGuidelines: $brandBarterGuidelines
        brandBarter: $brandBarter
      }
    ) {
      brand {
        id
        companyName
        brandName
        brandContactName
        brandContactNumber
        shortDescription
        sizeFitNote
        preferedPaymentMode
        brandSource
        address {
          firstName
          streetAddress1
          city
          postalCode
        }
        returnAddress {
          firstName
          streetAddress1
          city
          postalCode
        }
        shippingReturnPolicy
        panNumber
        botd
        commission
        tooManyOrders
        brandOrderInfo
        orderProcessingDays
        orderShippingDays
        brandBarterGuidelines
        brandBarter
      }
    }
  }
`;
