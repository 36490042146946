import { gql } from "@apollo/client";

export const UPDATE_ACTIONS = gql`
  mutation UpdateActions($id: ID, $status: StoreStatusEnum!, $nextActions: StoreNextActionsEnum!) {
    storeManagerActionUpdate(input: { id: $id, status: $status, nextActions: $nextActions }) {
      storeManagerAction {
        nextActions
        status
      }
    }
  }
`;
