import {
  GET_MASTER_DASHBOARD_DATA,
  GET_MASTER_DASHBOARD_KPIS,
  GET_USER_DATA,
} from "./queries";
import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import { GET_PENALTY } from "./mutations";
import moment from "moment";
import { toast } from "react-toastify";
import useAuth from "../../hooks/useAuth/useAuth";
import { useSearchParams } from "react-router-dom";

const useMasterDashboard = () => {
  const [brand, setBrand] = useState("");
  const [stores, setStores] = useState([]);
  const [time, setTime] = useState("");
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });
  const [sortBy, setSortBy] = useState({
    label: "Creation Date",
    value: "CREATION_DATE",
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [page, setPage] = useState(1);
  const [endCursor, setEndCursor] = useState("");
  const [hasNextPage, setHasNextPage] = useState("");
  const [loading3, setLoading3] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [statusDict, setStatusDict] = useState({});
  const [updatedAt, setUpdatedAt] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [voucher, setVoucher] = useState("");
  const [email, setEmail] = useState("");
  const [cod, setCod] = useState(null);
  const [orderPenaltyDict, setOrderPenaltyDict] = useState({});
  const [orderNoteModal, setOrderNoteModal] = useState({ display: false });
  const [freshDeskTicketModal, setFreshDeskTicketModal] = useState({
    display: false,
  });

  const [searchParams] = useSearchParams();

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const {
    data: data2,
    loading: loading2,
    error: error2,
    fetchMore,
    refetch,
  } = useQuery(GET_MASTER_DASHBOARD_DATA, {
    notifyOnNetworkStatusChange: true,
    variables: {
      stores: stores?.length ? stores.map((e) => e.value) : [],
      // : data?.userByMobile?.authorisedStores.map(e => e.id),
      timePeriod: time ? time.value : undefined,
      brands: brand ? [brand.value] : [],
      direction: direction?.value,
      sortBy: sortBy?.value,
      status: statusFilter?.map((e) => e.value),
      brandOrderStatus: orderStatus?.value,
      voucher: voucher,
      cod: cod?.value,
      search: email === "" ? searchParams.get("order_id") : email,
    },
  });

  const {
    data: kpiData,
    loading: kpiLoading,
    error: kpiError,
  } = useQuery(GET_MASTER_DASHBOARD_KPIS, {
    variables: {
      timePeriod: time?.value ?? "OVERALL",
      brandIds:
        brand?.value ?? data?.userByMobile?.authorisedBrands?.map((e) => e.id),
    },
    skip: !data,
  });
  const [
    getPenalty,
    { data: penaltyData, loading: penaltyLoading, error: penaltyError },
  ] = useMutation(GET_PENALTY, {
    // refetchQueries: "active",
  });

  const parsedData2 = [];

  useEffect(() => {
    refetch();
    setPage(1);
  }, [
    brand?.value,
    time?.value,
    stores,
    direction?.value,
    sortBy?.value,
    statusFilter,
    orderStatus,
    cod,
    voucher,
    email,
  ]);

  useEffect(() => {
    if (data2?.masterDashboard?.pageInfo) {
      setEndCursor(data2.masterDashboard.pageInfo.endCursor);
      setHasNextPage(data2.masterDashboard.pageInfo.hasNextPage);
      setLoading3(false);
    }
  }, [data2]);

  useEffect(() => {
    const temp = { ...statusDict };
    const tempPenaltyDict = { ...orderPenaltyDict };
    data2?.masterDashboard?.edges.map((e, i) => {
      e?.node?.fulfillments?.map((j) => {
        if (j?.lines[0]?.orderLine && !temp[j.lines[0].orderLine?.id]) {
          temp[j.lines[0].orderLine?.id] = {
            updatedBy: j.updatedBy?.email,
            status: j.status,
            updatedAt: j.updatedAt,
          };
        }
      });
      //
      e?.node?.lines?.map((j) => {
        const order = fetchOrderLineId(e, j?.productName);
        console.log(j, "jhdhkah");
        tempPenaltyDict[order.id] =
          order?.metadata?.filter(
            (item) => item?.key?.toLowerCase() === "penalty"
          ).length > 0
            ? "Penalty Deducted"
            : "Add Penalty";
      });
    });
    setStatusDict(temp);
    setOrderPenaltyDict(tempPenaltyDict);
    // console.log(statusDict, 'status Here')
  }, [data2]);

  const onChange = (value, type) => {
    if (type === "BRAND") setBrand(value);
    else if (type === "STORES") setStores(value);
    else if (type === "TIME") setTime(value);
    else if (type === "DIR") setDirection(value);
    else if (type === "SORT") setSortBy(value);
    else if (type === "STATUS") setStatusFilter(value);
    else if (type === "ORDER_STATUS") setOrderStatus(value);
    else if (type === "VOUCHER") setVoucher(value);
    else if (type === "EMAIL") setEmail(value);
    else if (type === "COD") setCod(value);
  };

  const fetchMoreData = () => {
    data2.masterDashboard.pageInfo.hasNextPage && setLoading3(true);
    data2.masterDashboard.pageInfo.hasNextPage &&
      fetchMore({
        variables: {
          endCursor: endCursor,
        },
      });
    setPage(page + 1);
  };

  const clearAll = () => {
    setBrand("");
    setStores([]);
    setTime("");
    setDirection({ label: "Desc", value: "DESC" });
    setSortBy({
      label: "Creation Date",
      value: "CREATION_DATE",
    });
    setStatusFilter([]);
    setVoucher("");
    setEmail("");
    setCod(null);
  };

  let commissionDict = {};
  const returnParsedData = (e, i) => {
    e?.node?.fulfillments?.map((f) => {
      commissionDict[f?.lines[0]?.orderLine?.id] =
        f?.lines[0]?.orderLine?.commission;
    });
    return {
      fulfillmentLineId: e?.node?.fulfillments[0]?.lines?.[0]?.id,
      orderId: e?.node.id ?? "--",
      orderlineId: e?.node?.fulfillments[0]?.lines[0]?.orderLine?.id,
      store: e.node?.store ?? "--",
      source: e.node?.source ?? "--",
      discountName: e.node.discount.discountName ?? "--",
      voucher: e.node?.voucher?.name ?? "--",
      created: e.node?.created ?? "--",
      customerName: e?.node?.billingAddress?.firstName ?? "--",
      billingAddress: `${e?.node?.billingAddress?.streetAddress1 ?? "--"}, ${
        e?.node?.billingAddress?.city ?? "--"
      }, ${e?.node?.billingAddress?.postalCode ?? "--"}`,
      commission: e?.node?.fulfillments[0]?.lines[0]?.orderLine ?? "--",
      note: e?.node?.fulfillments[0]?.lines[0]?.note,
    };
  };

  const fetchOrderLineId = (data, productName) => {
    let order = {};
    data?.node?.fulfillments?.map((fulfillment) => {
      fulfillment.lines.map((line) => {
        // console.log(line, 'line');
        if (line?.orderLine?.productName === productName)
          order = line?.orderLine;
      });
    });
    return order;
  };

  const returnParsedLines = (data, dataAll, i) => {
    const commonData = { ...returnParsedData(dataAll, i) };
    let temp = [];
    data.map((e, j) => {
      const fulfillmentLine = dataAll?.node?.fulfillments?.find(
        (fulfilment) => fulfilment?.lines?.[0]?.orderLine?.id === e?.id
      );
      const order = fetchOrderLineId(dataAll, e?.productName);
      const orderNote = fulfillmentLine?.lines?.[0]?.note;
      const fulfillmentLineId = fulfillmentLine?.lines?.[0]?.id;

      if (cod === null || e?.cod === cod?.value) {
        temp.push({
          thumbnail: {
            img: e?.thumbnail?.url ?? "/assets/images/dummyLogo.png",
          },
          store: {
            link: {
              name: commonData.store.storeName ?? "#",
              url: commonData.store.storeUrl ?? "#",
            },
          },
          brandName: e?.variant?.product?.brand?.brandName ?? "--",
          productName: e?.productName ?? "--",
          orderId: {
            function: () => {
              setFreshDeskTicketModal({ display: true, id: fulfillmentLineId });
            },
            name: commonData?.orderId,
          },
          source: commonData?.source,
          // ? atob(commonData.orderId).replace("MasterDashboard:", "")
          // : "--"
          quantity: e?.quantity ?? "--",
          MRP: e?.variant?.costPrice?.amount ?? "--",
          MSP: e?.variant?.price?.amount ?? "--",
          discount:
            e?.variant?.costPrice?.amount && e?.variant?.price?.amount
              ? `${(
                  (100 *
                    (e?.variant?.costPrice?.amount -
                      e?.variant?.price?.amount)) /
                  e?.variant?.costPrice?.amount
                ).toFixed(2)}%`
              : "--",
          finalPricePaid: e?.discountedPrice ?? "--",
          COD: e?.cod ? "TRUE" : "FALSE",
          shippingPrice: e?.shippingPrice ?? "--",
          voucher: commonData.voucher,
          // commission: commonData?.commission?.commission
          //   ? `${commonData?.commission?.commission}%`
          //   : "--",
          commission: commissionDict[e?.id] ?? "--",
          brandOrderStatus: e?.brandOrderStatus ?? "--",
          status: {
            function: () =>
              commonData?.orderId
                ? toggleOrderStatusModal({
                    orderId: atob(commonData.orderId).replace(
                      "MasterDashboard:",
                      ""
                    ),
                    id: commonData.orderId,
                    lineId: e?.id,
                  })
                : null,
            name: `${statusDict[e.id]?.status} ${
              statusDict[e.id]?.updatedAt
                ? "updated at " +
                  moment(statusDict[e.id]?.updatedAt).format(
                    "MMMM Do, h:mm:ss a"
                  )
                : ""
            } ${
              statusDict[e.id]?.updatedBy
                ? "by " + statusDict[e.id]?.updatedBy
                : ""
            }`,
          },
          created: commonData.created,
          customerName: commonData.customerName,
          billingAddress: commonData.billingAddress,
          penalty: {
            function: async () => {
              if (
                order?.metadata?.filter(
                  (item) => item?.key?.toLowerCase() === "penalty"
                ).length === 0
              ) {
                const res = await getPenalty({
                  variables: {
                    id: order?.id,
                  },
                });
                if (res?.data?.orderlineMetaUpdate?.item) {
                  toast.success("Penalty Added!");
                  setOrderPenaltyDict({
                    ...orderPenaltyDict,
                    [order?.id]: "Penalty Deducted",
                  });
                } else toast.error("Something went wrong!");
              }
            },
            name: orderPenaltyDict?.[order?.id],
          },
          note: {
            function: () => {
              setOrderNoteModal({
                display: true,
                note: orderNote,
                id: fulfillmentLineId,
              });
            },
            name: orderNote ? (
              <p title={orderNote}>
                {orderNote?.length < 70
                  ? orderNote
                  : orderNote?.slice(0, 70) + "..."}
              </p>
            ) : (
              "--"
            ),
          },
          trackingUrl: {
            link: {
              name: order?.trackingUrl || "NA",
              url: order?.trackingUrl || "#",
            },
          },
        });
      }
    });
    return temp;
  };

  data2?.masterDashboard?.edges.map((e, i) => {
    parsedData2.push(...returnParsedLines(e.node?.lines, e, i));
  });

  const toggleOrderStatusModal = useCallback(
    (data) => {
      setModalShow(true);
      setModalData({
        orderId: data?.orderId,
        orderIdOriginal: data?.id,
        lineId: data?.lineId,
        statusDict: statusDict,
        setStatusDict: setStatusDict,
        setModalShow: setModalShow,
      });
    },
    [modalShow, modalData, statusDict]
  );

  return {
    brand,
    stores,
    time,
    sortBy,
    direction,
    page,
    setPage,
    endCursor,
    hasNextPage,
    loading3,
    data,
    loading,
    error,
    data2,
    loading2,
    error2,
    kpiData,
    kpiLoading,
    kpiError,
    onChange,
    fetchMore,
    fetchMoreData,
    clearAll,
    parsedData2,
    modalData,
    modalShow,
    setModalData,
    setModalShow,
    setStatusDict,
    statusDict,
    statusFilter,
    setStatusFilter,
    orderStatus,
    voucher,
    email,
    cod,
    orderNoteModal,
    setOrderNoteModal,
    freshDeskTicketModal,
    setFreshDeskTicketModal,
  };
};

export default useMasterDashboard;
