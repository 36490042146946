import styled from 'styled-components';

export const TextField = styled.textarea`
  resize: vertical;
  height: 80px;
  max-width: 100%;
  width: 100%;
  padding: 5px;
  margin-bottom: 16px;
`;

export const Heading = styled.h4`
    text-align: center;
    margin-bottom: 16px;
`