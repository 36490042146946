import { useLazyQuery } from "@apollo/client";
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import { FETCH_SOURCING } from "../queries";
import * as S from "../styles";
import ViewOrEdit from "../ViewOrEdit";
import ReactModal from "react-modal";
import OutOfStock from "./OutOfStock";

export default function Sourcing({ user, createCoupon, setErr }) {
  const [couponId, setCouponId] = useState("");
  const [check, setCheck] = useState("");
  const [modal, setModal] = useState(false);
  const [sourcingData, setSourcingData] = useState({
    title: "",
    conditions: "",
    type: "SPECIFIC_PRODUCT",
    discountValueType: "FIXED",
    applyOncePerOrder: true,
    applyOncePerCustomer: true,
    usageLimit: 1,
    owner: "BRAND",
    isShipping: true,
    stores: [],
    products: [],
    storeName: [],
    productName: [],
    brandName: [],
    maxDiscountValue: 0,
    discountValue: 0,
    outOfStock: false,
  });

  //fetch sourcing info
  const [fetchData, { data, loading, error }] = useLazyQuery(FETCH_SOURCING, {
    notifyOnNetworkStatusChange: true,
  });

  const handleCreate = async () => {
    if (sourcingData.outOfStock) return setModal(true);
    const res = await createCoupon({
      variables: {
        ...sourcingData,
        metadata: [
          { key: "title", value: sourcingData?.title },
          {
            key: "conditions",
            value: sourcingData?.conditions,
          },
        ],
      },
    });
    if (res.data?.voucherBulkCreate?.success) {
      toast.success("Coupon Created!");
      reset();
      setCouponId("");
    } else toast.warning("Something went wrong!");
    window.location.reload();
  };

  const fetchSourcing = async () => {
    const coupons = couponId.replace(/,/g, "");
    const ids = coupons.split("_");
    const encoded = ids.map((id) => {
      return window.btoa("SourcingRequest:" + id);
    });
    await fetchData({
      variables: { id: encoded },
    });
  };

  const reset = async () => {
    setSourcingData((prev) => {
      return {
        ...prev,
        name: "",
        title: "",
        conditions: "",
        stores: [],
        products: [],
        storeName: [],
        productName: [],
        discountValue: 0,
        maxDiscountValue: 0,
      };
    });
  };

  useEffect(() => {
    reset();
    if (data) {
      const coupons = couponId.replace(/,/g, "");
      const name = coupons.trim();
      const ids = coupons.split("_");
      const encoded = ids.map((id) => {
        return window.btoa("SourcingRequest:" + id);
      });
      const response = data?.sourcingRequest?.edges;
      const brandShipping = response[0]?.node?.brand?.brandShipping?.edges[0]?.node;
      const maxShipping = Math.max(
        brandShipping?.shippingCostSameStateAmount ?? 0,
        brandShipping?.shippingCostOtherStateAmount ?? 0
      );
      response?.map(({ node }, index) => {
        return setSourcingData((prev) => {
          return {
            ...prev,
            name: "SZ_" + name,
            stores: [response[0]?.node?.store?.id],
            products: [...prev.products, node?.product?.id],
            storeName: [response[0]?.node?.store?.storeName],
            productName: [...prev.productName, node?.product?.name],
            brandName: [response[0]?.node?.brand?.brandName],
            maxDiscountValue:
              prev.maxDiscountValue +
              node?.variant?.price?.amount +
              (index === 0 ? maxShipping : 0),
            discountValue:
              prev.discountValue + node?.variant?.price?.amount + (index === 0 ? maxShipping : 0),
            startDate: moment().toISOString(),
            endDate: moment().add(7, "days").toISOString(),
            applyOncePerOrder: encoded.length > 1 ? false : true,
            outOfStock: node?.variant?.quantityAvailable === 0 || prev.outOfStock ? true : false,
          };
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) setErr(true);

  return (
    <>
      <div style={{ marginTop: "20px", display: "flex", gap: 10 }}>
        <div style={{ width: "100%" }}>
          <h5 style={{ marginBottom: "5px" }}>Enter Sourcing Id</h5>
          <S.Input
            placeholder="Enter a Sourcing ID or Group of Sourcing IDs with _ seprated"
            value={couponId}
            onChange={(e) => setCouponId(e.target.value)}
          />
        </div>
        <Button
          disabled={couponId === check || couponId === ""}
          onClick={async () => {
            fetchSourcing();
            setCheck(couponId);
          }}
          style={{ width: "10%", marginTop: "20px" }}
        >
          Fetch
        </Button>
      </div>
      {loading ? (
        <S.SpinContainer>
          <S.Spinner />
        </S.SpinContainer>
      ) : sourcingData?.name ? (
        <div style={{ marginTop: "10px" }}>
          <S.FlexDiv>
            <ViewOrEdit
              label="Voucher Title"
              value={sourcingData?.title}
              onChange={(e) => setSourcingData((prev) => ({ ...prev, title: e.target.value }))}
            />
            <ViewOrEdit
              label="Voucher Conditions"
              value={sourcingData?.conditions}
              onChange={(e) => setSourcingData((prev) => ({ ...prev, conditions: e.target.value }))}
            />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Coupon Name" value={sourcingData.name} read />
            <ViewOrEdit label="User" value={user?.userByMobile?.email} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Type" value={sourcingData.type} read />
            <ViewOrEdit label="Discount Value Type" value={sourcingData.discountValueType} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Owner" value={sourcingData.owner} read />
            <ViewOrEdit label="Usage Limit" value={sourcingData.usageLimit} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit
              label="Start Date"
              value={moment(sourcingData?.startDate).format("LLLL")}
              read
            />
            <ViewOrEdit
              label="End Date"
              value={moment(sourcingData?.endDate).format("LLLL")}
              read
            />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Discount Value" value={sourcingData.discountValue} read />
            <ViewOrEdit label="Max Discount Value" value={sourcingData.maxDiscountValue} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Stores" value={sourcingData.storeName.join(",")} read />
            <ViewOrEdit label="Products" value={sourcingData.productName.join(",")} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Apply once per Order" value={sourcingData.applyOncePerOrder} read />
            <ViewOrEdit
              label="Apply once per Customer"
              value={sourcingData.applyOncePerCustomer}
              read
            />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Brand Name" value={sourcingData.brandName} read />
            <ViewOrEdit label="Shipping" value={sourcingData.isShipping} read />
          </S.FlexDiv>
          <Button
            disabled={sourcingData?.title === "" || sourcingData?.conditions === ""}
            onClick={handleCreate}
            style={{ marginTop: "10px" }}
          >
            Create
          </Button>
        </div>
      ) : null}
      <ReactModal isOpen={modal} onRequestClose={() => setModal(false)} style={S.Modal}>
        <OutOfStock close={() => setModal(false)} />
      </ReactModal>
    </>
  );
}
