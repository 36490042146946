import styled from "styled-components";

export const Header = styled.div`
  position: fixed;
  padding: 10px 0;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  text-align: center;
  max-width: ${({ desktop }) => (desktop ? "100%" : "500px")};
  margin: auto;
  z-index: 2;
  -webkit-box-shadow: 0px 2px 5px 0px rgba(176, 176, 176, 1);
  -moz-box-shadow: 0px 2px 5px 0px rgba(176, 176, 176, 1);
  box-shadow: 0px 2px 5px 0px rgba(176, 176, 176, 1);
  div {
    position: realtive;
    width: 100%;
  }
  span {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
  img {
    max-width: 100%;
  }
  .logo {
    max-width: 160px;
  }
  .logout {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    text-decoration: none;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      margin-left: 5px;
    }
  }
`;
