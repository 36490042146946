import { useLazyQuery, useQuery } from "@apollo/client";
import * as S from "./styles";
import Transaction from "./transaction";
import { GET_TOTAL_AMOUNT, GET_TRANSACTIONS } from "./queries";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEffect } from "react";
import { client } from "../../../../apollo-client";

const NetPaid = ({ storeId }) => {
  //fetching total amount
  const { data, error } = useQuery(GET_TOTAL_AMOUNT, {
    variables: {
      storeId,
    },
  });

  //fetching transactions
  const [fetchTransactions, { data: data2, error: error2, fetchMore }] =
    useLazyQuery(GET_TRANSACTIONS, {
      // fetchPolicy: "no-cache",
    });

  useEffect(() => {
    client.cache.evict({fieldName: "storePayout"})
    fetchTransactions({
      variables: {
        storeId,
      },
    });
  }, [storeId]);

  return (
    <div style={{ position: "relative" }}>
      <S.Heading>Payouts</S.Heading>
      <div style={{ textAlign: "left" }}>
        <div style={{ margin: "20px 10px" }}>
          <S.SubHeading>TOTAL AMOUNT PAID</S.SubHeading>
          <h2 style={{ marginTop: "10px", fontWeight: 400 }}>
            &#8377; {data?.earningAnalytics?.totalAmountPaid}
          </h2>
          <S.SubHeading style={{ margin: "60px 0px 30px" }}>
            Transactions
          </S.SubHeading>
        </div>
        {data2?.storePayout?.edges.length === 0 || error || error2 ? (
          <div style={{ margin: "0px 10px" }}>--</div>
        ) : (
          <div id="scrollableDiv" style={{ maxHeight: "40vh", overflow: "scroll" }}>
            <InfiniteScroll
              hasMore={data2?.storePayout?.pageInfo?.hasNextPage}
              dataLength={data2?.storePayout?.edges?.length ?? 0}
              endMessage={<p>No more transactions!</p>}
              next={() => {
                console.log("fetching more...");
                fetchMore({
                  variables: {
                    endCursor: data2?.storePayout?.pageInfo?.endCursor,
                  },
                });
              }}
              loader={<p>Loading...</p>}
              scrollableTarget="scrollableDiv"
            >
              {data2?.storePayout?.edges.map((transaction, index) => {
                return (
                  <Transaction
                    key={index}
                    mode={
                      transaction?.node?.modeofpayment === "UPI"
                        ? "UPI"
                        : "NEFT / IMPS"
                    }
                    amount={transaction?.node?.amount}
                    date={transaction?.node?.date}
                    transactionDetails={transaction?.node?.transactionDetails}
                  />
                );
              })}
            </InfiniteScroll>
          </div>
        )}
      </div>
    </div>
  );
};

export default NetPaid;
