import { gql } from "@apollo/client";

export const COD_UPDATE = gql`
  mutation codUpdate($id: ID!, $cod: Boolean, $codBasePrice: Float) {
    brandUpdate(input: { id: $id, cod: $cod, codBasePrice: $codBasePrice }) {
      brand {
        brandName
        id
        companyName
        cod
        codBasePrice
        address {
          firstName
        }
      }
    }
  }
`;
