import styled from "styled-components";

export const Heading = styled.h2`
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
  padding: 0 0 7px;
  border-bottom: 1.5px solid #eee;
`;

export const OrderDetails = styled.div`
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
`;

export const ProdFlex = styled.div`
  display: flex;
  span {
    padding-right: 20px;
  }
`;

export const ListItem = styled.div`
  padding: 5px 10px;
  display: flex;
  label {
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 7px;
  }
  p {
    color: #111111c4;
    font-size: 0.8rem;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
  }
`;

export const ProductList = styled.div`
  padding: 10px 0;
  overflow-y: scroll;
  max-height: 60vh;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ProductCard = styled.div`
  margin: 10px 0;
  display: flex;
  width: 100%;
`;
export const Image = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
  max-height: 120px;
  max-width: 100px;
`;
export const Description = styled.div`
  padding: 0 7px;
  margin-left: 7px;
  width: 100%;
  p {
    font-size: 13px;
  }
  span {
    font-size: 13px;
    font-weight: 600;
    display: block;
  }
`;
export const StatusChange = styled.div`
  padding: 0;
  display: grid;
  grid-template-columns: 70% 30%;
  label {
    font-size: 0.8rem;
    font-weight: 600;
    color: gray;
  }
`;
export const ShippingId = styled.div`
  input {
    border: 1.5px solid #eee;
    border-radius: 2px;
    padding: 4px 7px;
    font-size: 0.7rem;
    margin: 2px 0;
    width: 90%;
    font-weight: 400;
    color: #555;
    :focus {
      outline: none !important;
    }
  }
`;

export const MetaData = styled.div`
  transform: scale(0.9);
  textarea {
    border: 1.5px solid #eee;
    border-radius: 2px;
    padding: 4px 7px;
    font-size: 0.7rem;
    margin: 2px 0;
    width: 100%;
    min-height: 70px;
    font-weight: 400;
    color: #555;
    :focus {
      outline: none !important;
    }
  }
`;
