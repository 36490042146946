import { useEffect, useState } from "react";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import * as S from "./styles";

const Filters = (props) => {
  const [searchInputValue, setSearchInputValue] = useState("");
  const [clear, setClear] = useState(false);

  return (
    <S.Filters>
      <div>
        <label>Select Stores</label>
        <Dropdown
          style={{ width: "100%", padding: "2px 0" }}
          data={[...props?.stores]}
          label="storeName"
          value="id"
          onChange={props.onStoreChange}
          defaultValue={props.storesValue}
          isMulti
          placeholder="Select stores"
        />
      </div>
      <div>
        <label>Select Brands</label>
        <Dropdown
          style={{ width: "100%", padding: "2px 0" }}
          data={[...props?.brands]}
          label="brandName"
          value="id"
          onChange={props.onBrandChange}
          defaultValue={props.brandsValue}
          isMulti
          placeholder="Select brands"
        />
      </div>
      <div>
        <label>Select Collections</label>
        <Dropdown
          style={{ width: "100%", padding: "2px 0" }}
          data={[...props.collectionFilter]}
          value="collectionId"
          label="name"
          onChange={props.onCollectionChange}
          defaultValue={props.collectionsValue}
          isMulti
          placeholder="Select Collections"
        />
      </div>
      <div>
        <label>Search Voucher by Name or Code</label>
        <S.Flex>
          <input
            style={{
              padding: "10px",
              border: "1px solid #CFD2CF",
              width: "80%",
              borderRadius: "5px",
            }}
            type="text"
            value={searchInputValue}
            onChange={(e) => {
              setSearchInputValue(e.target.value);
              setClear(false);
            }}
          />
          <Button
            onClick={() => {
              if (clear) {
                props.setSearch("");
                setSearchInputValue("");
                setClear(false);
              } else {
                props.setSearch(searchInputValue);
                setClear(true);
              }
            }}
            style={{ width: "15%", backgroundColor: clear ? "tomato" : "#000" }}
          >
            {clear ? "Clear" : "Search"}
          </Button>
        </S.Flex>
      </div>
    </S.Filters>
  );
};

export default Filters;
