import { Link } from "react-router-dom";
import * as S from "./styles";

function CouponCard({
  name,
  storeName,
  code,
  amount,
  amountType,
  validUntil,
  status,
  id,
  active,
  used,
  type,
}) {
  const exclude = ["COTD", "BOTD", "DOTD"];

  const showEdit = (name) => {
    let flag = true;
    exclude.map((word) => {
      if (name.includes(word)) flag = false;
    });
    return flag;
  };

  return (
    <Link to={`details?voucher_id=${id}`} style={{ textDecoration: "none", color: "initial" }}>
      <S.Wrapper>
        <S.Left>
          <S.Circle>
            <S.Value>
              {amountType !== "PERCENTAGE" && "₹"}
              {amount}
              {amountType === "PERCENTAGE" && "%"}
              <p>off</p>
            </S.Value>
          </S.Circle>
        </S.Left>
        <S.Divide>
          <S.Cut />
          <S.DivideLine />
          <S.Cut bottom />
        </S.Divide>
        <S.Right>
          <S.Details>
            <S.Name>{name}</S.Name>
            <S.Table>
              <S.Row>
                <S.RowHeading>Code:</S.RowHeading>
                <S.RowValue>{code}</S.RowValue>
              </S.Row>
              <S.Row>
                <S.RowHeading>Amount:</S.RowHeading>
                <S.RowValue>
                  {amountType !== "PERCENTAGE" && "₹"}
                  {amount}
                  {amountType === "PERCENTAGE" && "%"}
                </S.RowValue>
              </S.Row>
              <S.Row>
                <S.RowHeading>Type:</S.RowHeading>
                <S.RowValue>{type ?? "--"}</S.RowValue>
              </S.Row>
              <S.Row>
                <S.RowHeading>Valid until:</S.RowHeading>
                <S.RowValue>{validUntil ?? "--"}</S.RowValue>
              </S.Row>
            </S.Table>
          </S.Details>
          <S.StatusDetails>
            <div style={{ textAlign: "right" }}>
              <p>{storeName}</p>
              <span>Used: {used ?? "--"}</span>
              <S.Status active={active && used === 0}>
                {!active && used !== 0 ? "Expired" : active && used === 0 ? "Active" : "Used"}
              </S.Status>
              {showEdit(name) && (
                <Link to={`edit?id=${id}`}>
                  <h2 style={{ margin: "10px 0px" }}>Edit</h2>
                </Link>
              )}
            </div>
          </S.StatusDetails>
        </S.Right>
      </S.Wrapper>
    </Link>
  );
}

export default CouponCard;
