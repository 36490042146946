import { useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { boolOptions } from "../../BrandsDashboard/Filters";
import { GET_STORES } from "../queries";
import { UPDATE_STORE } from "./mutations";
import * as S from "./styles";

export default function EditStore({ store, close, showInfluencerNotes }) {
  const storeBarter = JSON.parse(store?.metadata)?.store_barter ?? "null";
  const guidelines = JSON.parse(store?.metadata)?.barter_guidelines ?? "";
  const influencerNotes = JSON.parse(store?.metadata)?.influencer_notes ?? "";
  const [storeData, setStoreData] = useState({
    barter_guidelines: guidelines,
    store_barter:
      storeBarter === "null"
        ? null
        : {
            label:
              storeBarter === true || storeBarter === "true" ? "True" : "False",
            value: storeBarter,
          },
    influencer_notes: influencerNotes,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initial = useMemo(() => storeData, []);
  const [updateStore, { error }] = useMutation(UPDATE_STORE);

  const onChange = (e, type) => {
    setStoreData({
      ...storeData,
      [type]: e,
    });
  };

  const handleUpdate = async () => {
    try {
      if (!storeData?.store_barter)
        return toast.warning("Please select store barter value!");
      const res = await updateStore({
        variables: {
          id: store?.id,
          value: storeData.store_barter?.value,
          ...storeData,
        },
      });
      if (res.data?.storeUpdate) {
        toast.success("Store Edited successfully!");
        client.refetchQueries({
          include: [GET_STORES],
        });
      }
      close();
    } catch (err) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <S.Label>Edit Store</S.Label>
      <div>
        <S.TextLabel>Store Barter</S.TextLabel>
        <Dropdown
          style={{ marginTop: "5px" }}
          data={boolOptions}
          onChange={(e) => onChange(e, "store_barter")}
          value="value"
          label="label"
          defaultValue={storeData.store_barter}
        />
      </div>
      <S.TextLabel style={{ marginTop: 10 }}>Barter Guidelines</S.TextLabel>
      <S.TextField
        value={storeData?.barter_guidelines}
        placeholder="Barter Guidelines"
        onChange={(e) =>
          setStoreData({ ...storeData, barter_guidelines: e.target.value })
        }
      />
      {showInfluencerNotes && (
        <>
          <S.TextLabel style={{ marginTop: 10 }}>Influencer Notes</S.TextLabel>
          <S.TextField
            value={storeData?.influencer_notes}
            placeholder="Influencer Notes"
            onChange={(e) =>
              setStoreData({ ...storeData, influencer_notes: e.target.value })
            }
          />
        </>
      )}
      <Button
        disabled={JSON.stringify(storeData) === JSON.stringify(initial)}
        style={{ margin: "0px 0px 10px" }}
        onClick={handleUpdate}
      >
        Save
      </Button>
    </div>
  );
}
