import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding: 0.6rem;
  width: 100%;
  margin: 0.4rem 0 1rem;
  font-size: 0.8rem;
  :focus {
    border: 2px solid var(--toastify-color-info);
  }
`;
