import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import Table from "../../components/Table";
import { GET_USER_DATA } from "../MasterDashboard/queries";
import Filters from "./components/Filters";
import { fetchContent } from "./utils";

const ContentAnalytics = () => {
  const [page, setPage] = useState(1);
  const [contentData, setContentData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [contentLoading, setContentLoading] = useState(false);

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  //fitler states
  const [brand, setBrand] = useState("");
  const [time, setTime] = useState({
    startDate: "",
    endDate: "",
  });
  const [typeOfContent, setTypeOfContent] = useState("");
  const [sortBy, setSortBy] = useState({
    label: "total_views",
    value: "total_views",
  });
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });

  const onChange = (value, type) => {
    if (type === "BRAND") {
      setBrand(value);
    } else if (type === "DIR") {
      setDirection(value);
    } else if (type === "SORT") {
      setSortBy(value);
    } else if (type === "TIME") {
      setTime(value);
    } else if (type === "CONT") {
      setTypeOfContent(value);
    }
  };

  const clearAll = () => {
    setOffset(0);
    setPage(1);
    setBrand("");
    setTime({
      startDate: "",
      endDate: "",
    });
    setTypeOfContent("");
    setSortBy({ label: "total_views", value: "total_views" });
    setDirection({ label: "Desc", value: "DESC" });
  };

  useEffect(() => {
    setContentData([]);
    setOffset(0);
    setPage(1);
    formatData(0, brand, time, typeOfContent, sortBy, direction);
  }, [brand, time, typeOfContent, sortBy, direction]);

  const formatData = async (
    offset,
    brand,
    time,
    typeOfContent,
    sortBy,
    direction
  ) => {
    setContentLoading(true);
    const res = await fetchContent(
      offset,
      brand,
      time,
      typeOfContent,
      sortBy,
      direction
    );
    if (res.data) {
      const data = res.data.map((value) => ({
        avgWatchTime: value.average_watch_time ?? "--",
        brandName: value.brand_name ?? "--",
        mediaLink: {
          link: {
            url: value.media_link
              ? value.media_link === ""
                ? "--"
                : value.media_link
              : "--",
            name: value.media_link
              ? value.media_link === ""
                ? "--"
                : value.media_link
              : "--",
          },
        },
        taggedLink: {
          link: {
            url: value.tagged_link
              ? value.tagged_link === ""
                ? "--"
                : value.tagged_link
              : "--",
            name: value.tagged_link
              ? value.tagged_link === ""
                ? "--"
                : value.tagged_link
              : "--",
          },
        },
        timeOfUpload: value.time_of_upload ?? "--",
        totalNoOfViews: value.total_number_of_views ?? "--",
        totalStreamTime: value.total_stream_time ?? "--",
        totalViewButtonClicks: value.total_view_button_clicks ?? "--",
        typeOfContent: value.type_of_content ?? "--",
      }));
      setContentData((prev) => {
        return [...prev, ...data];
      });
    }
    setContentLoading(false);
  };

  if (loading || contentLoading) return <PacmanLoader />;
  if (error) return <div>Oops something went wrong</div>;

  return (
    <>
      <Layout
        pagination={{
          hasNext: page * 100 <= contentData.length,
          current: page,
          onNextClick: () => {
            setPage(page + 1);
            formatData(
              offset + 100,
              brand,
              time,
              typeOfContent,
              sortBy,
              direction
            );
            setOffset(offset + 100);
          },
          onPrevClick: () => {
            setContentData(
              contentData.length % 100 === 0
                ? contentData.length !== 100
                  ? contentData.slice(0, contentData.length - 100)
                  : contentData
                : contentData.slice(
                    0,
                    contentData.length - (contentData.length % 100)
                  )
            );
            setOffset(offset - 100);
            setPage(page - 1);
          },
        }}
        title="Content Analytics"
        desktop
      >
        <Filters
          onChange={onChange}
          clearAll={clearAll}
          data={data?.userByMobile}
          defaultValue={{
            brand: brand,
            time: time,
            typeOfContent: typeOfContent,
            sortBy: sortBy,
            direction: direction,
          }}
        />
        <Table data={contentData} displayPageSize={100} currentPage={page} />
      </Layout>
    </>
  );
};

export default ContentAnalytics;
