import styled from "styled-components";

export const Label = styled.h5`
  color: grey;
  padding-bottom: 5px;
`;

export const Contain = styled.div`
  padding: 40px;
  max-width: 600px;
  width: 100%;
  margin: auto;
`;

export const Input = styled.input`
  padding: 10px;
  width: 300px;
`;

export const BtnStyle = {
  width: "300px",
  margin: "0",
};
