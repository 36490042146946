import { useQuery } from "@apollo/client";
import { useState } from "react";
import Layout from "../../components/Layout";
import MeetupDetails from "./MeetupDetails";
import { GET_MEETUPS } from "./queries";
import PacmanLoader from "../../components/PackmanLoader";
import ErrorPage from "../../components/ErrorPage/ErrorPage";

export default function MeetupDashboard() {
  const [lines, setLines] = useState(1);
  const { data, loading, error } = useQuery(GET_MEETUPS);

  if (loading) return <PacmanLoader />;
  if (error) return <ErrorPage />;

  return (
    <Layout desktop>
      <div style={{ padding: "20px" }}>
        <h2 style={{ marginBottom: "20px" }}>Create Meetup</h2>
        {[...Array(lines)].map((_, i) => {
          return <MeetupDetails key={i} />;
        })}
        <h5 onClick={() => setLines(lines + 1)} style={{ cursor: "pointer", margin: "20px 0px" }}>
          &#43; Add Creation
        </h5>
        <div>
          <h2 style={{ margin: "30px 0px 20px" }}>Meetups</h2>
          {data?.meetups?.edges?.map(({ node }) => {
            return (
              <MeetupDetails
                key={node?.id}
                id={node?.id}
                dateTime={node?.datetime}
                details={node?.details}
              />
            );
          })}
        </div>
      </div>
    </Layout>
  );
}
