import { gql } from "@apollo/client";

export const CREATE_MEETUP = gql`
  mutation CreateMeetup($dateTime: DateTime!, $details: JSONString!) {
    meetupCreate(input: { datetime: $dateTime, details: $details }) {
      meetup {
        id
        details
        datetime
      }
    }
  }
`;

export const UPDATE_MEETUP = gql`
  mutation UpdateMeetup($id: ID!, $dateTime: DateTime!, $details: JSONString!) {
    meetupUpdate(input: { id: $id, datetime: $dateTime, details: $details }) {
      meetup {
        id
        details
        datetime
      }
    }
  }
`;

export const DELETE_MEETUP = gql`
  mutation DeleteMeetup($id: ID!) {
    meetupDelete(input: { id: $id }) {
      success
    }
  }
`;
