import { ApolloProvider, useReactiveVar } from "@apollo/client";
import { client } from "./apollo-client";
import Navigation from "./navigation";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { authVars } from "./reactiveVariables";
import Modal from 'react-modal';

Modal.setAppElement('#root');

function App() {
  const auth = useReactiveVar(authVars);

  useEffect(() => {
    console.log("client")
  }, [auth]);
  return (
    <ApolloProvider client={client}>
      <Navigation />
      <ToastContainer />
    </ApolloProvider>
  );
}

export default App;
