import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { TOKEN_VERIFY } from "./mutation";
import { CHECK_SUPERUSER } from "./queries";

const useAuth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [tokenVerify, { loading, error }] = useMutation(TOKEN_VERIFY);
  const [checkSuperuser, { data }] = useLazyQuery(CHECK_SUPERUSER);

  useEffect(() => {
    if (data) console.log("superuserdata", data) && setIsSuperuser(data?.isSuperuser);
  }, [data]);

  useEffect(() => {
    const localStorageToken = localStorage.getItem("token");
    const mobileNo = localStorage.getItem("analytics_mobileNo");
    if (localStorageToken && mobileNo) {
      const main = async () => {
        const { data } = await tokenVerify({
          variables: {
            token: localStorageToken,
          },
        });
        const isValid = data.tokenVerify.isValid;
        const user = data?.tokenVerify?.user;
        if (isValid) {
          if (user?.isStaff || user?.isSuperuser) {
            if (location.pathname === "/") return navigate("/home");
          }
          if (!user?.isStaff && !user?.isSuperuser) {
            if (!(location.pathname === "/brand") && !(location.pathname === "/brand/management"))
              toast.error("Restricted Access!");
            return navigate("/brand/management");
          }
        } else {
          navigate("/");
        }
      };
      main();
    } else {
      if (location.pathname === "/brand") navigate("/brand");
      else navigate("/");
    }
  }, []);

  return { authLoading: loading, authError: error };
};

export default useAuth;
