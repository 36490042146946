import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import { TAG_UPDATE } from "./mutations";
import { ALL_PRODUCT_TAGS, GET_PRODUCT_TAGS } from "./queries";
import * as S from "./styles";

const EditTagsModal = ({ productId, close }) => {
  //state for choosing tags
  const [productTags, setProductTags] = useState([]);
  //tags the product already has
  const initialTags = useRef(null);
  //options in dropdown
  const { data, loading, error } = useQuery(ALL_PRODUCT_TAGS);
  //fetching the products tags
  const {
    data: tags,
    loading: tagsLoading,
    error: tagsError,
  } = useQuery(GET_PRODUCT_TAGS, {
    variables: {
      id: productId,
    },
  });
  //mutation for updating the set of tags for the product
  const [updateTag, { error: updateError }] = useMutation(TAG_UPDATE);

  useEffect(() => {
    //formatting the tags of the product
    const tagsToSet = tags?.productTagMapping?.edges?.map(({ node }) => ({
      value: node?.productTag?.id,
      label: node?.productTag?.name,
    }));
    setProductTags(tagsToSet);
    initialTags.current = tagsToSet;
  }, [tags]);

  const handleUpdate = async () => {
    const res = await updateTag({
      variables: {
        productId,
        tags: productTags.map((tag) => tag?.value),
      },
    });
    if (res.data?.productTagUpdate) {
      toast.success("Tags Saved!");
      client.refetchQueries({
        include: [GET_PRODUCT_TAGS],
      });
    } else toast.error("Something went wrong!");
    close();
  };

  if (error || tagsError || updateError) return <>Something Went Wrong!</>;

  return (
    <>
      <S.Label>Edit Tags for Product</S.Label>
      <S.Center>
        {loading || tagsLoading ? (
          <S.SpinContainer>
            <S.Spinner />
          </S.SpinContainer>
        ) : (
          <div style={{ display: "grid", gap: "5px" }}>
            <S.TextLabel>Select Tags</S.TextLabel>
            <Dropdown
              style={{ width: "400px" }}
              data={data?.productTags?.edges?.map(({ node }) => node)}
              value="id"
              label="name"
              onChange={(e) => setProductTags(e)}
              placeholder="Select Tags"
              defaultValue={productTags}
              isMulti
            />
            <Button
              disabled={
                JSON.stringify(productTags) ===
                JSON.stringify(initialTags.current)
              }
              onClick={handleUpdate}
              style={{ marginTop: "10px" }}
            >
              Save
            </Button>
          </div>
        )}
      </S.Center>
    </>
  );
};

export default EditTagsModal;
