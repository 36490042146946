import Button from "../../components/Button";
import * as S from "./styles";

const SignIn = ({ handleSubmit, phone, handleChange }) => {
  return (
    <S.Wrapper>
      <S.PhoneNumber>OTP Verification Required</S.PhoneNumber>
      <S.TextDiv>
        We will send you an <span>One Time Password.</span> On your mobile
        number.
      </S.TextDiv>
      <img src="/assets/images/back.svg" alt="" />
      <S.FormStyled>
        Enter Mobile Number
        <form onSubmit={handleSubmit}>
          <S.TakeInput
            autoFocus
            type="phone"
            pattern="[6789]{1}\d{9}$"
            placeholder="Enter your phone No"
            value={phone}
            onChange={handleChange}
            className="form-field"
            title="Add 10 digit mobile number"
            required
          />
          <Button type="submit" fullWidth>
            GET OTP
          </Button>
        </form>
      </S.FormStyled>
    </S.Wrapper>
  );
};

export default SignIn;