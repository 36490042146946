import styled from "styled-components";

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};

export const TextFieldReadOnly = styled.textarea`
  height: 120px;
  max-width: 100%;
  width: 100%;
  padding: 5px;
  resize: vertical;
  :focus {
    outline: none;
    border: 1px solid #444;
  }
`;
export const TextField = styled.textarea`
  resize: vertical;
  height: 80px;
  max-width: 100%;
  width: 100%;
  padding: 5px;
`;

export const Label = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 5px;
  margin-top: 1rem;
`;

export const Button = styled.button`
  border: hidden;
  border-radius: 5px;
  padding: 8px 12px;
  background-color: #111;
  color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
  width: 100px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  cursor: pointer;
`;

export const Grid = styled.div`
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  grid-gap: 10px;
  padding: 10px 10px;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 18%;
  display: flex;
  flex-direction: column;
  aling-items: center;
  jusify-content: center;
  padding: 0;
  background: #fff;
  border: hidden;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  -moz-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
  }
  span {
    font-size: 0.7rem;
    color: #606060;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
    padding: 5px 10px;
  }
  @media (max-width: 768px) {
    width: 30%;
  }
`;

export const Input = styled.input`
  width: 100%;
  height: 2rem;
  padding: 0 0.25rem;
  margin: 0.4rem 0 0;
`;