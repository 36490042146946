import { gql } from "@apollo/client";

export const GET_TOTAL_AMOUNT = gql`
  query TotalAmount($storeId: ID!) {
    earningAnalytics(storeId: $storeId) {
      totalAmountPaid
    }
  }
`;

export const GET_TRANSACTIONS = gql`
  query Transactions($storeId: ID!, $endCursor: String) {
    storePayout(
      storeId: $storeId
      first: 5
      sortBy: { field: DATE, direction: DESC }
      after: $endCursor
    ) {
      pageInfo{
        hasNextPage
        endCursor
      }
      edges {
        node {
          amount
          id
          transactionDetails
          date
          modeofpayment
        }
      }
    }
  }
`;
