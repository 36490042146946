import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import Details from "./Details";
import ProductCard from "./ProductCard";
import { GET_ORDER_DETAILS, GET_STORE_DETAILS } from "./queries";
import * as S from "./styles";
import PacmanLoader from "../../../../components/PackmanLoader";
import { client } from "../../../../apollo-client";

const OrderDetails = (props) => {
  const { loading, data, error } = useQuery(GET_ORDER_DETAILS, {
    variables: {
      id: props?.modalData?.orderIdOriginal,
    },
    skip: props?.modalData?.orderId ? false : true,
    fetchPolicy: "no-cache",
  });
  const [getStoreDetails, { data: data2, error: error2, loading: loading2 }] =
    useLazyQuery(GET_STORE_DETAILS);

  const [fulfillmentIds, setFulfillmentIds] = useState({});
  const [orderStatus, setOrderStatus] = useState(undefined);

  const getStoreDetailsFunc = async () => {
    console.log("this is the store id:", data?.order?.store?.id);
    if (data) {
      getStoreDetails({
        variables: {
          storeId: data?.order?.store?.id,
        },
      });
    }
  };
  
  useEffect(() => {
    getStoreDetailsFunc();
  }, [data]);

  useEffect(() => {
    if (data) {
      let temp = {};
      let priority = undefined;
      data?.order?.fulfillments.map((e) => {
        temp[e?.lines[0]?.orderLine?.id] = {
          fulfillmentId: e?.id,
          status: e?.status,
        };
        if (priority === undefined) {
          priority = e?.status;
        } else {
          fulfillmentPriority[priority] > fulfillmentPriority[e?.status] && (priority = e?.status);
        }
      });
      setFulfillmentIds(temp);
      setOrderStatus(priority);
    }
  }, [data]);

  if (loading || loading2)
    return (
      <div
        style={{
          maxHeight: "80vh",
          position: "relative",
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PacmanLoader />
      </div>
    );
  if (error2 || error) return <div>Error!</div>;

  return (
    <div style={{ position: "relative" }} id="order-edit-container">
      <S.Heading>ORDER DETAILS</S.Heading>
      <Details
        data={{
          ...data?.order,
          orderIdOriginal: props?.modalData?.orderIdOriginal,
          orderStatus: orderStatus,
        }}
      />

      <hr />
      <S.ProductList>
        {data?.order?.lines?.map(
          (e, i) =>
            props?.modalData?.lineId == e?.id && (
              <ProductCard
                key={i}
                {...{
                  ...e,
                  warehouseId: data2?.store?.warehouse,
                  fulfillmentIds: fulfillmentIds,
                }}
                statusDict={props?.modalData?.statusDict}
                setStatusDict={props?.modalData?.setStatusDict}
                setModalShow={props?.modalData?.setModalShow}
                fulfillments={data?.order?.fulfillments}
              />
            )
        )}
      </S.ProductList>
    </div>
  );
};

const fulfillmentPriority = {
  IN_PROCESS: 0,
  SHIPPED: 1,
  DELIVERED: 2,
  CANCELED: 3,
  RETURN_REQUESTED: 4,
  RETURN_INITIATED: 5,
  RETURN_COMPLETED: 6,
  FULFILLED: 7,
};

export default OrderDetails;
