import * as S from "./styles";
export default function ErrorPage() {
  return (
    <S.Wrapper>
      <S.Oops>Oops!</S.Oops>
      <S.ErrorMessage>
        There was an unexpected error while loading this page
      </S.ErrorMessage>
    </S.Wrapper>
  );
}
