import * as S from "./styles";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { HISTORY_NOTES_UPDATE } from "./mutation";
import Button from "../../../components/Button";
import { toast } from "react-toastify";

const HistoryNotesModal = ({
  historyNoteDefault,
  brandId,
  setHistoryNotesModal,
}) => {
  const [historyNote, setHistoryNote] = useState(historyNoteDefault);
  const [updateHistoryNotes] = useMutation(HISTORY_NOTES_UPDATE);

  const updateHistoryNotesHandler = async () => {
    const data = await updateHistoryNotes({
      variables: {
        id: brandId,
        historyNotes: historyNote,
      },
    });
    if (data?.data?.brandUpdate?.errors?.length === 0) {
      toast.success("Order note updated successfully!");
      setHistoryNotesModal({ display: false });
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <S.Heading>Update History Notes</S.Heading>
      <S.TextField
        onChange={(e) => setHistoryNote(e.target.value)}
        value={historyNote}
        placeholder="Type Here"
      />
      <Button onClick={updateHistoryNotesHandler}>Update</Button>
    </>
  );
};

export default HistoryNotesModal;
