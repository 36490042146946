import styled, { keyframes } from "styled-components";

export const Input = styled.input`
  width: 100%;
  padding: 10px;
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px;
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid #000;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const FlexDiv = styled.div`
  display: flex;
  gap: 20px;
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};

export const ProductModal = {
  content: {
    top: "52%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "700px",
    height: "90vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
};
