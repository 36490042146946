import { useRef, useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import { STATUS_ARRAY } from "../../UpdateContent-Status";
import { statusEnum } from "../../../StoreAnalytics/StoreManagerActions";
import { toast } from "react-toastify";
import * as S from "./styles";
import "flatpickr/dist/themes/airbnb.css";
import Flatpickr from "react-flatpickr";
import moment from "moment";

const Filters = (props) => {
  const [gte, setGte] = useState("");
  const [lte, setLte] = useState("");
  const [nextgte, setNextgte] = useState("");
  const [nextlte, setNextlte] = useState("");

  const dateRef = useRef(null);

  const orderStatusOptions = [
    { value: "PLACED", label: "Placed" },
    { value: "IN_PROCESS", label: "In Process" },
    { value: "SHIPPED", label: "Shipped" },
    { value: "DELIVERED", label: "Delivered" },
    { value: "CANCELLATION_INITIATED", label: "Cancellation Initiated" },
    { value: "CANCELLATION_PROCESSED", label: "Cancellation Processed" },
    { value: "RETURN_REQUESTED", label: "Return Requested" },
    { value: "RETURN_INITIATED", label: "Return Initiated" },
    { value: "RETURN_COMPLETED", label: "Return Completed" },
    { value: "FULFILLED", label: "Fulfilled" },
    { value: "NO_ORDER_PLACED", label: "No Order Placed" },
    { value: "EXCHANGE_REQUESTED", label: "Exchange Requested" },
    { value: "EXCHANGE_INITIATED", label: "Exchange Initiated" },
    { value: "EXCHANGE_COMPLETED", label: "Exchange Completed" },
  ];

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Select Brand</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={props?.data?.authorisedBrands}
            value="id"
            label="brandName"
            onChange={(e) => props.onChange(e, "BRAND")}
            placeholder="Select Brand"
            defaultValue={props.defaultValue.brand}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "DIR")}
            placeholder="Direction"
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "SORT")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.sortBy}
          />
        </div>
        <div>
          <label>Brand Collab</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={collabOption}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "COLLAB")}
            placeholder="Brand Collab"
            defaultValue={props.defaultValue.collab}
            isClearable
          />
        </div>
        <div>
          <label>Sourcing Request Status</label>
          <Dropdown
            style={{
              minWidth: "150px",
              margin: "5px",
              maxWidth: "200px",
              marginRight: "20px",
            }}
            data={STATUS_ARRAY}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "STATUS")}
            placeholder="Status"
            defaultValue={props.defaultValue.status}
            isMulti
            isClearable
          />
        </div>
        <div>
          <label>Store Status</label>
          <Dropdown
            style={{
              minWidth: "150px",
              margin: "5px",
              maxWidth: "200px",
              marginRight: "20px",
            }}
            data={statusEnum}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "STATE")}
            placeholder="Store Status"
            defaultValue={props.defaultValue.storeStatus}
            isMulti
            isClearable
          />
        </div>
        <div>
          <label>Stop Sourcing Request</label>
          <Dropdown
            style={{
              minWidth: "150px",
              margin: "5px",
              maxWidth: "200px",
              marginRight: "20px",
            }}
            data={boolOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "BARTER")}
            placeholder="Stop Request"
            defaultValue={props.defaultValue.barter}
            isClearable
          />
        </div>
        <div>
          <label>Status</label>
          <Dropdown
            style={{
              minWidth: "150px",
              margin: "5px",
              maxWidth: "200px",
              marginRight: "20px",
            }}
            data={orderStatusOptions}
            value="value"
            label="label"
            isMulti
            onChange={(e) => props.onChange(e, "ORDERSTATUS")}
            placeholder="Order Status"
            defaultValue={props.defaultValue.barter}
            isClearable
          />
        </div>
        <div style={{ position: "relative", top: 5, marginRight: 10 }}>
          <label>Time Period</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px 0px",
            }}
          >
            <Flatpickr
              options={{ mode: "range" }}
              ref={dateRef}
              style={{
                padding: "10px",
                marginBottom: "12px",
                width: "230px",
              }}
              placeholder="Select Range"
              onChange={(e) => {
                setGte(moment(e[0]).format("YYYY-MM-DD"));
                setLte(moment(e[1]).format("YYYY-MM-DD"));
              }}
              defaultValue={
                props.defaultValue.time.gte
                  ? `${props.defaultValue.time.gte} to ${props.defaultValue.time.lte}`
                  : ""
              }
            />
            <button
              style={{
                width: "fit-content",
                position: "absolute",
                right: 4,
                padding: 4,
                top: 6,
              }}
              onClick={() => {
                if (gte !== "" && lte !== "") {
                  props.onChange({ gte, lte }, "TIME");
                } else {
                  toast.warning("Please select a date range");
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <div style={{ position: "relative", top: 5, marginRight: 10 }}>
          <label>Next Date</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px 0px",
            }}
          >
            <Flatpickr
              options={{ mode: "range" }}
              ref={dateRef}
              style={{
                padding: "10px",
                marginBottom: "12px",
                width: "230px",
              }}
              placeholder="Select Range"
              onChange={(e) => {
                setNextgte(moment(e[0]).format("YYYY-MM-DD"));
                setNextlte(moment(e[1]).format("YYYY-MM-DD"));
              }}
              defaultValue={
                props.defaultValue.nextDate.gte
                  ? `${props.defaultValue.nextDate.gte} to ${props.defaultValue.nextDate.lte}`
                  : ""
              }
            />
            <button
              style={{
                width: "fit-content",
                position: "absolute",
                right: 4,
                padding: 4,
                top: 6,
              }}
              onClick={() => {
                if (nextgte !== "" && nextlte !== "") {
                  props.onChange({ gte: nextgte, lte: nextlte }, "NEXTDATE");
                  // props.onChange({ gte, lte }, "TIME");
                } else {
                  toast.warning("Please select a date range");
                }
              }}
            >
              Apply
            </button>
          </div>
        </div>

        <span
          style={{ position: "relative", top: 5, cursor: "pointer" }}
          onClick={() => {
            props.clearAll();
            dateRef.current?.flatpickr.clear();
          }}
        >
          Clear Filters
        </span>
      </S.Flex>
      <S.Flex>
        <div>
          <label>Select Stores</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={props?.data?.authorisedStores}
            value="id"
            label="storeName"
            onChange={(e) => props.onChange(e, "STORES")}
            isMulti
            placeholder="Select Stores"
            defaultValue={props.defaultValue.stores}
          />
        </div>
        <div>
          <label>Select Staff</label>
          <Dropdown
            style={{ minWidth: "260px", margin: "5px", width: "fit-content" }}
            data={props.staff}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "SEARCH")}
            placeholder="Select Staff"
            isClearable
            defaultValue={props.defaultValue.search}
          />
        </div>
        <div>
          <label>Recommended</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={recommendationOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "RECOMMENDED")}
            placeholder="Recommended"
            defaultValue={props.defaultValue.recommended}
            isClearable
          />
        </div>
      </S.Flex>
    </S.Div>
  );
};

const recommendationOptions = [
  { value: true, label: "True" },
  { value: false, label: "False" },
];

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Created At",
    value: "CREATED_AT",
  },
  {
    label: "Updated At",
    value: "UPDATED_AT",
  },
];

const boolOptions = [
  { label: "True", value: false },
  { label: "False", value: true },
];

const collabOption = [
  { label: "Yes", value: "YES" },
  { label: "No", value: "NO" },
];

export default Filters;
