import moment from "moment";
import * as S from "./styles";

const OrderModal = ({
  setShowModal,
  id,
  brandName,
  productName,
  createdAt,
  updatedAt,
  fulfilmentStatus,
  shippingId,
  shippingProvider,
}) => {
  return (
    <div style={{ position: "relative" }}>
      <S.Top style={{ padding: "0rem" }}>
        <S.Dot />
        <S.Orderid>Order ID: {id}</S.Orderid>
        <div
          onClick={() => {
            setShowModal(false);
          }}
          style={{ marginLeft: "auto" }}
        >
          x
        </div>
      </S.Top>
      <div
        style={{
          border: "2px solid #f1f1f1",
          borderLeftWidth: 0,
          borderRightWidth: 0,
          borderTopWidth: 0,
          paddingBottom: "1rem",
        }}
      >
        <S.CardTop>
          <S.CardHeading style={{ marginTop: "10px" }}>
            {brandName} | {productName}
          </S.CardHeading>
        </S.CardTop>
      </div>
      <div>
        <S.Details>
          <S.Label>Order Date</S.Label>
          <S.Value>{moment(createdAt).format("MMM Do YYYY, hh:mm a")}</S.Value>
        </S.Details>
        <S.Details>
          <S.Label>Status</S.Label>
          <S.Value>{fulfilmentStatus}</S.Value>
          <S.Value>{moment(updatedAt).format("MMM Do YYYY, hh:mm a")}</S.Value>
        </S.Details>
        <S.Details>
          <S.Label>Shipping ID</S.Label>
          <S.Value>
            {shippingId ? (
              shippingProvider === "From Link" ? (
                <a target="_blank" rel="noreferrer" href={shippingId}>
                  {shippingId}
                </a>
              ) : (
                shippingId
              )
            ) : (
              "-"
            )}
          </S.Value>
        </S.Details>
        <S.Details>
          <S.Label>Shipping Provider</S.Label>
          <S.Value>{shippingProvider || "-"}</S.Value>
        </S.Details>
      </div>
    </div>
  );
};

export default OrderModal;
