import * as S from "../styles";

import {
  GET_CATEGORIES,
  GET_CATEGORIES_BASED_ON_SELECTED_BRAND,
} from "./queries";
import React, { useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";

import AddProduct from "./AddProduct";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import ErrorPage from "../../../components/ErrorPage/ErrorPage";
import PacmanLoader from "../../../components/PackmanLoader";
import ReactModal from "react-modal";
import ViewOrEdit from "../ViewOrEdit";
import moment from "moment";
import { toast } from "react-toastify";
import axios from "axios";
import * as utils from "../../Products2/utils";
import { isEmpty } from "lodash";
import { client } from "../../../apollo-client";
import { COLLECTION_PRODUCTS } from "../queries";

const resetedCoupon = {
  title: "",
  conditions: "",
  Brand_discount: 0,
  Zaamo_discount: 0,
  type: "SPECIFIC_BRAND_PRODUCTS",
  applyOncePerOrder: false,
  applyOncePerCustomer: false,
  owner: "ZAAMO_BRAND",
  isShipping: false,
  products: [],
  name: "",
  categories: [],
  brands: [],
  collections: [],
  startDate: "",
  endDate: "",
  minAmountSpent: 0,
  minCheckoutItemsQuantity: 1,
  discountValueType: null,
  mixed_coupon_card_name: "",
  mixed_coupon_image_url: "",
  discountNProducts: 0,
  buyMProducts: 0,
  yPercentDiscount: 0,
};

export default function MixedCoupon({ user, createCoupon }) {
  const [openProducts, setOpenProducts] = useState(false);
  const [isBrandSelected, setIsBrandSelected] = useState(false);
  const [collectionSlug, setCollectionSlug] = useState("");
  const [image, setImage] = useState(null);
  const [coupon, setCoupon] = useState({
    title: "",
    conditions: "",
    Brand_discount: 0,
    Zaamo_discount: 0,
    discountValueType: 0,
    type: "SPECIFIC_BRAND_PRODUCTS",
    applyOncePerOrder: false,
    applyOncePerCustomer: false,
    owner: "ZAAMO_BRAND",
    isShipping: false,
    discount_value: 0,
    products: [],
    name: "",
    categories: [],
    brands: [],
    collections: [],
    startDate: "",
    endDate: "",
    minAmountSpent: 0,
    minCheckoutItemsQuantity: 1,
    mixed_coupon_card_name: "",
    mixed_coupon_image_url: "",
  });

  const [brandID, setBrandID] = useState(null);
  const [couponSubType, setCouponSubType] = useState("");
  const [couponSubTypeDict, setCouponSubTypeDict] = useState(undefined);

  // const { data, loading, error, fetchMore } = useQuery(GET_CATEGORIES);
  const onChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };
  // console.log(coupon);

  const [loadEntries, { data: entriesData, loading, error }] = useLazyQuery(
    GET_CATEGORIES_BASED_ON_SELECTED_BRAND
  );

  useEffect(() => {
    if (coupon?.brands) {
      setBrandID(coupon?.brands?.value);
      setIsBrandSelected(true);
    }

    if (coupon?.brands == null) {
      setIsBrandSelected(false);
    }
  }, [coupon.brands]);

  useEffect(() => {
    if (brandID) {
      setIsBrandSelected(true);
      loadEntries({
        variables: {
          brandId: brandID,
        },
      });
    }

    if (!brandID) {
      setIsBrandSelected(false);
    }
  }, [brandID]);

  //Setting new BrandID on the basis of which Select Categories list will be rendered
  const reset = () => {
    setCoupon({
      ...resetedCoupon,
    });
    setImage(null);
  };

  // upload mixed coupon image
  const handleUploadCouponImage = async () => {
    const toastId = toast.loading("Preparing to upload image...");
    const { streaming_url } = await utils.uploadCover({
      file: image,
      toastId,
      index: "0",
    });
    console.log(streaming_url, "streaming_url");
    setCoupon({ ...coupon, mixed_coupon_image_url: streaming_url });
    toast.update(toastId, {
      render: "Photo uploaded successfully!",
      type: "success",
      isLoading: false,
      autoClose: 2000,
      draggable: true,
    });
  };

  const validate = () => {
    if (!coupon?.mixed_coupon_card_name) {
      toast.error("Mixed coupon card name is required field!");
      return false;
    }
    if (!coupon?.mixed_coupon_image_url) {
      toast.error("Mixed coupon image url is required field!");
      toast.warn("Select and image and press Add button to upload!");
      return false;
    }
    if (!coupon?.discountValueType) {
      toast.error("Discount Type is required field!");
      return false;
    }
    if (coupon?.brands.length === 0) {
      toast.error("Brands is required field!");
      return false;
    }

    if (!coupon?.title) {
      toast.error("Title is required field!");
      return false;
    }
    if (!coupon?.conditions) {
      toast.error("Condition is required field!");
      return false;
    }
    if (couponSubType === "NORMAL") {
      if (!coupon?.Brand_discount || coupon?.Brand_discount < 1) {
        toast.error("Brand discount is required field!");
        return false;
      }
    }
    if (!coupon?.startDate) {
      toast.error("Start Date is required field!");
      return false;
    }
    if (!coupon?.endDate) {
      toast.error("End Date is required field!");
      return false;
    }
    if (!coupon?.name) {
      toast.error("Name is required field!");
      return false;
    }
    if (
      couponSubType === "BUY_M_GET_N_FREE" ||
      couponSubType === "BUY_M_GET_X_PERC_OFF_N_PROD" ||
      couponSubType === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF"
    ) {
      if (!coupon?.buyMProducts && coupon?.buyMProducts == 0) {
        toast.error("Buy M Products is required field!");
        return false;
      }
      if (!coupon?.discountNProducts && coupon?.discountNProducts == 0) {
        toast.error("Discount N Products is required field!");
        return false;
      }
    }
    if (couponSubType === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF") {
      if (!coupon?.yPercentDiscount && coupon?.yPercentDiscount == 0) {
        toast.error("Y Percent Discount is required field!");
        return false;
      }
    }
    if (couponSubType === "BUY_M_GET_Y_OFF") {
      if (!coupon?.buyMProducts && coupon?.buyMProducts == 0) {
        toast.error("Buy M Products is required field!");
        return false;
      }
    }
    return true;
  };

  const handleCreate = async () => {
    if (!validate()) {
      return;
    }

    let collectionID;
    if (collectionSlug) {
      const collectionRes = await client.query({
        query: COLLECTION_PRODUCTS,
        variables: {
          slug: collectionSlug,
        },
        fetchPolicy: "network-only",
      });
      collectionID = collectionRes?.data?.collection?.id;

      if (!collectionID) {
        toast.error("Collection not found");
        return;
      }
    }
    const res = await createCoupon({
      variables: {
        ...coupon,
        name: coupon?.name.toUpperCase(),
        products: coupon?.products?.map((prod) => prod.value),
        brands: coupon?.brands?.value,
        categories: coupon?.categories?.map((cat) => cat.value),
        collection: collectionID ? [collectionID] : [],
        maxDiscountValue:
          coupon?.discountValueType?.value === "PERCENTAGE"
            ? Number(coupon?.maxDiscountValue ?? 0)
            : Number(coupon?.maxDiscountValue) === 0
            ? Number(coupon?.Brand_discount) + Number(coupon?.Zaamo_discount)
            : Number(coupon?.maxDiscountValue),
        discountValueType: coupon?.discountValueType?.value,
        discountValue:
          couponSubType === "NORMAL"
            ? Number(coupon?.Brand_discount) + Number(coupon?.Zaamo_discount)
            : coupon?.discount_value,
        metadata: [
          { key: "title", value: coupon?.title },
          { key: "conditions", value: coupon?.conditions },
          { key: "Brand_discount", value: coupon?.Brand_discount },
          { key: "Zaamo_discount", value: coupon?.Zaamo_discount },
          {
            key: "mixed_coupon_card_name",
            value: coupon?.mixed_coupon_card_name,
          },
          {
            key: "mixed_coupon_image_url",
            value: coupon?.mixed_coupon_image_url,
          },
        ],
        dealType: couponSubType,
      },
    });
    if (res.data?.voucherBulkCreate?.success) {
      toast.success("Coupon Created!");
      reset();
      setTimeout(() => {
        onChangeSubType(couponSubTypeDict);
      }, 0);
    } else toast.warning("Something went wrong!");
  };

  const onChangeSubType = (e) => {
    reset();
    setTimeout(() => {
      let tempCoupon = { ...resetedCoupon };
      if (
        e.value === "BUY_M_GET_N_FREE" ||
        e.value === "BUY_PRODUCTS_AT_SAME_PRICE"
      ) {
        tempCoupon = {
          ...tempCoupon,
          discountValueType: { label: "FIXED", value: "FIXED" },
        };
      }
      if (
        e.value === "BUY_M_GET_X_PERC_OFF_N_PROD" ||
        e.value === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF"
      ) {
        tempCoupon = {
          ...tempCoupon,
          discountValueType: {
            label: "PERCENTAGE",
            value: "PERCENTAGE",
          },
        };
      }
      setCoupon({
        ...tempCoupon,
      });
      setCouponSubType(e.value);
      setCouponSubTypeDict(e);
    }, 100);
  };

  // useEffect(() => {
  //   if (data && data?.categories?.pageInfo?.hasNextPage) {
  //     fetchMore({
  //       variables: {
  //         endCursor: data?.categories?.pageInfo?.endCursor,
  //       },
  //     });
  //   }
  //   //eslint-disable-next-line
  // }, [data]);

  // if (loading) return <PacmanLoader />;
  if (error) return <ErrorPage />;

  return (
    <>
      <div style={{ marginTop: "10px" }}>
        <h5 style={{ marginBottom: "5px" }}>Select Coupon Sub Type</h5>
        <Dropdown
          data={[
            { value: "BUY_M_GET_N_FREE", label: "Buy m products get n free" },
            {
              value: "BUY_M_GET_X_PERC_OFF_N_PROD",
              label: "Buy m products get x percent off on n products",
            },
            {
              value: "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF",
              label:
                "Buy m products get x percent off and n products get y percent off",
            },
            {
              value: "BUY_PRODUCTS_AT_SAME_PRICE",
              label: "Buy products at same price",
            },
            { value: "BUY_M_GET_Y_OFF", label: "Buy m products get y off" },
            { value: "NORMAL", label: "Normal" },
          ]}
          label="label"
          value="value"
          defaultValue={couponSubTypeDict}
          onChange={(e) => {
            onChangeSubType(e);
          }}
        />
        <br />
        {!isEmpty(couponSubType) ? (
          <>
            <S.FlexDiv>
              <ViewOrEdit
                name="mixed_coupon_card_name"
                label="Mixed Coupon Card Name"
                value={coupon?.mixed_coupon_card_name}
                onChange={onChange}
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                name="collection_slug"
                label="Collection slug"
                value={collectionSlug}
                onChange={(e) => setCollectionSlug(e.target.value)}
              />
              <ViewOrEdit
                type={"file"}
                onChange={(e) => setImage(e?.target?.files[0])}
                accept={
                  "image/png, image/gif, image/jpeg, image/jpg, image/heic"
                }
                func={() => handleUploadCouponImage()}
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                name="title"
                label="Voucher Title"
                value={coupon?.title}
                onChange={onChange}
              />
              <ViewOrEdit
                name="conditions"
                label="Voucher Conditions"
                value={coupon?.conditions}
                onChange={onChange}
              />
            </S.FlexDiv>
            {couponSubType === "NORMAL" ? (
              <S.FlexDiv>
                <ViewOrEdit
                  name="Brand_discount"
                  label="Brand Discount"
                  value={coupon?.Brand_discount}
                  type="number"
                  onChange={onChange}
                />
                <ViewOrEdit
                  name="Zaamo_discount"
                  label="Zaamo Discount"
                  value={coupon?.Zaamo_discount}
                  type="number"
                  onChange={onChange}
                />
              </S.FlexDiv>
            ) : null}
            <S.FlexDiv>
              <div style={{ width: "50%", margin: "10px 0px" }}>
                <h5 style={{ marginBottom: "5px" }}>Select Brands</h5>
                <Dropdown
                  data={user?.userByMobile?.authorisedBrands}
                  value="id"
                  label="brandName"
                  onChange={(e) => setCoupon({ ...coupon, brands: e })}
                  placeholder="Select Brands"
                  isClearable
                  defaultValue={coupon?.brands}
                />
              </div>
              <div style={{ width: "50%", margin: "10px 0px" }}>
                <h5 style={{ marginBottom: "5px" }}>Select Categories</h5>
                <Dropdown
                  value="id"
                  label="name"
                  isMulti
                  isClearable
                  data={entriesData?.brand?.categories ?? []}
                  onChange={(e) => setCoupon({ ...coupon, categories: e })}
                  defaultValue={coupon?.categories}
                />
              </div>
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                name="name"
                label="Coupon Name"
                value={coupon?.name}
                onChange={onChange}
              />
              <ViewOrEdit
                label="Products"
                value={coupon?.products?.map((prod) => prod.label).join(", ")}
                func={() => setOpenProducts(true)}
                read
                isBrandSelected={entriesData?.brand ? false : true}
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                name="startDate"
                label="Start Date"
                value={moment(coupon?.startDate).format("YYYY-MM-DDTHH:mm:ss")}
                onChange={onChange}
                type="datetime-local"
              />
              <ViewOrEdit
                name="endDate"
                label="End Date"
                onChange={onChange}
                value={moment(coupon?.endDate).format("YYYY-MM-DDTHH:mm:ss")}
                type="datetime-local"
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                name="minAmountSpent"
                label="Min Amount Spent"
                value={coupon?.minAmountSpent}
                onChange={onChange}
                type="number"
              />
              <div style={{ width: "100%", margin: "10px 0px" }}>
                <h5 style={{ marginBottom: "5px" }}>Select Discount Type</h5>
                <Dropdown
                  value="value"
                  label="label"
                  data={[
                    { label: "FIXED", value: "FIXED" },
                    { label: "PERCENTAGE", value: "PERCENTAGE" },
                  ]}
                  onChange={(e) =>
                    setCoupon({ ...coupon, discountValueType: e })
                  }
                  defaultValue={coupon?.discountValueType}
                  isDisabled={
                    couponSubType === "BUY_M_GET_N_FREE" ||
                    couponSubType === "BUY_M_GET_X_PERC_OFF_N_PROD" ||
                    couponSubType ===
                      "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF" ||
                    couponSubType === "BUY_PRODUCTS_AT_SAME_PRICE"
                  }
                />
              </div>
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Type" value={coupon?.type} read />
              <ViewOrEdit label="Owner" value={coupon?.owner} read />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                label={
                  couponSubType === "BUY_PRODUCTS_AT_SAME_PRICE"
                    ? "Buy products at same price"
                    : "Discount Value"
                }
                name="discount_value"
                type="number"
                value={
                  couponSubType === "BUY_M_GET_N_FREE"
                    ? 0
                    : couponSubType === "NORMAL"
                    ? Number(coupon?.Brand_discount) +
                      Number(coupon?.Zaamo_discount)
                    : coupon?.discount_value
                }
                onChange={onChange}
                read={
                  couponSubType === "BUY_M_GET_N_FREE" ||
                  couponSubType === "NORMAL"
                }
              />
              {couponSubType === "NORMAL" ? (
                <ViewOrEdit
                  name="maxDiscountValue"
                  label="Max Discount Value"
                  value={
                    coupon?.discountValueType?.value === "PERCENTAGE"
                      ? coupon?.maxDiscountValue ?? 0
                      : coupon?.maxDiscountValue ??
                        Number(coupon?.Brand_discount) +
                          Number(coupon?.Zaamo_discount)
                  }
                  onChange={onChange}
                  type="number"
                />
              ) : null}
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit
                label="Apply once per Order"
                value={coupon?.applyOncePerOrder}
                read
              />
              <ViewOrEdit
                label="Apply once per Customer"
                value={coupon?.applyOncePerCustomer}
                read
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Shipping" value={coupon?.isShipping} read />
              {couponSubType === "BUY_PRODUCTS_AT_SAME_PRICE" ? (
                <ViewOrEdit
                  label="Min Checkout Items"
                  value={coupon?.minCheckoutItemsQuantity}
                  onChange={onChange}
                />
              ) : null}
            </S.FlexDiv>
            <S.FlexDiv>
              {couponSubType === "BUY_M_GET_N_FREE" ||
              couponSubType === "BUY_M_GET_X_PERC_OFF_N_PROD" ||
              couponSubType === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF" ? (
                <ViewOrEdit
                  label="Discount N Products"
                  value={coupon?.discountNProducts}
                  name="discountNProducts"
                  type="number"
                  onChange={onChange}
                />
              ) : (
                <></>
              )}
              {couponSubType === "BUY_M_GET_Y_OFF" ||
              couponSubType === "BUY_M_GET_X_PERC_OFF_N_PROD" ||
              couponSubType === "BUY_M_GET_N_FREE" ||
              couponSubType === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF" ? (
                <ViewOrEdit
                  label="Buy M Products"
                  value={coupon?.buyMProducts}
                  name="buyMProducts"
                  type="number"
                  onChange={onChange}
                />
              ) : (
                <></>
              )}
            </S.FlexDiv>

            <S.FlexDiv>
              {couponSubType === "BUY_M_GET_X_PERC_OFF_BUY_N_GET_Y_PERC_OFF" ? (
                <div style={{ width: "100%" }}>
                  <ViewOrEdit
                    label="Y Percent Discount"
                    value={coupon?.yPercentDiscount}
                    name="yPercentDiscount"
                    type="number"
                    onChange={onChange}
                  />
                </div>
              ) : (
                <></>
              )}
              <div style={{ width: "100%" }} />
            </S.FlexDiv>
            <br />
            <Button
              // disabled={}
              onClick={handleCreate}
            >
              Create Coupon
            </Button>

            <ReactModal
              isOpen={openProducts}
              onRequestClose={() => setOpenProducts(false)}
              style={S.ProductModal}
            >
              <AddProduct
                mainProducts={coupon?.products}
                setProduct={setCoupon}
                productsSelected={coupon?.products}
                brands={coupon?.brands}
                close={() => setOpenProducts(false)}
              />
            </ReactModal>
          </>
        ) : null}
      </div>
    </>
  );
}
