import styled from "styled-components";

export const Div = styled.div`
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  flex-wrap: wrap;
`;

export const SearchInput = styled.input`
  padding: 0.8rem 1rem;
  flex: 1;
  border-radius: 5px;

  border: none;
  outline: none;
  background: #f1f1f1;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  position: relative;
  /* animation-name: anim; */
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
`;