import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  /* flex: 1; */
`;

export const Four = styled.p.attrs({
  children: "4",
})`
  font-size: 100px;
  color: black;
  font-weight: 700;
`;

const rotate = keyframes`

    from{
        transform: translateY(-50px);
    }to{
        transform: translateY(0px);
    }

`;

export const O = styled.div.attrs({
  children: "!",
})`
  display: grid;
  place-items: center;
  font-weight: 700;
  font-size: 60px;
  color: white;
  height: 100px;
  width: 100px;
  border-radius: 100px;
  background: linear-gradient(45deg, #f90b00, dodgerblue);
  transform: translateY(0px);
  animation: ${rotate} 1s cubic-bezier(0.51, 0.18, 0.68, 0.15) alternate
    infinite;
`;

export const Text = styled.div`
  font-size: 20px;
  color: gray;
  font-weight: 700;
  text-align: center;
`;
