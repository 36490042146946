import * as S from "./style";

import Select from "react-select";

const Dropdown = (props) => {
  const options = props?.data?.map((e) => ({
    label: e[props.label],
    value: e[props.value],
  }));

  return (
    <S.Form style={props.style}>
      <Select
        ref={props.selectRef}
        options={options}
        isMulti={props.isMulti}
        onChange={props.onChange}
        isClearable={props.isClearable}
        placeholder={props.placeholder ?? "Select..."}
        value={props?.defaultValue}
        menuPlacement="auto"
        menuPosition="fixed"
        menuPortalTarget={props?.menuPortalTarget ?? document.body}
        styles={{ menuPortal: (base) => ({ ...base, zIndex: 99 }) }}
        isDisabled={props.isDisabled ?? false}
      />
    </S.Form>
  );
};

export default Dropdown;
