import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 6px;
`;
export const Preview = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background-color: #fff;

  display: flex;
  flex-wrap: wrap;

  & > img {
    height: 50%;
    width: 50%;
    object-fit: cover;
    object-position: center;
  }
  & > div {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1.6rem;
    font-weight: 800;
    color: #fff;
    text-shadow: 0 0 4px #000e;
  }
  margin-bottom: 10px;
`;
export const Title = styled.h4`
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  width: 100%;
`;
export const Input = styled.input`
  border: 1px solid #0004;
  outline: none;
  padding: 8px 8px;
  width: 100%;
  border-radius: 4px;
`;
export const SaveButton = styled.button`
  font-weight: 400;
  font-size: 16px;
  margin-top: 10px;
  background-color: #000d;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: #fffe;
  &:disabled {
    opacity: 0.7;
  }
`;
