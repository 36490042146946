import styled from "styled-components";

export const Button = styled.button`
  background: ${({ color }) => (color ? color : "#000000")};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.79);
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 30px;
  color: #ffffff;
  padding: 5px 10px;
  margin: auto;
  border: hidden;
  display: block;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  img {
      margin-right: 5px;
  }
  :disabled {
    background: #111111c4 !important;
  } 
`;
