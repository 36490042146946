import { gql } from "@apollo/client";

export const GET_OVERALL_DASHBOARD_DATA = gql`
  query OverallDashboard($page: Int) {
    overallDashboard(page: $page) {
      date
      totalNetGmv
      activeInfluencerCount
      totalSellingPrice
      totalBrandDiscount
      totalCouponAdjustments
      abandonedCartCount
      totalStoreVisits
      totalProductVisits
      totalLandingPageVisits
      totalCollectionVisits
      productsAddedInCollections
      wishlistCount
      signupsCount
      perDayOrderCount
      perDayCheckoutCount
    }
  }
`;
