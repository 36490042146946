import { gql } from "@apollo/client";

export const GET_MEETUPS = gql`
  query GetMeetups {
    meetups(first: 100) {
      edges {
        node {
          id
          details
          datetime
        }
      }
    }
  }
`;
