import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Filters from "./components/Filters";
import * as S from "./styles";
import PacmanLoader from "../../components/PackmanLoader";
import useMasterDashboard from "./useMasterDashboard";
import ReactModal from "react-modal";
import OrderDetails from "./components/OrderDetails";
import { useRef } from "react";
import { useEffect } from "react";
import OrderNote from "./components/OrderNote";
import FreshDeskTicket from "./components/FreshDeskTicket";

const MasterDashboard = () => {
  const initialFetch = useRef(false);

  const {
    brand,
    stores,
    time,
    direction,
    sortBy,
    page,
    setPage,
    hasNextPage,
    data,
    loading,
    error,
    loading2,
    error2,
    kpiData,
    kpiLoading,
    kpiError,
    onChange,
    fetchMoreData,
    clearAll,
    parsedData2,
    modalShow,
    setModalShow,
    modalData,
    statusFilter,
    orderStatus,
    voucher,
    email,
    cod,
    orderNoteModal,
    setOrderNoteModal,
    freshDeskTicketModal,
    setFreshDeskTicketModal,
  } = useMasterDashboard();

  const getCommaSepNum = (value) => {
    let spl = `${value}`.split(".");
    let v = parseInt(spl[0]).toLocaleString("en-IN");
    if (spl[1]) v += `.${spl[1]}`;
    return v;
  };

  if (kpiError || error || error2) {
    return <div>Oops something went wrong</div>;
  }
  if (loading) {
    return <PacmanLoader />;
  }
  return (
    <Layout
      desktop
      hideBack
      pagination={
        parsedData2.length
          ? {
              hasNext: hasNextPage || parsedData2.length - page * 10 >= 0,
              current: page,
              onNextClick: () => {
                (hasNextPage || parsedData2.length - page * 10 >= 0) &&
                  fetchMoreData();
              },
              onPrevClick: () => setPage(page - 1),
            }
          : false
      }
    >
      <S.Wrapper>
        <Filters
          onChange={onChange}
          data={data.userByMobile}
          defaultValue={{
            brand: brand,
            stores: stores,
            time: time,
            direction: direction,
            sortBy: sortBy,
            status: statusFilter,
            orderStatus: orderStatus,
            voucher: voucher,
            email: email,
            cod: cod,
          }}
          clearAll={clearAll}
        />
        {kpiLoading ? (
          <S.SpinContainer>
            <S.Spinner />
          </S.SpinContainer>
        ) : (
          <S.Grid>
            <S.Card>
              <span>GMV</span>
              <p>
                {getCommaSepNum(Math.trunc(kpiData?.masterDashboardKpi?.gmv))}
              </p>
            </S.Card>
            <S.Card>
              <span>Products Sold</span>
              <p>
                {kpiData?.masterDashboardKpi?.productsSold?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </S.Card>
            <S.Card>
              <span>Influencer Earning</span>
              <p>
                {kpiData?.masterDashboardKpi?.influencerEarning?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </S.Card>
            <S.Card>
              <span>Completed Sourcing Requests</span>
              <p>
                {kpiData?.masterDashboardKpi?.completedSourcingRequests?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </S.Card>
            <S.Card>
              <span>Number of BOTD true brands</span>
              <p>
                {kpiData?.masterDashboardKpi?.numberOfBotdtruebrands?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </S.Card>
            <S.Card>
              <span>Delayed Order Count</span>
              <p>
                {kpiData?.masterDashboardKpi?.delayedOrderCount?.toLocaleString(
                  "en-IN"
                )}
              </p>
            </S.Card>
            <S.Card>
              <span>Store GMV</span>
              <p>
                {kpiData?.masterDashboardKpi?.storeGmv?.toLocaleString("en-IN")}
              </p>
            </S.Card>
          </S.Grid>
        )}
        {loading2 ? (
          <PacmanLoader />
        ) : (
          <S.Container>
            <Table data={parsedData2} currentPage={page} />
          </S.Container>
        )}
      </S.Wrapper>

      <ReactModal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        style={S.Modal}
      >
        <OrderDetails modalData={{ ...modalData }} />
      </ReactModal>
      <ReactModal
        isOpen={orderNoteModal?.display}
        onRequestClose={() => setOrderNoteModal({ display: false })}
        style={S.Modal}
      >
        <OrderNote
          defaultData={orderNoteModal}
          setOrderNoteModal={setOrderNoteModal}
        />
      </ReactModal>
      <ReactModal
        isOpen={freshDeskTicketModal?.display}
        onRequestClose={() => setFreshDeskTicketModal({ display: false })}
        style={S.Modal}
      >
        <FreshDeskTicket
          defaultData={freshDeskTicketModal}
          setFreshDeskTicketModal={setFreshDeskTicketModal}
        />
      </ReactModal>
    </Layout>
  );
};

export default MasterDashboard;
