import { gql } from "@apollo/client";

export const ADD_COMMENT = gql`
  mutation AddComment($storeId: ID!, $comment: String!) {
    storeManagerCommentCreate(input: { store: $storeId, comment: $comment }) {
      StoreManagerComment {
        user {
          userId
          firstName
          email
        }
        store {
          storeName
        }
        comment
      }
    }
  }
`;
