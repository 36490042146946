import * as S from "./styles";
function PageNotFound() {
  return (
    <S.Wrapper>
      <S.Heading>
        <S.Four />
        <S.O />
        <S.Four />
      </S.Heading>
      <S.Text>This page does not exists...</S.Text>
    </S.Wrapper>
  );
}

export default PageNotFound;
