import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import Filters from "./Filters";
import * as S from "./styles";
import {
  GET_ALL_BRANDS,
  GET_ALL_CATEGORIES,
  GET_FILTERED_PRODUCTS,
  GET_FILTER_DATA,
  SEARCH_PRODUCTS,
} from "./queries";
import { useLazyQuery, useQuery } from "@apollo/client";
import ProductCard from "./ProductCard";
import PacmanLoader from "../../components/PackmanLoader";
import useAuth from "../../hooks/useAuth/useAuth";
import { fetchMediaCount } from "./utils";
import Search from "../CustomerSupport/Search";
import { client } from "../../apollo-client";

const Products2 = () => {
  //set of product ids of the brand
  const [productIds, setProductIds] = useState([]);
  //data recieved from the request
  const [brandMediaCount, setBrandMediaCount] = useState({});
  //search string state
  const [searchString, setSearchString] = useState("");
  //handling the data
  const [productInfo, setProductInfo] = useState([]);
  const {
    data: brandsData,
    loading,
    fetchMore: fetchMoreBrands,
  } = useQuery(GET_ALL_BRANDS);
  const [getBrandFilters, { data: filterData }] = useLazyQuery(GET_FILTER_DATA);
  const { data } = useQuery(GET_ALL_CATEGORIES);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [filtersApplied, setFiltersApplied] = useState({
    brandCollection: [],
    brandCategory: [],
    brandSubCategory: [],
    productType: [],
    isPublished: true,
    dataSource: "postgres",
  });
  const [page, setPage] = useState(1);

  //search string query
  const [
    getSearchData,
    { data: searchData, loading: searchLoading, error: searchError },
  ] = useLazyQuery(SEARCH_PRODUCTS, {
    notifyOnNetworkStatusChange: true,
  });

  const { data: productsData, loading: productLoading } = useQuery(
    GET_FILTERED_PRODUCTS,
    {
      nextFetchPolicy: "cache-first",
      variables: { brandId: selectedBrand, ...filtersApplied, page: page },
      skip: !selectedBrand,
    }
  );
  useEffect(() => {
    if (searchData?.pdpProductSearch?.length > 0) {
      getSearchData({
        variables: {
          search: searchString,
          brandName: filterData?.pdpAnalytics?.brandName ?? "",
          page: page,
        },
      });
    }
  }, [page]);

  useEffect(() => {
    if (brandsData && brandsData?.brands?.pageInfo?.hasNextPage) {
      fetchMoreBrands({
        variables: {
          endCursor: brandsData?.brands?.pageInfo?.endCursor,
        },
      });
    }
  }, [brandsData]);

  const handleSelectBrand = (e) => {
    setSelectedBrand(e.value);
    getBrandFilters({ variables: { brandId: e.value } });
    if (productLoading) client.cache.evict({ fieldName: "productsAnalytics" });
  };

  const handleFilterCollections = (e) => {
    setFiltersApplied((prev) => {
      const obj = { ...prev, brandCollection: e.map((item) => item.value) };
      return obj;
    });
  };

  const handleFilterCategories = (e) => {
    setFiltersApplied((prev) => {
      const obj = { ...prev, brandCategory: e.map((item) => item.value) };
      return obj;
    });
  };

  const handleFilterProductType = (e) => {
    setFiltersApplied((prev) => {
      const obj = { ...prev, productType: e.map((item) => item.value) };
      return obj;
    });
  };

  const handleFilterSubCategories = (e) => {
    setFiltersApplied((prev) => {
      const obj = { ...prev, subCategories: e.map((item) => item.value) };
      return obj;
    });
  };

  const handleFilterVisibility = (e) => {
    setFiltersApplied((prev) => {
      const obj = { ...prev, isPublished: e.value, dataSource: "" };
      return obj;
    });
  };

  const searchProduct = () => {
    if (searchString !== "") {
      getSearchData({
        variables: {
          search: searchString,
          brandName: filterData?.pdpAnalytics?.brandName ?? "",
          page: page,
        },
      });
    }
  };

  useEffect(() => {
    setPage(1);
  }, [filtersApplied]);

  useEffect(() => {
    if (searchData?.pdpProductSearch?.length > 0) {
      setProductInfo(searchData?.pdpProductSearch);
    } else {
      setProductInfo(productsData?.pdpAnalytics?.products);
    }
  }, [searchData, productsData]);

  //setting the product ids
  useEffect(() => {
    setProductIds([]);
    productsData?.pdpAnalytics?.products?.map((item) => {
      setProductIds((prev) => {
        return [...prev, item?.productZaamoId];
      });
    });
  }, [productsData]);

  //fetching the data for the product ids
  useEffect(() => {
    if (productIds.length > 0)
      (async () => {
        const values = await fetchMediaCount(productIds);
        setBrandMediaCount(values);
      })();
  }, [productIds]);

  if (loading) {
    return <PacmanLoader />;
  }
  if (searchError) {
    return <div>Oops something went wrong</div>;
  }

  return (
    <Layout
      title={"PDP2"}
      backUrl="/home"
      pagination={{
        hasNext: productInfo?.length,
        current: page,
        onNextClick: () => setPage(page + 1),
        onPrevClick: () => setPage(page - 1),
      }}
    >
      <S.Wrapper>
        <Filters
          brands={brandsData}
          handleSelectBrand={handleSelectBrand}
          filterData={filterData?.pdpAnalytics}
          filtersApplied={filtersApplied}
          handleFilterCollections={handleFilterCollections}
          handleFilterCategories={handleFilterCategories}
          handleFilterProductType={handleFilterProductType}
          handleFilterSubCategories={handleFilterSubCategories}
          handleFilterVisibility={handleFilterVisibility}
        />
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
          }}
        >
          <S.SearchInput
            value={searchString}
            onChange={(e) => {
              setSearchString(e.target.value);
            }}
            placeholder="Search for product"
          />
          <div
            onClick={() => searchProduct()}
            style={{ position: "absolute", right: "15px" }}
          >
            <Search />
          </div>
        </div>
        <S.ProductsContainer>
          <>
            {productInfo?.length === 0 ? (
              <p style={{ textAlign: "center" }}>NO MORE DATA!</p>
            ) : productLoading || searchLoading ? (
              <PacmanLoader />
            ) : (
              <>
                {productInfo?.map((product, index) => (
                  <ProductCard
                    key={index}
                    categories={data}
                    brand={selectedBrand}
                    product={product}
                    mediaCount={brandMediaCount}
                  />
                ))}
              </>
            )}
          </>
        </S.ProductsContainer>
      </S.Wrapper>
    </Layout>
  );
};

export default Products2;
