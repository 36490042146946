import { useQuery } from "@apollo/client";
import { useLocation, useRoutes } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import CouponCard from "./CouponCard";
import CouponConditions from "./CouponConditions";
import { GET_VOUCHER_DETAILS } from "./queries";

const CouponDetails = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  
  const { data, loading, error } = useQuery(GET_VOUCHER_DETAILS, {
    variables: {
      id: params.get("voucher_id"),
    },
  });

  if (loading) return <PacmanLoader />;
  if (error) return <ErrorPage />;
  console.log(data);

  let couponId = data.voucher.id,
    code = data.voucher?.code,
    name = data.voucher.name,
    metadata = data.voucher?.metadata,
    description = metadata.find((obj) => obj.key === "title")?.value,
    conditions = metadata.find((obj) => obj.key === "conditions")?.value,
    discount = data.voucher?.discountValue,
    valid = data.voucher?.endDate,
    isPercentage = data.voucher?.discountValueType === "PERCENTAGE",
    usageLimit = data.voucher?.usageLimit,
    used = data.voucher?.used,
    status = !data.voucher?.active && used !== 0 ? "Expired" : data?.voucher?.active && used === 0 ? "Active" : "Used";

//   if (usageLimit - used > 0) {
//     if (Date.now() - Date.parse(valid) < 0) {
//       status = "Available";
//     }
//   }
  return (
    <Layout>
      <CouponCard
        {...{
          code,
          discount,
          isPercentage,
          status: status,
          name,
          description,
          valid,
        }}
      />
      {conditions && <CouponConditions conditions={conditions} />}
    </Layout>
  );
};

export default CouponDetails;
