import * as S from "../BrandSourcing/styles";

import React, { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import CreateLedger from "./CreateLedger";
import EditBrand from "./EditBrand";
import EditShipping from "./EditShipping";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Filters from "./Filters";
import { GET_DASHBOARD_BRANDS } from "./queries";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import PickupAddress from "./PickupAddress";
import { RESTRICT_ORDER } from "./mutation";
import ReactModal from "react-modal";
import Table from "../../components/Table";
import ViewContact from "./ViewContact";
import ViewPolicy from "./ViewPolicy";
import { toast } from "react-toastify";
import { CodModal } from "./CodModal/CodModal";
import { BOTDModal } from "./BOTDModal";
import HistoryNotesModal from "./HistoryNotesModal";
import { ReactSVG } from "react-svg";
import GstDetailsModal from "./GstDetailsModal";

const BrandsDashboard = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [showShipping, setShowShipping] = useState(false);
  const [codModal, setCodModal] = useState(false);
  const [BOTDmodal, setBOTDmodal] = useState(false);
  const [showPolicy, setShowPolicy] = useState(false);
  const [brandsData, setBrandsData] = useState([]);
  const [page, setPage] = useState(1);
  const [brandShipping, setBrandShipping] = useState(null);
  const [pickupAddress, setPickupAddress] = useState(null);
  const [showPickupAddress, setShowPickupAddress] = useState(false);
  const [brandPolicy, setBrandPolicy] = useState(null);
  const [viewLedger, setViewLedger] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [showGstModal, setShowGstModal] = useState(null);
  const [filters, setFilters] = useState({
    search: "",
    barter: undefined,
    orders: undefined,
    commission: "",
    status: undefined,
    field: { label: "Created At", value: "CREATED_AT" },
    direction: { label: "Desc", value: "DESC" },
    importance: undefined,
  });
  const [historyNotesModal, setHistoryNotesModal] = useState({
    display: false,
  });

  const { data, loading, error, fetchMore, refetch } = useQuery(
    GET_DASHBOARD_BRANDS,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        search: filters.search,
        barter: filters.barter?.value,
        orders: filters.orders?.value,
        commission: filters.commission?.value,
        field: filters.field?.value,
        direction: filters.direction?.value,
        status: filters?.status?.length
          ? filters?.status?.map((item) => item?.value)
          : [],
        importance: filters?.importance?.length
          ? filters?.importance?.map((item) => item?.value)
          : [],
      },
    }
  );

  const [restrictUpdate, { error: restrictErr }] = useMutation(RESTRICT_ORDER);

  useEffect(() => {
    setPage(1);
  }, [filters]);

  const onChange = (e, type) => {
    setFilters((prev) => {
      return { ...prev, [type]: e };
    });
  };

  useEffect(() => {
    if (data) {
      const newData = data.brands.edges.map(({ node }) => {
        return {
          actions: {
            function: () => {
              setSelectedBrand(node);
              if (
                (!node?.commission || node?.commission === 0.0) &&
                (!node?.zaamoCommission || node?.zaamoCommission === 0.0)
              ) {
                toast.warning("Add commission first");
                return;
              }
              setModalShow(true);
            },
            name: "Edit",
          },
          restrictOrder: {
            function: async (bool, data) => {
              const res = await restrictUpdate({
                variables: {
                  id: node?.id,
                  private: JSON.stringify({
                    ...data,
                    orders_not_allowed: bool,
                  }),
                },
              });
              if (res.data?.brandUpdate)
                toast.success("Order Restriction Updated");
            },
            checkbox: true,
            data: JSON.parse(node?.privateMetadata),
            bool:
              JSON.parse(node?.privateMetadata)?.orders_not_allowed ?? false,
          },
          image: {
            img: node?.image?.url ?? "/assets/images/dummyLogo.png",
            id: node?.id,
          },
          generateBOTD: {
            function: () => {
              setSelectedBrand(node);
              setBOTDmodal(true);
            },
            name: "Generate",
          },
          brandName: node?.brandName ?? "--",
          brandSource: node?.brandSource ?? "--",
          brandBarter: node?.brandBarter ? "TRUE" : "FALSE",
          botd: node?.botd ? "TRUE" : "FALSE",
          brandCommission: {
            data: node?.commission,
            id: node?.id,
            zaamoCommission: node?.zaamoCommission,
          },
          cod: {
            function: () => {
              setSelectedBrand(node);
              setCodModal(true);
            },
            name: node?.cod ? node?.codBasePrice || "--" : "--",
          },
          // cod: node?.codBasePrice ?? "--",
          status: node?.status ?? "--",
          tooManyOrders: node?.tooManyOrders ? "TRUE" : "FALSE",
          return:
            JSON.parse(node?.metadata)?.return &&
            JSON.parse(node?.metadata)?.return.toUpperCase() !== "FALSE"
              ? "TRUE"
              : "FALSE",
          exchange:
            JSON.parse(node?.metadata)?.exchange &&
            JSON.parse(node?.metadata)?.exchange.toUpperCase() !== "FALSE"
              ? "TRUE"
              : "FALSE",
          brandStaffMembers: {
            data: node?.brandStaffMembers,
            id: node?.id,
          },
          brandEmails: {
            data: node?.brandEmails?.edges,
            id: node?.id,
          },
          brandShipping: {
            function: () => {
              setBrandShipping({
                id: node?.id,
                data: node?.brandShipping?.edges,
              });
              setShowShipping(true);
            },
            name: "View Shipping",
          },
          pickupAddress: {
            function: () => {
              setPickupAddress({
                id: node?.id,
                data: node?.pickupAddress,
              });
              setShowPickupAddress(true);
            },
            name: "Pickup Address",
          },
          updatedBy: node?.updatedBy?.email ?? "--",
          brandOrderInfo: node?.brandOrderInfo ?? "--",
          shortDescription: node?.shortDescription ?? "--",
          orderProcessingDays: node?.orderProcessingDays ?? "--",
          orderShippingDays: node?.orderShippingDays ?? "--",
          brandBarterGuidelines: node?.brandBarterGuidelines ?? "--",
          returnPolicy: {
            function: () => {
              setBrandPolicy({
                policyTitle: "Return Policy",
                policy:
                  JSON.parse(node?.shippingReturnPolicy).return_policy ?? "--",
              });
              setShowPolicy(true);
            },
            name: JSON.parse(node?.shippingReturnPolicy).return_policy
              ? "View Return Policy"
              : "--",
          },
          shippingPolicy: {
            function: () => {
              setBrandPolicy({
                policyTitle: "Shipping Policy",
                policy:
                  JSON.parse(node?.shippingReturnPolicy).shipping_policy ??
                  "--",
              });
              setShowPolicy(true);
            },
            name: JSON.parse(node?.shippingReturnPolicy).shipping_policy
              ? "View Shipping Policy"
              : "--",
          },
          downloadSourcing: {
            link: {
              name: "Sourcing",
              url: `${process.env.REACT_APP_GRAPHQL_BASE_URL.replace(
                "/graphql/",
                ""
              )}/csv/export_csv_for_brand_dashboard?brand_id=${node.id}`,
            },
          },
          ledgerCreation: {
            function: () => {
              setSelectedBrand(node);
              setViewLedger(true);
            },
            name: `Create ${
              node?.lastBrandPaymentTimestamp
                ? node?.lastBrandPaymentTimestamp
                : ""
            }`,
          },
          instagramLink: {
            name: JSON.parse(node?.metadata)?.instagram_link ?? "--",
            url: JSON.parse(node?.metadata)?.instagram_link ?? "--",
          },
          postPay: {
            name:
              JSON.parse(node?.metadata)?.postpay &&
              JSON.parse(node?.metadata)?.postpay.toUpperCase() !== "FALSE"
                ? "TRUE"
                : "FALSE",
          },
          postPayType: {
            name: JSON.parse(node?.metadata)?.postpay_type ?? "--",
          },
          contact: {
            function: () => {
              setSelectedBrand(node);
              setShowContact(true);
            },
            name: "View Contact",
          },
          step_active:
            JSON.parse(node?.metadata)?.step_active &&
            JSON.stringify(
              JSON.parse(node?.metadata)?.step_active
            )?.toUpperCase() !== "FALSE"
              ? "TRUE"
              : "FALSE",
          historyNotes: {
            function: () => {
              setHistoryNotesModal({
                display: true,
                brandId: node?.id,
                note: node?.historyNotes,
              });
            },
            name: (
              <div style={{ display: "flex" }}>
                {node?.historyNotes ? (
                  node?.historyNotes?.length > 40 ? (
                    <p title={node?.historyNotes}>
                      {node?.historyNotes?.slice(0, 40) + "..."}
                    </p>
                  ) : (
                    node?.historyNotes
                  )
                ) : (
                  "--"
                )}{" "}
                <ReactSVG
                  style={{ padding: "1px" }}
                  src="/assets/images/edit.svg"
                />
              </div>
            ),
          },
          content_tagging:
            JSON.parse(node?.metadata)?.content_tagging &&
            JSON.stringify(
              JSON.parse(node?.metadata)?.content_tagging
            )?.toUpperCase() !== "FALSE"
              ? "TRUE"
              : "FALSE",
          importance: node?.importance ?? "--",
          GSTDetails: {
            name: "Edit Details",
            function: () => {
              setShowGstModal(node);
            },
          },
        };
      });
      setBrandsData(newData);
    }
  }, [data, page]);

  if (loading) return <PacmanLoader />;
  if (error || restrictErr) return <ErrorPage />;

  return (
    <>
      <Layout
        pagination={{
          hasNext:
            data?.brands?.pageInfo?.hasNextPage ||
            page * 100 < data?.brands?.edges?.length,
          current: page,
          onNextClick: () => {
            setPage(page + 1);
            fetchMore({
              variables: {
                endCursor: data?.brands?.pageInfo?.endCursor,
              },
            });
          },
          onPrevClick: () => setPage(page - 1),
        }}
        desktop
      >
        <Filters onChange={onChange} defaultValue={{ ...filters }} />
        <Table
          currentPage={page}
          displayPageSize={100}
          data={brandsData}
          from="brands"
        />
        <ReactModal
          isOpen={modalShow}
          onRequestClose={() => setModalShow(false)}
          style={S.Modal}
        >
          <EditBrand close={() => setModalShow(false)} brand={selectedBrand} />
        </ReactModal>
        <ReactModal
          isOpen={showShipping}
          onRequestClose={() => setShowShipping(false)}
          style={S.Modal}
        >
          <EditShipping
            shipping={brandShipping?.data}
            id={brandShipping?.id}
            close={() => setShowShipping(false)}
          />
        </ReactModal>
        <ReactModal
          isOpen={showPickupAddress}
          onRequestClose={() => setShowPickupAddress(false)}
          style={S.Modal}
        >
          <PickupAddress
            pickupAddress={pickupAddress}
            close={() => setShowPickupAddress(false)}
          />
        </ReactModal>
        <ReactModal
          isOpen={showPolicy}
          onRequestClose={() => setShowPolicy(false)}
          style={S.Modal}
        >
          <ViewPolicy
            title={brandPolicy?.policyTitle}
            policy={brandPolicy?.policy}
          />
        </ReactModal>
        <ReactModal
          isOpen={viewLedger}
          onRequestClose={() => setViewLedger(false)}
          style={S.Modal}
        >
          <CreateLedger id={selectedBrand?.id} />
        </ReactModal>
        <ReactModal
          isOpen={showContact}
          onRequestClose={() => setShowContact(false)}
          style={{
            ...S.Modal,
            content: { ...S.Modal.content, maxWidth: "660px" },
          }}
        >
          <ViewContact
            name={selectedBrand?.brandContactName ?? "--"}
            number={selectedBrand?.brandContactNumber ?? "--"}
            brandId={selectedBrand?.id ?? "--"}
          />
        </ReactModal>
        <ReactModal
          isOpen={codModal}
          onRequestClose={() => setCodModal(false)}
          style={S.Modal}
        >
          <CodModal
            id={selectedBrand?.id}
            setModal={setCodModal}
            cod={selectedBrand?.cod}
            price={selectedBrand?.codBasePrice}
            refetch={refetch}
          />
        </ReactModal>
        <ReactModal
          isOpen={BOTDmodal}
          onRequestClose={() => setBOTDmodal(false)}
          style={S.Modal}
        >
          <BOTDModal />
        </ReactModal>
        <ReactModal
          isOpen={historyNotesModal?.display}
          onRequestClose={() => setHistoryNotesModal({ display: false })}
          style={S.Modal}
        >
          <HistoryNotesModal
            historyNoteDefault={historyNotesModal?.note}
            brandId={historyNotesModal?.brandId}
            setHistoryNotesModal={setHistoryNotesModal}
          />
        </ReactModal>
        <ReactModal
          isOpen={!!showGstModal}
          onRequestClose={() => setShowGstModal(null)}
          style={S.Modal}
        >
          <GstDetailsModal
            brand={showGstModal}
            setShowGstModal={setShowGstModal}
          />
        </ReactModal>
      </Layout>
    </>
  );
};

export default BrandsDashboard;
