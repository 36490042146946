import * as S from "./styles";
import Switch from "../../components/Switch";

function Edit() {
  return (
    <S.Edit>
      <S.Row>
        <S.Cell>
          <S.Value>600</S.Value>
          <S.Small>Visits</S.Small>
        </S.Cell>
        <S.Cell>
          <S.Value>600</S.Value>
          <S.Small>Whishlists</S.Small>
        </S.Cell>
        <S.Cell>
          <S.Value>600</S.Value>
          <S.Small>Orders</S.Small>
        </S.Cell>
        <S.Cell>
          <S.Value>15</S.Value>
          <S.Small>Stores with this product</S.Small>
        </S.Cell>
      </S.Row>
      <S.Row style={{ alignItems: "center" }}>
        <S.Cell>
          <S.Value>21-Nov</S.Value>
          <S.Small>Date since live</S.Small>
        </S.Cell>
        <S.Row>
          <S.Small>Barter</S.Small>
          <Switch checked />
        </S.Row>
        <S.Row>
          <S.Small>Visibility</S.Small>
          <Switch />
        </S.Row>
      </S.Row>
    </S.Edit>
  );
}

export default Edit;
