import * as S from "./styles";

const CouponConditions = ({ conditions }) => {
  return (
    <S.ConditionsWrapper>
      <S.ConditionsInnerWrapper>
        <S.Heading>Terms & Conditions</S.Heading>
        <S.Conditions dangerouslySetInnerHTML={{ __html: conditions }} />
      </S.ConditionsInnerWrapper>
    </S.ConditionsWrapper>
  );
};
export default CouponConditions;
