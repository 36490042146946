import { useCallback, useEffect, useState } from "react";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_MASTER_DASHBOARD_DATA, GET_USER_DATA } from "./queries";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth/useAuth";

const useMasterDashboard = () => {
  const navigate = useNavigate();
  const [time, setTime] = useState("");
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });
  const [sortBy, setSortBy] = useState({
    label: "Creation Date",
    value: "CREATION_DATE",
  });
  const [statusFilter, setStatusFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [endCursor, setEndCursor] = useState("");
  const [hasNextPage, setHasNextPage] = useState("");
  const [loading3, setLoading3] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});
  const [statusDict, setStatusDict] = useState({});
  const [voucher, setVoucher] = useState("");
  const [productName, setProductName] = useState("");

  const auth = useAuth();

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const [getMasterdashboardData, { data: data2, loading: loading2, error: error2, fetchMore }] =
    useLazyQuery(GET_MASTER_DASHBOARD_DATA, {
      notifyOnNetworkStatusChange: true,
    });

  const parsedData2 = [];

  const setStoreId = () => {
    if (data) {
      if (!localStorage.getItem("store-id")) {
        const storeId = data?.userByMobile?.authorisedStores[0]?.id;
        const id = window.atob(storeId).replace("Store:", "");
        localStorage.setItem("store-id", id);
      }
    }
  };

  useEffect(() => {
    if (data) {
      if (data?.userByMobile?.userType !== "BRAND") {
        toast.error("You don't have permission to access this page!");
        localStorage.clear();
        navigate("/brand");
      } else setStoreId();
    }
  }, [data]);

  useEffect(() => {
    if (data) {
      fetchData();
      setPage(1);
    }
    //eslint-disable-next-line
  }, [
    time?.value,
    direction?.value,
    sortBy?.value,
    statusFilter,
    voucher,
    data,
    search,
    productName,
  ]);

  useEffect(() => {
    if (data2?.masterDashboard?.pageInfo) {
      setEndCursor(data2.masterDashboard.pageInfo.endCursor);
      setHasNextPage(data2.masterDashboard.pageInfo.hasNextPage);
      setLoading3(false);
    }
  }, [data2]);

  useEffect(() => {
    const temp = { ...statusDict };
    data2?.masterDashboard?.edges.map((e, i) => {
      e?.node?.fulfillments?.map((j) => {
        if (j?.lines[0]?.orderLine && !temp[j.lines[0].orderLine?.id]) {
          temp[j.lines[0].orderLine?.id] = j?.status;
        }
      });
    });
    setStatusDict(temp);
  }, [data2]);

  const getMasterdashboardFunction = async () => {
    await getMasterdashboardData({
      variables: {
        timePeriod: time ? time.value : undefined,
        brands: data?.userByMobile?.authorisedBrands?.map((brand) => brand.id),
        direction: direction?.value,
        sortBy: sortBy?.value,
        voucher: voucher?.value ?? undefined,
        search: search,
        status: statusFilter?.map((e) => e.value),
        productName,
      },
    });
  };

  const onChange = (value, type) => {
    if (type === "TIME") setTime(value);
    else if (type === "DIR") setDirection(value);
    else if (type === "SORT") setSortBy(value);
    else if (type === "VOUCHER") setVoucher(value);
    else if (type === "SEARCH") setSearch(value);
    else if (type === "STATUS") setStatusFilter(value);
    else if (type === "PRODUCT") setProductName(value);
  };

  const fetchData = () => {
    console.log("fetching...");
    getMasterdashboardFunction();
  };

  const fetchMoreData = () => {
    data2.masterDashboard.pageInfo.hasNextPage && setLoading3(true);
    data2.masterDashboard.pageInfo.hasNextPage &&
      fetchMore({
        variables: {
          endCursor: endCursor,
        },
      });
    setPage(page + 1);
  };

  const clearAll = () => {
    setTime("");
    setDirection({ label: "Desc", value: "DESC" });
    setSortBy({
      label: "Creation Date",
      value: "CREATION_DATE",
    });
    setVoucher("");
    setStatusFilter([]);
    setSearch("");
    setProductName("");
  };

  let commissionDict = {};
  const returnParsedData = (e, i) => {
    e?.node?.fulfillments?.map((f) => {
      commissionDict[f?.lines[0]?.orderLine?.id] = f?.lines[0]?.orderLine?.commission;
    });
    return {
      orderId: e?.node.id ?? "--",
      store: e.node?.store ?? "--",
      source: e.node?.source ? (e.node?.source === "INFLUENCER_HOME" ? "IH" : "IS") : "--",
      discountName: e.node.discount.discountName ?? "--",
      voucher: e.node?.voucher?.name ?? "--",
      created: e.node?.created ?? "--",
      customerName: e?.node?.billingAddress?.firstName ?? "--",
      billingAddress: `${e?.node?.billingAddress?.streetAddress1 ?? "--"}, ${
        e?.node?.billingAddress?.city ?? "--"
      }, ${e?.node?.billingAddress?.postalCode ?? "--"}`,
      commission: e?.node?.fulfillments[0]?.lines[0]?.orderLine ?? "--",
      customerPhone: e?.node?.billingAddress?.phone ?? "--",
      customerEmail: e?.node?.billingAddress?.email ?? "--",
    };
  };

  const returnParsedLines = (data, dataAll, i) => {
    const commonData = { ...returnParsedData(dataAll, i) };
    data.filter((e) => e.length == 0 && alert("hi"));
    let temp = data.map((e, j) => ({
      thumbnail: {
        img: e?.thumbnail?.url ?? "/assets/images/dummyLogo.png",
      },
      productName: e?.productName ?? "--",
      quantity: e?.quantity ?? "--",
      MRP: e?.variant?.costPrice?.amount ?? "--",
      MSP: e?.variant?.price?.amount ?? "--",
      shippingPrice: e?.shippingPrice ?? "--",
      mspShipping: parseFloat(e?.variant?.price?.amount ?? 0) + parseFloat(e?.shippingPrice ?? 0),
      barterOrder: commonData.voucher.includes("SZ") ? "Yes" : "No",
      status: {
        function: () =>
          commonData?.orderId
            ? toggleOrderStatusModal({
                orderId: atob(commonData.orderId).replace("MasterDashboard:", ""),
                id: commonData.orderId,
                lineId: e?.id,
              })
            : null,
        name: statusDict[e.id] ?? "--",
      },
      created: commonData?.created,
      customerName: commonData?.customerName,
      customerAddress: commonData?.billingAddress,
      customerPhone: commonData?.customerPhone,
      customerEmail: commonData?.customerEmail,
    }));
    return temp;
  };

  data2?.masterDashboard?.edges.map((e, i) => {
    parsedData2.push(...returnParsedLines(e.node?.lines, e, i));
  });

  const toggleOrderStatusModal = useCallback(
    (data) => {
      setModalShow(true);
      setModalData({
        orderId: data?.orderId,
        orderIdOriginal: data?.id,
        lineId: data?.lineId,
        statusDict: statusDict,
        setStatusDict: setStatusDict,
        setModalShow: setModalShow,
      });
    },
    //eslint-disable-next-line
    [modalShow, modalData, statusDict]
  );

  return {
    time,
    sortBy,
    direction,
    page,
    setPage,
    endCursor,
    hasNextPage,
    loading3,
    data,
    loading,
    error,
    data2,
    loading2,
    error2,
    onChange,
    fetchData,
    fetchMore,
    fetchMoreData,
    clearAll,
    parsedData2,
    modalData,
    modalShow,
    setModalData,
    setModalShow,
    setStatusDict,
    statusDict,
    voucher,
    auth,
  };
};

export default useMasterDashboard;
