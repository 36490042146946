import { gql } from "@apollo/client";

export const UPDATE_CONTENT_AND_STATUS = gql`
  mutation UpdateContentAndStatus(
    $id: ID!
    $content: String
    $status: SourcingRequestStatusEnum
  ) {
    sourcingRequestUpdate(id: $id, content: $content, status: $status) {
      sourcingRequest {
        status
        content
      }
    }
  }
`;

export const UPDATE_RECOMMENDED = gql`
  mutation UpdateRecommend($id: ID!, $bool: Boolean) {
    sourcingRequestUpdate(id: $id, recommended: $bool) {
      sourcingRequest {
        recommended
      }
    }
  }
`;

export const UPDATE_NEXT_DATA = gql`
  mutation UpdateNextData($id: ID!, $nextDate: Date, $nextTask: String) {
    sourcingRequestUpdate(id: $id, nextDate: $nextDate, nextTask: $nextTask) {
      sourcingRequest {
        id
        status
        nextTask
        nextDate
      }
    }
  }
`;
export const CREATE_PRODUCT_NOTIFICATION = gql`
  mutation createNotificationTrigger($sourcingId: ID!, $content: String) {
    notificationTrigger(
      input: {
        notificationType: PRODUCT_SOURCING
        sourcingId: $sourcingId
        content: $content
      }
    ) {
      success
    }
  }
`;
