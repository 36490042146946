import * as S from "./styles";

const CommentBox = ({ data }) => {
  return (
    <S.Wrapper>
      <h6>{data?.node?.user?.email}</h6>
      <S.GreyBg>
        <p>{data?.node?.comment}</p>
      </S.GreyBg>
      <S.Time>
        {new Date(data?.node?.createdAt).toDateString().substring(4)}
      </S.Time>
    </S.Wrapper>
  );
};

export default CommentBox;
