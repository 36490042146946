import { useState } from "react";
import Layout from "../../components/Layout";
import * as S from "./styles";
import { Button } from "../BulkDeals/styles";

const ShopifyAnalytics = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  return (
    <Layout desktop>
      <S.Contain>
        <h2 style={{ marginBottom: "20px" }}>Export Shopify Analytics CSV</h2>
        <S.Label>Start Date</S.Label>
        <S.Input
          name="date-start"
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          required
        />
        <br />
        <br />
        <S.Label>End Date</S.Label>
        <S.Input
          name="date-end"
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          required
        />
        <br />
        <br />
        {startDate && endDate ? (
          <a
            href={`${process.env.REACT_APP_GRAPHQL_BASE_URL?.replace(
              "/graphql/",
              ""
            )}/csv/export_shopify_product_details?start_date=${startDate}&end_date=${endDate}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button style={S.BtnStyle}>Export</Button>
          </a>
        ) : (
          <Button disabled style={S.BtnStyle}>
            Export
          </Button>
        )}
      </S.Contain>
    </Layout>
  );
};

export default ShopifyAnalytics;
