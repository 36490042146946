import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: ${({desktop}) => desktop ? "100%" : "500px"};
  margin: auto;
  min-height: 100vh;
  background: #fff;
  padding-top: 52px;
  padding-bottom: 60px;
`;
