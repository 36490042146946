import * as S from "./styles";

import Layout from "../../components/Layout";
import CouponCard from "../../components/CouponCard";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import {
  GET_COLLECTIONS_BY_STORE,
  GET_COUPONS_DATA,
  GET_USER_BRANDS,
} from "./queries";
import PacmanLoader from "../../components/PackmanLoader";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import moment from "moment";
import { useEffect, useState } from "react";
import Filters from "./Filters";
import useAuth from "../../hooks/useAuth/useAuth";
import { filterVars } from "../../reactiveVariables";
import { getDecodedJson, getEncodedJson } from "../../utils/utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Loader } from "../../components/PackmanLoader/styles";

function Coupons() {
  const [page, setPage] = useState(1);
  const [collectionFilter, setCollectionFilter] = useState([]);
  const [stores, setStores] = useState([]);
  const [collections, setCollections] = useState([]);
  const [brands, setBrands] = useState([]);
  const [brandsData, setBrandsData] = useState([]);
  const [loading3, setLoading3] = useState(false);
  const [storesData, setStoresData] = useState({});
  const [search, setSearch] = useState("");

  const location = useLocation();
  const navigate = useNavigate();

  const {
    data: data2,
    loading: loading2,
    error: error2,
  } = useQuery(GET_USER_BRANDS, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const { data, loading, error, fetchMore } = useQuery(GET_COUPONS_DATA, {
    skip: !data2,
    variables: {
      brands: brands?.map((e) => e.value),
      collections: collections?.map((e) => e.value),
      stores: stores?.map((e) => e.value),
      search: search,
    },
  });

  const [
    getCollectionsByStore,
    {
      data: collectionsData,
      loading: collectionsDataLoading,
      error: collectionsDataError,
    },
  ] = useLazyQuery(GET_COLLECTIONS_BY_STORE);

  const filters = useReactiveVar(filterVars);

  useEffect(() => {
    if (collectionsData) {
      let temp = collectionsData?.collectionAnalytics?.edges?.map(
        (collection) => collection.node
      );
      console.log(temp, "get collection by store id");
      setCollectionFilter(temp);
    }
  }, [collectionsData]);

  useEffect(() => {
    filters?.collections && setCollections(filters?.collections);
    filters?.brands && setBrands(filters?.brands);
  }, []);

  // useEffect(() => {
  //   console.log(data2);
  //   if (data2) {
  //     getVoucherAnalytics({
  //       variables: {
  //         brands: brands.map((e) => e.value),
  //         collections: collections.map((e) => e.value),
  //         stores: stores.map((e) => e.value),
  //       },
  //     });
  //   }
  // }, [data2]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("store_filters")) {
      const temp = getDecodedJson(params.get("store_filters"));
      if (data2) {
        let temp2 = {};
        data2?.userByMobile?.authorisedStores.map((e) => {
          temp2[e.id] = { value: e.id, label: e.storeName };
        });
        let temp3 = [];
        for (let i in temp) {
          if (temp2[temp[i]]) {
            temp3.push(temp2[temp[i]]);
          } else {
            navigate({
              pathname: location.pathname,
              search: ``,
            });
            temp3 = [];
            break;
          }
        }
        setStores(temp3);
        // onStoreChange(temp3);
      }
    }
  }, [location.search, data2]);

  // useEffect(() => {
  //   getVoucherAnalytics({
  //     variables: {
  //       brands: brands.map((i) => i.value),
  //       collections: collections.map((i) => i.value),
  //       stores: stores.map((e) => e.value),
  //     },
  //   });
  // }, [stores, collections, brands]);

  useEffect(() => {
    if (stores.length) {
      getCollectionsByStore({
        variables: {
          storeIds: stores.map((store) => store.value),
        },
      });
    }
  }, [stores]);

  // if (loading || loading2 || loading3) return <PacmanLoader />;
  if (error || error2) return <ErrorPage />;

  let voucherData = data?.vouchers,
    vouchers = voucherData?.edges,
    endCursor = voucherData?.pageInfo?.endCursor,
    hasMore = voucherData?.pageInfo?.hasNextPage;

  const onNextPage = () => {
    if (hasMore && vouchers.length <= page * 10) {
      fetchMore({
        variables: {
          endCursor,
        },
      });
    }
    setPage(page + 1);
  };

  const onPrevPage = () => {
    setPage(page - 1);
  };

  const onStoreChange = (e) => {
    setStores(e);
    // setLoading3(true);

    getCollectionsByStore({
      variables: {
        storeIds: e.map((store) => store.value),
      },
    });

    const params = getEncodedJson([...e].map((e) => e.value));
    navigate({
      pathname: location.pathname,
      search: `?store_filters=${params}`,
    });

    setPage(1);
  };

  const onBrandChange = (e) => {
    setBrands(e);
    console.log(brandsData);
    // setLoading3(true);

    setPage(1);
  };

  const onCollectionChange = (e) => {
    // setLoading3(true);
    setCollections(e);
    setPage(1);
  };

  const filtersData = {
    brands: data2?.userByMobile?.authorisedBrands ?? [],
    onBrandChange: onBrandChange,
    brandsValue: brands,
    collectionFilter: collectionFilter,
    onCollectionChange: onCollectionChange,
    collectionsValue: collections,
    storesValue: stores,
    stores: data2?.userByMobile?.authorisedStores ?? [],
    onStoreChange: onStoreChange,
    search,
    setSearch,
  };

  return (
    <Layout
      hideBack={false}
      title="Coupons"
      pagination={{
        hasNext: hasMore || vouchers?.length > (page - 1) * 10 + 10,
        current: page,
        onNextClick: onNextPage,
        onPrevClick: onPrevPage,
      }}
      backUrl="/prod/analytics/store"
    >
      {loading || loading2 || loading3 ? <Loader /> : null}
      <Filters {...filtersData} />
      <S.Wrapper>
        {vouchers?.length ? (
          vouchers
            ?.slice(
              (page - 1) * 10,
              Math.min((page - 1) * 10 + 10, vouchers.length)
            )
            .map((voucher) => {
              const node = voucher.node;
              const key = node.id;
              const props = {
                id: node.id,
                name: node.name,
                storeName: node?.store?.storeName,
                amount: node.discountValue,
                code: node.code,
                validUntil: moment(node.endDate).format("MMM Do YYYY, hh:mm"),
                amountType: node.discountValueType,
                active: node.active,
                used: node?.used,
                type: node?.type,
              };

              return <CouponCard {...props} key={key} />;
            })
        ) : (
          <S.NoData>No data found!</S.NoData>
        )}
      </S.Wrapper>
    </Layout>
  );
}

export default Coupons;
