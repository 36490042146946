import { useMutation } from "@apollo/client";
import React, { memo, useState } from "react";
import { toast } from "react-toastify";
import * as S from "./styles";
import Button from "../../../../components/Button";
import { CREATE_FRESHDESK_TICKET } from "./mutation";

function FreshDeskTicket({ setFreshDeskTicketModal, defaultData }) {
  const [ticket, setTicket] = useState("");
  const [createFreshDeskTicket] = useMutation(CREATE_FRESHDESK_TICKET);

  const createFreshDeskTicketHandler = async () => {
    const resp = await createFreshDeskTicket({
      variables: {
        id: defaultData?.id,
        note: ticket,
      },
    });
    if (resp?.data?.createOrderFreshdeskTicket?.orderErrors?.length === 0) {
      toast.success("Created ticket successfully!");
      setFreshDeskTicketModal({ display: false });
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <S.Heading>Fresh Desk Ticket</S.Heading>
      <S.TextField
        onChange={(e) => setTicket(e.target.value)}
        value={ticket}
        placeholder="Type Here"
      />
      <Button onClick={createFreshDeskTicketHandler}>Update</Button>
    </>
  );
}

export default memo(FreshDeskTicket);
