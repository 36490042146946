import React from "react";
import domtoimage from "dom-to-image";
import * as S from "./styles";

const SafeImg = (props) => {
  return (
    <img
      {...props}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = "/assets/images/dummyLogo.png";
      }}
    />
  );
};

export const BOTDModal = () => {
  const [image1, setImage1] = React.useState("");
  const [image2, setImage2] = React.useState("");
  const [image3, setImage3] = React.useState("");
  const [image4, setImage4] = React.useState("");
  const [brand, setBrand] = React.useState("");

  const previewRef = React.useRef(null);

  const noInput = !image1 && !image2 && !image3 && !image4;

  const handleDownload = () => {
    domtoimage.toBlob(previewRef.current).then(function (blob) {
      // window.saveAs(blob, "my-node.png");
      const a = document.createElement("a");
      a.href = window.URL.createObjectURL(blob);
      a.download = "BOTD-grid-image";
      a.click();
    });
  };
  return (
    <S.Wrapper>
      <S.Title>Preview</S.Title>
      <S.Preview ref={previewRef}>
        <SafeImg src={image1} alt="first image" />
        <SafeImg src={image2} alt="second image" />
        <SafeImg src={image3} alt="third image" />
        <SafeImg src={image4} alt="fourth image" />
        {!!brand ? <div>{brand}</div> : null}
      </S.Preview>
      <S.Title>Image URLs</S.Title>
      <S.Input
        type="text"
        placeholder="Image 1"
        value={image1}
        onChange={(e) => setImage1(e.target.value)}
      />
      <S.Input
        type="text"
        placeholder="Image 2"
        value={image2}
        onChange={(e) => setImage2(e.target.value)}
      />
      <S.Input
        type="text"
        placeholder="Image 3"
        value={image3}
        onChange={(e) => setImage3(e.target.value)}
      />
      <S.Input
        type="text"
        placeholder="Image 4"
        value={image4}
        onChange={(e) => setImage4(e.target.value)}
      />
      <S.Title>Brand</S.Title>
      <S.Input
        type="text"
        placeholder="Brand Name"
        value={brand}
        onChange={(e) => setBrand(e.target.value)}
      />
      <S.SaveButton disabled={noInput} onClick={() => handleDownload()}>
        Generate
      </S.SaveButton>
    </S.Wrapper>
  );
};
