import * as S from "./styles";

import {
  boolOptions,
  brandImportanceOptions,
  postpaytypeOptions,
  statusOptions,
} from "../Filters/index";
import { useMemo, useState } from "react";

import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { EDIT_BRAND } from "./mutations";
import { GET_DASHBOARD_BRANDS } from "../queries";
import { client } from "../../../apollo-client";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

const EditBrand = ({ close, brand }) => {
  const [editBrand, { error }] = useMutation(EDIT_BRAND);

  const quality_assured = JSON.parse(brand?.metadata)?.quality_assured;
  const timely_delivery = JSON.parse(brand?.metadata)?.timely_delivery;
  const postpay = JSON.parse(brand?.metadata)?.postpay;
  const postpaytype = JSON.parse(brand?.metadata)?.postpay_type;
  const easy_exchange = JSON.parse(brand?.metadata)?.easy_exchange;
  const zaamo_shipping = JSON.parse(brand?.metadata)?.zaamo_shipping;
  const _return = JSON.parse(brand?.metadata)?.return;
  const exchange = JSON.parse(brand?.metadata)?.exchange;
  const step_active = JSON.parse(brand?.metadata)?.step_active;
  const content_tagging = JSON.parse(brand?.metadata)?.content_tagging;

  const noCommission =
    (!brand?.commission || brand?.commission === 0.0) &&
    (!brand?.zaamoCommission || brand?.zaamoCommission === 0.0);
  //formated data for updation
  const [brandData, setBrandData] = useState({
    id: brand?.id ?? "",
    brandName: brand?.brandName ?? "",
    brandOrderInfo: brand?.brandOrderInfo ?? "",
    shortDescription: brand?.shortDescription ?? "",
    brandBarterGuidelines: brand?.brandBarterGuidelines ?? "",
    orderProcessingDays: brand?.orderProcessingDays ?? "",
    orderShippingDays: brand?.orderShippingDays ?? "",
    status: { label: brand?.status, value: brand?.status },
    brandBarter: {
      label: brand?.brandBarter ? "True" : "False",
      value: brand?.brandBarter,
    },
    botd: { label: brand?.botd ? "True" : "False", value: brand?.botd },
    tooManyOrders: {
      label: brand?.tooManyOrders ? "True" : "False",
      value: brand?.tooManyOrders,
    },
    returnPolicy: JSON.parse(brand?.shippingReturnPolicy).return_policy ?? "--",
    shippingPolicy:
      JSON.parse(brand?.shippingReturnPolicy).shipping_policy ?? "--",
    instagram_link: JSON.parse(brand?.metadata)?.instagram_link || "",
    postpay:
      postpay === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    postpaytype:
      postpaytype === "shipping"
        ? { label: "Shipping", value: "shipping" }
        : { label: "Delivered", value: "delivered" },
    quality_assured:
      quality_assured === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    timely_delivery:
      timely_delivery === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    easy_exchange:
      easy_exchange === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    zaamo_shipping:
      zaamo_shipping === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    return:
      _return === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    exchange:
      exchange === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    step_active:
      step_active === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    content_tagging:
      content_tagging === "true"
        ? { label: "True", value: true }
        : { label: "False", value: false },
    importance: brand?.importance
      ? brandImportanceOptions.find((e) => e.value == brand?.importance)
      : undefined,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initial = useMemo(() => brandData, []);

  const onChange = (value, type) => {
    if (type === "postpay") {
      setBrandData({
        ...brandData,
        postpay: value,
        postpaytype: { label: "Delivered", value: "delivered" },
      });
      return;
    }
    setBrandData({ ...brandData, [type]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (noCommission) {
      toast.warning("Please add commissions first!");
      return;
    }
    try {
      const res = await editBrand({
        variables: {
          ...brandData,
          brandBarter: brandData.brandBarter.value,
          botd: brandData.botd.value,
          tooManyOrders: brandData.tooManyOrders.value,
          status: brandData.status.value,
          shippingReturnPolicy: {
            return_policy: brandData.returnPolicy,
            shipping_policy: brandData.shippingPolicy,
          },
          quality_assured: brandData?.quality_assured?.value,
          timely_delivery: brandData?.timely_delivery?.value,
          instagram_link: brandData?.instagram_link,
          postpay: brandData?.postpay.value,
          postpay_type: brandData?.postpaytype.value,
          easy_exchange: brandData?.easy_exchange.value,
          zaamo_shipping: brandData?.zaamo_shipping.value,
          return: brandData?.return.value,
          exchange: brandData?.exchange.value,
          step_active: brandData?.step_active.value,
          content_tagging: brandData?.content_tagging.value,
          importance: brandData?.importance?.value,
        },
      });
      if (res.data?.brandUpdate) {
        toast.success("Brand Edited successfully!");
        // client.refetchQueries({
        //   include: [GET_DASHBOARD_BRANDS],
        // });
      }
      close();
    } catch (err) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };

  return (
    <form onSubmit={handleSubmit} style={{ fontSize: "0.8rem" }}>
      <S.Label>Edit Brand {noCommission}</S.Label>
      <S.TextLabel>Brand Name</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "brandName")}
        value={brandData.brandName}
      />
      <S.TextLabel>Brand Order Info</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "brandOrderInfo")}
        value={brandData.brandOrderInfo}
      />
      <S.TextLabel>Short Description</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "shortDescription")}
        value={brandData.shortDescription}
      />
      <S.TextLabel>Brand Barter Guidelines</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "brandBarterGuidelines")}
        value={brandData.brandBarterGuidelines}
      />
      <S.TextLabel>Order Processing Days</S.TextLabel>
      <S.TextField
        type="number"
        required
        onChange={(e) => onChange(e.target.value, "orderProcessingDays")}
        value={brandData.orderProcessingDays}
      />
      <S.TextLabel>Order Shipping Days</S.TextLabel>
      <S.TextField
        type="number"
        required
        onChange={(e) => onChange(e.target.value, "orderShippingDays")}
        value={brandData.orderShippingDays}
      />
      <S.TextLabel>Return Policy</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "returnPolicy")}
        value={brandData.returnPolicy}
      />
      <S.TextLabel>Shipping Policy</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "shippingPolicy")}
        value={brandData.shippingPolicy}
      />
      <S.TextLabel>Instagram Link</S.TextLabel>
      <S.TextField
        onChange={(e) => onChange(e.target.value, "instagram_link")}
        value={brandData.instagram_link}
      />
      <S.Flex>
        <div>
          <S.TextLabel>Brand Barter</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "brandBarter")}
            value="value"
            label="label"
            defaultValue={brandData.brandBarter}
          />
        </div>
        <div>
          <S.TextLabel>BOTD</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "botd")}
            value="value"
            label="label"
            defaultValue={brandData.botd}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <div>
          <S.TextLabel>Too Many Orders</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "tooManyOrders")}
            value="value"
            label="label"
            defaultValue={brandData.tooManyOrders}
          />
        </div>
        <div>
          <S.TextLabel>Status</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={statusOptions}
            onChange={(e) => onChange(e, "status")}
            value="value"
            label="label"
            defaultValue={brandData.status}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <div>
          <S.TextLabel>Quality Assured</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "quality_assured")}
            value="value"
            label="label"
            defaultValue={brandData?.quality_assured}
          />
        </div>
        <div>
          <S.TextLabel>Timely Delivery</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "timely_delivery")}
            value="value"
            label="label"
            defaultValue={brandData?.timely_delivery}
          />
        </div>
        <div>
          <S.TextLabel>Postpay</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "postpay")}
            value="value"
            label="label"
            defaultValue={brandData?.postpay}
          />
        </div>
        <div
          style={{
            opacity: brandData?.postpay.value ? "1" : "0.4",
            pointerEvents: brandData?.postpay.value ? "all" : "none",
          }}
        >
          <S.TextLabel>Postpay Type</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={postpaytypeOptions}
            onChange={(e) => onChange(e, "postpaytype")}
            value="value"
            label="label"
            defaultValue={brandData?.postpaytype}
          />
        </div>
        <div>
          <S.TextLabel>Easy Exchange</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "easy_exchange")}
            value="value"
            label="label"
            defaultValue={brandData?.easy_exchange}
          />
        </div>
        <div>
          <S.TextLabel>Zaamo Shipping</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "zaamo_shipping")}
            value="value"
            label="label"
            defaultValue={brandData?.zaamo_shipping}
          />
        </div>
        <div>
          <S.TextLabel>Return</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "return")}
            value="value"
            label="label"
            defaultValue={brandData.return}
          />
        </div>
        <div>
          <S.TextLabel>Exchange</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "exchange")}
            value="value"
            label="label"
            defaultValue={brandData.exchange}
          />
        </div>
        <div>
          <S.TextLabel>Step Active</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "step_active")}
            value="value"
            label="label"
            defaultValue={brandData.step_active}
          />
        </div>
        <div>
          <S.TextLabel>Content Tagging</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={boolOptions}
            onChange={(e) => onChange(e, "content_tagging")}
            value="value"
            label="label"
            defaultValue={brandData.content_tagging}
          />
        </div>
        <div>
          <S.TextLabel>Brand Importance</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={brandImportanceOptions}
            onChange={(e) => onChange(e, "importance")}
            value="value"
            label="label"
            defaultValue={brandData.importance}
          />
        </div>
      </S.Flex>
      <Button
        disabled={JSON.stringify(brandData) === JSON.stringify(initial)}
        style={{ margin: "10px 0px" }}
      >
        Save
      </Button>
    </form>
  );
};

export default EditBrand;
