import styled from "styled-components";

export const Label = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 15px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const TextLabel = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.8rem;
`;

export const TextField = styled.input`
  width: 100%;
  padding: 10px;
  margin: 5px 0px 12px;
  border: 1px solid #000;
`;
export const TextArea = styled.textarea`
  width: 100%;
  min-height: 7rem;
  padding: 10px;
  margin: 5px 0px 12px;
  border: 1px solid #000;
  border-radius: 5px;
`;