import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  max-height: 100vh;
  min-height: 80vh;
  gap: 1rem;
  /* margin: 2rem 1rem; */
  /* height: 90vh; */
  /* border: 1px solid red; */

  #partitioned {
    padding: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(
      to left,
      black 70%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 230px;
    min-width: 220px;
  }

  #partitioned:focus {
    border: none;
    outline: none;
  }

  #divInner {
    left: 0;
    position: sticky;
  }

  #divOuter {
    margin: auto;
    width: 200px;
    overflow: hidden;
  }
`;
export const ImgContainer = styled.div``;

export const PhoneNumber = styled.div`
  font-weight: bold;
  font-size: 24px;
`;
export const TextDiv = styled.div`
  color: black;
  font-weight: normal;
  padding-top: 1rem;
  /* padding-bottom: 2rem; */
  font-size: 16px;

  span {
    font-weight: bold;
  }

  span2 {
  }
`;

export const BottomTextDiv = styled.div`
  color: black;
  font-weight: normal;
  padding-top: 1rem;
  padding-bottom: 2rem;
  font-size: 16px;

  button {
    font-weight: bold;
    background: none !important;
    border: none;
    padding: 0 !important;
    font-size: 16px;
    font-weight: bold;
    color: black;
    text-decoration: underline;
    cursor: pointer;
  }
`;
export const FormStyled = styled.span`
  color: grey;
  font-size: 16px;
`;

export const TakeInput = styled.input`
  font-size: 18px;
  padding: 10px;
  margin: 2rem;
  border: none;
  border-bottom: 1px solid black;
  border-radius: 3px;

  &.partitioned {
    padding-left: 15px;
    letter-spacing: 42px;
    border: 0;
    background-image: linear-gradient(
      to left,
      black 70%,
      rgba(255, 255, 255, 0) 0%
    );
    background-position: bottom;
    background-size: 50px 1px;
    background-repeat: repeat-x;
    background-position-x: 35px;
    width: 220px;
    min-width: 220px;
    color: black;
    &:focus {
      border: none;
    }
  }
`;

export const Label = styled.label`
  margin: 0px auto 8px 0px;
`;