import * as S from "./styles";

import Dropdown from "../../../components/Dropdown";
import { Link } from "react-router-dom";
import { statusEnum } from "../../StoreAnalytics/StoreManagerActions";

const Filters = (props) => {
  // console.log(props.defaultValue);
  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Select Brands</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={props?.data?.authorisedBrands}
            value="id"
            label="brandName"
            onChange={(e) => props.onChange(e, "brands")}
            placeholder="Select Brand"
            defaultValue={props.defaultValue?.brands}
            isMulti
          />
        </div>
        <div>
          <label>Select Stores</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={props?.data?.authorisedStores}
            value="id"
            label="storeName"
            onChange={(e) => props.onChange(e, "stores")}
            placeholder="Select Stores"
            defaultValue={props.defaultValue?.stores}
            isMulti
          />
        </div>
        <div>
          <label>Created By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={props.createdIds.map(({ node }) => ({
              id: node?.id,
              email: node?.email,
            }))}
            value="id"
            label="email"
            onChange={(e) => props.onChange(e, "createdBy")}
            placeholder="Select Stores"
            isMulti
          />
        </div>
        <div>
          <label>State</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={stateOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "state")}
            placeholder="State"
            defaultValue={props.defaultValue?.state}
            isClearable
          />
        </div>
        <div>
          <label>Store Bucket</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={statusEnum}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "storeBucket")}
            placeholder="Store Bucket"
            defaultValue={props.defaultValue?.storeBucket}
            isClearable
          />
        </div>
        <div>
          <label>Brand Collab</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={collabOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "brandCollab")}
            placeholder="Brand Collab"
            defaultValue={props.defaultValue.brandCollab}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "direction")}
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <S.Button
          onClick={props.open}
        >
          Create Deal
        </S.Button>
        <Link to={`/prod/dashboard/bulk_deal/stores`} style={{ textDecoration: "none" }}>
          <S.Button
            onClick={props.open}
          >
            Create Bulk Deals
          </S.Button>
        </Link>
      </S.Flex>
    </S.Div>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const stateOptions = [
  { label: "Request Received", value: "REQUEST_RECEIVED" },
  {
    label: "Accept",
    value: "ACCEPT",
  },
  {
    label: "Reject",
    value: "REJECT",
  },
];

const collabOptions = [
  { label: "YES", value: "YES" },
  { label: "NO", value: "NO" },
];

export default Filters;
