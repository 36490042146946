import { toast } from "react-toastify";
import * as S from "./styles";
import { ReactSVG } from "react-svg";
import moment from "moment";

const CouponCard = ({
  code,
  status,
  discount,
  isPercentage,
  name,
  description,
  valid,
}) => {
  return (
    <S.CardWrapper>
      <S.InnerCardWrapper>
        <S.Status status={status === "Active"}>{status}</S.Status>
        <S.Mid>
          <S.Discount>{name}</S.Discount>
          <div>{description}</div>
        </S.Mid>
        <S.Divide />
        <S.Bottom>
          <S.Code>{code}</S.Code>
          <a
            onClick={() => {
              toast.success("Code Copied");
            }}
          >
            <S.CopyCode text={code}>
              {/* <p> */}
              <S.CopyButton size="l">
                <S.HiddenSpace src="/images/link-icon.svg" />
                <p>copy code</p>
                <ReactSVG src="/images/link-icon.svg" />
                {/* </p> */}
              </S.CopyButton>
            </S.CopyCode>
          </a>
          <S.Valid>
            Valid untill {moment(valid).format("MMM Do YYYY, h:mm A")}
          </S.Valid>
        </S.Bottom>
      </S.InnerCardWrapper>
    </S.CardWrapper>
  );
};

export default CouponCard;
