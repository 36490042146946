import { useState } from "react";

export default function CreateLedger({ id }) {
  const [endDate, setEndDate] = useState(
    new Date().toISOString().substring(0, 10)
  );

  return (
    <div>
      <h4>Create Ledger Upto </h4>
      <div
        style={{
          display: "flex",
          placeContent: "center",
          gap: "10px",
          marginTop: "10px",
          height: "50px",
        }}
      >
        <input
          style={{ padding: "10px", minWidth: "50%" }}
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          max={new Date().toISOString().substring(0, 10)}
        />
        <button
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_GRAPHQL_BASE_URL.replace(
                "/graphql/",
                ""
              )}/csv/export_csv_for_brand_ledger?brand_id=${id}&end_date=${endDate}`
            );
          }}
          style={{ width: "50%", padding: "12px" }}
        >
          Save
        </button>
        <button
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_GRAPHQL_BASE_URL.replace(
                "/graphql/",
                ""
              )}/csv/export_csv_for_brand_ledger?brand_id=${id}&send_to_brand=true`
            );
          }}
          style={{ width: "50%", padding: "12px" }}
        >
          Send Ledger
        </button>
      </div>
    </div>
  );
}
