import { gql } from "@apollo/client";

export const CREATE_BULK_DEAL = gql`mutation brandSourceCreate(
    $brandID: ID!
    $stores: [ID]!
    $termsAndConditions: String!
    $campaignName: String!
  ) {
    brandSourceRequestBulkCreate(
      input: {
        stores: $stores
        brand: $brandID
        termsAndConditions: $termsAndConditions
        campaignName: $campaignName
      }
    ) {
      count
      sourcingRequests {
        id
        termsAndConditions
        store {
          storeName
        }
        brand {
          brandName
        }
      }
    }
  }
  
`