import * as S from "../BrandSourcing/styles";

import {
  ACTIVATE_ACCOUNT,
  GET_INFLUENCERS,
  GET_INFLUENCERS_FILTERED,
  GET_INFLUENCERS_SEARCH_FIELD,
} from "./queries";
import { useEffect, useState } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";

import EditDate from "./EditDate";
import EditStatus from "./EditStatus";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Filters from "./Filters";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import ReactModal from "react-modal";
import Table from "../../components/Table";
import { toast } from "react-toastify";

export default function InfluencerDashboard() {
  const [page, setPage] = useState(1);
  const [influencers, setInfluencers] = useState([]);
  const [showDate, setShowDate] = useState(false);
  const [showStatus, setShowStatus] = useState(false);
  const [currentInf, setCurrentInf] = useState(null);
  const [filters, setFilters] = useState({
    search: "",
    direction: { label: "DESC", value: "DESC" },
    field: { label: "UPDATED_AT", value: "UPDATED_AT" },
    status: { label: "REQUEST_RECEIVED", value: "REQUEST_RECEIVED" },
  });

  const { data, loading, error, fetchMore } = useQuery(GET_INFLUENCERS, {
    // notifyOnNetworkStatusChange: true,
    variables: {
      search: filters.search,
      direction: filters.direction?.value,
      field: filters.field?.value,
      status: filters?.status?.value,
    },
  });
  const [activateAccount, { data: data2, loading: loading2, error: error2 }] =
    useMutation(ACTIVATE_ACCOUNT);

  // const [fetchFilteredData, { data, loading, error, fetchMore }] = useLazyQuery(GET_INFLUENCERS)

  const onChange = (value, type) => {
    setPage(1);
    setFilters((prev) => {
      return { ...prev, [type]: value };
    });
  };

  const handleAccountActivation = async (node) => {
    const username = node?.instagramLink
      ? String(node?.instagramLink).split("www.instagram.com/")[1]
      : "";

    if (username === "") {
      toast.error("Instagram profile not available");
      return;
    }
    const res = await activateAccount({
      variables: {
        mobileNo: node?.user?.mobileNo,
        instagramUserId: username,
        instagramUsername: username,
      },
    });
    if (res) {
      toast.success("Account activated successfully");
    }
  };

  useEffect(() => {
    if (data) {
      const influencerData = data?.influencers?.edges?.map(({ node }) => ({
        instagramUsername: node?.instagramUsername,
        instagram: {
          link: {
            url: node?.instagramLink ? node?.instagramLink : "--",
            name: node?.instagramLink ? node?.instagramLink : "--",
          },
        },
        state:
          node?.state === 0
            ? {
                link: {
                  url: `/prod/influencer/signup?mobile=${node?.user?.mobileNo}`,
                  name: "store not created",
                },
              }
            : "store created",
        activateAccount: {
          status: node?.status,
          onClick: () => handleAccountActivation(node),
        },
        dateOfBirth: {
          function: () => {
            setCurrentInf(node);
            setShowDate(true);
          },
          name:
            node?.metadata && node?.metadata.includes("date-of-birth")
              ? JSON.parse(node?.metadata)["date-of-birth"]
              : "--",
        },
        mobileNo: node?.user?.mobileNo ?? "--",
        createdAt: node?.createdAt ?? "--",
        status: {
          function: () => {
            setCurrentInf(node);
            setShowStatus(true);
          },
          name: node?.status,
        },
      }));
      setInfluencers(influencerData);
    }
  }, [data, page, filters]);

  if (loading) return <PacmanLoader />;
  if (error) return <ErrorPage />;

  return (
    <Layout
      pagination={{
        hasNext:
          data?.influencers?.pageInfo?.hasNextPage ||
          page * 100 < data?.influencers?.edges?.length,
        current: page,
        onNextClick: () => {
          setPage(page + 1);
          fetchMore({
            variables: {
              endCursor: data?.influencers?.pageInfo?.endCursor,
            },
          });
        },
        onPrevClick: () => setPage(page - 1),
      }}
      desktop
    >
      <Filters
        onChange={onChange}
        defaultValue={{
          ...filters,
        }}
      />
      <Table
        from="influencer"
        displayPageSize={100}
        currentPage={page}
        data={influencers}
      />
      <ReactModal
        isOpen={showDate}
        onRequestClose={() => setShowDate(false)}
        style={S.Modal}
      >
        <EditDate influencer={currentInf} close={() => setShowDate(false)} />
      </ReactModal>
      <ReactModal
        isOpen={showStatus}
        onRequestClose={() => setShowStatus(false)}
        style={S.Modal}
      >
        <EditStatus
          influencer={currentInf}
          close={() => setShowStatus(false)}
        />
      </ReactModal>
    </Layout>
  );
}
