import { gql } from "@apollo/client";

export const REGISTER_USER = gql`
  mutation RegisterUser($no: String!) {
    userRegister(input: { mobileNo: $no, isActive: true }) {
      user {
        id
        isActive
      }
      accountErrors {
        message
        field
      }
    }
  }
`;

export const ACTIVATE_BRAND = gql`
  mutation ActivateBrand($no: String!, $brandId: ID!) {
    activateBrandAccount(input: { mobileNo: $no, brandId: $brandId }) {
      user {
        id
        mobileNo
        isActive
      }
    }
  }
`;

export const ADD_CONTACT = gql`
  mutation BrandUpdate($mobileNo: String!, $userName: String!, $type: BrandMobileTypeEnum!, $id: ID!){
  brandUpdate(input:{id: $id, mobiles:{ type: $type, mobileNo: $mobileNo, userName: $userName }}){
    brand{
      id
      brandName
    }
  }
}
`;

