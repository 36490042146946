import { gql } from "@apollo/client";

export const CREATE_COUPON = gql`
  mutation CreateCoupon(
    $name: String
    $type: VoucherTypeEnum!
    $discountValueType: DiscountValueTypeEnum
    $discountValue: PositiveDecimal
    $startDate: DateTime
    $endDate: DateTime
    $applyOncePerOrder: Boolean
    $applyOncePerCustomer: Boolean
    $usageLimit: Int
    $maxDiscountValue: PositiveDecimal
    $owner: VoucherOwnerEnum
    $products: [ID]
    $stores: [String]!
    $isShipping: Boolean
    $metadata: [MetadataInput]
  ) {
    voucherBulkCreate(
      input: {
        name: $name
        type: $type
        code: $name
        discountValueType: $discountValueType
        discountValue: $discountValue
        startDate: $startDate
        endDate: $endDate
        applyOncePerOrder: $applyOncePerOrder
        applyOncePerCustomer: $applyOncePerCustomer
        usageLimit: $usageLimit
        maxDiscountValue: $maxDiscountValue
        owner: $owner
        products: $products
        metadata: $metadata
      }
      stores: $stores
      isShipping: $isShipping
    ) {
      success
    }
  }
`;
export const CREATE_BULK_VOUCHER = gql`
  mutation voucherBulkCreate(
    $name: String
    $type: VoucherTypeEnum!
    $discountValueType: DiscountValueTypeEnum
    $discountValue: PositiveDecimal
    $startDate: DateTime
    $endDate: DateTime
    $maxDiscountValue: PositiveDecimal
    $owner: VoucherOwnerEnum
    $stores: [String]!
    $maxProductsAllowed: Int
    $brandId: [ID]
    $applyOncePerOrder: Boolean
    $applyOncePerCustomer: Boolean
    $usageLimit: Int
    $isShipping: Boolean
    $metadata: [MetadataInput]
  ) {
    voucherBulkCreate(
      input: {
        name: $name
        type: $type
        code: $name
        discountValueType: $discountValueType
        discountValue: $discountValue
        maxDiscountValue: $maxDiscountValue
        startDate: $startDate
        endDate: $endDate
        owner: $owner
        brands: $brandId
        applyOncePerOrder: $applyOncePerOrder
        applyOncePerCustomer: $applyOncePerCustomer
        usageLimit: $usageLimit
        metadata: $metadata
      }
      maxProductsAllowed: $maxProductsAllowed
      stores: $stores
      isShipping: $isShipping
    ) {
      success
    }
  }
`;

export const CREATE_CUSTOM_COUPON = gql`
  mutation CreateCoupon(
    $name: String
    $code: String
    $type: VoucherTypeEnum!
    $discountValueType: DiscountValueTypeEnum
    $discountValue: PositiveDecimal
    $startDate: DateTime
    $endDate: DateTime
    $applyOncePerOrder: Boolean
    $applyOncePerCustomer: Boolean
    $usageLimit: Int
    $maxDiscountValue: PositiveDecimal
    $owner: VoucherOwnerEnum
    $stores: [String]!
    $isShipping: Boolean
    $metadata: [MetadataInput]
    $minAmountSpent: PositiveDecimal
    $minCheckoutItemsQuantity: Int
  ) {
    voucherBulkCreate(
      input: {
        name: $name
        code: $code
        type: $type
        discountValueType: $discountValueType
        discountValue: $discountValue
        startDate: $startDate
        endDate: $endDate
        applyOncePerOrder: $applyOncePerOrder
        applyOncePerCustomer: $applyOncePerCustomer
        usageLimit: $usageLimit
        maxDiscountValue: $maxDiscountValue
        owner: $owner
        metadata: $metadata
        minAmountSpent: $minAmountSpent
        minCheckoutItemsQuantity: $minCheckoutItemsQuantity
      }
      stores: $stores
      isShipping: $isShipping
    ) {
      success
    }
  }
`;

export const CREATE_MIXED = gql`
  mutation CreateCoupon(
    $name: String
    $type: VoucherTypeEnum!
    $discountValueType: DiscountValueTypeEnum
    $discountValue: PositiveDecimal
    $startDate: DateTime
    $endDate: DateTime
    $applyOncePerOrder: Boolean
    $applyOncePerCustomer: Boolean
    $maxDiscountValue: PositiveDecimal
    $owner: VoucherOwnerEnum
    $products: [ID]
    $brands: [ID]
    $categories: [ID]
    $collection: [ID]
    $isShipping: Boolean
    $metadata: [MetadataInput]
    $minAmountSpent: PositiveDecimal
    $discountNProducts: Int
    $buyMProducts: Int
    $dealType: DealTypeEnum
    $yPercentDiscount: Int
  ) {
    voucherBulkCreate(
      input: {
        name: $name
        type: $type
        code: $name
        discountValueType: $discountValueType
        discountValue: $discountValue
        startDate: $startDate
        endDate: $endDate
        applyOncePerOrder: $applyOncePerOrder
        applyOncePerCustomer: $applyOncePerCustomer
        maxDiscountValue: $maxDiscountValue
        owner: $owner
        products: $products
        brands: $brands
        categories: $categories
        collections: $collection
        metadata: $metadata
        minAmountSpent: $minAmountSpent
      }
      isShipping: $isShipping
      discountNProducts: $discountNProducts
      buyMProducts: $buyMProducts
      dealType: $dealType
      yPercentDiscount: $yPercentDiscount
    ) {
      success
      voucherObjectList {
        id
      }
    }
  }
`;
