import { gql } from "@apollo/client";

export const UPDATE_ORDER_NOTE = gql`
  mutation FulfillmentNote($id: ID!, $note: String!) {
    orderFulfillmentNote(id: $id, note: $note) {
      fulfillmentLine {
        id
        note
        noteCreator {
          id
          email
        }
      }
      orderErrors {
        field
        message
      }
    }
  }
`;
