import { gql } from "@apollo/client";

export const GET_INFLUENCERS = gql`
  query GetInfluencers(
    $search: String
    $direction: OrderDirection!
    $field: InfluencerSortField!
    $endCursor: String
    $status: InfluencerStatusEnum!
  ) {
    influencers(
      first: 100
      after: $endCursor
      sortBy: { direction: $direction, field: $field }
      filter: { status: $status, search: $search }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          instagramUsername
          instagramLink
          status
          metadata
          user {
            mobileNo
          }
          createdAt
        }
      }
    }
  }
`;
export const ACTIVATE_ACCOUNT = gql`
  mutation activateInfluencerAccount(
    $mobileNo: String!
    $instagramUserId: String
    $instagramUsername: String
  ) {
    activateAccount(
      input: {
        mobileNo: $mobileNo
        instagramUserId: $instagramUserId
        instagramUsername: $instagramUsername
        storeBarter: false
        storeManagerEmail: "evon.nelson@zaamo.co"
      }
    ) {
      user {
        id
        influencer {
          id
          state
          status
        }
      }
    }
  }
`;
