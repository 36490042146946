import { useState } from "react";
import Dropdown from "../../../components/Dropdown";
import Search from "../../CustomerSupport/Search";
import * as S from "./styles";

const Filters = (props) => {
  const [search, setSearch] = useState("");

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Store Barter</label>
          <Dropdown
            style={btnStyle}
            data={boolOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "storeBarter")}
            placeholder="Store Barter"
            defaultValue={props.defaultValue.storeBarter}
            isClearable
          />
        </div>
        <div>
          <label>Stop Source With Zaamo</label>
          <Dropdown
            style={btnStyle}
            data={stopSource}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "stopSource")}
            placeholder="Stop Source"
            defaultValue={props.defaultValue.stopSource}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={btnStyle}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "dir")}
            placeholder="Store Barter"
            defaultValue={props.defaultValue.dir}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={btnStyle}
            data={sortBy}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "field")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.field}
          />
        </div>
        <div>
          <label>Search Store</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.SearchInput value={search} onChange={(e) => setSearch(e.target.value)} />
            <div
              onClick={() => props.onChange(search, "search")}
              style={{ position: "absolute", right: "15px", cursor: "pointer" }}
            >
              <Search />
            </div>
          </div>
        </div>
      </S.Flex>
    </S.Div>
  );
};

const btnStyle = { minWidth: "150px", margin: "5px", maxWidth: "200px" };

const sortDirections = [
  { value: "DESC", label: "Desc" },
  { value: "ASC", label: "Asc" },
];

const sortBy = [
  { value: "GMV_LAST_24_HOUR", label: "GMV Last 24 Hours" },
  { value: "GMV_LAST_7_DAYS", label: "GMV Last 7 Days" },
  { value: "GMV_LAST_30_DAYS", label: "GMV Last 30 Days" },
];

const boolOptions = [
  { label: "True", value: true },
  { label: "False", value: false },
];

const stopSource = [
  { label: "True", value: false },
  { label: "False", value: true },
];

export default Filters;
