import * as S from "./styles";

function Switch({ checked, onClick }) {
  return (
    <S.Wrapper onClick={onClick} checked={checked}>
      <S.Thumb checked={checked} />
    </S.Wrapper>
  );
}

export default Switch;
