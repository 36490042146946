import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { CREATEDBY_IDS } from "../../BrandSourcing/queries";
import { GET_STORES } from "../queries";
import { CREATE_STAFF } from "./mutations";
import * as S from "./styles";

export default function EditStaff({ id, staff, close }) {
  //current staff the brand
  const [selectedStaff, setSelectdStaff] = useState(staff);
  //all the staff members for dropdown
  const { data, loading, error } = useQuery(CREATEDBY_IDS);
  //create new staff
  const [createStaff, { error: createErr }] = useMutation(CREATE_STAFF);

  const handleSubmit = async () => {
    if (selectedStaff.length > 0) {
      const res = await createStaff({
        variables: {
          id: id,
          staff: selectedStaff.map((staff) => staff.value),
        },
      });
      if (res.data?.storeManagerCreate) {
        toast.success("Staff updated successfully!");
        client.refetchQueries({
          include: [GET_STORES],
        });
      }
      close();
    } else toast.warning("Please select atleast one staff member!");
  };

  if (error || createErr) return <p>Something went wrong!</p>;

  return (
    <>
      <S.Label>Edit Staff Members</S.Label>
      <S.Flex>
        {loading ? (
          <S.SpinContainer>
            <S.Spinner />
          </S.SpinContainer>
        ) : (
          <div style={{ display: "grid", gap: "5px" }}>
            <S.TextLabel>Staff Members</S.TextLabel>
            <Dropdown
              style={{ width: "400px" }}
              data={data?.staffUsers?.edges?.map(({ node }) => node)}
              value="id"
              label="email"
              onChange={(e) => setSelectdStaff(e)}
              placeholder="Select Stores"
              defaultValue={selectedStaff}
              isMulti
            />
            <Button
              disabled={JSON.stringify(staff) === JSON.stringify(selectedStaff)}
              onClick={handleSubmit}
              style={{ marginTop: "10px" }}
            >
              Save
            </Button>
          </div>
        )}
      </S.Flex>
    </>
  );
}
