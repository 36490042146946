import { gql } from "@apollo/client";

export const CREATE_DEAL = gql`
  mutation CreateDeal(
    $store: ID!
    $brand: ID!
    $termsAndConditions: String!
    $campaignName: String!
  ) {
    brandSourceRequestCreate(
      input: {
        store: $store
        brand: $brand
        termsAndConditions: $termsAndConditions
        campaignName: $campaignName
      }
    ) {
      brandSourceRequest {
        id
        termsAndConditions
        store {
          id
        }
        brand {
          id
        }
        createdAt
        createdBy {
          id
        }
        brandCollab
        brandManagers
        storeManagers
        storeBucket
      }
    }
  }
`;

export const CREATE_BRAND_NOTIFICATION = gql`
  mutation createNotificationTrigger($sourcingId: ID!) {
    notificationTrigger(
      input: { notificationType: BRAND_SOURCING, sourcingId: $sourcingId }
    ) {
      success
    }
  }
`;
