import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import { GET_DASHBOARD_BRANDS } from "../../../screens/BrandsDashboard/queries";
import * as S from "./styles";
import Dropdown from "../../Dropdown";
import { CREATE_EMAIL, DELETE_EMAIL, UPDATE_EMAIL } from "./mutations";

export default function EmailItem({ id, emailValue }) {
  const [email, setEmail] = useState(emailValue);
  const [createEmail, { error }] = useMutation(CREATE_EMAIL);
  const [updateEmail, { error: updateErr }] = useMutation(UPDATE_EMAIL);
  const [deleteEmail, { error: delErr }] = useMutation(DELETE_EMAIL);

  const refetch = () => {
    client.refetchQueries({
      include: [GET_DASHBOARD_BRANDS],
    });
  };

  const handleCreate = async () => {
    if (!email || email.brandEmail.length === 0 || !email.state) return toast.error("Fill in the fields!");
    const res = await createEmail({
      variables: {
        brandId: id,
        email: email.brandEmail,
        state: email.state,
      },
    });
    if (res.data?.brandEmailCreate) {
      toast.success("Email Created!");
      refetch();
    }
  };

  const handleUpdate = async () => {
    if (email.brandEmail.length === 0 || !email.state) return toast.error("Fill in the fields!");
    const res = await updateEmail({
      variables: {
        emailId: email.id,
        email: email.brandEmail,
        state: email.state,
      },
    });
    if (res.data?.brandEmailUpdate) {
      toast.success("Email Updated!");
      refetch();
    }
  };

  const handleDelete = async () => {
    const res = await deleteEmail({
      variables: {
        emailId: email.id,
      },
    });
    if (res.data?.brandEmailDelete) {
      toast.success("Email Deleted!");
      refetch();
    }
  };

  useEffect(() => {
    if (error?.message || updateErr?.message || delErr?.message)
      toast.error("Something went wrong!");
  }, [error, updateErr, delErr]);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        marginTop: "10px",
      }}
    >
      <div style={{ width: "50%" }}>
        <S.TextLabel>Email</S.TextLabel>
        <input
          style={{ padding: "10px", border: "1px solid #CFD2CF" }}
          placeholder="Email"
          type="text"
          onChange={(e) => setEmail({ ...email, brandEmail: e.target.value })}
          value={email?.brandEmail}
        />
      </div>
      <div style={{ width: "50%" }}>
        <S.TextLabel>State</S.TextLabel>
        <Dropdown
          value="value"
          label="label"
          data={options}
          defaultValue={email ? { label: email?.state, value: email?.state } : null}
          onChange={(e) => setEmail({ ...email, state: e.value })}
          placeholder="Select State"
        />
      </div>
      {emailValue ? (
        <S.Btn
          disabled={JSON.stringify(emailValue) === JSON.stringify(email)}
          onClick={handleUpdate}
        >
          Update
        </S.Btn>
      ) : (
        <S.Btn style={{ width: "38%" }} onClick={handleCreate}>
          Create
        </S.Btn>
      )}
      {emailValue ? <S.Btn onClick={handleDelete}>Delete</S.Btn> : null}
    </div>
  );
}

const options = [
  { label: "SUPPORT", value: "SUPPORT" },
  { label: "PRIMARY", value: "PRIMARY" },
  { label: "SECONDARY", value: "SECONDARY" },
];
