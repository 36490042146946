import styled from "styled-components";

export const SearchInput = styled.input`
  padding: 1rem 1rem;
  flex: 1;
  border-radius: 5px;

  border: none;
  outline: none;
  background: #f1f1f1;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  position: relative;
  /* animation-name: anim; */
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
`;

export const Order = styled.div`
  border-bottom: 6px solid #00000011;
`;

export const NoResult = styled.div`
  height: 75vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Dot = styled.div`
  width: 0.7rem;
  height: 0.7rem;
  background: #000;
  border-radius: 50%;
  margin-right: 0.5rem;
`;

export const Orderid = styled.div`
  font-size: 0.9rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: "100%";
`;

export const DetailsBorder = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem;
  border: 2px solid #000;
  padding: 10px;
  box-shadow: 2px 2px;
  border-radius: 10px;
`;

export const OrderInfo = styled.div`
  display: flex;
  font-size: 0.7rem;
  white-space: initial;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin: 0.5rem 0rem;
  padding: 0rem 1rem;
`;

export const Card = styled.div`
  margin: 0.6rem 0;
  padding: 0rem 1rem;
`;

export const CardMain = styled.div`
  display: flex;
  align-items: center;
  gap: 0.6rem;
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      margin-right: 1rem;
    }
  }
  padding-bottom: 1rem;
  padding-top: 1rem;
  font-size: 12px;
`;

export const NoOfItems = styled.div`
  width: 1.2rem;
  height: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 50%;
  position: absolute;
  right: -1px;
  bottom: 1px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
`;

export const Right = styled.div`
  flex: 1;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
`;

export const Left = styled.div`
  position: relative;
`;

export const Price = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
`;

export const PropName = styled.div`
  font-size: 0.7rem;
`;

export const PropValue = styled.div`
  color: gray;
  font-size: 0.7rem;
`;

export const Image = styled.img`
  object-fit: cover;
  border-radius: 10px;
  height: 4.5rem;
  width: 4.5rem;
`;
export const CardTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CardMid = styled.div``;

export const CardBottom = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 0.6rem;
`;

export const CardHeading = styled.p`
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 0.35rem;
`;

export const CardText = styled.p`
  color: gray;
`;

export const BottomDetails = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px 5px;
`;

export const BottomDetailsCard = styled.div`
  background: #f3f3f3;
  padding: 8px 6px 8px 6px;
  display: flex;
  align-items: center;
  border-radius: 6px;
  margin-right: 0.6rem;
  flex-wrap: wrap;
`;

export const RefundInitiated = styled.button`
  background-color: rgba(243, 189, 135, 0.6);
  font-size: 0.7rem;
  padding: 8px 6px 8px 6px;
  border: none;
  border-radius: 6px;
  align-items: center;
  font-weight: bold;
  color: rgba(57, 57, 57, 0.9);
`

export const InitiateRefund = styled.button`
  font-size: 0.7rem;
  padding: 8px 6px 8px 6px;
  border: none;
  font-weight: bolder;
  border-radius: 6px;
  align-items: center;
  background-color: #43bb47;
  color: #f3f3f3;
  &:hover {
    cursor: pointer;
  }
`

export const RefundAmount = styled.div`
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 4px 4px 4px;
  background-color: rgba(99, 74, 140, 0.5);
  color: floralwhite;
  border-radius: 4px;
  font-weight: bolder;
  font-size: 0.65rem;
`

export const ViewDetailsButton = styled.div`
  color: gray;
  text-decoration: underline;
  font-size: 0.7rem;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
`;

export const Label = styled.div`
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
  font-weight: bold;
`;

export const Value = styled.div`
  font-size: 0.8rem;
  color: gray;
`;

export const Details = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 100,
    padding: "20px",
  },
  overlay: { zIndex: 100, backgroundColor: "rgb(0 0 0 / 75%)" },
};
