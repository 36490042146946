import { useState } from "react";
import ShippingItem from "./ShippingItem";

export default function EditShipping({ id, shipping, close }) {
  const [brandShipping, setBrandShipping] = useState(shipping);

  return (
    <div>
      <h3 style={{ margin: "10px 0px" }}>Edit Brand Shipping Details</h3>
      {brandShipping.map(({ node }, index) => {
        return (
          <div style={{ margin: "20px 0px 0px" }}>
            <h5 style={{ marginBottom: "10px" }}>Shipping Details {index + 1}: </h5>
            <ShippingItem id={id} key={index} shippingValue={node} close={close} />
          </div>
        );
      })}
      {brandShipping.length < 1 && (
        <h5
          style={{ marginTop: "10px", cursor: "pointer" }}
          onClick={() => {
            setBrandShipping((prev) => {
              return [...prev, { node: null }];
            });
          }}
        >
          Add Shipping Details
        </h5>
      )}
    </div>
  );
}
