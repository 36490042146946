import { gql } from "@apollo/client";

export const GET_USER_STORES = gql`
  query UserByMobile($mobileNo: String!) {
    userByMobile(mobileNo: $mobileNo) {
      authorisedStores {
        id
        storeName
      }
    }
  }
`;

export const GET_COLLECTION_ANALYTICS = gql`
  query CollectionAnalytics(
    $stores: [ID]
    $endCursor: String
    $direction: OrderDirection!
    $sortBy: CollectionSortField!
  ) {
    collectionAnalytics(
      first: 10
      filter: { stores: $stores }
      after: $endCursor
      sortBy: { direction: $direction, field: $sortBy }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          name
          noOfProducts
          totalCollectionViews
          totalViewOfProductPages
          totalProductPurchaseCount
          dateOfLastVisit
          dateOfLastPurchase
          createdAt
          updatedAt
          imageUrl
        }
      }
    }
  }
`;
