import { gql } from "@apollo/client";

export const CREATE_STAFF = gql`
  mutation CreateStaff($id: ID!, $staff: [ID]!) {
    storeManagerCreate(input: { store: $id, users: $staff }) {
      store {
        storeName
        storeManagers {
          id
          email
        }
      }
    }
  }
`;
