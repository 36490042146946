import { gql } from "@apollo/client";

export const GET_DASHBOARD_BRANDS = gql`
  query GetDashboardBrands(
    $search: String
    $barter: Boolean
    $orders: Boolean
    $commission: String
    $field: BrandSortField!
    $direction: OrderDirection!
    $endCursor: String
    $status: [BrandStatusEnum]
    $importance: [BrandImportanceEnum]
  ) {
    brands(
      first: 100
      after: $endCursor
      filter: {
        search: $search
        brandBarter: $barter
        tooManyOrders: $orders
        commission: $commission
        status: $status
        duplicateBrand: false
        importance: $importance
      }
      sortBy: { field: $field, direction: $direction }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          cod
          historyNotes
          codBasePrice
          privateMetadata
          metadata
          brandName
          brandSource
          brandBarter
          brandOrderInfo
          active
          botd
          zaamoCommission
          commission
          status
          shortDescription
          shippingReturnPolicy
          image {
            url
          }
          orderProcessingDays
          orderShippingDays
          brandBarterGuidelines
          tooManyOrders
          importance
          brandStaffMembers {
            id
            email
          }
          brandContactNumber
          brandContactName
          updatedBy {
            email
          }
          lastBrandPaymentTimestamp
          brandEmails(first: 10) {
            edges {
              node {
                brandEmail
                id
                state
              }
            }
          }
          mobiles {
            id
            userName
            mobileNo
          }
          pickupAddress {
            firstName
            lastName
            email
            streetAddress1
            city
            countryArea
            phone
            postalCode
          }
          brandShipping(first: 10) {
            edges {
              node {
                id
                homeStatePincode
                shippingCostSameState {
                  amount
                }
                shippingCostOtherState {
                  amount
                }
                minOrderValueFreeCost {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const GET_USER_DATA = gql`
  query UserData($mobileNo: String!) {
    userByMobile(mobileNo: $mobileNo) {
      id
      userType
      isActive
      authorisedBrands {
        id
        brandName
      }
      authorisedStores {
        id
        storeName
      }
    }
  }
`;
