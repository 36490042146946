import { ReactSVG } from "react-svg";
import Button from "../../../../components/Button";
import useImageUrlValidator from "../../../../hooks/useImageUrlValidator";
import * as S from "./styles";

export default function ProductCard({
  imageURL,
  productName,
  brandName,
  productId,
  price,
  priceUndiscounted,
  discountPercentage,
  outOfStock,
  commission,
  isThrift,
  searchImage,
  isTmo,
  isBrandBarter,
  isDeal,
  selected,
  setProduct,
}) {
  const { isImageUrlValid, loading: imgLoading } = useImageUrlValidator(imageURL);

  const handleAction = () => {
    if (!selected) {
      setProduct((prev) => {
        return {
          ...prev,
          products: [...prev?.products, { label: productName, value: productId }],
        };
      });
    } else {
      setProduct((prev) => {
        const newProducts = prev.products?.filter((prod) => prod.value !== productId);
        return { ...prev, products: newProducts };
      });
    }
  };

  return (
    <S.Overlay>
      <S.OutterWrapper id={productId}>
        <S.Wrapper
          className="product-card-wrapper"
          bgImg={
            !imgLoading && (searchImage ?? (isImageUrlValid ? imageURL : "/images/dummyImage.png"))
          }
          grey={outOfStock}
        >
          {outOfStock && (
            <S.OutOfStock>
              <p> Out of Stock</p>
            </S.OutOfStock>
          )}
          <S.ProductDetails>
            {discountPercentage ? (
              <S.Ribbon>
                <ReactSVG src="assets/images/ribbon.svg" />
                <span>-{discountPercentage}%</span>
              </S.Ribbon>
            ) : (
              ""
            )}
            {isBrandBarter && (
              <S.BrandBarterIcon>
                <ReactSVG src="/images/brandBarterIcon.svg" />
              </S.BrandBarterIcon>
            )}
            {isDeal && (
              <S.Row0>
                <S.StealDeal>
                  Steal Deal
                  <ReactSVG src="/images/deals.svg" />
                </S.StealDeal>
              </S.Row0>
            )}
            <S.Row1>
              <S.Brand>
                {brandName}
                {isTmo && <ReactSVG style={S.Thunder} src="/images/thunder.svg" />}
              </S.Brand>
              <S.PriceAfterDiscount>
                ₹<span>{price}</span>
              </S.PriceAfterDiscount>
            </S.Row1>
            <S.Row2>
              <S.ProductName line={1} element="div" truncateText="…" text={productName} />
              {discountPercentage ? (
                <S.PriceBeforeDiscount>₹{priceUndiscounted}</S.PriceBeforeDiscount>
              ) : (
                ""
              )}
            </S.Row2>
          </S.ProductDetails>
        </S.Wrapper>
        <S.Bottom>
          <S.Commission>
            {!isThrift && (
              <>
                <ReactSVG src="/images/commission.svg" /> <S.Bold>{commission}%</S.Bold>
              </>
            )}
          </S.Commission>
          <Button
            style={{ width: "fit-content", height: 20, margin: "0px", fontSize: "0.8rem" }}
            onClick={handleAction}
          >
            {selected ? "Remove" : "Add"}
          </Button>
        </S.Bottom>
      </S.OutterWrapper>
    </S.Overlay>
  );
}
