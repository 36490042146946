import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  gap: 5px;
  grid-template-columns: 1fr;
  margin: 0.5rem 0;
`;

export const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
`;

export const Filters = styled.div`
  padding: 0px 1% 20px 1%;
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  background-color: #f1f1f1;
`;