import { gql } from "@apollo/client";

export const HISTORY_NOTES_UPDATE = gql`
  mutation HistoryNotesUpdate($id: ID!, $historyNotes: String!) {
    brandUpdate(input: { id: $id, historyNotes: $historyNotes }) {
      brand {
        id
        historyNotes
      }
      message
      errors {
        field
        message
      }
    }
  }
`;
