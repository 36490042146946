import styled from "styled-components";

export const Div = styled.div`
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const SearchInput = styled.input`
  padding: 0.8rem 1rem;
  flex: 1;
  border-radius: 5px;

  border: none;
  outline: none;
  background: #f1f1f1;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  position: relative;
  /* animation-name: anim; */
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "700px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};