import { gql } from "@apollo/client";

export const CREATE_SHIPPING = gql`
  mutation CreateShipping(
    $brandId: ID!
    $homeStatePincode: String
    $shippingCostSameStateAmount: Float
    $shippingCostOtherStateAmount: Float
    $minOrderValueFreeCostAmount: Float
  ) {
    brandShippingDataCreate(
      input: {
        brand: $brandId
        homeStatePincode: $homeStatePincode
        shippingCostSameStateAmount: $shippingCostSameStateAmount
        shippingCostOtherStateAmount: $shippingCostOtherStateAmount
        minOrderValueFreeCostAmount: $minOrderValueFreeCostAmount
      }
    ) {
      brandShippingData {
        id
        homeStatePincode
        brand {
          id
        }
        shippingCostSameStateAmount
        shippingCostOtherStateAmount
        minOrderValueFreeCostAmount
        currency
        country
        createdAt
      }
    }
  }
`;

export const UPDATE_SHIPPING = gql`
  mutation UpdatingShipping(
    $id: ID!
    $homeStatePincode: String
    $shippingCostSameStateAmount: Float
    $shippingCostOtherStateAmount: Float
    $minOrderValueFreeCostAmount: Float
  ) {
    brandShippingDataUpdate(
      input: {
        brandShippingData: $id
        homeStatePincode: $homeStatePincode
        shippingCostSameStateAmount: $shippingCostSameStateAmount
        shippingCostOtherStateAmount: $shippingCostOtherStateAmount
        minOrderValueFreeCostAmount: $minOrderValueFreeCostAmount
      }
    ) {
      brandShippingData {
        id
        shippingCostSameStateAmount
        shippingCostOtherStateAmount
        minOrderValueFreeCostAmount
        homeStatePincode
        country
        currency
        createdAt
        updatedAt
      }
    }
  }
`;

export const DELETE_SHIPPING = gql`
  mutation DeleteShipping($id: ID!) {
    brandShippingDataDelete(input: { brandShippingData: $id }) {
      success
    }
  }
`;
