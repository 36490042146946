import { useMutation } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import Button from "../../../components/Button";
import * as S from "../../StoresDashboard/EditStaff/styles";
import { GET_INFLUENCERS } from "../queries";
import { UPDATE_DATE } from "./mutations";

export default function EditDate({ influencer, close }) {
  const date = JSON.parse(influencer?.metadata)["date-of-birth"] ?? "";
  const [current, setCurrent] = useState(new Date(date));
  const [updateDate, { error }] = useMutation(UPDATE_DATE);

  const handleSubmit = async () => {
    const res = await updateDate({
      variables: {
        id: influencer?.id,
        date: current,
      },
    });
    if (res.data?.influencerUpdate?.user) {
      toast.success("Date Updated!");
      client.refetchQueries({
        include: [GET_INFLUENCERS],
      });
    } else toast.error("Something went wrong!");
    close();
  };

  if (error) return <p>Something went wrong...</p>;

  return (
    <div>
      <S.Label>Edit Birth Date</S.Label>
      <S.TextLabel>Select Date</S.TextLabel>
      <S.Flex>
        <input
          max={new Date().toISOString().substring(0, 10)}
          type="date"
          style={{ width: "80%", marginRight: 5, padding: 8 }}
          value={current}
          onChange={(e) => setCurrent(e.target.value)}
        />
        <Button onClick={handleSubmit} style={{ width: "60px", margin: "none" }}>
          Update
        </Button>
      </S.Flex>
    </div>
  );
}
