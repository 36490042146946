import * as S from "./styles";

import { useCallback, useEffect, useRef, useState } from "react";

import Button from "../../../../components/Button";
import ErrorPage from "../../../../components/ErrorPage/ErrorPage";
import LoadingDots from "../../../../components/LoadingDots";
import ProductCard from "./ProductCard";
import { ReactSVG } from "react-svg";
import { SEARCH_PRODUCTS } from "./queries";
import _ from "lodash";
import { useLazyQuery } from "@apollo/client";

export default function AddProduct({ mainProducts, setProduct, close, productsSelected, brands }) {
  // console.log(brands);
  const inputRef = useRef(null);
  const [search, setSearch] = useState("");

  const [getSearchProds, { data, loading, error, fetchMore }] = useLazyQuery(SEARCH_PRODUCTS, {
    fetchPolicy: "network-only",
  });

  const sortByVariantsPrice = (array) => {
    return array.sort(function (a, b) {
      var x = a?.costPrice?.amount;
      var y = b?.costPrice?.amount;
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };

  const debouncer = useCallback(_.debounce(getSearchProds, 1000), []);

  useEffect(() => {
    if (search !== "")
      debouncer({
        variables: {
          search,
          brands: brands?.value,
        },
      });
  }, [search]);

  let productsData, products, hasMore, endCursor;
  productsData = data?.products;
  products = productsData?.edges;
  hasMore = productsData?.pageInfo.hasNextPage;
  endCursor = productsData?.pageInfo.endCursor;

  if (error) return <ErrorPage />;

  return (
    <div>
      <S.SearchBar>
        <S.BackIcon onClick={() => close()}>
          <ReactSVG src="/assets/images/back.svg" />
        </S.BackIcon>
        <S.SearchInput
          ref={inputRef}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          autoFocus
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              inputRef?.current?.blur();
            }
          }}
        />
        <S.CrossIcon onClick={() => setSearch("")}>
          <p>x</p>
        </S.CrossIcon>
      </S.SearchBar>
      <div style={{ marginTop: 80 }} />
      <h3 style={{ marginLeft: "20px" }}>Selected Products: </h3>
      {productsSelected?.map((p) => {
        return (
          <S.Products>
            <p>{p?.label}</p>
            <S.RemoveBtn
              onClick={() => {
                setProduct((prev) => {
                  const newProducts = prev.products?.filter((prod) => prod.value !== p?.value);
                  return { ...prev, products: newProducts };
                });
              }}
            >
              Remove
            </S.RemoveBtn>
          </S.Products>
        );
      })}
      {loading ? (
        <LoadingDots />
      ) : (
        <S.MainWrapper style={{ marginTop: 20 }}>
          {products?.map(({ node: product }) => {
            const imageURL = product?.thumbnail?.url,
              productName = product?.name;
            let brandName;
            brandName = product?.brand.brandName;
            let productsInStock = product?.variants?.filter((item) => item.quantityAvailable > 0),
              outOfStock = productsInStock.length === 0,
              productId = product?.id,
              tooManyOrders = product?.tooManyOrders,
              commission = product?.hasCustomCommission
                ? product?.commissionPercentage
                : Math.round(product?.brand?.commission);

            let isDeal =
              product?.metadata?.filter((e) => e.key === "value_deal")[0]?.value === "true"
                ? true
                : false;

            let priceUndiscounted, price, discountPercentage;

            // if not ot of stock then setting price to least available variant pricing
            if (!outOfStock) {
              let leastPriceVariant = sortByVariantsPrice(productsInStock)[0];
              priceUndiscounted = Math.round(leastPriceVariant?.costPrice?.amount);
              price = Math.round(leastPriceVariant?.pricing?.priceUndiscounted?.gross?.amount);
            } else {
              priceUndiscounted = Math.round(product?.defaultVariant?.costPrice?.amount);
              price = Math.round(
                product?.defaultVariant?.pricing?.priceUndiscounted?.gross?.amount
              );
            }

            discountPercentage = Math.round(
              ((priceUndiscounted - price) / priceUndiscounted) * 100
            );
            return (
              <ProductCard
                productId={productId}
                imageURL={imageURL}
                price={price}
                priceUndiscounted={priceUndiscounted}
                productName={productName}
                discountPercentage={discountPercentage}
                brandName={brandName}
                commission={commission}
                productSlug={product.slug}
                outOfStock={outOfStock}
                isTmo={tooManyOrders}
                isDeal={isDeal}
                isBrandBarter={product?.brandBarter}
                selected={mainProducts.some((prod) => prod.value === productId)}
                setProduct={setProduct}
              />
            );
          })}
        </S.MainWrapper>
      )}
      {products?.length !== 0 && hasMore && (
        <Button
          style={{ marginTop: 20 }}
          onClick={() =>
            fetchMore({
              variables: { endCursor },
            })
          }
        >
          Fetch More
        </Button>
      )}
    </div>
  );
}
