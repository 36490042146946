import * as S from "../../StoresDashboard/EditStaff/styles";

import { UPDATE_DATE, UPDATE_STATUS } from "./mutations";

import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { GET_INFLUENCERS } from "../queries";
import { client } from "../../../apollo-client";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useState } from "react";

export default function EditStatus({ influencer, close }) {
  // console.log(influencer.id)
  const status = influencer?.status ?? "";
  const [currentStatus, setCurrentStatus] = useState(status);
  const [updateStatus, {error: statusError}] = useMutation(UPDATE_STATUS);
  // console.log(currentStatus);
  // const handleSubmit = async () => {
  //   const res = await updateDate({
  //     variables: {
  //       id: influencer?.id,
  //       date: current,
  //     },
  //   });
  //   if (res.data?.influencerUpdate?.user) {
  //     toast.success("Date Updated!");
  //     client.refetchQueries({
  //       include: [GET_INFLUENCERS],
  //     });
  //   } else toast.error("Something went wrong!");
  //   close();
  // };

  const handleSubmit = async () => {
    const res = await updateStatus({
      variables : {
        id: influencer?.id,
        status: currentStatus?.value
      }
    });

    if (res.data?.influencerUpdate?.user) {
      toast.success("Status updated!");
      client.refetchQueries({
        include: [GET_INFLUENCERS],
      });
    } else toast.error("Something went wrong");
    close();
  }

  if (statusError) return <p>Something went wrong...</p>;

  return (
    <div>
      <S.Label>Edit Status</S.Label>
      <S.Flex>
        <Dropdown
          style={{ marginTop: "5px", width: "100%" }}
          data={statusActions}
          onChange={(e) => setCurrentStatus(e)}
          value="value"
          label="label"
          placeHolder="Select Status"
          defaultValue={currentStatus}
        />
        <Button onClick={handleSubmit} style={{ width: "100px", marginTop: "10px" }}>
          Update
        </Button>
      </S.Flex>
    </div>
  );
}

const statusActions = [
  { label: "Hold", value: "HOLD" },
  { label: "Request Received", value: "REQUEST_RECEIVED" },
  { label: "In Progress", value: "IN_PROGRESS" },
  { label: "Rejected", value: "REJECTED" },
  { label: "Onboarded", value: "ONBOARDED" },
];


