import { gql } from "@apollo/client";

export const EDIT_BRAND_GST_DETAILS = gql`
  mutation EditBrandGstDetails(
    $id: ID!
    $registered_company_name: String!
    $address: String!
    $PAN: String!
    $GSTIN: String!
  ) {
    brandUpdate(
      input: {
        id: $id
        metadata: [
          { key: "registered_company_name", value: $registered_company_name }
          { key: "address", value: $address }
          { key: "PAN", value: $PAN }
          { key: "GSTIN", value: $GSTIN }
        ]
      }
    ) {
      brand {
        id
        metadata
      }
    }
  }
`;
