import styled from "styled-components";

export const Div = styled.div`
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  flex-wrap: wrap;
`;