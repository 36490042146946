import * as S from "./styles";

import Dropdown from "../../../components/Dropdown";
import Search from "../../CustomerSupport/Search";
import { useState } from "react";

const Filters = (props) => {
  const [search, setSearch] = useState("");

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Direction</label>
          <Dropdown
            style={btnStyle}
            data={directions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "direction")}
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Field</label>
          <Dropdown
            style={btnStyle}
            data={fields}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "field")}
            defaultValue={props.defaultValue.field}
          />
        </div>
        <div>
          <label>Status</label>
          <Dropdown
            style={btnStyle}
            data={statusFilter}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "status")}
            defaultValue={props.defaultValue.status}
          />
        </div>
        <div>
          <label>Search Store</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.SearchInput value={search} onChange={(e) => setSearch(e.target.value)} />
            <div
              onClick={() => props.onChange(search, "search")}
              style={{ position: "absolute", right: "15px", cursor: "pointer" }}
            >
              <Search />
            </div>
          </div>
        </div>
      </S.Flex>
    </S.Div>
  );
};

const btnStyle = { minWidth: "150px", margin: "5px", maxWidth: "200px" };

const directions = [
  { label: "ASC", value: "ASC" },
  { label: "DESC", value: "DESC" },
];

const fields = [{ label: "UPDATED_AT", value: "UPDATED_AT" }];

const stateFilter = [
  {label: "NOT_VERIFIED", value: "NOT_VERIFIED"},
  {label: "VERIFIED", value: "VERIFIED"}
]

const statusFilter = [
  {label: "HOLD", value: "HOLD"},
  {label: "REQUEST_RECEIVED", value: "REQUEST_RECEIVED"},
  {label: "IN_PROGRESS", value: "IN_PROGRESS"},
  {label: "REJECTED", value: "REJECTED"},
  {label: "ONBOARDED", value: "ONBOARDED"}
]

export default Filters;
