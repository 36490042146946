import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100vw;
  background-color: white;
  height: 60px;
  box-shadow: 0px 0px 5px 0px #eee;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 300px;
`;

export const BackIcon = styled.img`
  /* height: 100px; */
  cursor: pointer;
`;
export const NextIcon = styled.img`
  transform: rotateY(180deg);
  cursor: pointer;
`;

// export const Dots = styled.p`
//   letter-spacing: 5px;
//   margin-left: 5px;
// `;

export const Button = styled.button`
  border: none;
  border: 2px solid ${(props) => (props.active ? "black" : "gray")};
  background-color: ${(props) => (props.active ? "black" : "white")};
  color: ${(props) => (!props.active ? "black" : "white")};
  height: 25px;
  width: 25px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 3px;
  font-weight: 600;
`;
