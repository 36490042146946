export default function OutOfStock({ close }) {
  return (
    <div>
      <h2>Out of Stock</h2>
      <p style={{ marginTop: "5px" }}>
        Cannot create coupon for the specified requests due to stock unavailability.
      </p>
      <button onClick={close} style={{ padding: "10px", width: "100%", marginTop: "10px" }}>
        Close
      </button>
    </div>
  );
}
