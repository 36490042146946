import { gql } from "@apollo/client";

export const UPDATE_CONTENT = gql`
  mutation storeUpdate($storeId: ID!, $content: String) {
    storeUpdate(input: { store: $storeId, content: $content }) {
      store {
        id
        slug
        storeUrl
        storeName
        content
      }
    }
  }
`;
