import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import { CREATEDBY_IDS } from "../../../screens/BrandSourcing/queries";
import Button from "../../Button";
import Dropdown from "../../Dropdown";
import { STAFF_MANAGER_EDIT } from "./mutations";
import * as S from "./styles";
import { client } from "../../../apollo-client";
import { GET_DASHBOARD_BRANDS } from "../../../screens/BrandsDashboard/queries";

const EditStaff = ({ id, staff, close }) => {
  //current staff the brand
  const [selectedStaff, setSelectdStaff] = useState(staff);
  //all the staff members for dropdown
  const { data, loading, error } = useQuery(CREATEDBY_IDS);
  //mutation to edit the staff members
  const [editStaff, { error: staffError }] = useMutation(STAFF_MANAGER_EDIT);

  //mutate the staff for the brand
  const handleSubmit = async () => {
    if (selectedStaff.length > 0) {
      const res = await editStaff({
        variables: {
          id: id,
          staff: selectedStaff.map((staff) => staff.value),
        },
      });
      if (res.data?.brandManagerCreate) {
        toast.success("Staff updated successfully!");
        client.refetchQueries({
          include: [GET_DASHBOARD_BRANDS],
        });
      }
      close();
    } else toast.warning("Please select atleast one staff member!");
  };

  if (error || staffError) return <div>Something Went Wrong!</div>;

  return (
    <>
      <S.Label>Edit Staff Members</S.Label>
      <S.Flex>
        {loading ? (
          <S.SpinContainer>
            <S.Spinner />
          </S.SpinContainer>
        ) : (
          <div style={{ display: "grid", gap: "5px" }}>
            <S.TextLabel>Staff Members</S.TextLabel>
            <Dropdown
              style={{ width: "400px" }}
              data={data?.staffUsers?.edges?.map(({ node }) => node)}
              value="id"
              label="email"
              onChange={(e) => setSelectdStaff(e)}
              placeholder="Select Stores"
              defaultValue={selectedStaff}
              isMulti
            />
            <Button
              disabled={JSON.stringify(staff) === JSON.stringify(selectedStaff)}
              onClick={handleSubmit}
              style={{ marginTop: "10px" }}
            >
              Save
            </Button>
          </div>
        )}
      </S.Flex>
    </>
  );
};

export default EditStaff;
