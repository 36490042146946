import axios from "axios";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";

async function getUrl(extension, index) {
  try {
    const res = await axios.get(
      `${
        process.env.REACT_APP_CONTENT_API_BASE_URl
      }/streaming/api/presigned/url/toupload/?key=${index + extension}`,
      {
        headers: {
          "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
        },
        params: {
          extension,
        },
      }
    );

    return {
      streaming_url: res.data?.streaming_url,
      url: res.data?.url,
      file_key: res.data?.file_key,
    };
  } catch (e) {
    console.error(e);

    throw new Error("There was an error while fetching streaming url");
  }
}

export async function tagContent({
  // file,
  // productId,
  // variantId,
  // contentType,
  data,
  brandId,
}) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CONTENT_API_BASE_URl}/streaming/api/tag/content/`,
      {
        data: [...data],
        // {
        //   media_url: file,
        //   product_id: productId,
        //   sku_id: variantId,
        //   content_type: contentType,
        // },
        zaamo_id: brandId,
        user_type: "BRAND",
        tag_type: "PRODUCT",
      },
      {
        headers: {
          "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
        },
      }
    );

    return res.data;
  } catch (e) {
    console.error(e);

    throw new Error("There was an error while fetching streaming url");
  }
}

export async function untagContent({ content_id, product_id, sku_id }) {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_CONTENT_API_BASE_URl}/streaming/api/untag/content/`,
      {
        data: [
          {
            content_id: content_id,
            product_id: product_id,
            sku_id: sku_id,
          },
        ],
        untag_type: "PRODUCT",
      },
      {
        headers: {
          "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
        },
      }
    );

    return res.data;
  } catch (e) {
    console.error(e);

    throw new Error("There was an error while fetching streaming url");
  }
}

async function uploadMedia(url, selectedFile, contentType) {
  try {
    console.log("uploading ", contentType);

    await axios.put(url, selectedFile, {
      headers: { "Content-Type": contentType, "x-ms-blob-type": "BlockBlob" },
    });
    console.log("uploaded ");

    return true;
  } catch (e) {
    console.error(e);
    throw new Error("There was an error while uploading this media");
  }
}

async function getConvertStatus(job_id) {
  const res = await axios.get(
    `${process.env.REACT_APP_CONTENT_API_BASE_URl}/engine/video/convert/status?job_id=${job_id}`
  );
  console.log(res.data);

  return res.data;
}

async function compressImage(fileKey) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_CONTENT_API_BASE_URl}/engine/image/compress`,
        {
          data: [
            {
              // format: extension,
              file_key: fileKey,
            },
          ],
        }
      )
      .then((res) => {
        return true;
      });
  } catch (e) {
    console.error(e);
    throw new Error();
  }
}
async function convertImage(extension, fileKey) {
  try {
    axios
      .post(
        `${process.env.REACT_APP_CONTENT_API_BASE_URl}/engine/image/convert`,
        {
          data: [
            {
              format: extension,
              file_key: fileKey,
            },
          ],
        }
      )
      .then((res) => {
        return true;
      });
  } catch (e) {
    console.error(e);

    throw new Error();
  }
}

async function convertVideo(extension, fileKey) {
  try {
    console.log("video convert");
    const res = await axios.post(
      `${process.env.REACT_APP_CONTENT_API_BASE_URl}/engine/video/convert`,
      // `http://9d9a-27-56-241-171.ngrok.io/engine/video/convert`,
      {
        data: [
          {
            // format: extension,
            file_key: fileKey,
          },
        ],
      }
    );

    const id = toast.loading("Optimizing video");
    const job_id = res.data.data[0].job_id;
    // const { status } = await getConvertStatus(job_id);
    // client.refetchQueries({ include: "active" });
    const cancel = setInterval(async () => {
      const { status } = await getConvertStatus(job_id);
      //   alert(status);
      if (status !== "PROGRESSING") {
        if (status === "COMPLETE") {
          toast.update(id, {
            render: `video optimized`,
            type: "info",
            isLoading: false,
            autoClose: 2000,
            draggable: true,
          });
          clearInterval(cancel);
          // optimizingVideoVar(null);
        } else {
          toast.update(id, {
            render: `video couldn't be optimized`,
            type: "warning",
            isLoading: false,
            autoClose: 2000,
            draggable: true,
          });
          // optimizingVideoVar(null);
          clearInterval(cancel);
        }
      }
    }, 5000);

    return {
      job_id,
      thumbnail_url: res.data.data[0]?.thumbnail_url,
    };
  } catch (e) {
    console.error(e);

    throw new Error("Can't convert video");
  }
}

function isExtensionUnsupported(file) {
  const contentType = file.type;
  let fileType = file.name.split(".");
  fileType = fileType[fileType.length - 1];
  const state = {
    isUnsupported: true,
    extension: fileType,
    isVideoType: false,
    contentType,
  };

  if (contentType.includes("video")) {
    state.isVideoType = true;
    return state;
  } else if (["png", "jpg", "jpeg", "webp"].includes(fileType)) {
    state.isUnsupported = false;
  }

  return state;
}

export async function uploadCover({ file, toastId, index }) {
  try {
    const { isUnsupported, extension, contentType, isVideoType } =
      isExtensionUnsupported(file);

    let { streaming_url, file_key, url } = await getUrl(extension, index);
    // let response = await axios.get(
    //   `${process.env.REACT_APP_CONTENT_API_BASE_URl}/streaming/api/presigned/url/toupload/`,
    //   {
    //     headers: {
    //       "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
    //     },
    //     params: {
    //       extension,
    //     },
    //   }
    // );
    // let streaming_url = response.data?.streaming_url as string;
    // let url = response.data?.url;
    // let file_key = response.data?.file_key;

    let thumbnail_url = null;
    toast.update(toastId, {
      render: `Uploading ${isVideoType ? "Video" : "Image"}`,
      type: "info",
      isLoading: true,
      autoClose: 2000,
      draggable: true,
    });

    const res = await uploadMedia(url, file, contentType);
    console.log({ res });

    if (isVideoType) {
      toast.update(toastId, {
        render: "Converting video",
        type: "success",
        isLoading: true,
        autoClose: 2000,
        draggable: true,
      });

      const { job_id, thumbnail_url: t } = await convertVideo(
        extension,
        file_key
      );
      streaming_url = streaming_url;
      thumbnail_url = t;
    } else if (isUnsupported) {
      toast.update(toastId, {
        render: "Converting image",
        type: "info",
        isLoading: true,
        autoClose: 2000,
        draggable: true,
      });

      convertImage(extension, file_key);
    } else {
      // compress image
      toast.update(toastId, {
        render: "Compressing image",
        type: "info",
        isLoading: true,
        autoClose: 2000,
        draggable: true,
      });

      compressImage(file_key);
    }

    toast.update(toastId, {
      render: "Image Uploaded",
      type: "success",
      isLoading: true,
      autoClose: 2000,
      draggable: true,
    });
    // alert(streaming_url);
    return {
      streaming_url,
      thumbnail_url,
    };
  } catch (e) {
    toast.update(toastId, {
      render: e.message,
      type: "error",
      isLoading: false,
      autoClose: 2000,
      draggable: true,
    });

    return { streaming_url: null };
  }
}

export async function fetchMediaCount(productIds) {
  // console.log(productIds);
  try {
    const res = await axios({
      url: `${process.env.REACT_APP_CONTENT_API_BASE_URl}/streaming/api/products/tagcount/`,
      method: "POST",
      data: {
        products: productIds,
      },
      headers: {
        "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
      },
    });
    return res.data.data;
  } catch (error) {
    console.log(error);
    return {};
  }
}
