import { useRef, useState } from "react";
import { toast } from "react-toastify";
import * as utils from "./utils";
import axios from "axios";

export const usePdp2 = () => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedVideos, setSelectedVideos] = useState([]);

  const [imagesLoading, setImagesLoading] = useState(false);
  const [videosLoading, setVideosLoading] = useState(false);
  const [videoError, setVideoError] = useState(false);
  const [imageError, setImageError] = useState(false);
  const addImageRef = useRef(null);
  const [contentType, setContentType] = useState({});
  const [taggedContent, setTaggedContent] = useState([]);

  const getTaggedData = async (productData) => {
    let url = `https://prodcontent.zaamo.co/streaming/api/tag/content/?product_id=${productData.id}&brand_id=${productData.brand_id}&limit=50`;
    if (process.env.REACT_APP_GRAPHQL_BASE_URL?.includes("beta.zaamo.co")) {
      url = `https://betacontent.zaamo.co/streaming/api/tag/content/?product_id=${productData.id}&brand_id=${productData.brand_id}&limit=50`;
    }
    const resp = await axios.get(url, {
      headers: {
        "service-token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
      },
    });
    console.log(resp.data.data, "resp");
    setTaggedContent(resp?.data?.data);
  };

  const handleCoverUpload = async (file, index) => {
    const toastId = toast.loading("Preparing to upload image...");
    const { streaming_url } = await utils.uploadCover({
      file: file,
      toastId,
      index,
    });

    return [streaming_url, toastId];
  };

  const handleSelectImages = (images, productData) => {
    setImagesLoading(true);

    Array.from(images).map(async (image, index) => {
      // setTimeout(async () => {
      const [imgUrl, toastID] = await handleCoverUpload(
        image,
        index,
        contentType
      );
      setSelectedImages((prev) => [...prev, imgUrl]);
      toast.update(toastID, {
        render: "Photo uploaded successfully!",
        type: "success",
        isLoading: false,
        autoClose: 2000,
        draggable: true,
      });

      let data = [
        {
          media_url: imgUrl,
          product_id: productData.id,
          sku_id: productData.zaamoVariants[0].id,
          content_type: contentType.value,
        },
      ];
      // productData.zaamoVariants.map((variant) => {
      //   data.push({
      //     media_url: imgUrl,
      //     product_id: productData.id,
      //     sku_id: variant.id,
      //     content_type: contentType.value,
      //   });
      // });

      const resp = await utils.tagContent({
        brandId: productData.brand_id,
        data: [...data],
      });
      console.log(resp, "tagged content resp");
      if (resp) {
        if (index === images.length - 1) {
          setSelectedImages([]);
          getTaggedData(productData);
        }
      }
      if (resp?.message === "created") {
        toast.success("Tagged Image successfully!");
      } else {
        toast.error("There is some error in tagging Image!");
      }
      // }, (index+1)*2000)
    });

    setImagesLoading(false);
  };

  const handleSelectVideos = (videos, productData) => {
    setVideosLoading(true);
    if (selectedVideos.length + videos.length <= 6) {
      setVideoError(false);
      Array.from(videos).map(async (video, index) => {
        const [imgUrl, toastID] = await handleCoverUpload(video, index);
        setSelectedVideos((prev) => [...prev, imgUrl]);
        toast.update(toastID, {
          render: "Video uploaded successfully!",
          type: "success",
          isLoading: false,
          autoClose: 2000,
          draggable: true,
        });

        let data = [
          {
            media_url: imgUrl,
            product_id: productData.id,
            sku_id: productData.zaamoVariants[0].id,
            content_type: contentType.value,
          },
        ];
        // productData.zaamoVariants.map((variant) => {
        //   data.push({
        //     media_url: imgUrl,
        //     product_id: productData.id,
        //     sku_id: variant.id,
        //     content_type: contentType.value,
        //   });
        // });

        const resp = await utils.tagContent({
          brandId: productData.brand_id,
          data: [...data],
        });
        console.log(resp, "tagged content resp video");
        if (resp) {
          if (index === videos.length - 1) {
            setSelectedVideos([]);
            getTaggedData(productData);
          }
        }
        if (resp?.message === "created") {
          toast.success("Tagged video successfully!");
        } else {
          toast.error("There is some error in tagging video!");
        }
      });
    } else {
      setVideoError(true);
    }
    setVideosLoading(false);
  };

  return {
    selectedImages,
    setSelectedImages,
    selectedVideos,
    setSelectedVideos,
    imagesLoading,
    setImagesLoading,
    videosLoading,
    setVideosLoading,
    videoError,
    setVideoError,
    imageError,
    setImageError,
    handleCoverUpload,
    handleSelectImages,
    handleSelectVideos,
    addImageRef,
    contentType,
    setContentType,
    getTaggedData,
    taggedContent,
    setTaggedContent,
  };
};
