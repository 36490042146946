export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};
