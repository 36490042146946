import { gql } from "@apollo/client";

export const GET_EMAIL = gql`
  query GetEmail($no: String!) {
    userByMobile(mobileNo: $no) {
      email
      authorisedStores {
        id
        storeName
      }
      authorisedBrands {
        id
        brandName
      }
    }
  }
`;

export const FETCH_SOURCING = gql`
  query FetchSourcing($id: [ID]) {
    sourcingRequest(first: 100, filter: { ids: $id }) {
      edges {
        node {
          id
          store {
            id
            storeName
          }
          product {
            id
            name
          }
          brand {
            id
            brandName
            brandShipping(first: 10) {
              edges {
                node {
                  shippingCostSameStateAmount
                  shippingCostOtherStateAmount
                }
              }
            }
          }
          variant {
            quantityAvailable
            price {
              amount
            }
          }
        }
      }
    }
  }
`;

export const FETCH_BRAND_SOURCING = gql`
  query FetchBrandSourcing($id: ID) {
    brandSourceRequests(first: 100, filter: { id: $id }) {
      edges {
        node {
          id
          notification
          campaignName
          store {
            id
            storeUrl
            storeName
          }
          brand {
            id
            brandName
            brandShipping(first: 10) {
              edges {
                node {
                  shippingCostSameStateAmount
                  shippingCostOtherStateAmount
                }
              }
            }
          }
          termsAndConditions
          content
          brandCollab
          brandManagers
          state
          storeBucket
          storeManagers
          createdAt
          createdBy {
            email
          }
					
        }
      }
    }
  }
`;

export const COLLECTION_PRODUCTS = gql`
  query CollectionProducts($slug: String) {
    collection(slug: $slug) {
      name
      id
    }
  }
`;
