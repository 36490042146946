import * as S from "../EditBrand/styles";

export default function AddressFields({ type, brand, setBrand }) {
  const onChange = (val, field) => {
    if (type === "both")
      return setBrand({
        ...brand,
        address: {
          ...brand.address,
          [field]: val,
        },
        returnAddress: {
          ...brand.returnAddress,
          [field]: val,
        },
      });
    setBrand({
      ...brand,
      [type]: {
        ...brand[type],
        [field]: val,
      },
    });
  };

  return (
    <>
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>First Name *</S.TextLabel>
          <S.TextField
            value={brand[type]?.firstName}
            placeholder="Eg: John"
            onChange={(e) => onChange(e.target.value, "firstName")}
          />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Street Address *</S.TextLabel>
          <S.TextField
            value={brand[type]?.streetAddress1}
            placeholder="Eg: Street 2625"
            onChange={(e) => onChange(e.target.value, "streetAddress1")}
          />
        </div>
      </S.Flex>
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>City *</S.TextLabel>
          <S.TextField
            value={brand[type]?.city}
            placeholder="Eg: Ahmedabad"
            onChange={(e) => onChange(e.target.value, "city")}
          />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Postal Code *</S.TextLabel>
          <S.TextField
            value={brand[type]?.postalCode}
            placeholder="Eg: 382323"
            onChange={(e) => onChange(e.target.value, "postalCode")}
          />
        </div>
      </S.Flex>
    </>
  );
}
