import styled from "styled-components";

export const Wrapper = styled.div`
  background-color: #f5f5f5;
  /* height: 100px; */
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

export const ImageWrapper = styled.div`
  padding: 0.5rem;
  display: grid;
  place-items: center;
`;

export const Image = styled.img`
  border-radius: 5px;
  object-fit: cover;
  object-position: top;
  height: 6rem;
  width: 6rem;
  margin: auto;
`;

export const Right = styled.div`
  padding: 0.5rem;
`;
export const Name = styled.div`
  font-weight: 600;
  font-size: 14px;
`;
export const Details = styled.div`
  display: grid;
  padding: 0.5rem 0;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-rows: 1fr 1fr; */
  align-items: start;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
`;
export const Cell = styled.div`
  text-align: left;
  flex: 1;
`;
export const Value = styled.div`
  font-size: 14px;
`;
export const Small = styled.div`
  font-size: 9px;
`;

export const Edit = styled.div`
  display: grid;
  align-items: center;
  /* grid-template-rows: 1fr 1fr; */
  padding: 0.5rem 0;
  align-items: start;
  justify-content: space-between;
`;

export const Row = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 0.5rem;
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 0 1rem 0.5rem 1rem;
`;
export const Tags = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;
export const Tag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TagIcon = styled.img.attrs({
  src: "assets/images/tag.svg",
})`
  margin-right: 5px;
`;
export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  padding: 2px 5px;
  border-radius: 3px;
  border: 1px solid #eee;
`;
export const SearchIcon = styled.img.attrs({
  src: "assets/images/search.svg",
})`
  margin-right: 5px;
`;
export const Input = styled.input.attrs({
  placeholder: "Search Tags",
})`
  flex: 1;
  border: none;
  outline: none;
  background-color: transparent;
  &:focus {
    outline: none;
  }
`;
