import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div``;

export const FilterWrapper = styled.div`
  padding: 16px 10px;
  display: grid;
  grid-template-columns: auto auto auto;
  gap: 8px;
  .css-14el2xx-placeholder {
    font-size: 13px;
  }
`;

export const SearchInput = styled.input`
  padding: 1rem 1rem;
  flex: 1;
  border-radius: 5px;

  border: none;
  outline: none;
  background: #f1f1f1;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  position: relative;
  /* animation-name: anim; */
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
`;

export const ProductsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const ProductCard = styled.div`
  width: 100%;
  background: #f7f7f7;
  padding: 12px;
  overflow: hidden;
`;

export const ProductTop = styled.div`
  display: flex;
`;

export const ImageCont = styled.div`
  position: relative;
`;

export const Quantity = styled.div`
  width: 1.1rem;
  height: 1.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  font-size: 0.7rem;
  border-radius: 50%;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
`;

export const Image = styled.img`
  width: 7rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 6px;
`;
export const TopDetails = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;
export const Title = styled.div`
  font-size: 1rem;
`;
export const Variants = styled.div`
  display: flex;
  margin-top: 8px;
  flex-wrap: wrap;
  align-items: "center";
`;
export const Variant = styled.div`
  font-size: 0.7rem;
  margin-right: 8px;
  margin-top: 8px;
  color: grey;
`;
export const StatisticDetails = styled.div`
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
`;
export const Detail = styled.div`
  margin-right: 8px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Value = styled.div``;

export const SmallerValue = styled.div`
  font-size: 0.8rem;
`;

export const DetailKey = styled.div`
  font-size: 0.6rem;
  color: grey;
  text-align: center;
`;

export const ProductMiddle = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`;
export const AddContent = styled.div`
  width: 7rem;
  background: #02bdd0;
  border-radius: 12px;
  color: #fff;
  height: 24px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MiddleContent = styled.div`
  margin-left: 10px;
  display: flex;
`;

export const SwitchWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 0.3rem;
  align-items: center;
  margin-right: 10px;
  label {
    color: black;
    padding: 0.1rem;
    font-size: 10px;
    color: grey;
  }
  .react-toggle {
    &-track {
      height: 15px;
      width: 30px;
    }
    &-thumb {
      height: 13px;
      width: 13px;
    }
    &--checked {
      .react-toggle-thumb {
        left: 15px;
      }
    }
  }
`;

export const ProductBottom = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

export const RemoveContent = styled.div`
  width: 7rem;
  background: #fff;
  border: 1px solid #e9753a;
  border-radius: 12px;
  color: #e9753a;
  height: 24px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BottomContent = styled.div`
  margin-left: 10px;
  display: flex;
  align-items: center;
`;
export const Search = styled.input`
  outline: none;
  border: 0.5px solid grey;
  border-radius: 6px;
  height: 22px;
  padding: 0 4px;
`;

export const Text = styled.div`
  font-size: 0.9rem;
  color: black;
`;

export const ModalContent = styled.div`
  /* padding: 20px 15px; */
`;

export const ContentImage = styled.div`
  margin: 2%;
  border: 1px solid grey;
  border-radius: 5px;
  width: 20%;
  height: 70px;
  justify-content: center;
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  position: relative;
  img,
  video {
    max-width: 100%;
    max-height: 70px;
  }
  .cross {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    border: hidden;
    position: absolute;
    top: -7px;
    right: -7px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
    img {
      max-width: 20px !important;
    }
  }
`;

export const Flex = styled.div`
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
`;

export const InputFile = styled.input`
  display: none;
  color: transparent;
`;

export const QuantityInput = {
  width: "100%",
  margin: "20px 0px",
  height: "35px",
  paddingLeft: "10px",
};

export const Input = {
  width: "100%",
  height: "35px",
  padding: "0px 5px",
};

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};

export const Button = styled.button`
  padding: 8px 18px;
`;

export const Label = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const TextLabel = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.8rem;
`;

export const Center = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const TextField = styled.textarea`
  max-width: 100%;
  width: 80%;
  padding: 8px;
  margin-top: 10px;
`;

export const SpinContainer = styled.div`
  display: flex;
  margin: auto;
  padding: 45px 0px 10px 40px;
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid #000;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;
