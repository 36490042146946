import styled from "styled-components";

export const Wrapper = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`;

export const Oops = styled.h1`
  font-size: 50px;
  text-align: center;
  margin-bottom: 1rem;
  /* margin-top: 10vh; */
`;

export const ErrorMessage = styled.p`
  color: gray;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
`;
