import { gql } from "@apollo/client";

export const GET_ALL_BRANDS = gql`
  query GetBrands($endCursor: String) {
    brands(first: 100, after: $endCursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          brandName
        }
      }
    }
  }
`;

export const GET_FILTER_DATA = gql`
  query GetFilterData($brandId: ID!) {
    pdpAnalytics(brandId: $brandId) {
      brandName
      id
      collections
      categories
      subCategories
      productType {
        id
        name
      }
    }
  }
`;

export const GET_FILTERED_PRODUCTS = gql`
  query GetFilteredProductsData(
    $brandId: ID!
    $page: Int!
    $brandCollection: [String]
    $brandCategory: [String]
    $brandSubCategory: [String]
    $productType: [ID]
    $isPublished: Boolean
    $dataSource: String
  ) {
    pdpAnalytics(brandId: $brandId) {
      products(
        page: $page
        brandCategory: $brandCategory
        brandCollection: $brandCollection
        isPublished: $isPublished
        brandSubCategory: $brandSubCategory
        productType: $productType
        dataSource: $dataSource
      ) {
        slug
        brandId
        dataSource
        productZaamoId
        brandBarter
        valueDeal
        zaamoVariants {
          name
          id
          trackInventory
          quantityAvailable
          price {
            amount
          }
          costPrice {
            amount
          }
        }
        description
        productIdBrand
        variantIdBrands
        commission
        name
        images
        category {
          id
          name
        }
        variants {
          name
          brandStock
          trackInventory
        }
        publicationDate
        isPublished
        weeklyVisits
        wishlistCount
        orderCount
        storeCount
        totalStoreViews
        brandProductMrp
        brandProductMsp
        brandProductCreationDate
      }
    }
  }
`;

export const GET_PRODUCT_IMAGES = gql`
  query GetProductImages($slug: String!) {
    product(slug: $slug) {
      images {
        id
        url
      }
    }
  }
`;

export const GET_ALL_CATEGORIES = gql`
  query GetAllCategories {
    categories(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_PRODUCTS = gql`
  query SearchProducts($search: String!, $brandName: String!, $page: Int!) {
    pdpProductSearch(productName: $search, brandName: $brandName, page: $page) {
      slug
      brandId
      dataSource
      productZaamoId
      brandBarter
      valueDeal
      zaamoVariants {
        name
        id
        trackInventory
        quantityAvailable
        price {
          amount
        }
        costPrice {
          amount
        }
      }
      description
      productIdBrand
      variantIdBrands
      commission
      name
      images
      category {
        id
        name
      }
      variants {
        name
        brandStock
        trackInventory
      }
      publicationDate
      isPublished
      weeklyVisits
      wishlistCount
      orderCount
      storeCount
      totalStoreViews
      brandProductMrp
      brandProductMsp
      brandProductCreationDate
    }
  }
`;

export const ALL_PRODUCT_TAGS = gql`
  query {
    productTags(first: 100) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_PRODUCT_TAGS = gql`
  query GetProductTags($id: ID!) {
    productTagMapping(first: 100, productid: $id) {
      edges {
        node {
          productTag {
            id
            name
          }
        }
      }
    }
  }
`;
