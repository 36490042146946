import * as S from "./styles";

import React, { useState } from "react";
import { usePagination, useTable } from "react-table";

import EditBrandEmails from "./EditBrandEmails";
import EditCommission from "./EditCommission";
import EditImage from "./EditImage";
import EditStaff from "./EditStaff";
import PacmanLoader from "../PackmanLoader";
import ReactModal from "react-modal";
import { ReactSVG } from "react-svg";

const Table = ({ data, currentPage, displayPageSize, from }) => {
  // console.log(data)
  //modal bool for editing the staff members
  const [showModal, setShowModal] = useState(false);
  //modal bool for showing commission update
  const [showEdit, setShowEdit] = useState(false);
  //image editing modal
  const [imageEdit, setImageEdit] = useState(false);
  //modal bool for showing the brand emails
  const [showEmails, setShowEmails] = useState(false);
  //for values of the brand to be edited
  const [currentBrand, setCurrentBrand] = useState({
    id: "",
    staff: [],
    policy: "",
    policyTitle: "",
    commission: 0.0,
    zaamoCommission: 0.0,
    emails: [],
    shipping: [],
  });
  //setting the brand values to be edited
  const handleEditStaff = (id, staff) => {
    setCurrentBrand({
      id: id,
      staff: staff.map((staff) => ({ label: staff.email, value: staff.id })),
    });
    setShowModal(true);
  };

  //opening the commission update modal
  const handleCommission = (id, commission, zaamoCommission) => {
    setCurrentBrand({
      ...currentBrand,
      id: id,
      commission: commission,
      zaamoCommission: zaamoCommission,
    });
    setShowEdit(true);
  };

  //image editing modal
  const handleImage = (id) => {
    setCurrentBrand({
      ...currentBrand,
      id: id,
    });
    setImageEdit(true);
  };

  //handling brand emails
  const handleBrandEmails = (id, emails) => {
    setCurrentBrand({
      ...currentBrand,
      id: id,
      emails: emails,
    });
    setShowEmails(true);
  };

  // console.log(data, "data");
  const columns = React.useMemo(() => {
    let temp = { ...data[0] };
    delete temp.__typename;

    return Object.keys(temp).map((key, id) => {
      return {
        Header: (value) => {
          return <p>{tableCols[value.headers[id].id]}</p>;
        },
        accessor: key,
        Cell: ({ cell: { value } }) => {
          if (key === "support") {
            const name = value?.link?.name;
            return (
              <a
                style={name === "No Orders" ? S.disabled : null}
                rel="noopener noreferrer"
                target="_blank"
                href={value?.link?.url}
              >
                {name}
              </a>
            );
          }
          if (key === "trackingUrl") {
            const name = value?.link?.name;
            return (
              <a
                style={name === "NA" ? S.disabled : null}
                rel="noopener noreferrer"
                target="_blank"
                href={value?.link?.url}
              >
                {name}
              </a>
            );
          }
          if (key === "instagramLink") {
            const name = value?.name;
            let link = value?.url;
            link = link.search("https://") !== -1 ? link : `https://${link}`;
            return name !== "--" ? (
              <a target="_blank" href={link} rel="noreferrer">
                {name}
              </a>
            ) : (
              <div>{name}</div>
            );
          }
          if (key === "activateAccount") {
            const disabled = value?.status !== "REQUEST_RECEIVED";
            return (
              <button
                style={{
                  backgroundColor: "#000",
                  color: "#eee",
                  border: "none",
                  borderRadius: "6px",
                  width: "max-content",
                  padding: "0.3rem 0.4rem",
                  cursor: disabled ? "default" : "pointer",
                  opacity: disabled ? "0.6" : "1",
                }}
                disabled={disabled}
                onClick={
                  value?.onClick ??
                  (() => {
                    console.log("No function available");
                  })
                }
              >
                Campus Ambassador
              </button>
            );
          }
          if (key === "postPay" || key === "postPayType") {
            return <div>{value?.name}</div>;
          }
          if (value?.hyperlink) {
            return (
              <p
                onClick={value?.function}
                dangerouslySetInnerHTML={{ __html: value?.name }}
              />
            );
          }
          if (key === "notification") {
            return (
              <div onClick={value.function}>
                {!value.value ? (
                  <img
                    src="/assets/images/whatsapp.png"
                    style={{ height: "30px", cursor: "pointer" }}
                  />
                ) : (
                  <img
                    src="/assets/images/whatsapp.png"
                    style={{
                      height: "30px",
                      filter: "saturate(0)",
                      opacity: "0.75",
                    }}
                  />
                )}
              </div>
            );
          }
          if (value?.checkbox) {
            return (
              <input
                type="checkbox"
                onChange={(e) => value.function(e.target.checked, value.data)}
                defaultChecked={value.bool}
              />
            );
          }
          if (from === "influencer" && key === "dateOfBirth") {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {value.name}
                <div onClick={value.function}>
                  <ReactSVG
                    style={{ padding: "1px" }}
                    src="/assets/images/edit.svg"
                  />
                </div>
              </div>
            );
          }
          if (key === "cod") {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {value.name}
                <div onClick={value.function}>
                  <ReactSVG
                    style={{ padding: "1px" }}
                    src="/assets/images/edit.svg"
                  />
                </div>
              </div>
            );
          }
          if (from === "influencer" && key === "status") {
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {value.name}
                <div onClick={value.function}>
                  <ReactSVG
                    style={{ padding: "1px" }}
                    src="/assets/images/edit.svg"
                  />
                </div>
              </div>
            );
          }
          if (from === "stores" && key === "storeManagers") {
            let members = [];
            value.data.map((mem) => members.push(mem?.email));
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                {members.length > 0 ? members.join(", ") : "--"}
                <div onClick={value.function}>
                  <ReactSVG
                    style={{ padding: "1px" }}
                    src="/assets/images/edit.svg"
                  />
                </div>
              </div>
            );
          }
          if (from === "brands" && value?.img) {
            return (
              <>
                <div className="img">
                  <img src={value?.img} alt="brand" />
                </div>
                <div onClick={() => handleImage(value.id)}>
                  <ReactSVG style={S.SvgStyle} src="/assets/images/edit.svg" />
                </div>
              </>
            );
          } else if (value?.img) {
            return (
              <div className="img">
                <img src={value.img} />
              </div>
            );
          } else if (value?.link?.url) {
            return (
              <a
                href={value?.link?.url}
                rel="noopener noreferrer"
                target="_blank"
              >
                {value?.link?.name}
              </a>
            );
          } else if (value?.function) {
            return (
              <span
                style={{
                  cursor: "pointer",
                  color: value?.disable ? "grey" : "blue",
                  pointerEvents: value?.disable ? "none" : "auto",
                }}
                onClick={value.function}
              >
                {value.name}
              </span>
            );
          } else {
            // if (id === 10 || id === 11) {
            //   return (Math.round(value * 10) / 10)
            //     .toFixed(1)
            //     .toString()
            //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // }
            if (from === "brands" && key === "brandStaffMembers") {
              let staffMembers = [];
              value.data.map((staff) => staffMembers.push(staff?.email));
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {staffMembers.length > 0 ? staffMembers.join(", ") : "--"}
                  <div onClick={() => handleEditStaff(value.id, value.data)}>
                    <ReactSVG
                      style={{ padding: "1px" }}
                      src="/assets/images/edit.svg"
                    />
                  </div>
                </div>
              );
            }
            if (from === "brands" && key === "brandEmails") {
              let brandEmails = [];
              value.data.map(({ node }) => brandEmails.push(node?.brandEmail));
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {brandEmails.length > 0 ? brandEmails.join(", ") : "--"}
                  <div onClick={() => handleBrandEmails(value.id, value.data)}>
                    <ReactSVG
                      style={{ padding: "1px" }}
                      src="/assets/images/edit.svg"
                    />
                  </div>
                </div>
              );
            }
            if (value === "") {
              return "--";
            } else if (from === "brands" && key === "brandCommission") {
              return (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  {value.data ?? "--"}
                  <div
                    onClick={() =>
                      handleCommission(
                        value.id,
                        value.data,
                        value.zaamoCommission
                      )
                    }
                  >
                    <ReactSVG
                      style={{ padding: "1px" }}
                      src="/assets/images/edit.svg"
                    />
                  </div>
                </div>
              );
            } else if (
              (from === "influencer" || from === "management") &&
              !isNaN(value)
            ) {
              return value;
            } else if (from === "overallDashboard" && !isNaN(value)) {
              return Math.trunc(value).toLocaleString("en-IN");
            } else if (!isNaN(value)) {
              let spl = `${value}`.split(".");
              let v = parseInt(spl[0]).toLocaleString("en-IN");
              if (spl[1]) v += `.${spl[1]}`;
              return v;
            } else {
              return value;
            }
          }
        },
      };
    });
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
    prepareRowpage,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage - 1,
        pageSize: displayPageSize ?? 10,
      },
    },
    usePagination
  );

  if (!data) return <PacmanLoader />;

  // console.log(data, page);
  if (!page.length) {
    return <S.NoData>NO DATA FOUND!</S.NoData>;
  }

  return (
    <S.ScrollX>
      <S.Table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              <th>Sno.</th>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                <td>{i + 1 + (currentPage - 1) * (displayPageSize ?? 10)}</td>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </S.Table>
      <ReactModal
        isOpen={showModal}
        onRequestClose={() => setShowModal(false)}
        style={S.Modal}
      >
        <EditStaff
          id={currentBrand.id}
          staff={currentBrand.staff}
          close={() => setShowModal(false)}
        />
      </ReactModal>
      <ReactModal
        isOpen={showEdit}
        onRequestClose={() => setShowEdit(false)}
        style={S.Modal}
      >
        <EditCommission
          brandCommission={currentBrand.commission}
          zaamoCommissionValue={currentBrand.zaamoCommission}
          id={currentBrand.id}
          close={() => setShowEdit(false)}
        />
      </ReactModal>
      <ReactModal
        isOpen={imageEdit}
        onRequestClose={() => setImageEdit(false)}
        style={S.Modal}
      >
        <EditImage id={currentBrand.id} close={() => setImageEdit(false)} />
      </ReactModal>
      <ReactModal
        isOpen={showEmails}
        onRequestClose={() => setShowEmails(false)}
        style={{
          ...S.Modal,
          content: { ...S.Modal.content, maxWidth: "550px" },
        }}
      >
        <EditBrandEmails
          id={currentBrand.id}
          emails={currentBrand.emails}
          close={() => setShowEmails(false)}
        />
      </ReactModal>
    </S.ScrollX>
  );
};

const tableCols = {
  thumbnail: "Product Image",
  store: "Store Name",
  brandName: "Brand Name",
  productName: "Product Name",
  orderId: "Order ID",
  source: "Source",
  status: "Status",
  orderStatus: "Order Status",
  MRP: "MRP",
  MSP: "MSP",
  quantity: "QTY",
  discount: "Discount",
  finalPricePaid: "Final price paid",
  discountName: "Coupon",
  voucher: "Voucher",
  commission: "Influencer Commissions",
  created: "Timestamp",
  customerName: "Customer Name",
  billingAddress: "Billing Address",
  penalty: "Penalty",
  date: "Date",
  activeInfluencerCount: "Active Influencer Count",
  wishlistCount: "Wishlist Count",
  signupsCount: "Signups Count",
  purchaseCount: "Purchase Count",
  abandonedCartCount: "Abandoned Cart Count",
  totalStoreVisits: "Total Store Visits",
  totalProductVisits: "Total Product Visits",
  totalLandingPageVisits: "Total zaamo.co visits",
  totalSellingPrice: "Total Selling Price",
  totalBrandDiscount: "Total Brand Discount",
  totalCouponAdjustments: "Total Coupon Adjustments",
  shippingPrice: "Shipping Price",
  pickupAddress: "Pickup Address",
  totalCollectionVisits: "Total collection visits",
  range: "Range",
  brandOrderStatus: "Brand Order Status",
  storeName: "Store Name",
  storeUrl: "Store URL",
  influencerManagers: "Influencer Managers",
  image: "Image",
  generateBOTD: "Generate BOTD",
  variantName: "Variant Name",
  variantMRP: "Variant MRP",
  variantMSP: "Variant MSP",
  brandManagers: "Brand Managers",
  userLastUpdated: "User Last Updated",
  content: "Content",
  updatedAt: "Updated At",
  createdAt: "Created At",
  productsAddedInCollections: "Products Added In Collections",
  avgWatchTime: "Average Watch Time",
  mediaLink: "Media Link",
  taggedLink: "Tagged Link",
  timeOfUpload: "Time of Upload",
  totalNoOfViews: "Total Number of Views",
  totalStreamTime: "Total Stream Time",
  totalViewButtonClicks: "Total View Button Clicks",
  typeOfContent: "Type of Content",
  totalNetGmv: "Total Net GMV",
  brandBarterGuidelines: "Brand Barter Guidelines",
  sourcingRequestId: "Sourcing Request ID",
  brandSourcingId: "Brand Sourcing Request ID",
  termsAndConditions: "Terms and Conditions",
  brandCollab: "Brand Collab",
  state: "State",
  storeBucket: "Store Bucket",
  storeManagers: "Store Managers",
  createdBy: "Created By",
  brandId: "Brand Id",
  brandSource: "Brand Source",
  brandBarter: "Brand Barter",
  brandOrderInfo: "Brand Order Info",
  active: "Active",
  botd: "BOTD",
  brandCommission: "Brand Commission",
  cod: "COD",
  COD: "COD",
  shortDescription: "Short Description",
  orderProcessingDays: "Order Processing Days",
  orderShippingDays: "Order Shipping Days",
  tooManyOrders: "Too Many Orders",
  return: "Return",
  exchange: "Exchange",
  brandStaffMembers: "Brand Staff Members",
  actions: "Actions",
  updatedBy: "Updated By",
  requestReceived: "Request Received",
  brandCouponCreated: "Brand Coupon Created",
  brandSharedDeliverables: "Brand Shared Deliverables",
  currentInv: "Current Inventory",
  instagram: "Instagram",
  campaignName: "Campaign Name",
  returnPolicy: "Return Policy",
  shippingPolicy: "Shipping Policy",
  brandEmails: "Brand Emails",
  brandShipping: "Brand Shipping",
  downloadSourcing: "Download Sourcing",
  storeStatus: "Store Status",
  restrictOrder: "Restrict Barter Order",
  ledgerCreation: "Ledger Creation",
  instagramLink: "Instagram Link",
  postPay: "Postpay",
  postPayType: "Postpay Type",
  contact: "Contact",
  support: "Customer Support",
  stopSourceWithZaamo: "Stop Source With Zaamo",
  storeId: "Store Id",
  slug: "Slug",
  storeType: "Store Type",
  storeCategoryPageLevel: "Category Page Level",
  nextActions: "Next Actions",
  streakLive: "Streak Live",
  daysSinceProductsDelivered: "Days Since Products Delivered",
  deliveryDate: "Delivery Date",
  storeActions: "Store Actions",
  instagramUsername: "Instagram Username",
  dateOfBirth: "Date of Birth",
  mobileNo: "Mobile No",
  recommended: "Recommended",
  barterGuidelines: "Barter Guidelines",
  influencerNotes: "Influencer Notes",
  barterOrder: "Barter Order",
  customerPhone: "Customer Phone",
  customerEmail: "Customer Email",
  customerAddress: "Customer Address",
  mspShipping: "MSP + Shipping",
  nextTask: "Next task",
  nextDate: "Next date",
  perDayOrderCount: "Per Day Order Count",
  perDayCheckoutCount: "Per Day Checkout Count",
  notification: "Notification",
  appCode: "App Code",
  activateAccount: "Activate Account",
  note: "Note",
  step_active: "Step Active",
  historyNotes: "History Notes",
  content_tagging: "Content Tagging",
  importance: "Brand Importance",
  trackingUrl: "Tracking Url",
  GSTDetails: "GST Details",
};

export default Table;
