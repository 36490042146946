import { gql } from "@apollo/client";

export const GET_PENALTY = gql`
  mutation getPenalty($id: ID!) {
    orderlineMetaUpdate(id: $id, input: [{ key: "penalty", value: "10" }]) {
      item {
        metadata {
          key
          value
        }
      }
      metadataErrors {
        field
        message
      }
    }
  }
`;
