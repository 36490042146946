import axios from "axios";

export const fetchContent = async (
  offset,
  brand,
  time,
  typeOfContent,
  sortBy,
  direction
) => {
  const params = {
    limit: 100,
    offset: offset,
    start_date: time?.startDate,
    end_date: time?.endDate,
    type_of_content: typeOfContent?.value ?? "",
    brand_id: brand?.value ?? "",
    sort_by: sortBy?.value ?? "",
    direction: direction.value,
  };

  const res = await axios({
    method: "GET",
    url: `${process.env.REACT_APP_CONTENT_API_BASE_URl}/streaming/api/content/analytics/`,
    params: params,
    headers: {
      "Service-Token": "2900ba48-85f6-4929-b19d-0c0da14dbc14",
    },
  });
  return res.data;
};
