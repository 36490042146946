import { gql } from "@apollo/client";

export const GET_CATEGORIES = gql`
  query GetCategories($endCursor: String) {
    categories(first: 100, after: $endCursor) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORIES_BASED_ON_SELECTED_BRAND = gql`
  query GetBrandBasedCategory($brandId: ID!) {
    brand(id: $brandId) {
      brandName
      id
      categories {
        name
        id
      }
      __typename
    }
  }
`
