import CopyToClipboard from "react-copy-to-clipboard";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import Button from "../../components/Button";

export const CardWrapper = styled.div`
  padding: 1rem;
`;

export const InnerCardWrapper = styled.div`
  box-shadow: #eee 1px 1px 10px 10px;
  border-radius: 5px;
  overflow: hidden;
  font-size: 12px;
`;

export const Status = styled.div`
  padding: 0.5rem 1rem;
  text-align: center;
  background: ${(props) => (props.status ? "#00A362" : "tomato")};
  color: white;
  margin-bottom: 1rem;
  font-size: 12px;
  &:first-letter {
    text-transform: uppercase;
  }
`;
export const Mid = styled.div`
  display: grid;
  flex-direction: column;
  gap: 0.2rem;
  padding: 0rem 1rem;
  text-align: center;
  div {
    font-size: 12px;
    color: gray;
  }
`;
export const Discount = styled.p`
  font-size: 18px;
  margin-bottom: 0.25rem;
`;
export const Divide = styled.div`
  border-bottom: 2px dashed gray;
  width: 100%;
  margin: 1rem 0;
`;
export const Bottom = styled.div`
  padding: 0rem 1rem;
  text-align: center;
`;
export const Code = styled.p`
  text-transform: uppercase;
  font-size: 16px;
`;
export const CopyCode = styled(CopyToClipboard)`
  background: black;
  color: white;
  width: 90%;
  margin: 0.5rem auto;
  padding: 0.25rem;
  border-radius: 5px;
`;

export const CopyButton = styled(Button)`
  padding: 0 15px;

  p {
    flex: 1;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const HiddenSpace = styled(ReactSVG)`
  opacity: 0;
`;
export const Valid = styled.p`
  color: gray;
  margin: 1rem 0px;
  font-size: 12px;
`;

export const ConditionsWrapper = styled.div`
  padding: 0 1rem;
`;
export const ConditionsInnerWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: #eee 1px 1px 10px 10px;
  border-radius: 5px;
  overflow: hidden;
`;
export const Heading = styled.div`
  text-align: center;
  margin-bottom: 0.5rem;
  color: #999;
`;
export const Conditions = styled.div`
  font-size: 0.9rem;
  width: 100%;
  line-height: 1.7;
`;
