import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import { GET_DASHBOARD_BRANDS } from "../../../screens/BrandsDashboard/queries";
import Button from "../../Button";

const EditImage = ({ id, close }) => {
  const [image, setImage] = useState(null);

  const handleImage = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append("brand_id", id);
    formData.append("category_type", "brand");
    formData.append("image", image);
    axios({
      method: "POST",
      url:
        process.env.REACT_APP_GRAPHQL_BASE_URL.replace("/graphql/", "") +
        "/api/v1/zaamo_integration/upload_brand_image/",
      data: formData,
    })
      .then((res) => {
        console.log(res.data);
        toast(res.data.message);
      })
      .then(() => {
        client.refetchQueries({
          include: [GET_DASHBOARD_BRANDS],
        });
      });
    close();
  };

  return (
    <div>
      <h3>Upload New Image</h3>
      <input style={{ margin: "10px 0px" }} type="file" required onChange={handleImage} />
      <Button onClick={handleUpload} disabled={!image}>
        Update
      </Button>
    </div>
  );
};

export default EditImage;
