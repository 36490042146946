import { useMutation } from "@apollo/client";
import Button from "../../components/Button";
import { CREATE_MEETUP, DELETE_MEETUP, UPDATE_MEETUP } from "./mutations";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import * as S from "./styles";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";
import { GET_MEETUPS } from "./queries";

export default function MeetupDetails({ id, details, dateTime }) {
  const [createMeetup, { error }] = useMutation(CREATE_MEETUP);
  const [updateMeetup, { error: errorUpdate }] = useMutation(UPDATE_MEETUP);
  const [deleteMeetup, { error: errorDelete }] = useMutation(DELETE_MEETUP);
  const urlValidator = "https?://.*";

  const { title, host, calendar_link, join_link } = id ? JSON.parse(details) : {};

  const handleDelete = async () => {
    const res = await deleteMeetup({
      variables: {
        id,
      },
    });
    if (res?.data?.meetupDelete?.success) {
      toast.success("Meetup deleted successfully");
      client.refetchQueries({
        include: [GET_MEETUPS],
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { date, title, host, calendar_link, join_link } = e.target;
    const dateTime = date.value;
    const details = JSON.stringify({
      title: title.value,
      host: host.value,
      calendar_link: calendar_link.value,
      join_link: join_link.value,
    });
    if (id) {
      const res = await updateMeetup({ variables: { id, dateTime, details } });
      if (res.data?.meetupUpdate?.meetup?.id) toast.success("Meetup updated successfully");
      client.refetchQueries({
        include: [GET_MEETUPS],
      });
    } else {
      const res = await createMeetup({ variables: { dateTime, details } });
      if (res.data?.meetupCreate?.meetup?.id) toast.success("Meetup created successfully!");
      document.getElementById("meetup").reset();
    }
  };

  if (error || errorUpdate || errorDelete) return <ErrorPage />;

  return (
    <form
      id="meetup"
      onSubmit={handleSubmit}
      style={{ display: "flex", width: "100%", marginTop: "25px" }}
    >
      <S.Contain>
        <S.Label>Title</S.Label>
        <S.Input name="title" defaultValue={title} required />
      </S.Contain>
      <S.Contain>
        <S.Label>Host</S.Label>
        <S.Input name="host" defaultValue={host} required />
      </S.Contain>
      <S.Contain>
        <S.Label>Calendar Link</S.Label>
        <S.Input
          name="calendar_link"
          placeholder="eg: https://zaamo.co"
          pattern={urlValidator}
          defaultValue={calendar_link}
          title="Enter the complete URL"
          required
        />
      </S.Contain>
      <S.Contain>
        <S.Label>Join Link</S.Label>
        <S.Input
          name="join_link"
          pattern={urlValidator}
          placeholder="eg: https://meet.google.com/etc"
          defaultValue={join_link}
          title="Enter the complete URL"
          required
        />
      </S.Contain>
      <S.Contain>
        <S.Label>Date & Time</S.Label>
        <S.Input name="date" type="datetime-local" defaultValue={dateTime} required />
      </S.Contain>
      {!id && (
        <Button type="submit" style={S.BtnStyle}>
          Create
        </Button>
      )}
      {id && (
        <>
          <Button type="submit" style={S.BtnStyle}>
            Update
          </Button>
          <Button type="button" onClick={handleDelete} style={{ ...S.BtnStyle, marginLeft: 10 }}>
            Delete
          </Button>
        </>
      )}
    </form>
  );
}
