export const getEncodedJson = (json) => {
  // const stringRep = JSON.stringify(json);
  // const encoded = window.btoa(stringRep);
  // return encoded;
  let temp = json.map((e) => window.atob(e).replace("Store:", ""));
  return temp.toString();
};

export const getDecodedJson = (query) => {
  console.log(query);
  // const decoded = window.atob(query);
  // const original = JSON.parse(decoded);
  // return original;
  let temp = query.split(",");
  return temp.map((e) => window.btoa(`Store:${e}`));
};

//adding anchor tags to string where links are available
export const convertToUrl = (value) => {
  const regex =
    /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/gi;
  return value.replace(regex, (url) => {
    return `<a style="color: blue;" href="${url}" target="_blank">${url}</a>`;
  });
};
