import * as S from "./styles";

function Bottom() {
  return (
    <S.Bottom>
      <S.Tags>
        <S.Tag>
          <S.TagIcon />
          <S.Small>Tag 1</S.Small>
        </S.Tag>
        <S.Tag>
          <S.TagIcon />
          <S.Small>Tag 2</S.Small>
        </S.Tag>
        <S.Tag>
          <S.TagIcon />
          <S.Small>Tag 3</S.Small>
        </S.Tag>
      </S.Tags>

      <S.SearchBar>
        <S.SearchIcon />
        <S.Input />
      </S.SearchBar>
    </S.Bottom>
  );
}

export default Bottom;
