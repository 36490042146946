import { gql } from "@apollo/client";

export const SEARCH_PRODUCTS = gql`
  query GetSearchedProducts($endCursor: String, $search: String, $brands: [ID]) {
    products(
      after: $endCursor
      first: 10
      filter: { isPublished: true, search: $search, brandStatus: [ACTIVE], brands: $brands }
      sortBy: { direction: DESC, field: HOT_SELLING }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          hasCustomCommission
          commissionPercentage
          tooManyOrders
          brandBarter
          metadata {
            key
            value
          }
          variants {
            id
            quantityAvailable
            costPrice {
              amount
            }
            pricing {
              onSale
              priceUndiscounted {
                gross {
                  amount
                }
              }
            }
          }
          brand {
            brandName
            commission
          }
          name
          slug
          productType {
            name
          }
          thumbnail(size: 1080) {
            url
          }
          defaultVariant {
            costPrice {
              amount
            }
            pricing {
              onSale
              priceUndiscounted {
                gross {
                  amount
                }
              }
              price {
                gross {
                  amount
                }
              }
              discount {
                gross {
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;
