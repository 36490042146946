import styled from "styled-components";

export const Input = styled.input`
  width: 100%;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const Wrapper = styled.div`
  width: fit-content;
  padding-bottom: 15px;
`;

export const GreyBg = styled.div`
  background-color: #dfdfde;
  width: fit-content;
  padding: 10px;
  margin: 10px 0px;
  border-radius: 5px;
`;

export const Time = styled.h6`
  margin-left: auto;
  color: grey;
  float: right;
`;

export const BottomWrapper = styled.div`
  display: flex;
  padding: 5px;
  border: 2px solid #000;
  position: fixed;
  max-width: inherit;
  width: 100%;
  bottom: 8px;
  background-color: #FFF;
`;

export const SaveButton = styled.button`
  color: #fff;
  background-color: #000;
  padding: 8px;
  border-radius: 5px;
`;
