import styled from "styled-components";

export const Btn = styled.button`
  padding: 10px;
  margin-top: 17px;
`;

export const TextLabel = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 0.8rem;
  margin-bottom: 5px;
`;