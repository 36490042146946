import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import Dropdown from "../../../components/Dropdown";
import { GET_DASHBOARD_BRANDS } from "../queries";
import { ACTIVATE_BRAND, ADD_CONTACT, REGISTER_USER } from "./mutations";
import { USER, USER_BY_MOBILE } from "./queries";
import * as S from "./styles";

export default function ViewContact({ name, number, brandId }) {
  const [active, setActive] = useState(false);
  const [addContact, setAddContact] = useState(false);
  const [userName, setUserName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [type, setType] = useState("");

  const { data, loading, error } = useQuery(USER, {
    variables: {
      brandId,
    },
    fetchPolicy: "network-only",
  });
  const mobiles = data?.brand?.mobiles;

  const [registerUser, { error: userErr, loading: userLoad }] =
    useMutation(REGISTER_USER);
  const [activateBrand, { error: brandErr, loading: brandLoad }] = useMutation(
    ACTIVATE_BRAND,
    {
      refetchQueries: [GET_DASHBOARD_BRANDS],
    }
  );
  const [addBrandContact, { error: addError, loading: addLoading }] =
    useMutation(ADD_CONTACT, {
      refetchQueries: [GET_DASHBOARD_BRANDS],
    });

  const checkActive = async (mobileNo) => {
    const fetchedData = await client.query({
      query: USER_BY_MOBILE,
      variables: {
        mobile: mobileNo,
      },
      fetchPolicy: "no-cache",
    });
    return !!fetchedData?.data?.userByMobile;
  };

  const handleActivateBrand = async (mobileNo, id) => {
    const res = await client.mutate({
      mutation: ACTIVATE_BRAND,
      variables: {
        no: mobileNo,
        brandId: id,
      },
      refetchQueries: [GET_DASHBOARD_BRANDS],
    });
    if (res?.data?.activateBrandAccount?.user?.isActive) {
      toast.success("Brand is Activated!");
      setActive(true);
    } else toast.error("Brand activation went wrong!");
  };

  const handleRegistration = async (mobileNo, id) => {
    const res = await client.mutate({
      mutation: REGISTER_USER,
      variables: {
        no: mobileNo,
      },
    });
    if (res?.data?.userRegister?.user?.id) {
      toast.success("User Registered!");
      handleActivateBrand(mobileNo, id);
    } else toast.error("User registration went wrong!");
  };

  const handleActivation = async (active, id, mobileNo) => {
    if (!active) {
      const val = await checkActive(mobileNo);
      if (!val) return handleRegistration(mobileNo, id);
      else return await handleActivateBrand(mobileNo, id);
    }
  };
  if (error || userErr || brandErr) return <p>Something went wrong....</p>;

  const typeOptions = [
    { label: "primary", value: "primary" },
    { label: "secondary", value: "secondary" },
    { label: "support", value: "support" },
    { label: "marketing", value: "marketing" },
    { label: "founder", value: "founder" },
  ];

  const checkIfEmpty = () => {
    if (userName && mobileNo && type) {
      return false;
    }
    return true;
  };
  const mobileNoValidation = (number) => {
    return (/^(\+\d{1,3}[- ]?)?\d{10}$/).test(number)
  };
  const handleAddContact = async () => {
    if (!checkIfEmpty()) {
      if (mobileNoValidation(mobileNo)) {
        const res = await addBrandContact({
          variables: {
            mobileNo: `91${mobileNo.slice(-10)}`,
            userName: userName,
            type: type?.value.toUpperCase(),
            id: brandId,
          },
        });

        if (res?.data?.brandUpdate) {
          toast.success("Contact added successfully");
        } else {
          toast.error("Something went wrong!");
        }
      } else {
        toast.error("Enter a valid phone number");
      }
    }
  };
  return (
    <>
      {loading || userLoad || brandLoad ? (
        <S.SpinContainer>
          <S.Spinner />
        </S.SpinContainer>
      ) : (
        <S.Table>
          {mobiles.length === 0 ? (
            <p>No Records found</p>
          ) : (
            <>
              <S.TableRow>
                <S.HeaderCell>Name</S.HeaderCell>
                <S.HeaderCell>Mobile no</S.HeaderCell>
                <S.HeaderCell>Type</S.HeaderCell>
                <S.HeaderCell></S.HeaderCell>
              </S.TableRow>
              {mobiles.map((item, index) => {
                return (
                  <S.TableRow key={index}>
                    <S.Cell>{item?.userName}</S.Cell>
                    <S.Cell>{item?.mobileNo.slice(0, -4)}xxxx</S.Cell>
                    <S.Cell>{item?.type}</S.Cell>
                    <S.ButtonCell
                      bg={item?.active ? "green" : "red"}
                      onClick={() =>
                        handleActivation(
                          item?.active,
                          brandId,
                          `91${item?.mobileNo.slice(-10)}`
                        )
                      }
                    >
                      {item?.active ? "Activated" : "Activate"}
                    </S.ButtonCell>
                  </S.TableRow>
                );
              })}
            </>
          )}
          {!addContact ? (
            <S.ButtonCell
              style={{ width: "25%", marginTop: "10px" }}
              bg="black"
              onClick={() => setAddContact(true)}
            >
              Add Contact
            </S.ButtonCell>
          ) : (
            <S.TableRow>
              <S.InputCell
                placeholder="Name"
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <S.InputCell
                placeholder="Mobile no."
                type="number"
                value={mobileNo}
                onChange={(e) => setMobileNo(e.target.value)}
              />
              <Dropdown
                style={{ width: "23%", marginRight: "1.75%" }}
                data={typeOptions}
                onChange={(e) => setType(e)}
                value="value"
                label="label"
                defaultValue={type}
              />
              <S.ButtonCell
                style={{ opacity: checkIfEmpty() ? 0.4 : 1 }}
                bg="yellow"
                color="black"
                onClick={() => handleAddContact()}
              >
                Add
              </S.ButtonCell>
            </S.TableRow>
          )}
        </S.Table>
      )}
    </>
  );
}
