import styled from "styled-components";

export const Container = styled.div`
    height: 100vh;
    max-width: 800px;
    margin: auto;
    padding: 30px 20px;    
    li {
        padding: 10px 0;
    }
    ul {
        margin-bottom: 20px;
    }
    h2 {
        font-size: 1.1rem;
    }
`;