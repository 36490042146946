import { useState } from "react";
import EmailItem from "./EmailItem";

export default function EditBrandEmails({ id, emails }) {
  const [brandEmails, setBrandEmails] = useState(emails);

  return (
    <div>
      <h3 style={{ marginBottom: "20px" }}>Edit Brand Emails</h3>
      {brandEmails.map(({ node }, index) => {
        return <EmailItem id={id} key={index} emailValue={node} />;
      })}
      <h5
        style={{ marginTop: "10px", cursor: "pointer" }}
        onClick={() => {
          setBrandEmails((prev) => {
            return [...prev, { node: null }];
          });
        }}
      >
        &#43; Add new Email
      </h5>
    </div>
  );
}
