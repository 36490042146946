import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import Auth from "../screens/Auth";
import BotdDashboard from "../screens/BotdDashboard";
import BrandAuth from "../brand_management/BrandAuth";
import BrandSourcing from "../screens/BrandSourcing";
import BrandsDashboard from "../screens/BrandsDashboard";
import BrandsMasterDashboard from "../brand_management/BrandsMasterDashboard";
import BulkDeals from "../screens/BulkDeals";
import Collections from "../screens/Collections/Collections";
import Comments from "../screens/Comments";
import ContentAnalytics from "../screens/ContentAnalytics";
import CouponCreate from "../screens/CouponCreate";
import CouponDetails from "../screens/CouponDetails";
import Coupons from "../screens/Coupons";
import CustomerSupport from "../screens/CustomerSupport";
import EditCoupon from "../screens/EditCoupon";
import Home from "../screens/Home";
import InfluencerDashboard from "../screens/InfluencerDashboard";
import MasterDashboard from "../screens/MasterDashboard";
import MeetupDashboard from "../screens/MeetupDashboard";
import OverallDashboard from "../screens/OverallDashboard";
import PacmanLoader from "../components/PackmanLoader";
import PageNotFound from "../screens/PageNotFound";
import Products from "../screens/Products";
import Products2 from "../screens/Products2";
import Refund from "../screens/Refund";
import SignUp from "../screens/Auth/SignUp";
import SourceWithZaamo from "../screens/SourceWithZaamo";
import StoreAnalytics from "../screens/StoreAnalytics";
import StoresDashboard from "../screens/StoresDashboard";
import useAuth from "../hooks/useAuth/useAuth";
import ShopifyAnalytics from "../screens/ShopifyAnalytics";

function ProtectRoutes() {
  const { authLoading, authError } = useAuth();

  if (authLoading) {
    return <PacmanLoader />;
  }

  if (authError) {
    return <div>Oops something went wrong</div>;
  }
  return <Outlet />;
}

function Navigation() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Auth />} />
        <Route exact path="/" element={<ProtectRoutes />}>
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/products" element={<Products2 />} />
          <Route
            exact
            path="/prod/analytics/store"
            element={<StoreAnalytics />}
          />
          <Route exact path="/prod/analytics/voucher" element={<Coupons />} />
          <Route
            exact
            path="/prod/analytics/voucher/details"
            element={<CouponDetails />}
          />
          <Route
            exact
            path="/prod/analytics/voucher/edit"
            element={<EditCoupon />}
          />
          <Route exact path="/prod/analytics/product" element={<Products />} />
          <Route
            exact
            path="/prod/analytics/collection"
            element={<Collections />}
          />
          <Route
            exact
            path="/prod/dashboard/overall"
            element={<OverallDashboard />}
          />
          <Route
            exact
            path="/prod/dashboard/master"
            element={<MasterDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/overall"
            element={<OverallDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/master"
            element={<MasterDashboard />}
          />
          <Route
            exact
            path="/prod/support/orders"
            element={<CustomerSupport />}
          />
          <Route
            exact
            path="/beta/support/orders"
            element={<CustomerSupport />}
          />
          <Route exact path="/prod/support/refund" element={<Refund />} />
          <Route exact path="/prod/analytics/comments" element={<Comments />} />
          <Route exact path="/beta/analytics/comments" element={<Comments />} />
          <Route exact path="/sourcing" element={<SourceWithZaamo />} />
          <Route
            exact
            path="/beta/analytics/content"
            element={<ContentAnalytics />}
          />
          <Route
            exact
            path="/prod/analytics/content"
            element={<ContentAnalytics />}
          />
          <Route exact path="/beta/influencer/signup" element={<SignUp />} />
          <Route exact path="/prod/influencer/signup" element={<SignUp />} />
          <Route
            exact
            path="/beta/brand/sourcing"
            element={<BrandSourcing />}
          />
          <Route
            exact
            path="/prod/brand/sourcing"
            element={<BrandSourcing />}
          />
          <Route
            exact
            path="/prod/dashboard/bulk_deal/stores"
            element={<BulkDeals />}
          />
          <Route
            exact
            path="/prod/dashboard/brands"
            element={<BrandsDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/brands"
            element={<BrandsDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/botd"
            element={<BotdDashboard />}
          />
          <Route
            exact
            path="/prod/dashboard/botd"
            element={<BotdDashboard />}
          />
          <Route exact path="/beta/coupon/create" element={<CouponCreate />} />
          <Route exact path="/prod/coupon/create" element={<CouponCreate />} />
          <Route
            exact
            path="/beta/dashboard/stores"
            element={<StoresDashboard />}
          />
          <Route
            exact
            path="/prod/dashboard/stores"
            element={<StoresDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/influencer"
            element={<InfluencerDashboard />}
          />
          <Route
            exact
            path="/prod/dashboard/influencer"
            element={<InfluencerDashboard />}
          />
          <Route
            exact
            path="/beta/dashboard/meetup"
            element={<MeetupDashboard />}
          />
          <Route
            exact
            path="/prod/dashboard/meetup"
            element={<MeetupDashboard />}
          />
          <Route
            exact
            path="/prod/analytics/shopify"
            element={<ShopifyAnalytics />}
          />
        </Route>
        <Route exact path="/brand" element={<BrandAuth />} />
        <Route
          exact
          path="/brand/management"
          element={<BrandsMasterDashboard />}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default Navigation;
