import { gql } from "@apollo/client";

export const GET_STORE_COMMENTS = gql`
  query GetStoreComments($storeId: ID!, $endcursor: String) {
    storeManagerComments(
      first: 10
      after: $endcursor
      sortBy: { direction: DESC, field: CREATED_AT }
      filter: { stores: [$storeId] }
    ) {
      edges {
        node {
          user {
            firstName
            email
            userId
          }
          createdAt
          comment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`;
