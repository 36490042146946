import * as S from "./styles";
import Layout from "../../components/Layout";
import Button from "../../components/Button";
import { Link } from "react-router-dom";
import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { GET_STORE_ANALYTICS, GET_USER_STORES } from "./queries";
import { useEffect, useState } from "react";
import Filters from "./Filters";
import useAuth from "../../hooks/useAuth/useAuth";
import PacmanLoader from "../../components/PackmanLoader";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactModal from "react-modal";
import { filterVars } from "../../reactiveVariables";
import { getEncodedJson } from "../../utils/utils";
import NetPaid from "./components/NetPaid";
import { StoreManagersActions } from "./StoreManagerActions";
import { scrollVars } from "../../reactiveVariables";

const StoreAnalytics = () => {
  const [stores, setStores] = useState([]);
  const [timeperiod, setTimeperiod] = useState(null);
  //boolean for showing the payout modal
  const [showNetPaid, setShowNetPaid] = useState(false);
  //storeid for fetching transactions
  const [storeId, setStoreId] = useState("");
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });
  const [sortBy, setSortBy] = useState({
    label: "Created At",
    value: "CREATED_AT",
  });
  //state for storing store status filters
  const [storeStatus, setStoreStatus] = useState([]);
  //state for storing store next actions filters
  const [storeAction, setStoreAction] = useState([]);

  const { data, loading, error } = useQuery(GET_USER_STORES, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const {
    data: data2,
    loading: loading2,
    error: error2,
    fetchMore,
  } = useQuery(GET_STORE_ANALYTICS, {
    variables: {
      stores: stores.map((e) => e.value),
      timePeriod: timeperiod?.value?.length ? timeperiod.value : "OVERALL",
      direction: direction?.value,
      field: sortBy?.value ?? "TOTAL_VISITORS",
      storeStatus: storeStatus.map((e) => e.value),
      storeAction: storeAction.map((e) => e.value),
    },
  });

  const filters = useReactiveVar(filterVars);
  const scroll = useReactiveVar(scrollVars);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, scroll?.storeAnalytics?.horizontal);
    }, 300);
  }, []);

  if (loading || loading2) return <PacmanLoader />;
  if (error) return <ErrorPage />;

  const onStoreChange = (e) => {
    console.log(e);
    setStores(e);
  };

  const onTimeperiodChange = (e) => {
    setTimeperiod(e);
  };

  const filtersData = {
    stores: data?.userByMobile?.authorisedStores ?? [],
    onStoreChange: onStoreChange,
    storesValue: stores,
    timeperiod: timeperiod,
    onTimeperiodChange: onTimeperiodChange,
    sortBy,
    direction,
    setSortBy,
    setDirection,
    setStoreStatus,
    setStoreAction,
    storeStatus,
  };

  const setStoresState = (storeName) => {
    const temp2 = getStoreObj(storeName);
    filterVars({
      ...filterVars(),
      stores: [{ ...temp2 }],
    });
    return [{ ...temp2 }];
  };

  const getStoreObj = (storeName) => {
    const temp = data?.userByMobile?.authorisedStores?.filter(
      (e) => e?.storeName === storeName.label
    );
    if (temp.length === 0) return null;
    let temp2 = {};
    temp2["label"] = temp[0]["storeName"];
    temp2["value"] = temp[0]["id"];
    return [temp[0]["id"]];
  };

  const parseDate = (date, time = undefined) => {
    const d = new Date(date);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    if (time) {
      return `${d.getHours()}:${d.getMinutes()} - ${d.getDate()}-${
        months[d.getMonth()]
      }-${d.getFullYear()}`;
    }
    return `${d.getDate()}-${months[d.getMonth()]}-${d.getFullYear()}`;
  };

  return (
    <Layout hideBack title="Summary Metrices">
      <S.FilterWrap>
        <Filters {...filtersData} />
      </S.FilterWrap>
      <S.Wrapper>
        {data2?.storeAnalytics?.edges?.length ? (
          <InfiniteScroll
            dataLength={data2?.storeAnalytics?.edges?.length}
            next={() => {
              console.log("fetching more...");
              fetchMore({
                variables: {
                  endCursor: data2?.storeAnalytics?.pageInfo?.endCursor,
                },
              });
            }}
            onScroll={() => {
              scrollVars({
                ...scrollVars(),
                storeAnalytics: {
                  horizontal: window.scrollY,
                },
              });
            }}
            hasMore={data2?.storeAnalytics?.pageInfo?.hasNextPage}
            loader={<div>Loading...</div>}
            endMessage={
              <p style={{ textAlign: "center", marginTop: "20px" }}>
                Yay! You have seen it all
              </p>
            }
          >
            {data2?.storeAnalytics?.edges?.map((e) => {
              return getStoreObj({ label: e?.node?.storeName }) ? (
                <div className="store-item" key={e.node.id}>
                  <div style={{ display: "flex" }}>
                    <a target="_blank" href={e.node.storeUrl} rel="noreferrer">
                      <h3>{e.node.storeName}</h3>
                    </a>
                    <Link
                      to={`/prod/analytics/comments?id=${e?.node?.storeId}`}
                      style={{ marginLeft: "auto" }}
                    >
                      View History
                    </Link>
                  </div>
                  <StoreManagersActions e={e} />
                  <S.Grid>
                    {Object.keys(e.node).map(
                      (key, id) =>
                        ![
                          "storeName",
                          "storeUrl",
                          "id",
                          "__typename",
                          "totalMspSales",
                        ].includes(key) &&
                        key !== "actions" &&
                        key !== "storeId" && (
                          <S.Card
                            style={{
                              backgroundColor:
                                key === "last7DaysA2c" ? "#FFC300" : "#fff",
                              // key.indexOf("last7") === 0
                              //   ? "#FFC300"
                              //   : key.indexOf("last30") === 0
                              //   ? "#F76E11"
                              //   : key.indexOf("productLastAdded") === 0
                              //   ? "#80008063"
                              //   : "#FFF",
                            }}
                            key={id}
                            onClick={() => {
                              console.log(key);
                              if (key === "netPayoutDue") {
                                const store =
                                  data?.userByMobile?.authorisedStores?.filter(
                                    (val) =>
                                      val.storeName === e?.node?.storeName
                                  );
                                setStoreId(store[0].id);
                                setShowNetPaid(true);
                              }
                            }}
                          >
                            {key === "netPayoutDue" ||
                            key === "totalEarnings" ? (
                              <p>
                                {e?.node[key] && e?.node[key]?.length
                                  ? isNaN(e?.node[key])
                                    ? e?.node[key]
                                    : parseFloat(e?.node[key])
                                        .toFixed(0)
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : 0}
                              </p>
                            ) : key === "createdAt" ||
                              key === "lastLoginTime" ? (
                              <p>
                                {e.node[key] && e.node[key]?.length
                                  ? parseDate(
                                      e.node[key],
                                      key === "lastLoginTime"
                                    )
                                  : "--"}
                              </p>
                            ) : key === "totalStoreSales" ? (
                              <p>
                                {e.node[key] === 0
                                  ? "0"
                                  : e.node[key] && e.node[key]?.length
                                  ? eval(parseInt(e.node[key]))
                                      .toString()
                                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                                  : "--"}
                              </p>
                            ) : key === "totalBrandClicks" ||
                              key === "totalCategoryClicks" ||
                              key === "myOrders" ||
                              key === "checkoutFail" ? (
                              <p>{e?.node[key]}</p>
                            ) : (
                              <p>
                                {e.node[key] && `${e.node[key]}`?.length
                                  ? isNaN(e.node[key])
                                    ? e.node[key]
                                    : eval(e.node[key]).toFixed(2) -
                                        Math.floor(
                                          eval(e.node[key]).toFixed(2)
                                        ) >
                                      0
                                    ? eval(e.node[key]).toFixed(2)
                                    : Math.trunc(
                                        eval(e.node[key]).toFixed(2)
                                      ).toLocaleString()
                                  : "--"}
                              </p>
                            )}
                            <span
                              style={{
                                color:
                                  key.indexOf("last7") === 0 ||
                                  key.indexOf("last30") === 0
                                    ? "#000"
                                    : "",
                              }}
                            >
                              {labels[key]}
                            </span>
                          </S.Card>
                        )
                    )}
                  </S.Grid>
                  <S.Grid>
                    <Link
                      onClick={() =>
                        setStoresState({ label: e.node.storeName })
                      }
                      to={`/prod/analytics/product?store_filters=${getEncodedJson(
                        getStoreObj({ label: e.node.storeName })
                      )}`}
                    >
                      <Button>
                        <img src="/assets/images/product.svg" alt="icon" />
                        PDP
                      </Button>
                    </Link>
                    <Link
                      onClick={() =>
                        setStoresState({ label: e.node.storeName })
                      }
                      to={`/prod/analytics/collection?store_filters=${getEncodedJson(
                        getStoreObj({ label: e.node.storeName })
                      )}`}
                    >
                      <Button>
                        <img src="/assets/images/collection.svg" alt="icon" />
                        Collection
                      </Button>
                    </Link>
                    <Link
                      onClick={() =>
                        setStoresState({ label: e.node.storeName })
                      }
                      to={`/prod/analytics/voucher?store_filters=${getEncodedJson(
                        getStoreObj({ label: e.node.storeName })
                      )}`}
                    >
                      <Button>
                        <img src="/assets/images/coupon.svg" alt="icon" />
                        Coupons
                      </Button>
                    </Link>
                  </S.Grid>
                </div>
              ) : null;
            })}
          </InfiniteScroll>
        ) : (
          <S.NoData>No data found</S.NoData>
        )}
      </S.Wrapper>
      <ReactModal
        isOpen={showNetPaid}
        onRequestClose={() => setShowNetPaid(false)}
        style={S.Modal}
      >
        <NetPaid storeId={storeId} />
      </ReactModal>
    </Layout>
  );
};

const labels = {
  storeName: "Store Name",
  totalVisitors: "Total Visitors",
  totalProductPageViews: "Total product page views",
  totalCollectionViews: "Total collection views",
  wishlistOfProducts: "Wishlist of products",
  totalOrders: "Total orders",
  averageOrderValue: "Avg. order value",
  averageNumberOfItemsInOrder: "Avg. number of items in order",
  averageAbandonedCart: "# of abandoned carts",
  noOfCouponsUsed: "Number of coupons used",
  totalStoreSales: "Total store sales",
  totalEarnings: "Total earnings",
  lastPayout: "Last payout",
  netPayoutDue: "Net payout due",
  createdAt: "Store start date",
  numberOfProductsSold: "No. of product sold",
  last7DaysTotalOrders: "Last 7 days total orders",
  last7DaysA2c: "Last 7 days A2C",
  last7DaysTotalVisitors: "Last 7 days total visitors",
  last30DaysTotalStoreSales: "Last 30 days total store sales",
  last7DaysPdpViews: "Last 7 days PDP views",
  last30DaysPdpViews: "Last 30 days PDP views",
  totalBrandClicks: "Total Brand Clicks",
  totalCategoryClicks: "Total Category Clicks",
  myOrders: "My Orders",
  checkoutFail: "Checkout Fail",
  productLastAdded: "Product Last Added",
  noOfSourcingRequestsInitiated: "No of sourcing requests initiated",
  noOfSourcingRequestsBrandFullfilled:
    "No of sourcing requests brand fulfilled",
  lastLoginTime: "Last login time",
  cities: "Cities",
  appLastLaunchedAt: "App Last Launched At",
};

export default StoreAnalytics;
