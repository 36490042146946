import styled from "styled-components";

export const Label = styled.div`
  color: gray;
  font-weight: 600;
  font-size: 1rem;
  margin-bottom: 10px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
  flex-wrap: wrap;
`;

export const TextField = styled.textarea`
  width: 99%;
  padding: 8px;
  margin: 10px 0px 0px;
`;
