import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Button } from "../../../components/Button/style";
import Dropdown from "../../../components/Dropdown";
import { CREATE_DEAL } from "../mutations";
import * as S from "./styles";

const CreateDeal = ({ stores, brands, close }) => {
  const [createDeal, { error }] = useMutation(CREATE_DEAL);

  const [dealItem, setDealItem] = useState({
    store: [],
    brand: "",
    termsAndConditions: "",
    campaignName: "",
  });

  const onChange = (e, type) => {
    if (type === "store") return setDealItem((prev) => ({ ...prev, store: e }));
    setDealItem((prev) => ({ ...prev, [type]: e?.value }));
  };

  const handleCreate = async () => {
    let errorOccured = false;
    if (
      dealItem.store.length === 0 ||
      dealItem.brand === "" ||
      dealItem.termsAndConditions === ""
    )
      return toast.warning("Please fill all details!");
    dealItem.store.map(async (store) => {
      const res = await createDeal({
        variables: {
          ...dealItem,
          store: store?.value,
        },
      });
      if (!res.data?.brandSourceRequestCreate) errorOccured = true;
    });
    if (!errorOccured) toast.success("Deal(s) created successfully!");
    close();
  };

  const onChangeInput = (e) => {
    setDealItem((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  useEffect(() => {
    if (error?.message) toast.error("Something went wrong!");
  }, [error]);

  return (
    <>
      <S.Label>Create Deal</S.Label>
      <S.Flex>
        <Dropdown
          style={{ width: "48%" }}
          data={brands}
          value="id"
          label="brandName"
          onChange={(e) => onChange(e, "brand")}
          placeholder="Select Brand"
        />
        <Dropdown
          style={{ width: "48%", marginLeft: "10px" }}
          data={stores}
          value="id"
          label="storeName"
          onChange={(e) => onChange(e, "store")}
          placeholder="Select Store"
          isMulti
        />
      </S.Flex>
      <S.TextField
        placeholder="Terms and Conditions"
        name="termsAndConditions"
        onChange={onChangeInput}
        required
      />
      <S.TextField
        placeholder="Campaign Name"
        name="campaignName"
        onChange={onChangeInput}
      />
      <Button style={{marginTop: "10px"}} onClick={handleCreate}>Create</Button>
    </>
  );
};

export default CreateDeal;
