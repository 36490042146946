import { useMutation } from "@apollo/client";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { UPDATE_NEXT_DATA } from "./mutations";
import * as S from "./styles";

export const NextDateModal = ({ id, date, task, setModal }) => {
  const [nextDate, setNextDate] = useState(date);
  const [nextTask, setNextTask] = useState(task);

  const [getData, { data, loading, error }] = useMutation(UPDATE_NEXT_DATA);

  const handleSave = async () => {
    const res = await getData({
      variables: {
        id: id,
        nextDate,
        nextTask,
      },
    });
    if (res?.data) {
      toast.success("Update successful");
    } else {
      toast.error("Something went wrong");
    }
    setModal(false);
  };

  return (
    <>
      <S.Label>Update next task/date</S.Label>
      <S.TextField
        placeholder="Next task"
        onChange={(e) => setNextTask(e.target.value)}
        value={nextTask}
      />
      <S.Input
        type="date"
        value={nextDate}
        onChange={(e) => setNextDate(e.target.value)}
      />
      <S.Button
        style={{
          backgroundColor: nextDate || nextTask ? "#111" : "#555",
        }}
        disabled={nextDate || nextTask ? false : true}
        onClick={handleSave}
      >
        Save
      </S.Button>
    </>
  );
};
