import moment from "moment";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import * as S from "../styles";
import ViewOrEdit from "../ViewOrEdit";

export default function CustomCoupon({ user, createCoupon, type }) {
  const [store, setStore] = useState(null);
  const [coupon, setCoupon] = useState({
    title: "",
    conditions: "",
    type: "ENTIRE_ORDER",
    discountValueType: type === "welcome" ? "FIXED" : "PERCENTAGE",
    applyOncePerOrder: true,
    applyOncePerCustomer: true,
    usageLimit: type === "welcome" ? 1 : 1000,
    owner: "ZAAMO",
    isShipping: type === "welcome" ? true : false,
    maxDiscountValue: type === "welcome" ? 10000.0 : 500.0,
    discountValue: type === "welcome" ? 10000.0 : 30.0,
    minAmountSpent: type === "welcome" ? 0.0 : 1000.0,
    minCheckoutItemsQuantity: 1,
  });

  const reset = () => {
    setCoupon({
      title: "",
      conditions: "",
      type: "ENTIRE_ORDER",
      discountValueType: type === "welcome" ? "FIXED" : "PERCENTAGE",
      applyOncePerOrder: true,
      applyOncePerCustomer: true,
      usageLimit: type === "welcome" ? 1 : 1000,
      owner: "ZAAMO",
      isShipping: type === "welcome" ? true : false,
      maxDiscountValue: type === "welcome" ? 10000.0 : 500.0,
      discountValue: type === "welcome" ? 10000.0 : 30.0,
      minAmountSpent: type === "welcome" ? 0.0 : 1000.0,
      minCheckoutItemsQuantity: 1,
    });
  };

  const onChange = (name, val) => {
    setCoupon({ ...coupon, [name]: val });
  };

  const handleCreate = async () => {
    const res = await createCoupon({
      variables: {
        ...coupon,
        name: coupon?.name.toUpperCase(),
        stores: coupon?.store,
        metadata: [
          { key: "title", value: coupon?.title },
          {
            key: "conditions",
            value: coupon?.conditions,
          },
        ],
      },
    });
    if (res.data?.voucherBulkCreate?.success) {
      toast.success("Coupon Created!");
      reset();
      setStore(null);
    } else toast.warning("Something went wrong!");
  };

  return (
    <>
      <div style={{ marginTop: "20px", display: "flex", gap: 10 }}>
        <div style={{ width: "100%" }}>
          <h5 style={{ marginBottom: "5px" }}>Select Store</h5>
          <Dropdown
            data={user?.userByMobile?.authorisedStores}
            value="id"
            label="storeName"
            onChange={(e) => setStore(e)}
            placeholder="Select Store"
            defaultValue={store}
          />
        </div>
        <Button
          disabled={!store}
          onClick={() =>
            setCoupon({
              ...coupon,
              stores: [store?.label],
              store: [store?.value],
              name: store?.label?.toUpperCase() + (type === "welcome" ? "_WELCOME" : "30"),
              code:
                store?.label?.toUpperCase() + (type === "welcome" ? "_WELCOME_1000" : "_DISGRPB30"),
              startDate: moment().toISOString(),
              endDate: moment()
                .add(type === "welcome" ? 7 : 3, type === "welcome" ? "days" : "months")
                .toISOString(),
            })
          }
          style={{ width: "10%", marginTop: "20px", padding: "2.5px 0px" }}
        >
          Fetch
        </Button>
      </div>
      {coupon?.stores && (
        <div style={{ marginTop: "10px" }}>
          <S.FlexDiv>
            <ViewOrEdit
              label="Voucher Title"
              value={coupon?.title}
              onChange={(e) => onChange("title", e.target.value)}
            />
            <ViewOrEdit
              label="Voucher Conditions"
              value={coupon?.conditions}
              onChange={(e) => onChange("conditions", e.target.value)}
            />
          </S.FlexDiv>
          {type === "percent" && (
            <ViewOrEdit
              label="Code"
              value={coupon?.code}
              onChange={(e) => onChange("code", e.target.value)}
            />
          )}
          <S.FlexDiv>
            <ViewOrEdit
              label="Coupon Name"
              value={coupon?.name}
              onChange={(e) => onChange("name", e.target.value)}
              read={type === "welcome"}
            />
            <ViewOrEdit label="User" value={user?.userByMobile?.email} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Type" value={coupon.type} read />
            <ViewOrEdit label="Discount Value Type" value={coupon.discountValueType} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Owner" value={coupon.owner} read />
            <ViewOrEdit label="Usage Limit" value={coupon.usageLimit} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Start Date" value={moment(coupon?.startDate).format("LLLL")} read />
            <ViewOrEdit label="End Date" value={moment(coupon?.endDate).format("LLLL")} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Discount Value" value={coupon?.discountValue} read />
            <ViewOrEdit label="Max Discount Value" value={coupon?.maxDiscountValue} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Store" value={coupon?.stores[0]} read />
            <ViewOrEdit label="Shipping" value={coupon?.isShipping} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Apply once per Order" value={coupon?.applyOncePerOrder} read />
            <ViewOrEdit label="Apply once per Customer" value={coupon?.applyOncePerCustomer} read />
          </S.FlexDiv>
          <S.FlexDiv>
            <ViewOrEdit label="Min Amount Spent" value={coupon?.minAmountSpent} read />
            <ViewOrEdit label="Min Checkout Items" value={coupon?.minCheckoutItemsQuantity} read />
          </S.FlexDiv>
          <Button
            disabled={coupon?.title === "" || coupon?.conditions === ""}
            onClick={handleCreate}
            style={{ marginTop: "10px" }}
          >
            Create
          </Button>
        </div>
      )}
    </>
  );
}
