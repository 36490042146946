import styled from "styled-components";

export const ScrollX = styled.div`
  overflow-x: auto;
`;

export const Table = styled.table`
  margin: 15px auto 0;
  table-layout: fixed;
  min-width: 100%;
  .img {
    max-width: 80px;
    height: 80px;
    overflow: hidden;
    img {
      max-width: 80px;
    }
  }
  th {
    background: #fff;
    padding: 10px 3px;
    min-width: 80px;
  }
  td {
    padding: 10px;
    min-width: 80px;
  }
  tr {
    border-bottom: 2px solid #eee;
  }
  td,
  th {
    font-size: 0.7rem;
    font-weight: 400;
    color: #333;
    text-align: center;
  }
  tbody tr {
    background: #f7fafb;
  }
`;

export const NoData = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "15px",
    // borderRadius: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};

export const SvgStyle = {
  border: "1px solid",
  borderRadius: "40%",
  width: "16px",
  height: "15px",
  background: "#fff",
  position: "relative",
  left: 70,
  bottom: 85,
  cursor: "pointer",
};

export const disabled = {
  color: "grey",
  backgroundColor: "transparent",
  textDecoration: "none",
  pointerEvents: "none",
};
