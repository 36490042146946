import { gql } from "@apollo/client";

export const GET_PRODUCT_ANALYTICS = gql`
  query ProductsAnalytics(
    $stores: [ID]
    $endCursor: String
    $collections: [ID]
    $direction: OrderDirection!
    $sortBy: ProductOrderField!
  ) {
    productsAnalytics(
      first: 10
      filter: { stores: $stores, collections: $collections }
      after: $endCursor
      sortBy: { direction: $direction, field: $sortBy }
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          msp
          name
          latestPurchaseDate
          wishlistCount
          purchaseCount
          visits
          thumbnail {
            url
          }
        }
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_STORE = gql`
  query GetCollectionsByStore($storeIds: [ID]!) {
    collectionAnalytics(first: 100, filter: { stores: $storeIds }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          collectionId
          name
        }
      }
    }
  }
`;
