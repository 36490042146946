export default function Search() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11.207"
      height="11.207"
      viewBox="0 0 11.207 11.207"
    >
      <g
        id="Icon_feather-search"
        data-name="Icon feather-search"
        transform="translate(-4 -4)"
      >
        <path
          id="Path_16556"
          data-name="Path 16556"
          d="M13.389,8.944A4.444,4.444,0,1,1,8.944,4.5a4.444,4.444,0,0,1,4.444,4.444Z"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
        <path
          id="Path_16557"
          data-name="Path 16557"
          d="M27.392,27.392l-2.417-2.417"
          transform="translate(-12.892 -12.892)"
          fill="none"
          stroke="#000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
        />
      </g>
    </svg>
  );
}
