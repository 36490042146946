import axios from "axios";
import { useEffect, useState } from "react";
import * as S from "./styles";

function CollectionCard(props) {
  const [updatedImageUrl, setUpdatedImageUrl] = useState("");

  const parseDate = (date) => {
    const d = new Date(date);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "July",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return `${d.getDate()}-${months[d.getMonth()]}`;
  };

  return (
    <S.Wrapper>
      <S.Left>
        <S.Image
          src={
            props.imageUrl.includes(".heic")
              ? props.imageUrl.replace(".heic", ".jpeg")
              : props.imageUrl
          }
        />
      </S.Left>

      <S.Right>
        <S.Name>{props.name}</S.Name>
        <S.Details>
          <S.Col>
            <S.Cell>
              <S.Value>{props.noOfProducts ?? "--"}</S.Value>
              <S.Small>Number of products</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>{props.totalCollectionViews ?? "--"}</S.Value>
              <S.Small>Total Collection views</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>{props.totalViewOfProductPages ?? "--"}</S.Value>
              <S.Small>Total view of Product page</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>{props.totalProductPurchaseCount ?? "--"}</S.Value>
              <S.Small>Total Product Purchase</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>{parseDate(props.createdAt) ?? "--"}</S.Value>
              <S.Small>Collection Created at</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>{parseDate(props.updatedAt) ?? "--"}</S.Value>
              <S.Small>Last Modified At</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>
                {props.dateOfLastVisit.length ? props.dateOfLastVisit : "--"}
              </S.Value>
              <S.Small>Date of last visit</S.Small>
            </S.Cell>
            <S.Cell>
              <S.Value>
                {props.dateOfLastPurchase.length
                  ? props.dateOfLastPurchase
                  : "--"}
              </S.Value>
              <S.Small>Last Purchase Date</S.Small>
            </S.Cell>
          </S.Col>
        </S.Details>
      </S.Right>
    </S.Wrapper>
  );
}

export default CollectionCard;
