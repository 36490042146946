import { useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import Search from "../../../CustomerSupport/Search";
import * as S from "./styles";

const Filters = (props) => {
  const [voucher, setVoucher] = useState("");
  const [email, setEmail] = useState("");

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Select Brand</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={props?.data?.authorisedBrands}
            value="id"
            label="brandName"
            onChange={(e) => props.onChange(e, "BRAND")}
            placeholder="Select Brand"
            defaultValue={props.defaultValue.brand}
            isClearable
          />
        </div>
        <div>
          <label>Select Timeperiod</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={timePeriodOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "TIME")}
            placeholder="Select Time.."
            defaultValue={props.defaultValue.time}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "DIR")}
            placeholder="Direction"
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "SORT")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.sortBy}
          />
        </div>
        <div>
          <label>Status</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={statusOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "STATUS")}
            placeholder="Status"
            defaultValue={props.defaultValue.status}
            isMulti
            isClearable
          />
        </div>
        <div>
          <label>Brand Order Status</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={orderStatusOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "ORDER_STATUS")}
            placeholder="Order Status"
            defaultValue={props.defaultValue.orderStatus}
            isClearable
          />
        </div>
        <div>
          <label>COD Status</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={codStatusOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "COD")}
            placeholder="COD Status"
            defaultValue={props.defaultValue.cod}
            isClearable
          />
        </div>
        <div>
          <label>Search By Voucher</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.Input
              placeholder="SZ_"
              value={voucher}
              onChange={(e) => setVoucher(e.target.value)}
            />
            <div
              onClick={(e) => props.onChange(voucher, "VOUCHER")}
              style={{
                position: "absolute",
                right: "5px",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <Search />
            </div>
          </div>
        </div>
        <div>
          <label>Search By Order ID</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.Input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div
              onClick={(e) => props.onChange(email, "EMAIL")}
              style={{
                position: "absolute",
                right: "5px",
                cursor: "pointer",
                padding: "10px",
              }}
            >
              <Search />
            </div>
          </div>
        </div>
        <span
          className="clear"
          onClick={() => {
            props.clearAll();
            setVoucher("");
            setEmail("");
          }}
        >
          Clear All
        </span>
      </S.Flex>
      <div>
        <label>Select Stores</label>
        <Dropdown
          style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
          data={props?.data?.authorisedStores}
          value="id"
          label="storeName"
          onChange={(e) => props.onChange(e, "STORES")}
          isMulti
          placeholder="Select Stores"
          defaultValue={props.defaultValue.stores}
        />
      </div>
    </S.Div>
  );
};

const statusOptions = [
  { value: "PLACED", label: "Placed" },
  { value: "IN_PROCESS", label: "In Process" },
  { value: "SHIPPED", label: "Shipped" },
  { value: "DELIVERED", label: "Delivered" },
  { value: "CANCELLATION_INITIATED", label: "Cancellation Initiated" },
  { value: "CANCELLATION_PROCESSED", label: "Cancellation Processed" },
  { value: "RETURN_REQUESTED", label: "Return Requested" },
  { value: "RETURN_INITIATED", label: "Return Initiated" },
  { value: "RETURN_COMPLETED", label: "Return Completed" },
  { value: "FULFILLED", label: "Fulfilled" },
  { value: "EXCHANGE_REQUESTED", label: "Exchange Requested" },
  { value: "EXCHANGE_INITIATED", label: "Exchange Initiated" },
  { value: "EXCHANGE_COMPLETED", label: "Exchange Completed" },
  { value: "CANCELLED_BY_CUSTOMER", label: "Cancelled by Customer" },
];

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const orderStatusOptions = [
  {
    label: "On Time",
    value: "ON_TIME",
  },
  {
    label: "Delayed",
    value: "DELAYED",
  },
];
const codStatusOptions = [
  {
    label: "True",
    value: true,
  },
  {
    label: "False",
    value: false,
  },
];

const sortOptions = [
  {
    label: "Number",
    value: "NUMBER",
  },
  {
    label: "Creation Date",
    value: "CREATION_DATE",
  },
  {
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    label: "Payment",
    value: "PAYMENT",
  },
  {
    label: "Fulfillment Status",
    value: "FULFILLMENT_STATUS",
  },
  {
    label: "Total",
    value: "TOTAL",
  },
];

const timePeriodOptions = [
  {
    value: "LASTHOUR",
    label: "LAST HOUR",
  },
  {
    value: "TODAY",
    label: "TODAY",
  },
  {
    value: "YESTERDAY",
    label: "YESTERDAY",
  },
  {
    value: "TILLTODAYTHISWEEK",
    label: "TILL TODAY THIS WEEK",
  },
  {
    value: "LASTWEEK",
    label: "LAST WEEK",
  },
  {
    value: "TILLTODAYTHISMONTH",
    label: "TILL TODAY THIS MONTH",
  },
  {
    value: "LASTMONTH",
    label: "LAST MONTH",
  },
  {
    value: "OVERALL",
    label: "OVERALL",
  },
];

export default Filters;
