import { useLazyQuery, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import CollectionCard from "../../components/CollectionCard";
import Layout from "../../components/Layout";
import Filters from "./Filters";
import { GET_COLLECTION_ANALYTICS, GET_USER_STORES } from "./queries";
import { client } from "../../apollo-client";
import * as S from "./styles";
import PacmanLoader from "../../components/PackmanLoader";
import useAuth from "../../hooks/useAuth/useAuth";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { filterVars } from "../../reactiveVariables";
import { getDecodedJson, getEncodedJson } from "../../utils/utils";
import { useLocation, useNavigate } from "react-router-dom";

function Collections() {
  const [collectionData, setCollectionData] = useState([]);
  const [stores, setStores] = useState([]);
  const [page, setPage] = useState(1);
  const [direction, setDirection] = useState({ label: "Desc", value: "DESC" });
  const [sortBy, setSortBy] = useState({
    label: "Updated At",
    value: "UPDATED_AT",
  });

  const { data, loading, error } = useQuery(GET_USER_STORES, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });
  const [getCollectionAnalytics, { data: data2, loading: loading2, error: error2, fetchMore }] =
    useLazyQuery(GET_COLLECTION_ANALYTICS);

  const filters = useReactiveVar(filterVars);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get("store_filters")) {
      const temp = getDecodedJson(params.get("store_filters"));
      if (data) {
        let temp2 = {};
        data?.userByMobile?.authorisedStores.map((e) => {
          temp2[e.id] = { value: e.id, label: e.storeName };
        });
        let temp3 = [];
        for (let i in temp) {
          if (temp2[temp[i]]) {
            temp3.push(temp2[temp[i]]);
          } else {
            navigate({
              pathname: location.pathname,
              search: ``,
            });
            temp3 = [];
            break;
          }
        }
        setStores(temp3);
      }
    }
  }, [location.search, data]);

  useEffect(() => {
    console.log("store change fetching..", stores);
    getCollectionAnalytics({
      variables: {
        stores: stores.map((e) => e.value),
        direction: direction?.value,
        sortBy: sortBy?.value,
      },
    });
  }, [stores, direction, sortBy]);

  useEffect(() => {
    if (data) {
      !filters?.stores &&
        getCollectionAnalytics({
          variables: {
            stores: stores.map((e) => e.value),
            direction: direction?.value,
            sortBy: sortBy?.value,
          },
        });
    }
  }, [data]);

  useEffect(() => {
    if (data2) {
      console.log(data2, "data2");
      setCollectionData(data2?.collectionAnalytics?.edges);
    }
  }, [data2]);

  if (loading || loading2) return <PacmanLoader />;
  if (error || error2) return <ErrorPage />;

  const onStoreChange = (e) => {
    console.log(e);
    setStores(e);
    const params = getEncodedJson([...e].map((e) => e.value));

    navigate({
      pathname: location.pathname,
      search: `?store_filters=${params}`,
    });
  };

  const onDirectionChange = (e) => {
    setDirection(e);
    setPage(1);
  };

  const onSortChange = (e) => {
    setSortBy(e);
    setPage(1);
  };

  const filtersData = {
    stores: data?.userByMobile?.authorisedStores ?? [],
    onStoreChange: onStoreChange,
    storesValue: stores,
    sortByValue: sortBy,
    directionValue: direction,
    onDirectionChange: onDirectionChange,
    onSortChange: onSortChange,
  };

  const onNextPage = () => {
    if (
      data2?.collectionAnalytics?.pageInfo?.hasNextPage &&
      collectionData.length < page * 10 + 10
    ) {
      fetchMore({
        variables: {
          endCursor: data2?.collectionAnalytics?.pageInfo?.endCursor,
        },
      });
    }
    setPage(page + 1);
  };

  const onPrevPage = () => {
    setPage(page - 1);
  };

  return (
    <Layout
      title={"Collections"}
      pagination={{
        hasNext:
          data2?.collectionAnalytics?.pageInfo?.hasNextPage ||
          collectionData.length > (page - 1) * 10 + 10,
        current: page,
        onNextClick: onNextPage,
        onPrevClick: onPrevPage,
      }}
      backUrl="/prod/analytics/store"
    >
      <Filters {...filtersData} />
      <S.Wrapper>
        {collectionData?.length ? (
          collectionData
            ?.slice((page - 1) * 10, Math.min((page - 1) * 10 + 10, collectionData.length))
            .map((e) => <CollectionCard key={e.node.name} {...e.node} />)
        ) : (
          <S.NoData>No data found!</S.NoData>
        )}
      </S.Wrapper>
    </Layout>
  );
}

export default Collections;
