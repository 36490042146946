import { useState } from "react";
import * as S from "../EditBrand/styles";
import AddressFields from "./AddressFields";
import SelectValues from "./SelectValues";
import Button from "../../../components/Button";
import { CREATE_BRAND } from "./mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import { GET_DASHBOARD_BRANDS } from "../queries";

export default function CreateBrand({ close }) {
  const [sameAddress, setSameAddress] = useState(true);
  const [brand, setBrand] = useState({});
  const [createBrand, { error }] = useMutation(CREATE_BRAND);
  const mobileReg = /^\d{10}$/g;

  const onChange = (value, type) => {
    setBrand({ ...brand, [type]: value });
  };

  const handleCreate = async () => {
    if (!mobileReg.test(brand?.brandContactNumber)) return toast.warning("Invalid mobile number");
    const res = await createBrand({
      variables: {
        ...brand,
        preferedPaymentMode: brand?.preferedPaymentMode?.value ?? undefined,
        brandSource: brand?.brandSource?.value ?? undefined,
        botd: brand?.botd?.value ?? undefined,
        tooManyOrders: brand?.tooManyOrders?.value ?? undefined,
        brandBarter: brand?.brandBarter?.value ?? undefined,
        commission: brand?.commission?.value ?? undefined,
        shippingReturnPolicy: {
          return_policy: brand?.return_policy ?? "",
          shipping_policy: brand?.shipping_policy ?? "",
        },
      },
    });
    if (res.data?.brandCreate?.brand) {
      toast.success("Brand created successfully");
      client.refetchQueries({
        include: [GET_DASHBOARD_BRANDS],
      });
    } else toast.error("Error creating brand");
    close();
  };

  if (error) return <p>Something went wrong!</p>;

  return (
    <div>
      <S.Label>Create Brand</S.Label>
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Company Name *</S.TextLabel>
          <S.TextField
            value={brand?.companyName}
            placeholder="Eg: Apple"
            onChange={(e) => onChange(e.target.value, "companyName")}
          />
        </div>
        <div style={{ width: "49%", marginLeft: 10 }}>
          <S.TextLabel>Brand Name *</S.TextLabel>
          <S.TextField
            value={brand?.brandName}
            placeholder="Eg: iPhone"
            onChange={(e) => onChange(e.target.value, "brandName")}
          />
        </div>
      </S.Flex>
      <S.TextLabel>Short Description</S.TextLabel>
      <S.TextField
        value={brand?.shortDescription}
        placeholder="A short introduction about the brand"
        onChange={(e) => onChange(e.target.value, "shortDescription")}
      />
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Brand Contact Name *</S.TextLabel>
          <S.TextField
            value={brand?.brandContactName}
            placeholder="Eg: John Doe"
            onChange={(e) => onChange(e.target.value, "brandContactName")}
          />
        </div>
        <div style={{ width: "49%", marginLeft: 10 }}>
          <S.TextLabel>Brand Contact No. *</S.TextLabel>
          <S.TextField
            value={brand?.brandContactNumber}
            placeholder="Eg: 74*****345"
            onChange={(e) => onChange(e.target.value, "brandContactNumber")}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Prefered Payment Mode</S.TextLabel>
          <SelectValues
            data={paymentMode}
            onChange={onChange}
            type="preferedPaymentMode"
            val={brand?.preferedPaymentMode}
          />
        </div>
        <div style={{ width: "49%", marginLeft: 10 }}>
          <S.TextLabel>Brand Source</S.TextLabel>
          <SelectValues
            data={brandSource}
            onChange={onChange}
            type="brandSource"
            val={brand?.brandSource}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Size Fit Note</S.TextLabel>
          <S.TextField
            value={brand?.sizeFitNote}
            placeholder="Eg: note"
            onChange={(e) => onChange(e.target.value, "sizeFitNote")}
          />
        </div>
        <div style={{ width: "49%", marginLeft: 10 }}>
          <S.TextLabel>Pan Number *</S.TextLabel>
          <S.TextField
            value={brand?.panNumber}
            placeholder="Eg: w12323x243"
            onChange={(e) => onChange(e.target.value, "panNumber")}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Return Policy</S.TextLabel>
          <S.TextField
            value={brand?.return_policy}
            placeholder="Eg: Return Policy"
            onChange={(e) => onChange(e.target.value, "return_policy")}
          />
        </div>
        <div style={{ width: "49%", marginLeft: 10 }}>
          <S.TextLabel>Shipping Policy</S.TextLabel>
          <S.TextField
            value={brand?.shipping_policy}
            placeholder="Eg: Shipping Policy"
            onChange={(e) => onChange(e.target.value, "shipping_policy")}
          />
        </div>
      </S.Flex>
      <S.Flex>
        <S.TextLabel>Address *</S.TextLabel>
        <S.TextLabel style={{ display: "flex", alignItems: "center" }}>
          <input
            type="checkbox"
            checked={sameAddress}
            onChange={(e) => {
              setSameAddress(e.target.checked);
              if (!e.target.checked) onChange(brand?.address, "returnAddress");
              else onChange(brand?.address, "returnAddress");
            }}
            style={{ margin: "0px 5px 0px 10px" }}
          />
          Same for Return Address
        </S.TextLabel>
      </S.Flex>
      <AddressFields brand={brand} setBrand={setBrand} type={sameAddress ? "both" : "address"} />
      {!sameAddress && (
        <>
          <S.TextLabel>Return Address *</S.TextLabel>
          <AddressFields brand={brand} setBrand={setBrand} type="returnAddress" />
        </>
      )}
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>BOTD</S.TextLabel>
          <SelectValues data={boolean} onChange={onChange} type="botd" val={brand?.botd} />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Too Many Orders</S.TextLabel>
          <SelectValues
            data={boolean}
            onChange={onChange}
            type="tooManyOrders"
            val={brand?.tooManyOrders}
          />
        </div>
      </S.Flex>
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Brand Barter</S.TextLabel>
          <SelectValues
            data={boolean}
            onChange={onChange}
            type="brandBarter"
            val={brand?.brandBarter}
          />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Commission</S.TextLabel>
          <SelectValues
            data={new Array(101).fill().map((_, index) => {
              return {
                label: index.toFixed(1),
                value: index.toFixed(1),
              };
            })}
            onChange={onChange}
            type="commission"
            val={brand?.commission}
          />
        </div>
      </S.Flex>
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Brand Order Info</S.TextLabel>
          <S.TextField
            value={brand?.brandOrderInfo}
            onChange={(e) => onChange(e.target.value, "brandOrderInfo")}
            placeholder="Eg: this is your order info"
          />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Barter Guidelines</S.TextLabel>
          <S.TextField
            value={brand?.brandBarterGuidelines}
            onChange={(e) => onChange(e.target.value, "brandBarterGuidelines")}
            placeholder="Eg: guidelines"
          />
        </div>
      </S.Flex>
      <S.Flex style={{ gap: 10 }}>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Order Shipping Days</S.TextLabel>
          <S.TextField
            type="number"
            value={brand?.orderShippingDays}
            onChange={(e) => onChange(e.target.value, "orderShippingDays")}
            placeholder="Eg: 5"
          />
        </div>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Order Processing Days</S.TextLabel>
          <S.TextField
            type="number"
            value={brand?.orderProcessingDays}
            onChange={(e) => onChange(e.target.value, "orderProcessingDays")}
            placeholder="Eg: 10"
          />
        </div>
      </S.Flex>
      <S.TextLabel style={{ marginBottom: 10 }}>* Required Field</S.TextLabel>
      <Button
        disabled={
          !brand?.companyName ||
          !brand?.brandName ||
          !brand?.brandContactName ||
          !brand?.brandContactNumber ||
          (brand?.address ? Object.keys(brand?.address).length < 4 : true) ||
          (brand?.returnAddress ? Object.keys(brand?.returnAddress).length < 4 : true) ||
          !brand?.panNumber
        }
        onClick={handleCreate}
      >
        Save
      </Button>
    </div>
  );
}

const paymentMode = [
  { label: "Bank Account", value: "BANK_ACCOUNT" },
  { label: "UPI ID", value: "UPI_ID" },
];

const brandSource = [
  { label: "Manual", value: "MANUAL" },
  { label: "Shopify", value: "SHOPIFY" },
  { label: "Staff", value: "STAFF" },
  { label: "WooCommerce", value: "WOOCOMMERCE" },
  { label: "Wix", value: "WIX" },
  { label: "Achaindia", value: "ACHAINDIA" },
  { label: "Custom", value: "CUSTOM" },
];

const boolean = [
  { label: "True", value: true },
  { label: "False", value: false },
];
