import Bottom from "./Bottom";
import Details from "./Details";
import Edit from "./Edit";
import * as S from "./styles";
function ProductCard(props) {
  return (
    <>
      <div className="">
        <S.Wrapper>
          <S.ImageWrapper>
            <S.Image src={props?.thumbnail?.url ?? "/assets/images/dummyLogo.png"} />
          </S.ImageWrapper>
          <S.Right>
            <S.Name>{props.name}</S.Name>
            {props?.editable ? (
              <>
                <Edit />
              </>
            ) : (
              <Details {...props} />
            )}
          </S.Right>
        </S.Wrapper>
        {props?.editable && <Bottom />}
      </div>
    </>
  );
}

export default ProductCard;
