import { memo } from "react";
import * as S from "./styles";
import { useState } from "react";
import Button from "../../../components/Button";
import { useMemo } from "react";
import { useMutation } from "@apollo/client";
import { EDIT_BRAND_GST_DETAILS } from "./mutations";
import { toast } from "react-toastify";

function GstDetailsModal({ brand, setShowGstModal }) {
  const brandMetaData = JSON.parse(brand?.metadata ?? "{}");
  const [gstData, setGstData] = useState({
    id: brand?.id,
    registered_company_name: brandMetaData?.registered_company_name ?? "",
    address: brandMetaData?.address ?? "",
    PAN: brandMetaData?.PAN ?? "",
    GSTIN: brandMetaData?.GSTIN ?? "",
  });

  const [editBrandGstDetails, { loading }] = useMutation(
    EDIT_BRAND_GST_DETAILS
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await editBrandGstDetails({
        variables: {
          ...gstData,
        },
      });
      if (res.data?.brandUpdate) {
        toast.success("GST details updated!");
        setShowGstModal(false);
      }
    } catch (err) {
      console.log(err);
      toast.error("Something went wrong!");
    }
  };

  const onChange = (value, type) => {
    setGstData({ ...gstData, [type]: value });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initial = useMemo(() => gstData, []);

  return (
    <form
      onSubmit={handleSubmit}
      style={{ fontSize: "0.8rem" }}
      autoComplete="true"
    >
      <S.Label>Brand GST Details</S.Label>
      <S.TextLabel>Registered Company Name</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "registered_company_name")}
        value={gstData?.registered_company_name}
      />
      <S.TextLabel>Address</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "address")}
        value={gstData?.address}
      />
      <S.TextLabel>PAN</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "PAN")}
        value={gstData?.PAN}
      />
      <S.TextLabel>GSTIN</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "GSTIN")}
        value={gstData?.GSTIN}
      />

      <Button
        disabled={
          JSON.stringify(gstData) === JSON.stringify(initial) || loading
        }
        style={{ margin: "10px 0px" }}
        type="submit"
      >
        Save
      </Button>
    </form>
  );
}

export default memo(GstDetailsModal);
