import Dropdown from "../../components/Dropdown";
import { statusEnum, nextActionEnum } from "./StoreManagerActions";
import * as S from "./styles";

const Filters = (props) => {
  //   console.log(props);

  return (
    <S.Filters>
      <label>Select Stores</label>
      <Dropdown
        style={{ width: "100%", padding: "2px 0" }}
        data={[...props?.stores]}
        label="storeName"
        value="id"
        onChange={props.onStoreChange}
        defaultValue={props.storesValue}
        isMulti
        placeholder="Select stores"
      />
      <S.Flex>
        <div>
          <label>Select Timeperiod</label>
          <Dropdown
            style={{ width: "100%", padding: "5px", maxWidth: "200px" }}
            data={timePeriodOptions}
            value="value"
            label="label"
            onChange={(e) => props.onTimeperiodChange(e)}
            placeholder="Select Timeperiod"
            defaultValue={props.timeperiod}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ width: "100%", margin: "5px", maxWidth: "200px" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.setDirection(e)}
            placeholder="Direction"
            defaultValue={props.direction}
          />
        </div>
        <div style={{marginLeft: 10}}>
          <label>Sort By</label>
          <Dropdown
            style={{
              width: "100%",
              minWidth: "120px",
              margin: "5px",
              maxWidth: "200px",
            }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.setSortBy(e)}
            placeholder="Sort By"
            defaultValue={props.sortBy}
          />
        </div>
        <div>
          <label>Store Status</label>
          <Dropdown
            style={{ width: "230px", padding: "5px" }}
            data={statusEnum.map((status) => status)}
            value="value"
            label="label"
            onChange={(e) => props.setStoreStatus(e)}
            isMulti
            placeholder="Store Status"
            defaultValue={props.storeStatus}
          />
        </div>
        <div>
          <label>Store Next Actions</label>
          <Dropdown
            style={{ width: "230px", margin: "5px" }}
            data={nextActionEnum.map((actions) => actions)}
            value="value"
            label="label"
            onChange={(e) => props.setStoreAction(e)}
            isMulti
            placeholder="Store Next Actions"
          />
        </div>
      </S.Flex>
    </S.Filters>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Created At",
    value: "CREATED_AT",
  },
  {
    label: "Total visitors",
    value: "TOTAL_VISITORS",
  },
  {
    label: "Total orders",
    value: "TOTAL_ORDERS",
  },
  {
    label: "Wishlist",
    value: "WISHLIST",
  },
  {
    label: "Avg. order value",
    value: "AVG_ORDER_VALUE",
  },
  {
    value: "LAST_7_DAYS_TOTAL_ORDERS",
    label: "Last 7 Days Total Orders",
  },
  {
    value: "LAST_7_DAYS_TOTAL_STORE_SALES",
    label: "Last 7 Days Total Store Sales",
  },
  {
    value: "LAST_7_DAYS_NUMBER_OF_PRODUCT_SOLD",
    label: "Last 7 Days Number of Product Sold",
  },
  {
    value: "LAST_30_DAYS_TOTAL_ORDERS",
    label: "Last 30 Days Total Orders",
  },
  {
    value: "LAST_30_DAYS_TOTAL_STORE_SALES",
    label: "Last 30 Days Total Store Sales",
  },
  {
    value: "LAST_30_DAYS_NUMBER_OF_PRODUCT_SOLD",
    label: "Last 30 Days Number of Product Sold",
  },
  {
    value: "TOTAL_CATEGORY_CLICKS",
    label: "Total Category Clicks",
  },
  {
    value: "TOTAL_BRAND_CLICKS",
    label: "Total Brand Clicks",
  },
  {
    value: "MY_ORDERS",
    label: "My Orders",
  },
  {
    value: "CHECKOUT_FAIL",
    label: "Checkout Fail",
  },
];

const timePeriodOptions = [
  {
    value: "LASTHOUR",
    label: "LAST HOUR",
  },
  {
    value: "TODAY",
    label: "TODAY",
  },
  {
    value: "YESTERDAY",
    label: "YESTERDAY",
  },
  {
    value: "TILLTODAYTHISWEEK",
    label: "TILL TODAY THIS WEEK",
  },
  {
    value: "LASTWEEK",
    label: "LAST WEEK",
  },
  {
    value: "TILLTODAYTHISMONTH",
    label: "TILL TODAY THIS MONTH",
  },
  {
    value: "LASTMONTH",
    label: "LAST MONTH",
  },
  {
    value: "OVERALL",
    label: "OVERALL",
  },
];

export default Filters;
