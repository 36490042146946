import { useState } from "react";
import Dropdown from "../../components/Dropdown";
import Layout from "../../components/Layout";
import { useMutation, useQuery } from "@apollo/client";
import { GET_EMAIL } from "./queries";
import PacmanLoader from "../../components/PackmanLoader";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import {
  CREATE_BULK_VOUCHER,
  CREATE_COUPON,
  CREATE_CUSTOM_COUPON,
  CREATE_MIXED,
} from "./mutations";
import Sourcing from "./Sourcing";
import CustomCoupon from "./CustomCoupon";
import Button from "../../components/Button";
import MixedCoupon from "./MixedCoupon";
import BrandSourcing from "./BrandSourcing";

export default function CouponCreate() {
  const couponTypes = [
    { label: "Sourcing", value: "1" },
    { label: "Welcome Coupon", value: "2" },
    { label: "30% Coupon", value: "3" },
    { label: "Mixed Coupon", value: "4" },
    { label: "Brand Sourcing", value: "5" },
  ];
  const [currentType, setCurrentType] = useState(null);
  const [err, setErr] = useState(false);

  //create coupon
  const [createCoupon, { error: couponErr }] = useMutation(CREATE_COUPON);
  const [createCustomCoupon, { error: customCouponErr }] =
    useMutation(CREATE_CUSTOM_COUPON);
  const [createMixed, { error: mixedErr }] = useMutation(CREATE_MIXED);
  const [createBulkVoucher, { error: bulkErr }] =
    useMutation(CREATE_BULK_VOUCHER);

  //get user email
  const {
    data: user,
    loading: userLoading,
    error: userErr,
  } = useQuery(GET_EMAIL, {
    variables: {
      no: localStorage.getItem("analytics_mobileNo"),
    },
  });

  if (userLoading) return <PacmanLoader />;
  if (err || userErr || couponErr || customCouponErr || mixedErr)
    return <ErrorPage />;

  return (
    <Layout desktop>
      <div style={{ width: "50%", margin: "20px auto" }}>
        <h5 style={{ marginBottom: "5px" }}>Select type</h5>
        <Dropdown
          data={couponTypes}
          label="label"
          value="value"
          // isClearable
          onChange={(e) => setCurrentType(e)}
          defaultValue={currentType}
        />
        {currentType?.value === "1" && (
          <Sourcing user={user} createCoupon={createCoupon} setErr={setErr} />
        )}
        {currentType?.value === "2" && (
          <CustomCoupon
            user={user}
            createCoupon={createCustomCoupon}
            type="welcome"
          />
        )}
        {currentType?.value === "3" && (
          <CustomCoupon
            user={user}
            createCoupon={createCustomCoupon}
            type="percent"
          />
        )}
        {currentType?.value === "4" && (
          <MixedCoupon user={user} createCoupon={createMixed} />
        )}
        {currentType?.value === "5" && (
          <BrandSourcing
            user={user}
            createCoupon={createBulkVoucher}
            setErr={setErr}
          />
        )}
        {/* {!currentType && (
          <Button
            onClick={() => (window.location.href = "/prod/analytics/voucher/edit")}
            style={{ marginTop: 20 }}
          >
            Search and Edit Coupon
          </Button>
        )} */}
      </div>
    </Layout>
  );
}
