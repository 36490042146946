import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { GET_USER_DATA } from "../MasterDashboard/queries";
import { CREATEDBY_IDS, GET_BRAND_SOURCING_REQUESTS } from "./queries";
import Table from "../../components/Table";
import moment from "moment";
import Filters from "./Filters";
import PacmanLoader from "../../components/PackmanLoader";
import * as S from "./styles";
import ReactModal from "react-modal";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import CreateDeal from "./CreateDeal";
import { convertToUrl } from "../../utils/utils";
import { CREATE_BRAND_NOTIFICATION } from "./mutations";
import { toast } from "react-toastify";

const BrandSourcing = () => {
  const [sourcingData, setSourcingData] = useState([]);
  const [page, setPage] = useState(1);
  const [modalShow, setModalShow] = useState(false);
  const [currentNode, setCurrentNode] = useState({});
  const [filters, setFilters] = useState({
    state: undefined,
    storeBucket: undefined,
    stores: [],
    brands: [],
    createdBy: [],
    brandCollab: undefined,
    field: "CREATED_AT",
    direction: {
      label: "Desc",
      value: "DESC",
    },
  });

  const {
    data: createdByIds,
    loading: createdByIdsLoading,
    error: createdByIdsError,
  } = useQuery(CREATEDBY_IDS);

  const {
    data: sourceData,
    loading: sourceLoading,
    error: sourceError,
    fetchMore,
    refetch,
  } = useQuery(GET_BRAND_SOURCING_REQUESTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      stores: filters.stores.map((e) => e.value),
      brands: filters.brands.map((e) => e.value),
      createdBy: filters.createdBy.map((e) => e.value),
      field: filters.field,
      storeBucket: filters.storeBucket?.value,
      state: filters.state?.value,
      brandCollab: filters.brandCollab?.value,
      direction: filters.direction?.value,
    },
  });

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const [createNotification] = useMutation(CREATE_BRAND_NOTIFICATION);

  const onChange = (e, type) => {
    if (type === "storeBucket" || type === "state" || type === "brandCollab")
      return setFilters((prev) => ({ ...prev, [type]: e }));
    if (type === "direction")
      return setFilters((prev) => ({ ...prev, direction: e }));
    return setFilters((prev) => {
      return { ...prev, [type]: e.map((item) => item) };
    });
  };

  useEffect(() => {
    setPage(1);
  }, [filters]);

  async function setData() {
    const data = await sourceData?.brandSourceRequests?.edges.map(
      ({ node }, index) => ({
        brandSourcingId:
          atob(node?.id).replace("BrandSourceRequest:", "") ?? "--",
        storeName: node?.store?.storeName ?? "--",
        brandName: node?.brand?.brandName ?? "--",
        content: node?.content ?? "--",
        termsAndConditions: {
          hyperlink: true,
          name: convertToUrl(node?.termsAndConditions ?? "--"),
        },
        campaignName: node?.campaignName ?? "--",
        brandCollab: node?.brandCollab,
        brandManagers: node?.brandManagers ?? "--",
        state: node?.state ?? "--",
        storeBucket: node?.storeBucket ?? "--",
        storeManagers: node?.storeManagers ?? "--",
        createdAt: moment(node?.createdAt).format("DD-MMM-YY HH:MM"),
        createdBy: node?.createdBy?.email ?? "--",
        notification: {
          value: node?.notification,
          function: () =>
            node?.notification
              ? toast.warn("Whatsapp Notification already active")
              : handleNotification(
                  node?.id,
                  atob(node?.id).replace("BrandSourceRequest:", "")
                ),
        },
      })
    );
    setSourcingData(data);
  }
  useEffect(() => {
    if (sourceData) {
      setData();
    }
  }, [sourceData, page]);

  async function handleNotification(id, brandSourcingId) {
    const res = await createNotification({
      variables: {
        sourcingId: id,
      },
    });
    if (res?.data?.notificationTrigger?.success) {
      toast.success("Whatsapp notification activated");
      setSourcingData((prev) => {
        let data = prev.map((item) => {
          if (item?.brandSourcingId == brandSourcingId) {
            return {
              ...item,
              notification: {
                ...item?.notification,
                value: true,
              },
            };
          } else {
            return item;
          }
        });
        return data;
      });
    } else {
      toast.error("Something went wrong!");
    }
  }

  if (loading || createdByIdsLoading || sourceLoading) return <PacmanLoader />;
  if (error || createdByIdsError || sourceError) return <ErrorPage />;

  return (
    <Layout
      pagination={{
        hasNext:
          sourceData?.brandSourceRequests?.pageInfo.hasNextPage ||
          page * 100 < sourcingData.length,
        current: page,
        onNextClick: () => {
          setPage(page + 1);
          fetchMore({
            variables: {
              endCursor: sourceData?.brandSourceRequests?.pageInfo?.endCursor,
            },
          });
        },
        onPrevClick: () => setPage(page - 1),
      }}
      title="Brand Sourcing Request"
      desktop
    >
      <Filters
        data={data?.userByMobile}
        createdIds={createdByIds?.staffUsers?.edges}
        onChange={onChange}
        open={() => setModalShow(true)}
        defaultValue={{ ...filters }}
      />
      <Table data={sourcingData} displayPageSize={100} currentPage={page} />
      <ReactModal
        isOpen={modalShow}
        onRequestClose={() => setModalShow(false)}
        style={S.Modal}
      >
        <CreateDeal
          stores={data?.userByMobile?.authorisedStores}
          brands={data?.userByMobile?.authorisedBrands}
          close={() => setModalShow(false)}
        />
      </ReactModal>
    </Layout>
  );
};

export default BrandSourcing;
