import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import CommentBox from "./CommentBox";
import { ADD_COMMENT } from "./mutations";
import { GET_STORE_COMMENTS } from "./queries";
import * as S from "./styles";
import { client } from "../../apollo-client";

const Comments = () => {
  //store id
  const [storeId, setStoreId] = useState("");
  //new comment entered by user
  const [newComment, setNewComment] = useState("");
  //displaying the comments
  const [comments, setComments] = useState({
    edges: [],
  });

  //setting the store id from url params
  useEffect(() => {
    const url = new URL(window.location.href);
    setStoreId(url.searchParams.get("id"));
  }, []);

  //for adding a new comment
  const [sendNewComment, { error: commentError }] = useMutation(ADD_COMMENT);

  //fetching comments
  const [getComments, { data, loading, error, fetchMore }] =
    useLazyQuery(GET_STORE_COMMENTS);

  //setting the comments
  useEffect(() => {
    if (data)
      updateComments(
        data?.storeManagerComments?.edges,
        data?.storeManagerComments?.pageInfo,
        true
      );
  }, [data]);

  //adding the new comments to the exisiting comments
  const updateComments = (values, pageInfo, newCommentAdded) => {
    setComments((prev) => {
      return {
        edges: newCommentAdded ? values : [...prev.edges, ...values],
        pageInfo: pageInfo,
      };
    });
  };

  //mutation for new comment
  const handleSave = async () => {
    await sendNewComment({
      variables: {
        storeId: storeId,
        comment: newComment,
      },
    });
    client.refetchQueries({
      include: [GET_STORE_COMMENTS],
    });
    setNewComment("");
  };

  useEffect(() => {
    if (storeId) {
      getComments({
        variables: {
          storeId: storeId,
        },
      });
    }
  }, [storeId]);

  if (error || commentError) return <ErrorPage />;
  if (loading) return <PacmanLoader />;

  return (
    <Layout>
      <InfiniteScroll
        dataLength={comments?.edges.length}
        hasMore={comments?.pageInfo?.hasNextPage}
        endMessage={
          comments?.edges?.length > 0 ? (
            <p style={{ textAlign: "center" }}>No More Comments!</p>
          ) : (
            <></>
          )
        }
        next={async () => {
          const res = await fetchMore({
            variables: {
              endcursor: comments?.pageInfo?.endCursor,
            },
          });
          updateComments(
            res.data?.storeManagerComments?.edges,
            res.data?.storeManagerComments?.pageInfo,
            false
          );
        }}
        style={{ padding: "14px" }}
      >
        {data?.storeManagerComments?.edges?.length ? (
          <div style={{ display: "grid" }}>
            {comments?.edges?.map((comment, idx) => {
              return (
                <div key={idx}>
                  <CommentBox data={comment} />
                  <br />
                </div>
              );
            })}
          </div>
        ) : (
          <div
            style={{
              height: "83vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            No Comments Yet
          </div>
        )}
      </InfiniteScroll>
      <S.BottomWrapper>
        <S.Input
          placeholder="Type new comment"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
        />
        <S.SaveButton disabled={newComment === ""} onClick={handleSave}>
          Save
        </S.SaveButton>
      </S.BottomWrapper>
    </Layout>
  );
};

export default Comments;
