import { gql } from "@apollo/client";

export const EDIT_PICKUP_ADDRESS = gql`
  mutation brandUpdate(
    $id: ID!
    $firstName: String!
    $lastName: String!
    $streetAddress1: String!
    $city: String!
    $email: String!
    $countryArea: String!
    $phone: String!
    $postalCode: String!
  ) {
    brandUpdate(
      input: {
        id: $id
        pickupAddress: {
          firstName: $firstName
          lastName: $lastName
          streetAddress1: $streetAddress1
          city: $city
          email: $email
          countryArea: $countryArea
          phone: $phone
          postalCode: $postalCode
        }
      }
    ) {
      brand {
        id
        pickupAddress {
          id
          firstName
          lastName
          streetAddress1
          city
          phone
          postalCode
          email
          countryArea
        }
      }
    }
  }
`