import { gql } from "@apollo/client";

export const FETCH_VOUCHER = gql`
  query Voucher($id: ID!) {
    voucher(id: $id) {
      id
      name
      code
      user {
        email
      }
      type
      discountValueType
      usageLimit
      startDate
      endDate
      discountValue
      isShipping
      maxDiscount {
        amount
      }
      store {
        storeName
      }
      applyOncePerOrder
      applyOncePerCustomer
      minSpent {
        amount
      }
      minCheckoutItemsQuantity
      metadata {
        key
        value
      }
    }
  }
`;
