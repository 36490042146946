import { gql } from "@apollo/client";

export const GET_VOUCHER_DETAILS = gql`
  query Voucher($id: ID!) {
    voucher(id: $id) {
      id
      name
      type
      code
      active
      metadata {
        key
        value
      }
      usageLimit
      discountValue
      used
      endDate
      discountValueType
    }
  }
`;
