import { useMutation } from "@apollo/client";
import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { CREATE_PRODUCT_NOTIFICATION } from "./mutations";
import * as S from "./styles";

export const NotificationModal = ({ id, setModalClose, refetch }) => {
  const [content, setContent] = useState("");
  const [createNotification] = useMutation(CREATE_PRODUCT_NOTIFICATION);

  const handleNotification = async () => {
    const res = await createNotification({
      variables: {
        sourcingId: id,
        content: content,
      },
    });
    if (res?.data?.notificationTrigger?.success) {
      toast.success("Whatsapp notification activated");
    } else {
      toast.error("Something went wrong!");
    }
    refetch();
    setModalClose();
  };
  return (
    <>
      <S.Label>Add notification content</S.Label>
      <S.TextField
        placeholder="Content"
        onChange={(e) => setContent(e.target.value)}
        value={content}
        maxLength={500}
      />
      <S.Button
        style={{
          backgroundColor: !!content ? "#111" : "#555",
        }}
        disabled={!!content ? false : true}
        onClick={handleNotification}
      >
        Save
      </S.Button>
    </>
  );
};
