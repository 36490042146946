import { gql } from "@apollo/client";

export const UPDATE_STORE = gql`
  mutation UpdateStore(
    $id: ID!
    $value: String!
    $barter_guidelines: String!
    $influencer_notes: String!
  ) {
    storeUpdate(
      input: {
        store: $id
        metadata: [
          { key: "store_barter", value: $value }
          { key: "barter_guidelines", value: $barter_guidelines }
          { key: "influencer_notes", value: $influencer_notes }
        ]
      }
    ) {
      store {
        metadata
      }
    }
  }
`;
