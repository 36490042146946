import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { client } from "../../apollo-client";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import { NextIcon } from "../../components/Pagination/styles";
import Table from "../../components/Table";
import useAuth from "../../hooks/useAuth/useAuth";
import { GET_OVERALL_DASHBOARD_DATA } from "./queries";
import * as S from "./styles";

const OverallDashboard = () => {
  const [page, setPage] = useState(1);
  const [hasNext, setHasNext] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  //data for the table
  const [rangeData, setRangeData] = useState([]);
  const { data, loading, error } = useQuery(GET_OVERALL_DASHBOARD_DATA, {
    variables: {
      page: page,
    },
  });

  useEffect(() => {
    console.log(data?.overallDashboard.length);
    if (data?.overallDashboard?.length) {
      let temp = [...data.overallDashboard].map((e, i) => ({
        ...e,
      }));
      setDashboardData([...dashboardData, ...temp.reverse()]);
      if (data.length < 11) {
        setHasNext(false);
      }
    }
  }, [data]);

  //storing and calculating the value of daily and weekly stats
  useEffect(() => {
    let arr = [{}, {}];
    if (dashboardData?.length > 0) {
      const day1 = dashboardData[0];
      const day2 = dashboardData[1];
      const day8 = dashboardData[7];
      Object.keys(day1)
        .filter((e) => {
          if (e === "perDayOrderCount" || e === "perDayCheckoutCount") {
            return false;
          }
          return true;
        })
        .reduce((_, key) => {
          if (key === "date") {
            arr[0]["range"] = "Daily";
            arr[1]["range"] = "Weekly";
          } else {
            arr[0][key] = Math.round(day1[key] - day2[key]);
            arr[1][key] = Math.round(day1[key] - day8[key]);
          }
        });
      setRangeData(arr);
    }
  }, [dashboardData]);

  const fetchMore = async () => {
    console.log("fetching more...");
    await client.refetchQueries([GET_OVERALL_DASHBOARD_DATA]);
    setPage(page + 1);
  };

  if (loading) {
    return <PacmanLoader />;
  }
  if (error) <div>Oops something went wrong</div>;

  return (
    <Layout
      desktop
      hideBack
      pagination={{
        hasNext: hasNext,
        current: page,
        onNextClick: () => fetchMore(),
        onPrevClick: () => setPage(page - 1),
      }}
    >
      <S.Wrapper>
        <Table data={rangeData} currentPage={1} displayPageSize={2} />
        <br />
        <h2>Overall Dashboard</h2>
        <Table
          data={dashboardData}
          currentPage={page}
          displayPageSize={11}
          from="overallDashboard"
        />
      </S.Wrapper>
    </Layout>
  );
};

export default OverallDashboard;
