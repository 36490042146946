import { useNavigate } from "react-router-dom";
import * as S from "./style";

const Header = ({ title, hideBack, desktop, backUrl, from }) => {
  const navigate = useNavigate();
  return (
    <S.Header desktop={desktop}>
      <div>
        {!hideBack && (
          <span
            onClick={() => {
              backUrl ? navigate(backUrl) : navigate(-1);
            }}
          >
            <img src="/assets/images/back.svg" alt="" />
          </span>
        )}
        <img alt="logo" className="logo" src="/assets/images/logo.png" />
        <a
          className="logout"
          onClick={() => localStorage.clear()}
          href={from === "management" ? "/brand" : "/"}
        >
          Logout <img alt="logout" src="/assets/images/logout.svg" />
        </a>
      </div>
    </S.Header>
  );
};

export default Header;
