import { gql } from "@apollo/client";

export const GET_USER_BRANDS = gql`
  query UserByMobile($mobileNo: String!) {
    userByMobile(mobileNo: $mobileNo) {
      id
      isActive
      authorisedStores {
        id
        storeName
      }
      authorisedBrands {
        id
        brandName
      }
    }
  }
`;

export const GET_COLLECTIONS_BY_STORE = gql`
  query GetCollectionsByStore($storeIds: [ID]!) {
    collectionAnalytics(first: 100, filter: { stores: $storeIds }) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          collectionId
          name
        }
      }
    }
  }
`;

export const GET_COUPONS_DATA = gql`
  query Vouchers(
    $endCursor: String
    $brands: [ID]
    $collections: [ID]
    $stores: [ID]
    $search: String
  ) {
    vouchers(
      first: 10
      after: $endCursor
      filter: {
        brands: $brands
        collections: $collections
        stores: $stores
        search: $search
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          code
          type
          metadata {
            key
            value
          }
          used
          discountValue
          discountValueType
          active
          store {
            id
            storeName
          }
          endDate
        }
      }
    }
  }
`;
