import * as S from "./styles";

import Header from "../Header";
import Pagination from "../Pagination";

function Layout({ children, title, hideBack, pagination, desktop, backUrl, from }) {
  return (
    <S.Wrapper desktop={desktop}>
      <Header title={title} hideBack={hideBack} desktop={desktop} backUrl={backUrl} from={from} />
      {children}
      {pagination && <Pagination {...pagination} isMobile={!desktop} />}
    </S.Wrapper>
  );
}

export default Layout;
