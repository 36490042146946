import Dropdown from "../../components/Dropdown";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { UPDATE_STORE_MANAGER } from "./mutations";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { useState } from "react";

export const statusEnum = [
  { label: "TEST_STORE", value: "TEST_STORE" },
  { label: "STILL_EXPLORING", value: "STILL_EXPLORING" },
  { label: "A1_STORE_WORKING", value: "A1_STORE_WORKING" },
  {
    label: "A1_STORE_ENTHU_NO_SALES_RECENTLY",
    value: "A1_STORE_ENTHU_NO_SALES_RECENTLY",
  },
  { label: "A1_OWN_PACE", value: "A1_OWN_PACE" },
  { label: "A2_STORE_ENTHU", value: "A2_STORE_ENTHU" },
  { label: "A2_OWN_PACE", value: "A2_OWN_PACE" },
  { label: "A2_SOURCING_ENTHU", value: "A2_SOURCING_ENTHU" },
  { label: "B1_STORE_ENTHU", value: "B1_STORE_ENTHU" },
  { label: "B1_SOURCING_ENTHU", value: "B1_SOURCING_ENTHU" },
  { label: "INACTIVE_ON_INSTAGRAM", value: "INACTIVE_ON_INSTAGRAM" },
  { label: "BAD_ZAAMO_EXPERIENCE", value: "BAD_ZAAMO_EXPERIENCE" },
  {
    label: "UNABLE_TO_CONNECT_ON_CALL_OR_TEXT",
    value: "UNABLE_TO_CONNECT_ON_CALL_OR_TEXT",
  },
  { label: "NOT_INTRESTED_TO_CONTINUE", value: "NOT_INTRESTED_TO_CONTINUE" },
  { label: "A1_5K", value: "A1_5K" },
  { label: "B1_5K", value: "B1_5K" },
  { label: "A1_5K_ONLY_SOURCING", value: "A1_5K_ONLY_SOURCING" },
  { label: "A1_15K_ONLY_SOURCING", value: "A1_15K_ONLY_SOURCING" },
  { label: "A1_CELEB_ONLY_SOURCING", value: "A1_CELEB_ONLY_SOURCING" },
  { label: "B1_15K_ONLY_SOURCING", value: "B1_15K_ONLY_SOURCING" },
  { label: "B1_CELEB_ONLY_SOURCING", value: "B1_CELEB_ONLY_SOURCING" },
  { label: "A1_ONLY_SOURCING", value: "A1_ONLY_SOURCING" },
  { label: "A2_ONLY_SOURCING", value: "A2_ONLY_SOURCING" },
  { label: "B1_ONLY_SOURCING", value: "B1_ONLY_SOURCING" },
  { label: "A2_B2_NO_MANAGE", value: "A2_B2_NO_MANAGE" },
  { label: "STYLIST", value: "STYLIST" },
  { label: "MANAGER", value: "MANAGER" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
  { label: "CAMPUS_AMBASSADOR", value: "CAMPUS_AMBASSADOR" },
  { label: "SOURCING_2023", value: "SOURCING_2023" },
  { label: "STORE_2023", value: "STORE_2023" },
  { label: "CAMPAIGN_2023", value: "CAMPAIGN_2023" },
];

export const nextActionEnum = [
  { label: "Skincare/Beauty/Makeup", value: "SKINCARE_BEAUTY_MAKEUP" },
  { label: "Fashion", value: "FASHION" },
  { label: "Dance", value: "DANCE" },
  { label: "Mom", value: "MOM" },
  {
    label: "Review/Shoppable/Commercial",
    value: "REVIEW_SHOPPABLE_COMMERCIAL",
  },
  { label: "Male influencers", value: "MALE_INFLUENCERS" },
  { label: "Fitness/Athleisure", value: "FITNESS_ATHLEISURE" },
  { label: "Plus size", value: "PLUS_SIZE" },
  { label: "Lifestyle", value: "LIFESTYLE" },
  { label: "Food/Nutrition", value: "FOOD_NUTRITION" },
];

export const StoreManagersActions = ({ e }) => {
  //current status
  const [status, setStatus] = useState(e?.node?.actions?.status);
  //current next actions
  const [nextActon, setNextAction] = useState(e?.node?.actions?.nextActions);

  const [updateStore, { error }] = useMutation(UPDATE_STORE_MANAGER);

  //updating the store manager
  const handleSubmit = () => {
    updateStore({
      variables: {
        storeId: e?.node?.actions?.id,
        status,
        nextActions: nextActon,
      },
    });
    toast.success("Updated Store Settings");
  };

  if (error) return <ErrorPage />;

  return (
    <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
      <Dropdown
        style={{ width: "50%" }}
        value="value"
        label="label"
        data={statusEnum.map((status) => status)}
        onChange={(e) => setStatus(e.value)}
        placeholder={e?.node?.actions?.status}
      />
      <Dropdown
        style={{ width: "50%" }}
        value="value"
        label="label"
        data={nextActionEnum.map((actions) => actions)}
        onChange={(e) => setNextAction(e.value)}
        placeholder={e?.node?.actions?.nextActions}
      />
      <button
        onClick={handleSubmit}
        disabled={
          status === e?.node?.actions?.status &&
          nextActon === e?.node?.actions?.nextActions
        }
        style={{ padding: "10px" }}
      >
        Save
      </button>
    </div>
  );
};
