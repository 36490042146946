import { gql } from "@apollo/client";

export const GET_ORDERS = gql`
  query GetStores($searchString: String!, $endCursor: String) {
    orders(first: 10, after: $endCursor, filter: { search: $searchString }) {
      edges {
        node {
          id
          voucher {
            name
          }
          store {
            storeName
            storeUrl
          }
          total {
            net {
              amount
              currency
            }
            gross {
              amount
              currency
            }
          }
          number
          shippingAddress {
            id
            phone
            firstName
            email
            streetAddress1
            postalCode
            city
            cityArea
          }
          user {
            userId
            firstName
            email
            mobileNo
            lastName
            avatar {
              url
            }
            defaultBillingAddress {
              streetAddress1
              streetAddress2
              postalCode
            }
          }
          created
          lines {
            cod
            refundCreationTime
            fulfilment {
              status
              updatedAt
              shippingFulfillment {
                shippingId
                shippingProvider
              }
            }
            id
            variantName
            brandName
            productSku
            productName
            thumbnail {
              url
            }
            quantity
            totalPrice {
              net {
                amount
              }
              gross {
                amount
              }
            }
          }
        }
      }
      pageInfo {
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;
