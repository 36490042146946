import { gql } from "@apollo/client";

export const GET_STORE_DETAILS = gql`
  query GetStoreDetails($storeId: ID) {
    store(id: $storeId) {
      warehouse
    }
  }
`;

export const GET_ORDER_DETAILS = gql`
  query GetOrderDetails($id: ID!) {
    order(id: $id) {
      id
      status
      created
      store {
        id
      }
      fulfillments {
        id
        status
        metadata {
          key
          value
        }
        shippingFulfillment {
          shippingId
          shippingProvider
        }
        lines {
          id
          orderLine {
            id
          }
        }
      }
      shippingAddress {
        firstName
        streetAddress1
        city
        postalCode
        phone
      }
      voucher {
        name
      }
      isPaid
      total {
        gross {
          amount
        }
      }
      lines {
        id
        brandName
        productName
        variantName
        quantity
        thumbnail {
          url
        }
        variant {
          name
          pricing {
            price {
              net {
                amount
              }
            }
          }
        }
      }
    }
  }
`;
