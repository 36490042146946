import * as S from "./styles";

import { useEffect, useState } from "react";
import {
  useLazyQuery,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import Button from "../../components/Button";
import { CREATE_BULK_DEAL } from "./mutations";
import Dropdown from "../../components/Dropdown";
import EditActions from "../StoresDashboard/EditActions";
import EditStaff from "../StoresDashboard/EditStaff";
import EditStore from "../StoresDashboard/EditStore";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import Filters from "../StoresDashboard/Filters";
import { GET_ALL_BRANDS } from "../Products2/queries";
import { GET_STORES } from "../StoresDashboard/queries";
import { GET_USER_DATA } from "../BrandsDashboard/queries";
import Layout from "../../components/Layout";
import PacmanLoader from "../../components/PackmanLoader";
import ReactModal from "react-modal";
import TableWithSelection from "../../components/TableWithSelection";
import { toast } from "react-toastify";
import { STAFF_MEMBERS } from "../SourceWithZaamo/queries";
import { EditContent } from "./EditContent";
import { SelectRowsModal } from "./SelectRowsModal";
import { allSelectedStores } from "../../reactiveVariables";

const storeStatusDict = [
  { label: "TEST_STORE", value: "TEST_STORE" },
  { label: "STILL_EXPLORING", value: "STILL_EXPLORING" },
  { label: "A1_STORE_WORKING", value: "A1_STORE_WORKING" },
  {
    label: "A1_STORE_ENTHU_NO_SALES_RECENTLY",
    value: "A1_STORE_ENTHU_NO_SALES_RECENTLY",
  },
  { label: "A1_OWN_PACE", value: "A1_OWN_PACE" },
  { label: "A2_STORE_ENTHU", value: "A2_STORE_ENTHU" },
  { label: "A2_OWN_PACE", value: "A2_OWN_PACE" },
  { label: "A2_SOURCING_ENTHU", value: "A2_SOURCING_ENTHU" },
  { label: "B1_STORE_ENTHU", value: "B1_STORE_ENTHU" },
  { label: "B1_SOURCING_ENTHU", value: "B1_SOURCING_ENTHU" },
  { label: "INACTIVE_ON_INSTAGRAM", value: "INACTIVE_ON_INSTAGRAM" },
  { label: "BAD_ZAAMO_EXPERIENCE", value: "BAD_ZAAMO_EXPERIENCE" },
  {
    label: "UNABLE_TO_CONNECT_ON_CALL_OR_TEXT",
    value: "UNABLE_TO_CONNECT_ON_CALL_OR_TEXT",
  },
  { label: "NOT_INTRESTED_TO_CONTINUE", value: "NOT_INTRESTED_TO_CONTINUE" },
  { label: "A1_5K", value: "A1_5K" },
  { label: "B1_5K", value: "B1_5K" },
  { label: "A1_5K_ONLY_SOURCING", value: "A1_5K_ONLY_SOURCING" },
  { label: "A1_15K_ONLY_SOURCING", value: "A1_15K_ONLY_SOURCING" },
  { label: "A1_CELEB_ONLY_SOURCING", value: "A1_CELEB_ONLY_SOURCING" },
  { label: "B1_15K_ONLY_SOURCING", value: "B1_15K_ONLY_SOURCING" },
  { label: "B1_CELEB_ONLY_SOURCING", value: "B1_CELEB_ONLY_SOURCING" },
  { label: "A1_ONLY_SOURCING", value: "A1_ONLY_SOURCING" },
  { label: "A2_ONLY_SOURCING", value: "A2_ONLY_SOURCING" },
  { label: "B1_ONLY_SOURCING", value: "B1_ONLY_SOURCING" },
  { label: "A2_B2_NO_MANAGE", value: "A2_B2_NO_MANAGE" },
  { label: "STYLIST", value: "STYLIST" },
  { label: "MANAGER", value: "MANAGER" },
  { label: "INTERNATIONAL", value: "INTERNATIONAL" },
  { label: "CAMPUS_AMBASSADOR", value: "CAMPUS_AMBASSADOR" },
  { label: "SOURCING_2023", value: "SOURCING_2023" },
  { label: "STORE_2023", value: "STORE_2023" },
  { label: "CAMPAIGN_2023", value: "CAMPAIGN_2023" },
];

const nextActionOptions = [
  { label: "Skincare/Beauty/Makeup", value: "SKINCARE_BEAUTY_MAKEUP" },
  { label: "Fashion", value: "FASHION" },
  { label: "Dance", value: "DANCE" },
  { label: "Mom", value: "MOM" },
  {
    label: "Review/Shoppable/Commercial",
    value: "REVIEW_SHOPPABLE_COMMERCIAL",
  },
  { label: "Male influencers", value: "MALE_INFLUENCERS" },
  { label: "Fitness/Athleisure", value: "FITNESS_ATHLEISURE" },
  { label: "Plus size", value: "PLUS_SIZE" },
  { label: "Lifestyle", value: "LIFESTYLE" },
  { label: "Food/Nutrition", value: "FOOD_NUTRITION" },
];

export default function BulkDeals() {
  const [page, setPage] = useState(1);
  const [stores, setStores] = useState([]);
  const [currentStore, setCurrentStore] = useState({});
  const [editStaff, setEditStaff] = useState(false);
  const [editStore, setEditStore] = useState(false);
  const [editStatus, setEditStatus] = useState(false);

  const [selectRowsModal, setSelectRowsModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const AllSelectedStores = useReactiveVar(allSelectedStores);

  const [selectedBrand, setSelectedBrand] = useState("");
  const [campaignName, setCampaignName] = useState("");
  const [termsAndConditions, setTermsAndConditions] = useState("");
  const [allBrandsData, setAllBrandsData] = useState({});
  const [selectedStoreStatus, setSelectedStoresStatus] = useState([]);
  const [filters, setFilters] = useState({
    dir: { label: "Desc", value: "DESC" },
    field: { label: "GMV Last 30 Days", value: "GMV_LAST_30_DAYS" },
  });
  const [selectedStaff, setSelectedStaff] = useState([]);
  const [nextAction, setNextAction] = useState({});
  const [editContent, setEditContent] = useState(false);

  const {
    data: staffData,
    loading: staffLoading,
    error: staffError,
  } = useQuery(STAFF_MEMBERS);

  const { data, loading, error, refetch, fetchMore } = useQuery(GET_STORES, {
    notifyOnNetworkStatusChange: true,
    variables: {
      barter: filters?.barter?.value,
      dir: filters.dir?.value,
      field: filters.field?.value,
      search: filters?.search,
      status: selectedStoreStatus?.map((e) => e.value),
      managerEmail: selectedStaff?.map((e) => e.value),
      nextActions: nextAction?.value ? [nextAction?.value] : [],
    },
  });
  const [createBulkDeal, { bulkDealData, bulkDealLoading, bulkDealError }] =
    useMutation(CREATE_BULK_DEAL);

  // const [fetchData, {brandsData, brandsDataLoading, brandsDataError, brandsDataFetchMore}] = useLazyQuery(GET_ALL_BRANDS);
  const {
    data: xbrandsData,
    xbrandsLoading,
    xbrandsError,
  } = useQuery(GET_ALL_BRANDS, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    data: brandsData,
    loading: brandsLoading,
    error: brandsError,
  } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  useEffect(() => {
    refetch();
    setPage(1);
  }, [nextAction]);

  useEffect(() => {
    allSelectedStores(
      [...allSelectedStores(), ...selectedRows].filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.original.storeId === item.original.storeId)
      )
    );
  }, [selectedBrand, selectedStaff, nextAction, selectedStoreStatus]);
  const changeFilter = async (value, type) => {
    allSelectedStores(
      [...allSelectedStores(), ...selectedRows].filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.original.storeId === item.original.storeId)
      )
    );
    setPage(1);
    if (type === "storeBarter")
      return setFilters({
        ...filters,
        barter: value,
        storeBarter: value,
        stopSource: null,
      });
    if (type === "stopSource")
      return setFilters({
        ...filters,
        barter: value,
        stopSource: value,
        storeBarter: null,
      });
    setFilters({ ...filters, [type]: value });
  };
  const handleCreateBulkDealClick = () => {
    allSelectedStores(
      [...allSelectedStores(), ...selectedRows].filter(
        (item, index, self) =>
          index ===
          self.findIndex((t) => t.original.storeId === item.original.storeId)
      )
    );
    if (!selectedBrand) {
      toast.error("Select a brand first!");
      return;
    }
    if (selectedRows.length === 0 && AllSelectedStores.length === 0) {
      toast.error("Select atleast one store");
      return;
    }
    setSelectRowsModal(true);
  };

  const createBulkDealHandler = async (selectedStores) => {
    const selectedStoresID = [];

    selectedStores.forEach((entry) => {
      selectedStoresID.push(entry.original.storeId);
    });
    if (!selectedBrand) {
      toast.error("Select a brand first!");
    } else {
      const res = await createBulkDeal({
        variables: {
          brandID: selectedBrand?.value,
          stores: selectedStoresID,
          termsAndConditions: termsAndConditions,
          campaignName: campaignName,
        },
      });
      if (res.data?.brandSourceRequestBulkCreate) {
        toast.success(`Bulk deal created for ${selectedBrand.label}`);
      } else toast.error("Something went wrong!");
      window.location.reload();
    }
  };

  // useEffect(() => {
  //   console.log(selectedStoreStatus, "selectedStoreStatus");
  //   fetchStores({
  //     variables: {
  //       barter: filters?.barter?.value,
  //       dir: filters.dir?.value,
  //       field: filters.field?.value,
  //       search: filters?.search,
  //       status: selectedStoreStatus,
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filters, selectedStoreStatus]);

  useEffect(() => {
    if (data) {
      const storesData = data.stores.edges.map(({ node }) => ({
        storeId: window.atob(node?.id).replace("Store:", "") ?? "--",
        actions: {
          function: () => {
            setEditStore(true);
            setCurrentStore(node);
          },
          name: "Edit",
        },
        storeName: node?.storeName ?? "--",
        storeUrl: {
          link: {
            name: "View Store",
            url: node?.storeUrl ? node?.storeUrl : "--",
          },
        },
        slug: node?.slug ?? "--",
        state: node?.state ?? "--",
        content: {
          function: () => {
            setEditContent(true);
            setCurrentStore({
              id: node?.id,
              content: node?.content,
              staff: node?.storeManagers?.map((mem) => ({
                label: mem.email,
                value: mem.id,
              })),
            });
          },
          name: node?.content ?? "--",
        },
        storeManagers: {
          function: () => {
            setEditStaff(true);
            setCurrentStore({
              id: node?.id,
              staff: node?.storeManagers?.map((mem) => ({
                label: mem.email,
                value: mem.id,
              })),
            });
          },
          data: node?.storeManagers ?? [],
        },
        storeType: node?.storeType ?? "--",
        storeCategoryPageLevel: node?.storeCategoryPageLevel ?? "--",
        status: node?.actions?.status ?? "--",
        nextActions: node?.actions?.nextActions ?? "--",
        storeActions: {
          function: () => {
            setCurrentStore(node);
            setEditStatus(true);
          },
          name: "Edit Actions",
          disable: node?.actions ? false : true,
        },
        streakLive: node?.streakLive ? "True" : "False",
        instagram: {
          link: {
            name: node?.instagramLink ? node?.instagramLink : "--",
            url: node?.instagramLink ? node?.instagramLink : "--",
          },
        },
        stopSourceWithZaamo: node?.stopSourceWithZaamo ? "True" : "False",
        barterGuidelines: JSON.parse(node?.metadata)?.barter_guidelines ?? "--",
        storeId: node?.id,
      }));
      setStores(storesData);
    }
  }, [data, page]);

  // if (loading) return <PacmanLoader />;
  if (error || staffError) return <ErrorPage />;

  return (
    <>
      {loading || staffLoading ? <PacmanLoader /> : <></>}
      <Layout
        pagination={{
          hasNext:
            data?.stores?.pageInfo?.hasNextPage ||
            page * 100 < data?.stores?.edges?.length,
          current: page,
          onNextClick: () => {
            setPage(page + 1);
            fetchMore({
              variables: {
                endCursor: data?.stores?.pageInfo?.endCursor,
              },
            });
          },
          onPrevClick: () => setPage(page - 1),
        }}
        desktop
      >
        {/* <Dropdown /> */}
        <S.Div>
          <S.Flex>
            <div>
              <label>Brands List</label>
              <Dropdown
                style={btnStyle}
                data={brandsData?.userByMobile?.authorisedBrands?.map((e) => {
                  return e;
                })}
                value="id"
                label="brandName"
                onChange={(e) => setSelectedBrand(e)}
                // onChange={(e) => props.onChange(e, "field")}
                placeholder="Brands"
                // defaultValue={props.defaultValue.field}
              />
            </div>
            <div>
              <label>Campaign Name</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  margin: "5px",
                }}
              >
                <S.SearchInput
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </div>
            </div>
            <div>
              <label>Terms & Conditions</label>
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  alignItems: "center",
                  margin: "5px",
                }}
              >
                <S.SearchInput
                  onChange={(e) => setTermsAndConditions(e.target.value)}
                />
              </div>
            </div>
            <div>
              <S.Button onClick={handleCreateBulkDealClick}>
                Create Bulk Deal
              </S.Button>
            </div>
          </S.Flex>
        </S.Div>
        <Filters
          onChange={changeFilter}
          defaultValue={{
            ...filters,
          }}
        />
        <S.Div>
          <S.Flex>
            <div>
              <label>Store Status</label>
              <Dropdown
                style={{ ...btnStyle, width: "280px", maxWidth: "280px" }}
                data={storeStatusDict}
                value="value"
                label="label"
                onChange={(e) => setSelectedStoresStatus(e)}
                placeholder="Store Status"
                isMulti
                // defaultValue={props.defaultValue.field}
              />
            </div>
            <div>
              <label>Store Staff Managers</label>
              <Dropdown
                style={{ width: "280px" }}
                data={staffData?.staffUsers?.edges?.map(({ node }) => ({
                  id: node.email,
                  email: node.email,
                }))}
                value="id"
                label="email"
                onChange={(e) => setSelectedStaff(e)}
                placeholder="Select Staff Managers"
                defaultValue={selectedStaff}
                isMulti
              />
            </div>
            <div>
              <label>Next Action</label>
              <Dropdown
                style={{ width: "280px", marginLeft: "5px" }}
                data={nextActionOptions}
                value="value"
                label="label"
                onChange={(e) => setNextAction(e)}
                placeholder="Next Action"
              />
            </div>
          </S.Flex>
        </S.Div>
        <TableWithSelection
          currentPage={page}
          from="stores"
          data={stores}
          displayPageSize={100}
          setSelectedRows={setSelectedRows}
        />
        <ReactModal
          isOpen={editStaff}
          onRequestClose={() => setEditStaff(false)}
          style={S.Modal}
        >
          <EditStaff
            id={currentStore?.id}
            staff={currentStore?.staff}
            close={() => setEditStaff(false)}
          />
        </ReactModal>
        <ReactModal
          isOpen={editStore}
          onRequestClose={() => setEditStore(false)}
          style={S.Modal}
        >
          <EditStore store={currentStore} close={() => setEditStore(false)} />
        </ReactModal>
        <ReactModal
          isOpen={editStatus}
          onRequestClose={() => setEditStatus(false)}
          style={{
            ...S.Modal,
            content: {
              ...S.Modal.content,
              maxWidth: "900px",
            },
          }}
        >
          <EditActions
            storeData={currentStore?.actions}
            close={() => setEditStatus(false)}
          />
        </ReactModal>
        <ReactModal
          isOpen={editContent}
          onRequestClose={() => setEditContent(false)}
          style={S.Modal}
        >
          <EditContent
            storeId={currentStore?.id}
            prevContent={currentStore?.content}
            close={() => setEditContent(false)}
          />
        </ReactModal>
        <ReactModal
          isOpen={selectRowsModal}
          onRequestClose={() => setSelectRowsModal(false)}
          style={{
            ...S.Modal,
            content: {
              ...S.Modal.content,
              maxWidth: "900px",
            },
          }}
        >
          <SelectRowsModal
            allSelectedRows={AllSelectedStores}
            createBulkDealHandler={createBulkDealHandler}
            close={() => setSelectRowsModal(false)}
          />
        </ReactModal>
      </Layout>
    </>
  );
}

const btnStyle = { minWidth: "150px", margin: "5px", maxWidth: "200px" };
