import Table from "../../components/Table";
import Layout from "../../components/Layout";
import Filters from "./components/Filters";
import * as S from "./styles";
import PacmanLoader from "../../components/PackmanLoader";
import useMasterDashboard from "./useMasterDashboard";
import ReactModal from "react-modal";
import OrderDetails from "./components/OrderDetails";

const BrandsMasterDashboard = () => {
  const {
    time,
    direction,
    sortBy,
    page,
    setPage,
    hasNextPage,
    data,
    loading,
    error,
    loading2,
    error2,
    onChange,
    fetchMoreData,
    clearAll,
    parsedData2,
    modalShow,
    setModalShow,
    modalData,
    voucher,
  } = useMasterDashboard();

  if (error || error2) {
    return <div>Oops something went wrong</div>;
  }

  if (loading) {
    return <PacmanLoader />;
  }
  return (
    <Layout
      from="management"
      desktop
      hideBack
      pagination={
        parsedData2.length
          ? {
              hasNext: hasNextPage || parsedData2.length - page * 50 >= 0,
              current: page,
              onNextClick: () => {
                (hasNextPage || parsedData2.length - page * 50 >= 0) && fetchMoreData();
              },
              onPrevClick: () => setPage(page - 1),
            }
          : false
      }
    >
      <S.Wrapper>
        <Filters
          onChange={onChange}
          data={data.userByMobile}
          defaultValue={{
            time: time,
            direction: direction,
            sortBy: sortBy,
            voucher: voucher,
          }}
          clearAll={clearAll}
        />
        {loading2 ? (
          <PacmanLoader />
        ) : (
          <S.Container>
            <Table data={parsedData2} currentPage={page} from="management" displayPageSize={50} />
          </S.Container>
        )}
      </S.Wrapper>
      <ReactModal isOpen={modalShow} onRequestClose={() => setModalShow(false)} style={S.Modal}>
        <OrderDetails modalData={{ ...modalData }} />
      </ReactModal>
    </Layout>
  );
};

export default BrandsMasterDashboard;
