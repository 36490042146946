import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 0;
  width: 100%;
  background: #f1f1f1;
  h2 {
    text-align: center;
    font-size: 1.1rem;
    padding: 0;
    margin: 0;
  }
`;
