import styled from "styled-components";

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  padding: 10px 0;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background: #fff;
  border: hidden;
  border-radius: 5px;
  text-align: center;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  -moz-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  overflow: hidden;
  p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 500;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    max-width: 98%;
    /* overflow: hidden; */
  }
  span {
    font-size: 0.7rem;
    color: #606060;
    /* white-space: nowrap; */
    /* text-overflow: ellipsis; */
    /* overflow: hidden; */
    max-width: 98%;
  }
`;

export const Wrapper = styled.div`
  margin: 10px 0;
  background: #f8f8f8;
  padding: 10px;
  h3 {
    font-size: 1.1rem;
  }
  .store-item {
    margin: 10px 0;
    background-color: #f1f1f1;
    padding: 10px 0;
  }
`;

export const FilterWrap = styled.div`
  padding: 0px 10px;
  border-top: 2px solid #f1f1f1;
`;

export const Filters = styled.div`
  padding: 0 1% 0px 1%;
  label {
    padding-left: 5px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 40vh;
  background-color: #f1f1f1;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  flex-wrap: wrap;
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "90%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "20px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};
