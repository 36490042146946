import { useState } from "react";
import * as S from "./styles";
import Button from "../../../../components/Button";
import { useMutation } from "@apollo/client";
import { UPDATE_ORDER_NOTE } from "./mutation";
import { toast } from "react-toastify";

const OrderNote = ({ defaultData, setOrderNoteModal }) => {
  const [note, setNote] = useState(defaultData?.note);
  const [updateOrderNote] = useMutation(UPDATE_ORDER_NOTE);

  const updateOrderNoteHandler = async () => {
    const resp = await updateOrderNote({
      variables: {
        id: defaultData?.id,
        note: note,
      },
    });
    if (resp?.data?.orderFulfillmentNote?.fulfillmentLine?.id) {
      toast.success("Order note updated successfully!");
      setOrderNoteModal({ display: false });
    } else {
      toast.error("Something went wrong!");
    }
  };

  return (
    <>
      <S.Heading>Update Order Note</S.Heading>
      <S.TextField
        onChange={(e) => setNote(e.target.value)}
        value={note}
        placeholder="Type Here"
      />
      <Button onClick={updateOrderNoteHandler}>Update</Button>
    </>
  );
};

export default OrderNote;
