import React from 'react'
import Dropdown from "../../components/Dropdown";
import * as S from "./styles";



const Filters = (props) => {



  return (
    <S.FilterWrapper>
    <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="id"
      data={props.brands.brands.edges.map((brand) => brand.node)}
      label="brandName"
      placeholder="Brand"
      onChange={props.handleSelectBrand}
    
    />
     <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="value"
      data={props?.filterData?.collections.map(collection => {
        const obj = {label:collection,value:collection}
        return obj
      })|| [{label: "",value: ""}]}
      label="label"
      isMulti
      placeholder="Collections"
      onChange={props.handleFilterCollections}
    />
     <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="value"
      data={props?.filterData?.categories.map(category => {
        const obj = {label:category,value:category}
        return obj
      })|| [{label: "",value: ""}]}
      label="label"
      isMulti
      placeholder="Category"
      onChange={props.handleFilterCategories}
    />
     <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="id"
      data={props?.filterData?.productType || [{label: "HI",value: "HI"}]}
      label="name"
      isMulti
      placeholder="Product Type"
      onChange={props.handleFilterProductType}

    />
    <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="value"
      data={props?.filterData?.subCategories.map(category => {
        const obj = {label:category,value:category}
        return obj
      })|| [{label: "",value: ""}]}
      label="label"
      isMulti
      placeholder="Sub Categories"
      onChange={props.handleFilterSubCategories}

    />
     <Dropdown
      style={{ width: "100%", padding: "2px 0" }}
      value="value"
      data={[{label: "Visible",value: true}, {label: "Not Visible", value: false}]}
      label="label"
      placeholder="Visibility"
      // defaultValue={props.filtersApplied.isPublished}
      onChange={props.handleFilterVisibility}
    />
  </S.FilterWrapper>
  )
}

export default Filters