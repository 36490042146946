import styled from "styled-components";

export const Div = styled.div`
  label {
    padding-left: 10px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #666;
  }
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  flex-wrap: wrap;
`;


export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};

export const Input = styled.input`
  width: 15%;
  border: 1px solid red;
  &:focus {
    outline: none;
  }
`;

export const SearchInput = styled.input`
  padding: 0.8rem 1rem;
  flex: 1;
  border-radius: 5px;

  border: none;
  outline: none;
  background: #f1f1f1;
  height: 60%;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  position: relative;
  /* animation-name: anim; */
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
  /* animation-iteration-count: infinite; */
`;


export const Button = styled.button`
  background: ${({ color }) => (color ? color : "#000000")};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.79);
  border-radius: 5px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 30px;
  color: #ffffff;
  padding: 5px 10px;
  margin: 10px 10px;
  position: relative;
  bottom: -9px;
  border: hidden;
  display: block;
  cursor: pointer;
  img {
      margin-right: 5px;
  }
  :disabled {
    background: #111111c4 !important;
  } 
`;