import styled, { css } from "styled-components";
import TextTruncate from "react-text-truncate";
import { ReactSVG } from "react-svg";
import CopyToClipboard from "react-copy-to-clipboard";

export const MainWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(9.55rem, 1fr));
  grid-gap: 1rem;
  width: 625px;
  margin: auto;
`;

export const SearchBar = styled.div`
  height: 3.5rem;
  width: 95%;
  position: fixed;
  top: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
  z-index: 400;
`;
export const SearchInput = styled.input`
  height: 2.25rem;
  outline: none;
  border: none;
  background-color: #fff;
  flex: 1;
  border-radius: 5px;
  box-shadow: rgb(50 50 93 / 15%) 0px 50px 100px -20px, rgb(0 0 0 / 15%) 0px 11px 20px 5px;
  padding: 10px;
  font-size: 16px;
  -webkit-appearance: none;
`;

export const BackIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  background-color: white;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 5px;
  margin-right: 1rem;
  padding-left: 5px;
  box-shadow: rgb(50 50 93 / 15%) 0px 50px 100px -20px, rgb(0 0 0 / 15%) 0px 11px 20px 5px;
`;
export const CrossIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 20px; */
  background-color: white;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 5px;
  margin-left: 1rem;
  /* padding-left: 5px; */
  box-shadow: rgb(50 50 93 / 15%) 0px 50px 100px -20px, rgb(0 0 0 / 15%) 0px 11px 20px 5px;
`;

export const SearchHeading = styled.p`
  padding: 1.5rem 0 1rem 0;
  font-weight: 400;
  opacity: 0.5;

  ${({ empty }) => {
    if (empty)
      return css`
        display: flex;
        align-items: center;
        justify-content: center;
        /* height: 60vh; */
      `;
  }}
`;

export const Overlay = styled.div`
  background-color: black;
  border-radius: 10px;
`;

export const OutterWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 6px;
  background-color: white;
  opacity: ${(props) => (props.grey ? "0.6" : "1")};
`;

export const CopyIcon = styled(ReactSVG)``;
export const CopyLink = styled(CopyToClipboard)`
  position: absolute;
  right: 0;
  top: 15px;
  border-radius: 20px;
  border: hidden;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(15px); */
  /* background-color: #0000001c; */
  pointer-events: all;
`;
export const Bottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.3rem;
  width: 100%;
  background-color: white;
`;

export const Commission = styled.p`
  display: flex;
  align-items: center;
  color: black;
  font-size: 0.6rem;
  gap: 0.5rem;
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      margin-right: 10px;
    }
  }
`;
export const Bold = styled.b``;

export const Wrapper = styled.div`
  width: 100%;
  height: 70vw;
  max-height: 17rem;
  padding: 0.5rem;
  border: 2px solid aliceblue;
  background-image: ${({ bgImg }) =>
    `linear-gradient(to top, rgba(0,0,0,.3) 0%, rgba(255,255,255,0) 25%), url(${bgImg})`};
  background-position: top;
  background-size: cover;
  border-radius: 7px;
  display: flex;
  justify-content: space-between;
  position: relative;
  background-color: white;
  position: relative;
  opacity: ${(props) => (props.grey ? "0.5" : "1")};
`;

export const Ribbon = styled.span`
  position: absolute;
  top: 0.8rem;
  left: -5px;
  /* border-bottom-left-radius:15px; */
  span {
    font-size: 0.6rem;
    position: absolute;
    top: 7px;
    left: 4px;
  }
`;

export const ProductDetails = styled.div`
  align-self: flex-end;
  text-shadow: 1px 1px 2px #00000090;
  color: white;
  display: grid;
  flex-direction: column;
  width: 100%;
  font-size: 0.6rem;
  gap: 0.2rem;
  pointer-events: none;
`;

export const Row1 = styled.div`
  display: flex;
  gap: 0.5rem;
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      margin-right: 0.5rem;
    }
  }
  align-content: center;
  justify-content: space-between;
  align-items: center;
`;

export const Brand = styled.div`
  font-size: 0.7rem;
  /* white-space: nowrap; */
`;

export const ProductName = styled(TextTruncate)``;

export const Row2 = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  justify-content: space-between;
  gap: 0.5rem;
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      margin-right: 0.5rem;
    }
  }
`;

export const PriceBeforeDiscount = styled.span`
  font-size: 0.6rem;
  text-decoration: line-through;
`;

export const PriceAfterDiscount = styled.div`
  font-size: 0.7rem;
`;

export const OutOfStock = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  display: grid;
  place-items: center;
  color: #666;
  text-transform: uppercase;
  font-size: 24px;
  transform: rotateZ(-45deg);
  pointer-events: none;
`;

export const Thunder = {
  display: "inline-block",
  position: "relative",
  bottom: "-3px",
};
export const CardActions = styled.div`
  position: absolute;
  right: 0;
  top: 5px;
`;

export const BrandBarterIcon = styled.div`
  position: absolute;
  right: 4px;
  top: 40px;
  border-radius: 20px;
  border: hidden;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* backdrop-filter: blur(15px); */
  /* background-color: #0000001c; */
  pointer-events: all;
`;

export const Row0 = styled.div`
  display: flex;
  gap: 5px; //handled
  @supports (-webkit-touch-callout: none) and (not (translate: none)) {
    & > * {
      /* margin-right: 5px; */
    }
  }
  align-content: center;
  justify-content: flex-end;
  align-items: baseline;
  margin-bottom: 5px;
  svg {
    height: 8px;
    width: 8px;
    margin-left: 2px;
  }
`;

export const StealDeal = styled.div`
  border-radius: 200px;
  padding: 4px 9px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-around;
  font-size: 0.7rem;
  backdrop-filter: blur(100px);
  background-color: rgba(10, 10, 10, 0.667);
`;

export const Products = styled.div`
  display: flex;
  margin: 10px 20px 0px;
  align-items: center;
`;

export const RemoveBtn = styled.button`
  width: fit-content;
  margin: 0px;
  margin-left: auto;
  padding: 5px;
`;
