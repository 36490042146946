import { useEffect, useState } from "react";

import Button from "../../Button";
import Dropdown from "../../Dropdown";
import { GET_DASHBOARD_BRANDS } from "../../../screens/BrandsDashboard/queries";
import { UPDATE_COMMISSION } from "./mutation";
import { client } from "../../../apollo-client";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

export default function EditCommission({ id, brandCommission, close, zaamoCommissionValue }) {
  console.log(zaamoCommissionValue)
  const [commission, setCommission] = useState({ label: brandCommission, value: brandCommission });
  const [zaamoCommission, setZaamoCommission] = useState({ label: zaamoCommissionValue, value: zaamoCommissionValue })
  const [updateCommission, { error }] = useMutation(UPDATE_COMMISSION);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const handleSaveCommission = async () => {
    const res = await updateCommission({
      variables: {
        id: id,
        commission: commission.value,
        zaamoCommission: zaamoCommission.value
      },
    });
    if (res.data?.brandCommissionCreateUpdate) {
      toast.success("Commission updated successfully");
      client.refetchQueries({
        include: [GET_DASHBOARD_BRANDS],
      });
    }
    close();
  };

  useEffect(() => {
    if (error?.message) return toast.error("Something went wrong!");
  }, [error]);


  return (
    <div>
      <h2>Influencer Commission</h2>
      <Dropdown
        value="value"
        label="label"
        defaultValue={commission}
        data={new Array(101).fill().map((_, index) => {
          return {
            label: (index).toFixed(1),
            value: (index).toFixed(1),
          };
        })}
        style={{ margin: "10px 0px 10px" }}
        onChange={(e) => {
          setCommission(e);
          setButtonDisabled(false)
        }}
      />
      <h2>Zaamo Commission</h2>
      <Dropdown
        value="value"
        label="label"
        defaultValue={zaamoCommission}
        data={new Array(101).fill().map((_, index) => {
          return {
            label: (index).toFixed(1),
            value: (index).toFixed(1),
          };
        })}
        style={{ margin: "10px 0px 10px 0px" }}
        onChange={(e) => {
          setZaamoCommission(e)
          setButtonDisabled(false)
        }}
      />
      <Button disabled={buttonDisabled} onClick={handleSaveCommission}>
        Update
      </Button>
    </div>
  );
}
