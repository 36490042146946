import * as S from "./styles";

import { EDIT_BRAND, EDIT_PICKUP_ADDRESS } from "./mutations";
import { boolOptions, statusOptions } from "../Filters/index";
import { useMemo, useState } from "react";

import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { GET_DASHBOARD_BRANDS } from "../queries";
import { client } from "../../../apollo-client";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";

const PickupAddress = ({ close, pickupAddress }) => {
  // console.log(pickupAddress)
  const [editPickupAddress, { error }] = useMutation(EDIT_PICKUP_ADDRESS);
  //formated data for updation
  const [brandData, setBrandData] = useState({
    id: pickupAddress?.id ?? "",
    firstName: pickupAddress?.data?.firstName ?? "",
    lastName: pickupAddress?.data?.lastName ?? "",
    streetAddress1: pickupAddress?.data?.streetAddress1 ?? "",
    streetAddress2: pickupAddress?.data?.streetAddress2 ?? "",
    city: pickupAddress?.data?.city ?? "",
    countryArea: pickupAddress?.data?.countryArea ?? "",
    phone: pickupAddress?.data?.phone ?? "",
    postalCode: pickupAddress?.data?.postalCode ?? "",
    email: pickupAddress?.data?.email ?? ""
  });
  
  // console.log(brandData.countryArea);


  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initial = useMemo(() => brandData, []);

  const onChange = (value, type) => {
    setBrandData({ ...brandData, [type]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await editPickupAddress({
        variables: {
          id: pickupAddress?.id,
          ...brandData
        },
      });
      if (res.data?.brandUpdate) {
        toast.success("Pickup Address updated!");
        client.refetchQueries({
          include: [GET_DASHBOARD_BRANDS],
        });
      }
      close();
    } catch (err) {
      console.log(error);
      toast.error("Something went wrong!");
    }
  };


  return (
    <form
      onSubmit={handleSubmit}
      style={{ fontSize: "0.8rem" }}
      autoComplete="true"
    >
      <S.Label>Pickup Address</S.Label>
      <S.TextLabel>First Name</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "firstName")}
        value={brandData?.firstName}
      />
      <S.TextLabel>Last Name</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "lastName")}
        value={brandData?.lastName}
      />
      <S.TextLabel>Email</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "email")}
        value={brandData?.email}
      />
      <S.TextLabel>Address</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "streetAddress1")}
        value={brandData?.streetAddress1}
      />
      <S.TextLabel>City</S.TextLabel>
      <S.TextField
        required
        onChange={(e) => onChange(e.target.value, "city")}
        value={brandData?.city}
      />
      <S.TextLabel>State</S.TextLabel>
      <Dropdown
        style={{ margin: "5px 0px 12px", border: "0.5px solid #000", borderRadius: "4px" }}
        data={states}
        value="value"
        label="label"
        onChange={(e) => {
          onChange(e.label, "countryArea")
        }}
        placeholder={brandData.countryArea ?? ""}
      // defaultValue={brandData.countryArea ?? ""}
      />
      <S.TextLabel>Phone</S.TextLabel>
      <S.TextField
        // pattern="^\d{10}$"
        minLength="10"
        type="tel"
        required
        // placeholder="Phone Number, min 10 characters"
        onChange={(e) => onChange(e.target.value, "phone")}
        value={brandData?.phone}
      /><S.TextLabel>Postal Code</S.TextLabel>
      <S.TextField
        minLength="6"
        type="tel"
        onChange={(e) => onChange(e.target.value, "postalCode")}
        value={brandData?.postalCode}
        required
      />
      <Button
        disabled={(JSON.stringify(brandData) === JSON.stringify(initial)) 
          && 
          (brandData?.phone.length > 10)
          && 
          (brandData?.postalCode.length > 6)}
        style={{ margin: "10px 0px" }}
        type="submit"
      >
        Save
      </Button>
    </form>
  );
};

export default PickupAddress;


const states = [
  {
    value: "ANDRA_PRADESH",
    label: "Andhra Pradesh"
  },
  {
    value: "ANDAMAN_AND_NICOBAR_ISLANDS",
    label: "Andaman & Nicobar Islands"
  },
  {
    value: "ARUNACHAL_PRADESH",
    label: "Arunachal Pradesh"
  },
  {
    value: "ASSAM",
    label: "Assam"
  },
  {
    value: "BIHAR",
    label: "Bihar"
  },
  {
    value: "CHANDIGARH",
    label: "Chandigarh"
  },
  {
    value: "CHATTISGARH",
    label: "Chattisgarh"
  },
  {
    value: "DELHI",
    label: "Delhi"
  },
  {
    value: "DAMAN_AND_DIU",
    label: "Daman & Diu"
  },
  {
    value: "GOA",
    label: "Goa"
  },
  {
    value: "GUJARAT",
    label: "Gujarat"
  },
  {
    value: "HARYANA",
    label: "Haryana"
  },
  {
    value: "HIMACHAL_PRADESH",
    label: "Himachal Pradesh"
  },
  {
    value: "JAMMU_AND_KASHMIR",
    label: "Jammu & Kashmir"
  },
  {
    value: "JHARKHAND",
    label: "Jharkhand"
  },
  {
    value: "KARNATAKA",
    label: "Karnataka"
  },
  {
    value: "KERALA",
    label: "Kerala"
  },
  {
    value: "LADAKH",
    label: "Ladakh"
  },
  {
    value: "LAKSHADWEEP",
    label: "Lakshadweep"
  },
  {
    value: "MADHYA_PRADESH",
    label: "Madhya Pradesh"
  },
  {
    value: "MAHARASHTRA",
    label: "Maharashtra"
  },
  {
    value: "MANIPUR",
    label: "Manipur"
  },
  {
    value: "MEGHALAYA",
    label: "Meghalaya"
  },
  {
    value: "MIZORAM",
    label: "Mizoram"
  },
  {
    value: "NAGALAND",
    label: "Nagaland"
  },
  {
    value: "ODISHA",
    label: "Odisha"
  },
  {
    value: "PUDUCHERRY",
    label: "Puducherry"
  },
  {
    value: "PUNJAB",
    label: "Punjab"
  },
  {
    value: "RAJASTHAN",
    label: "Rajasthan"
  },
  {
    value: "SIKKIM",
    label: "Sikkim"
  },
  {
    value: "TAMIL_NADU",
    label: "Tamil Nadu"
  },
  {
    value: "TELANGANA",
    label: "Telangana"
  },
  {
    value: "TRIPURA",
    label: "Tripura"
  },
  {
    value: "UTTAR_PRADESH",
    label: "Uttar Pradesh"
  },
  {
    value: "UTTARAKHAND",
    label: "Uttarakhand"
  },
  {
    value: "WEST_BENGAL",
    label: "West Bengal"
  },
]
