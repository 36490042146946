import { gql } from "@apollo/client";

export const EDIT_BRAND = gql`
  mutation EditBrand(
    $id: ID!
    $brandName: String
    $brandOrderInfo: String
    $shortDescription: String
    $brandBarterGuidelines: String
    $brandBarter: Boolean
    $botd: Boolean
    $tooManyOrders: Boolean
    $orderProcessingDays: Int
    $orderShippingDays: Int
    $status: BrandStatusEnum
    $shippingReturnPolicy: GenericScalar
    $quality_assured: String!
    $timely_delivery: String!
    $instagram_link: String!
    $postpay: String!
    $postpay_type: String!
    $easy_exchange: String!
    $zaamo_shipping: String!
    $return: String!
    $exchange: String!
    $step_active: String!
    $content_tagging: String!
    $importance: BrandImportanceEnum
  ) {
    brandUpdate(
      input: {
        id: $id
        brandName: $brandName
        brandOrderInfo: $brandOrderInfo
        shortDescription: $shortDescription
        brandBarterGuidelines: $brandBarterGuidelines
        brandBarter: $brandBarter
        botd: $botd
        tooManyOrders: $tooManyOrders
        orderProcessingDays: $orderProcessingDays
        orderShippingDays: $orderShippingDays
        status: $status
        shippingReturnPolicy: $shippingReturnPolicy
        importance: $importance
        metadata: [
          { key: "quality_assured", value: $quality_assured }
          { key: "timely_delivery", value: $timely_delivery }
          { key: "instagram_link", value: $instagram_link }
          { key: "postpay", value: $postpay }
          { key: "postpay_type", value: $postpay_type }
          { key: "easy_exchange", value: $easy_exchange }
          { key: "zaamo_shipping", value: $zaamo_shipping }
          { key: "return", value: $return }
          { key: "exchange", value: $exchange }
          { key: "step_active", value: $step_active }
          { key: "content_tagging", value: $content_tagging }
        ]
      }
    ) {
      brand {
        id
        cod
        historyNotes
        codBasePrice
        privateMetadata
        metadata
        brandName
        brandSource
        brandBarter
        brandOrderInfo
        active
        botd
        zaamoCommission
        commission
        status
        shortDescription
        shippingReturnPolicy
        image {
          url
        }
        orderProcessingDays
        orderShippingDays
        brandBarterGuidelines
        tooManyOrders
        importance
        brandStaffMembers {
          id
          email
        }
        brandContactNumber
        brandContactName
        updatedBy {
          email
        }
        brandEmails(first: 10) {
          edges {
            node {
              brandEmail
              id
              state
            }
          }
        }
        mobiles {
          id
          userName
          mobileNo
        }
        pickupAddress {
          firstName
          lastName
          email
          streetAddress1
          city
          countryArea
          phone
          postalCode
        }
        brandShipping(first: 10) {
          edges {
            node {
              id
              homeStatePincode
              shippingCostSameState {
                amount
              }
              shippingCostOtherState {
                amount
              }
              minOrderValueFreeCost {
                amount
              }
            }
          }
        }
      }
    }
  }
`;
