import { gql } from "@apollo/client";

export const UPDATE_DATE = gql`
  mutation UpdateDate($id: ID!, $date: String!) {
    influencerUpdate(input: { id: $id, metadata: [{ key: "date-of-birth", value: $date }] }) {
      user {
        id
        influencer {
          id
          name
        }
      }
    }
  }
`;
