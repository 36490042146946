import { gql } from "@apollo/client";

export const CREATE_UPDATE_BOTD = gql`
  mutation CreateUpdateBotd($store: ID!, $brand: ID!, $bioText: String) {
    brandOfTheDayCreateOrUpdate(
      input: { store: $store, brand: $brand, bioText: $bioText }
    ) {
      brandOfTheDay {
        brand {
          brandName
        }
        voucherCode
        voucherName

        bioText
      }
    }
  }
`;
