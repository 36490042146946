import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import { toast } from "react-toastify";
import Button from "../../components/Button";
import Dropdown from "../../components/Dropdown";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import PacmanLoader from "../../components/PackmanLoader";
import { GET_USER_DATA } from "../MasterDashboard/queries";
import { CREATE_UPDATE_BOTD } from "./mutations";
import * as S from "./styles";

const BotdDashboard = () => {
  const [selectedValue, setSelectedValue] = useState({
    store: null,
    brand: null,
    bioText: "",
  });

  const { data, loading, error } = useQuery(GET_USER_DATA, {
    variables: {
      mobileNo: localStorage.getItem("analytics_mobileNo"),
    },
  });

  const [botdMutation, { error: botdError }] = useMutation(CREATE_UPDATE_BOTD);

  const onChange = (e, type) => {
    return setSelectedValue((prev) => ({ ...prev, [type]: e }));
  };

  const handleBotd = async () => {
    try {
      if (selectedValue.brand && selectedValue.store && selectedValue.bioText) {
        const res = await botdMutation({
          variables: {
            store: selectedValue.store?.value,
            brand: selectedValue.brand?.value,
            bioText: selectedValue.bioText,
          },
        });
        if (res.data?.brandOfTheDayCreateOrUpdate)
          return toast.success("BOTD Saved!");
      } else toast.warning("Fields Empty!");
    } catch (error) {
      return console.log(error);
    }
  };

  if (loading) return <PacmanLoader />;
  if (error || botdError) return <ErrorPage />;

  return (
    <S.Wrapper>
      <S.Flex>
        <div style={{ display: "grid" }}>
          <S.Label>Select Brand</S.Label>
          <Dropdown
            style={{ width: "300px" }}
            data={data?.userByMobile?.authorisedBrands}
            value="id"
            label="brandName"
            onChange={(e) => onChange(e, "brand")}
            placeholder="Select Brand"
            defaultValue={selectedValue.brand}
          />
        </div>
        <div style={{ display: "grid" }}>
          <S.Label>Select Store</S.Label>
          <Dropdown
            style={{ width: "300px" }}
            data={data?.userByMobile?.authorisedStores}
            value="id"
            label="storeName"
            onChange={(e) => onChange(e, "store")}
            placeholder="Select Stores"
            defaultValue={selectedValue.store}
          />
        </div>
      </S.Flex>
      <div style={{ margin: "25px 0px 5px", display: "grid" }}>
        <S.Label>Bio</S.Label>
        <S.TextField onChange={(e) => onChange(e.target.value, "bioText")} />
      </div>
      <Button onClick={handleBotd} style={{ margin: 0, width: "620px" }}>
        Save
      </Button>
    </S.Wrapper>
  );
};

export default BotdDashboard;
