import React, { useEffect, useRef, useState } from "react";
import * as S from "./styles";
import "react-toggle/style.css";
import Toggle from "react-toggle";
import moment from "moment";
import { useMutation } from "@apollo/client";
import {
  CHANGE_VISIBILITY,
  DELETE_IMAGE,
  TOGGLE_BARTER,
  UPDATE_CATEGORY,
  UPDATE_COMMISSION,
  UPDATE_VALUE_DEAL,
} from "./mutations";
import Modal from "react-modal";
import AddContentModal from "./AddContentModal";
import { ReactSVG } from "react-svg";
import EditInventory from "./EditInventory";
import Dropdown from "../../components/Dropdown";
import { toast } from "react-toastify";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import EditTagsModal from "./EditTagsMpdal";
import EditProduct from "./EditProduct";

const ProductCard = ({ product, brand, categories, mediaCount }) => {
  const [isPublished, setIsPublished] = useState(product.isPublished);
  const [barter, setBarter] = useState({
    label: product?.brandBarter === "false" ? "NO_BARTER" : product?.brandBarter,
    value: product?.brandBarter === "false" ? "NO_BARTER" : product?.brandBarter,
  });
  const [valueDeal, setValueDeal] = useState(product.valueDeal);
  const [modalOpen, setModalOpen] = useState(false);
  const [loadingPublish, setLoadingPublish] = useState(false);
  const [modalType, setModalType] = useState("");
  const [showEdit, setShowEdit] = useState(false);
  //showing the modal for updating the inventory
  const [showModal, setShowModal] = useState(false);
  //storing the category for visibility mutation
  const [category, setCategory] = useState(null);
  //storing the commission for commission mutation
  const [commission, setCommission] = useState(null);
  const categoryRef = useRef(null);
  //state for opening tags modal
  const [openTag, setOpenTag] = useState(false);

  const [changeVisibility, { error }] = useMutation(CHANGE_VISIBILITY);

  const [updateCategory, { error: categoryError }] = useMutation(UPDATE_CATEGORY);

  const [updateCommission, { error: commissionError }] = useMutation(UPDATE_COMMISSION);

  const [toggleBarter, { error: barterError }] = useMutation(TOGGLE_BARTER);

  const [toggleValueDeal, { error: valueError }] = useMutation(UPDATE_VALUE_DEAL);

  useEffect(() => {
    if (product) {
      setIsPublished(product.isPublished)
    }
  }, [product])

  //checking if all the zaamoVariants have trackInventory as false
  const checkTrackInventory = () => {
    let flag = false;
    product?.zaamoVariants.forEach((element) => {
      if (element?.trackInventory) flag = true;
    });
    return flag;
  };

  const handleChangeVisibility = async (e) => {
    const categoryOfProduct = categoryRef.current.props.value
      ? categoryRef.current.props.value.label
      : categoryRef.current.props.placeholder;
    const categories = [
      "shopify_uncategorized",
      "woocommerce_uncategorized",
      "acha_india_uncategorized",
    ];
    if (categories.includes(categoryOfProduct) && e.target.checked) {
      return toast.error("Please change category in order to publish!");
    }
    setIsPublished(e.target.checked);
    setLoadingPublish(true);
    //variables for mutation
    const values = {
      productIdBrand: product.productIdBrand,
      variantIdBrands: product.variantIdBrands,
      brandId: brand,
      isPublished: e.target.checked,
      categoryId: category,
      commission: commission,
      valueDeal: valueDeal,
    };
    //checking for null values in the variables and removing the key value pair
    const nonEmptyValues = Object.fromEntries(Object.entries(values).filter(([_, v]) => v != null));
    try {
      const res = await changeVisibility({
        variables: nonEmptyValues,
      });
      if (!res.data?.pdpPublishProduct.success) {
        toast.error(res.data?.pdpPublishProduct.message);
        setIsPublished(!e.target.checked);
      }
      //   if (data?.pdpPublishProduct?.success) {
      //     setLoadingPublish(false);
      //     if (product?.dataSource === "mongo") {
      //       setLoadingPublish(true);
      //       setTimeout(() => {
      //         client.refetchQueries({
      //           include: [GET_FILTERED_PRODUCTS],
      //         });
      //         setLoadingPublish(false);
      //       }, 2000);
      //     }
      //   }
    } catch (err) {
      setIsPublished(!e.target.checked);
    }
  };

  const handleChangeCategory = async (e) => {
    setCategory(e.value);
    if (product?.dataSource === "postgres") {
      updateCategory({
        variables: {
          productId: product?.productZaamoId,
          brandId: product?.brandId,
          category: e.value,
        },
      });
      toast.success("Product Category Update Successfully!");
    }
  };

  const handleCommission = async (e) => {
    setCommission(e.value);
    if (product?.dataSource === "postgres") {
      updateCommission({
        variables: {
          productId: product?.productZaamoId,
          brandId: product?.brandId,
          commission: e.value,
        },
      });
      toast.success("Commission Updated Successfully!");
    }
  };

  const handleToggleBarter = async (e) => {
    try {
      toggleBarter({
        variables: {
          productId: product?.productZaamoId,
          brandId: product?.brandId,
          barter: e.value,
        },
      });
      toast.success("Barter Updated Successfully!");
      setBarter(e);
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleValueDeal = async (e) => {
    try {
      setValueDeal(e.target.checked);
      if (product?.dataSource === "postgres") {
        toggleValueDeal({
          variables: {
            productId: product?.productZaamoId,
            brandId: product?.brandId,
            valueDeal: e.target.checked,
          },
        });
        toast.success("Value Deal Updated Successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (error || commissionError || categoryError || barterError || valueError) {
    return <ErrorPage />;
  }

  return (
    <>
      <S.ProductCard>
        <>
          <S.ProductTop>
            {product?.dataSource === "postgres" ? (
              <a
                target="_blank"
                rel="noreferrer"
                href={
                  process.env.REACT_APP_GRAPHQL_BASE_URL?.includes("beta.zaamo.co")
                    ? `https://betahome.zaamo.co/products/${product?.slug}`
                    : `https://home.zaamo.co/products/${product?.slug}`
                }
              >
                <S.ImageCont>
                  <S.Image src={product?.images[0]} />
                </S.ImageCont>
              </a>
            ) : (
              <S.ImageCont>
                <S.Image src={product?.images[0]} />
                {/* <S.Quantity>5</S.Quantity> */}
              </S.ImageCont>
            )}
            <S.TopDetails>
              <S.ProductTop>
                <S.Title style={{ width: "100%", display: "flex", alignItems: "center" }}>
                  {product?.dataSource === "postgres" ? (
                    <a
                      href={
                        process.env.REACT_APP_GRAPHQL_BASE_URL?.includes("beta.zaamo.co")
                          ? `https://beta.zaamo.co/admin/product/product/${atob(
                              product.productZaamoId
                            ).replace("Product:", "")}/change/`
                          : `https://production.zaamo.co/admin/product/product/${atob(
                              product.productZaamoId
                            ).replace("Product:", "")}/change/`
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {product?.name}
                      <ReactSVG
                        wrapper="span"
                        style={{ padding: "5px" }}
                        src="assets/images/postgres.svg"
                      />
                    </a>
                  ) : (
                    <>
                      {product?.name}
                      <ReactSVG
                        wrapper="span"
                        style={{ padding: "5px" }}
                        src="assets/images/mongo.svg"
                      />
                    </>
                  )}
                  <div onClick={() => setShowEdit(true)}>
                    <ReactSVG src="/assets/images/edit.svg" />
                  </div>
                </S.Title>
              </S.ProductTop>
              <S.Variants>
                {product?.dataSource === "postgres"
                  ? product?.variants.map((variant, index) => (
                      <S.Variant key={index}>
                        {variant.name} ({product?.zaamoVariants[index]?.quantityAvailable})
                      </S.Variant>
                    ))
                  : product?.variants.map((variant, index) => (
                      <S.Variant key={index}>
                        {variant.name} ({variant?.brandStock})
                      </S.Variant>
                    ))}
                {product?.dataSource === "postgres" && checkTrackInventory() ? (
                  <div onClick={() => setShowModal(true)}>
                    <ReactSVG style={{ padding: "1px" }} src="/assets/images/edit.svg" />
                  </div>
                ) : null}
              </S.Variants>
              <S.ProductTop style={{ margin: "10px 0px" }}>
                <Dropdown
                  style={{ marginLeft: "auto", width: "50%" }}
                  value="value"
                  selectRef={categoryRef}
                  data={categories?.categories?.edges.map((val) => {
                    return { label: val?.node?.name, value: val?.node?.id };
                  })}
                  label="label"
                  placeholder={product?.category?.name}
                  onChange={handleChangeCategory}
                />
                <Dropdown
                  value="value"
                  label="label"
                  placeholder={product?.commission}
                  data={new Array(100).fill().map((_, index) => {
                    return {
                      label: (index + 1).toFixed(1),
                      value: (index + 1).toFixed(1),
                    };
                  })}
                  style={{ width: "50%", marginLeft: "15px" }}
                  onChange={handleCommission}
                />
              </S.ProductTop>
              <S.StatisticDetails>
                <S.Detail>
                  <S.Value>{product?.weeklyVisits}</S.Value>
                  <S.DetailKey>Weekly Visits</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.Value>{product?.wishlistCount}</S.Value>
                  <S.DetailKey>Wishlist</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.Value>{product?.orderCount}</S.Value>
                  <S.DetailKey>Orders</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.Value>{product?.storeCount}</S.Value>
                  <S.DetailKey>No. Of stores with this product</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.Value>{product?.totalStoreViews}</S.Value>
                  <S.DetailKey>Pdp Views</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.SmallerValue>
                    {moment(
                      product?.dataSource === "mongo"
                        ? product?.brandProductCreationDate
                        : product?.publicationDate
                    ).format("Do MMM")}
                  </S.SmallerValue>
                  <S.DetailKey>Date since Live</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.SmallerValue>
                    {product?.dataSource === "mongo"
                      ? product?.brandProductMrp
                      : product?.zaamoVariants[0]?.costPrice?.amount}
                  </S.SmallerValue>
                  <S.DetailKey>MRP</S.DetailKey>
                </S.Detail>
                <S.Detail>
                  <S.SmallerValue>
                    {product?.dataSource === "mongo"
                      ? product?.brandProductMsp
                      : product?.zaamoVariants[0]?.price?.amount}
                  </S.SmallerValue>
                  <S.DetailKey>MSP</S.DetailKey>
                </S.Detail>
              </S.StatisticDetails>
            </S.TopDetails>
          </S.ProductTop>

          <h6 style={{ marginLeft: "50px" }}>
            {mediaCount[product?.productZaamoId]
              ? `${mediaCount[product?.productZaamoId]?.images + product?.images?.length}/${
                  mediaCount[product?.productZaamoId]?.videos
                }`
              : `${product?.images?.length}/0`}
          </h6>
          <S.ProductMiddle>
            <S.AddContent
              onClick={() => {
                setModalOpen(true);
                setModalType("add");
              }}
            >
              {" "}
              + Edit Content
            </S.AddContent>
            <S.MiddleContent>
              <S.SwitchWrapper>
                <Dropdown
                  style={{ width: "150px" }}
                  value="value"
                  label="label"
                  data={barterOps}
                  onChange={handleToggleBarter}
                  defaultValue={barter}
                />
              </S.SwitchWrapper>
              <S.SwitchWrapper>
                <Toggle
                  id="switch"
                  icons={false}
                  checked={isPublished}
                  onChange={handleChangeVisibility}
                />
                <label htmlFor="switch">Visibility</label>
              </S.SwitchWrapper>

              <S.SwitchWrapper>
                <Toggle
                  id="switch"
                  icons={false}
                  checked={valueDeal}
                  onChange={handleToggleValueDeal}
                />
                <label htmlFor="switch">Value Deal</label>
              </S.SwitchWrapper>

              {/* <S.Button onClick={() => setOpenTag(!openTag)}>Tags</S.Button> */}
              {/* <S.Search placeholder="Search tags" /> */}
            </S.MiddleContent>
          </S.ProductMiddle>
          {/* <S.ProductBottom> */}
          {/* <S.RemoveContent
                  onClick={() => {
                    setModalOpen(true);
                    setModalType("remove");
                  }}
                >
                  remove Content
                </S.RemoveContent> */}
          {/* </S.ProductBottom> */}
        </>
      </S.ProductCard>
      <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} style={customStyles}>
        <AddContentModal
          from={modalType}
          isPostgres={product?.dataSource === "postgres" ? true : false}
          productImagesFromCard={product?.images}
          productData={{
            id: product.productZaamoId,
            brand_id: product.brandId,
            slug: product.slug,
            zaamoVariants: product.zaamoVariants,
          }}
        />
      </Modal>
      <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} style={S.Modal}>
        <EditInventory
          setShowModal={setShowModal}
          variants={product?.variants}
          zaamoVariants={product?.zaamoVariants}
        />
      </Modal>
      <Modal isOpen={openTag} onRequestClose={() => setOpenTag(false)} style={S.Modal}>
        <EditTagsModal close={() => setOpenTag(false)} productId={product.productZaamoId} />
      </Modal>
      <Modal isOpen={showEdit} onRequestClose={() => setShowEdit(false)} style={S.Modal}>
        <EditProduct close={() => setShowEdit(false)} product={product} />
      </Modal>
    </>
  );
};

const barterOps = [
  { label: "ACTIVE_BARTER", value: "ACTIVE_BARTER" },
  { label: "ACTIVE_ONLY_BARTER", value: "ACTIVE_ONLY_BARTER" },
  { label: "NO_BARTER", value: "NO_BARTER" },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "95%",
    height: "70vh",
    maxHeight: "70vh",
    maxWidth: "500px",
  },
};

export default ProductCard;
