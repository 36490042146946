import { useState } from "react";
import Dropdown from "../../../../components/Dropdown";
import Search from "../../../../screens/CustomerSupport/Search";
import * as S from "./styles";

const Filters = (props) => {
  const [search, setSearch] = useState("");
  const [productName, setProductName] = useState("");

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Select Timeperiod</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={timePeriodOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "TIME")}
            placeholder="Select Time.."
            defaultValue={props.defaultValue.time}
            isClearable
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "DIR")}
            placeholder="Direction"
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "SORT")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.sortBy}
          />
        </div>
        <div>
          <label>Status</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={statusOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "STATUS")}
            placeholder="Status"
            defaultValue={props.defaultValue.status}
            isMulti
            isClearable
          />
        </div>
        <div>
          <label>Barter Order</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={barterOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "VOUCHER")}
            placeholder="Status"
            defaultValue={props.defaultValue.voucher}
            isClearable
          />
        </div>
        <div>
          <label>Search by Email or Phone</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.Input value={search} onChange={(e) => setSearch(e.target.value)} />
            <div
              onClick={(e) => props.onChange(search, "SEARCH")}
              style={{ position: "absolute", right: "15px", cursor: "pointer" }}
            >
              <Search />
            </div>
          </div>
        </div>
        <div>
          <label>Search by Product Name</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.Input value={productName} onChange={(e) => setProductName(e.target.value)} />
            <div
              onClick={(e) => props.onChange(productName, "PRODUCT")}
              style={{ position: "absolute", right: "15px", cursor: "pointer" }}
            >
              <Search />
            </div>
          </div>
        </div>
        <span
          className="clear"
          onClick={() => {
            props.clearAll();
            setSearch("");
            setProductName("");
          }}
        >
          Clear All
        </span>
      </S.Flex>
    </S.Div>
  );
};

const statusOptions = [
  { value: "PLACED", label: "Placed" },
  { value: "IN_PROCESS", label: "In Process" },
  { value: "SHIPPED", label: "Shipped" },
  { value: "DELIVERED", label: "Delivered" },
  { value: "CANCELLATION_INITIATED", label: "Cancellation Initiated" },
  { value: "CANCELLATION_PROCESSED", label: "Cancellation Processed" },
  { value: "RETURN_REQUESTED", label: "Return Requested" },
  { value: "RETURN_INITIATED", label: "Return Initiated" },
  { value: "RETURN_COMPLETED", label: "Return Completed" },
  { value: "FULFILLED", label: "Fulfilled" },
];

const barterOptions = [{ label: "Yes", value: "SZ" }];

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

const sortOptions = [
  {
    label: "Number",
    value: "NUMBER",
  },
  {
    label: "Creation Date",
    value: "CREATION_DATE",
  },
  {
    label: "Customer",
    value: "CUSTOMER",
  },
  {
    label: "Payment",
    value: "PAYMENT",
  },
  {
    label: "Fulfillment Status",
    value: "FULFILLMENT_STATUS",
  },
  {
    label: "Total",
    value: "TOTAL",
  },
];

const timePeriodOptions = [
  {
    value: "LASTHOUR",
    label: "LAST HOUR",
  },
  {
    value: "TODAY",
    label: "TODAY",
  },
  {
    value: "YESTERDAY",
    label: "YESTERDAY",
  },
  {
    value: "TILLTODAYTHISWEEK",
    label: "TILL TODAY THIS WEEK",
  },
  {
    value: "LASTWEEK",
    label: "LAST WEEK",
  },
  {
    value: "TILLTODAYTHISMONTH",
    label: "TILL TODAY THIS MONTH",
  },
  {
    value: "LASTMONTH",
    label: "LAST MONTH",
  },
  {
    value: "OVERALL",
    label: "OVERALL",
  },
];

export default Filters;
