import styled, { keyframes } from "styled-components";

export const Wrapper = styled.div`
  padding: 20px 10px;
  .clear {
    font-size: 0.9rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    margin-left: 15px;
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px;
`;

const spin = keyframes`
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
`;

export const Spinner = styled.div`
  border: 5px solid #000;
  border-top: 5px solid #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: ${spin} 1s linear infinite;
`;

export const Container = styled.div`
  background: #f1f1f1;
  margin: 30px 0;
`;

export const Grid = styled.div`
  display: flex;
  /* grid-template-columns: 1fr 1fr 1fr 1fr 1fr; */
  grid-gap: 10px;
  padding: 10px 0;
  flex-wrap: wrap;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  width: 100%;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: 18%;
  display: flex;
  flex-direction: column;
  aling-items: center;
  jusify-content: center;
  padding: 0;
  background: #fff;
  border: hidden;
  border-radius: 5px;
  border: 1px solid #f1f1f1;
  -webkit-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  -moz-box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  box-shadow: 0px 0px 12px 0px rgba(171, 171, 171, 1);
  p {
    margin: 0;
    font-size: 1.2rem;
    font-weight: 600;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 10px 10px;
  }
  span {
    font-size: 0.7rem;
    color: #606060;
    text-align: left;
    border-bottom: 1px solid #f0f0f0;
    padding: 5px 10px;
  }
  @media (max-width: 768px) {
    width: 30%;
  }
`;

export const Modal = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    maxWidth: "426px",
    maxHeight: "80vh",
    borderRadius: "0px",
    zIndex: 9,
    padding: "10px",
  },
  overlay: { zIndex: 9, backgroundColor: "rgb(0 0 0 / 75%)" },
};
