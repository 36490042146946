import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1rem;
  max-height: 100vh;
  min-height: 80vh;
`;

export const Label = styled.label`
  margin: 0px auto 8px 0px;
`;

export const Flex = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const TextField = styled.input`
  width: 620px;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #000;
`;
