import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import { GET_DASHBOARD_BRANDS } from "../queries";
import Button from "../../../components/Button";
import { CREATE_SHIPPING, DELETE_SHIPPING, UPDATE_SHIPPING } from "./mutations";
import * as S from "./styles";

export default function ShippingItem({ id, shippingValue, close }) {
  const [shipping, setShipping] = useState({
    ...shippingValue,
    shippingCostSameStateAmount: shippingValue?.shippingCostSameState.amount ?? 0,
    shippingCostOtherStateAmount: shippingValue?.shippingCostOtherState.amount ?? 0,
    minOrderValueFreeCostAmount: shippingValue?.minOrderValueFreeCost.amount ?? 0,
  });
  const [createShipping, { error }] = useMutation(CREATE_SHIPPING);
  const [updateShipping, { error: updErr }] = useMutation(UPDATE_SHIPPING);
  const [deleteShipping, { error: delErr }] = useMutation(DELETE_SHIPPING);

  const onChange = (e) => {
    setShipping((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const refetch = () => {
    client.refetchQueries({
      include: [GET_DASHBOARD_BRANDS],
    });
  };

  const handleCreate = async () => {
    if (
      shipping.homeStatePincode.length === 0 ||
      shipping.shippingCostSameStateAmount.length === 0 ||
      shipping.shippingCostOtherStateAmount.length === 0 ||
      shipping.minOrderValueFreeCostAmount.length === 0
    )
      return toast.error("Fill in the fields!");
    const res = await createShipping({ variables: { ...shipping, brandId: id } });
    if (res.data?.brandShippingDataCreate) {
      toast.success("Shipping Data Created!");
      close();
      refetch();
    }
  };

  const handleUpdate = async () => {
    if (
      shipping.homeStatePincode.length === 0 ||
      shipping.shippingCostSameStateAmount.length === 0 ||
      shipping.shippingCostOtherStateAmount.length === 0 ||
      shipping.minOrderValueFreeCostAmount.length === 0
    )
      return toast.error("Fill all the fields!");
    const res = await updateShipping({ variables: { ...shipping } });
    if (res.data?.brandShippingDataUpdate) {
      toast.success("Shipping Data Updated!");
      close();
      refetch();
    }
  };

  const handleDelete = async () => {
    const res = await deleteShipping({ variables: { id: shipping.id } });
    if (res.data?.brandShippingDataDelete) {
      toast.success("Shipping Data Deleted!");
      close();
      refetch();
    }
  };

  useEffect(() => {
    if (error?.message || updErr?.message || delErr?.message)
      return toast.error("Something went wrong!");
  }, [error, updErr, delErr]);

  return (
    <div style={{ fontSize: "0.8rem" }}>
      <S.TextLabel>Home State Pincode</S.TextLabel>
      <S.TextField name="homeStatePincode" onChange={onChange} value={shipping?.homeStatePincode} />
      <S.TextLabel>Shipping Cost - Same State</S.TextLabel>
      <S.TextField
        type="number"
        name="shippingCostSameStateAmount"
        onChange={onChange}
        value={shipping?.shippingCostSameStateAmount}
      />
      <S.TextLabel>Shipping Cost - Other State</S.TextLabel>
      <S.TextField
        type="number"
        name="shippingCostOtherStateAmount"
        onChange={onChange}
        value={shipping?.shippingCostOtherStateAmount}
      />
      <S.TextLabel>Min Order Value Free Cost</S.TextLabel>
      <S.TextField
        type="number"
        name="minOrderValueFreeCostAmount"
        onChange={onChange}
        value={shipping?.minOrderValueFreeCostAmount}
      />
      <S.Flex>
        {shippingValue ? (
          <Button onClick={handleUpdate} style={{ width: "49%" }}>
            Update
          </Button>
        ) : (
          <Button onClick={handleCreate}>Create</Button>
        )}
        {shippingValue ? (
          <Button onClick={handleDelete} style={{ width: "49%" }}>
            Delete
          </Button>
        ) : null}
      </S.Flex>
    </div>
  );
}
