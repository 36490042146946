import { gql } from "@apollo/client";

export const UPDATE_STORE_MANAGER = gql`
  mutation UpdateStoreManager(
    $storeId: ID!
    $status: StoreStatusEnum!
    $nextActions: StoreNextActionsEnum!
  ) {
    storeManagerActionUpdate(
      input: { id: $storeId, status: $status, nextActions: $nextActions }
    ) {
      storeManagerAction {
        id
        store {
          storeName
        }
        status
        nextActions
      }
    }
  }
`;
