import { useMutation, useReactiveVar } from "@apollo/client";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";
import { authVars } from "../../reactiveVariables";
import { GENERATE_OTP, TOKEN_CREATE, VALIDATE_OTP } from "./mutations";

const useAuthVars = () => {
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [invalidOtp, setInvalidOtp] = useState(false);
  const [isOtpGeneratedSuccessfully, setIsOtpGeneratedSuccessfully] = useState(false);

  const [generateOtp, { data, loading, error }] = useMutation(GENERATE_OTP);
  const [verifyOtp, { data: data2, loading: loading2, error: error2 }] = useMutation(VALIDATE_OTP);
  const [tokenCreate, { data: data3, loading: loading3, error: error3 }] =
    useMutation(TOKEN_CREATE);

  const handleChange = (e) => {
    setPhone(e?.target?.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const data = await generateOtp({
      variables: { mobileNo: "91" + phone },
    });

    if (data.data.generateOtp.success != null) {
      console.log(data);
      setIsOtpGeneratedSuccessfully(true);
    } else {
      setIsOtpGeneratedSuccessfully(false);
    }
  };

  const regenerateOtp = () => async () => {
    await generateOtp({
      variables: { mobileNo: "91" + phone },
    });
    console.log("Otp had been regenerated");
  };

  const handleOtpSubmit = async (e) => {
    e.preventDefault();

    const value = await verifyOtp({
      variables: { mobileNo: "91" + phone, otp },
    });

    const isOtpValid = value?.data?.verifyOtp?.success;
    // const isOtpValid = true;

    if (isOtpValid) {
      const { data: tokenCreateData } = await tokenCreate({
        variables: {
          mobileNo: "91" + phone,
        },
      });
      console.log(tokenCreateData);
      const token = tokenCreateData.tokenCreate.token;
      const refreshToken = tokenCreateData.tokenCreate.refreshToken;
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("analytics_mobileNo", "91" + phone);

      if (token) {
        console.log("token generated successfully", token);
        authVars({
          isAuth: true,
        });
        navigate("/brand/management");
      } else {
        toast("User with this Mobile Number is not registered!");
      }
    } else {
      setInvalidOtp(true);
      toast("Invaid OTP!");
    }
  };

  return {
    phone,
    setPhone,
    otp,
    setOtp,
    invalidOtp,
    setInvalidOtp,
    isOtpGeneratedSuccessfully,
    setIsOtpGeneratedSuccessfully,
    tokenCreate,
    generateOtp: [generateOtp, { data, loading, error }],
    verifyOtp: [verifyOtp, { data2, loading2, error2 }],
    handleChange,
    handleSubmit,
    regenerateOtp,
    handleOtpSubmit,
    loading: loading || loading2,
    error: error || error2,
  };
};

export default useAuthVars;
