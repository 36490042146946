import { useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Dropdown from "../../components/Dropdown";
import { DELETE_IMAGE } from "./mutations";
import { GET_PRODUCT_IMAGES } from "./queries";
import * as S from "./styles";
import { usePdp2 } from "./usePdp2";
import * as utils from "./utils";

const CONTENT_TYPE_VIDEOS = [
  "REEL",
  "IG_VIDEO",
  "IG_TV_VIDEO",
  "STORY",
  "INFLUENCER_VERTICAL_IMAGE",
  "INFLUENCER_SQUARE_IMAGE",
];

const AddContentModal = ({ from, productData, isPostgres, productImagesFromCard }) => {
  const {
    selectedImages,
    setSelectedImages,
    selectedVideos,
    setSelectedVideos,
    imagesLoading,
    setImagesLoading,
    videosLoading,
    setVideosLoading,
    videoError,
    setVideoError,
    imageError,
    setImageError,
    handleCoverUpload,
    handleSelectImages,
    handleSelectVideos,
    addImageRef,
    contentType,
    setContentType,
    getTaggedData,
    taggedContent,
    setTaggedContent,
  } = usePdp2();

  useEffect(() => {
    getTaggedData(productData);
  }, []);

  const {
    data: productImages,
    loading: productImagesLoading,
    error: productImagesError,
  } = useQuery(GET_PRODUCT_IMAGES, {
    variables: {
      slug: productData?.slug,
    },
    skip: !productData?.slug,
  });

  const [deleteImg] = useMutation(DELETE_IMAGE, {
    refetchQueries: [GET_PRODUCT_IMAGES],
  });

  const onSubmit = async (data) => {
    if (!(selectedImages.length > 0)) {
      setImageError(true);
      return;
    }
    // let data = [];
    // selectedImages.map((image) => {
    //   productData.zaamoVariants.map((variant) => {
    //     data.push({
    //       media_url: image,
    //       product_id: productData.id,
    //       sku_id: variant.id,
    //       content_type: contentType.value,
    //     });
    //   });
    // });
    // console.log(data, "data")
    // const resp = await utils.tagContent({
    //   zaamo_id: productData.brand_id,
    //   data: data,
    // });
  };

  const handleRemoveContent = async ({ index, content_id, product_id, sku_id }) => {
    let temp = [...taggedContent];
    temp.splice(index, 1);
    setTaggedContent(temp);
    const resp = await utils.untagContent({
      content_id,
      product_id,
      sku_id,
    });
    console.log(resp, "untag content");
    if (resp) {
      toast.success("Untagged content successfully");
    }
  };

  const handleDelete = async (id) => {
    try {
      const res = await deleteImg({
        variables: {
          id,
        },
      });
      if (res.data?.productImageDelete?.product?.id) toast.success("Image Deleted!");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <S.ModalContent>
      {!isPostgres ? (
        <S.Text
          style={{
            margin: "10px 0 10px 5px",
          }}
        >
          Please publish this product first to add or remove content.
        </S.Text>
      ) : (
        <S.Text style={{ marginBottom: "10px" }}>
          {from === "add" ? "Add Content" : "Remove Content"}
        </S.Text>
      )}
      {from === "add" && isPostgres && (
        <S.Flex style={{ margin: "0 0 10px 0" }}>
          <Dropdown
            style={{ width: "100%", padding: "2px 0" }}
            data={[...CONTENT_TYPES]}
            label="name"
            value="id"
            onChange={setContentType}
            placeholder="Select Content type"
          />
        </S.Flex>
      )}
      <S.Flex>
        {!isPostgres &&
          productImagesFromCard.map((image, index) => (
            <S.ContentImage key={index}>
              <img src={image} />
            </S.ContentImage>
          ))}
        {taggedContent.map((content, index) => (
          <S.ContentImage key={index}>
            {isPostgres && (
              <div
                className="cross"
                onClick={() =>
                  handleRemoveContent({
                    index: index,
                    content_id: content.content_id,
                    product_id: content.product_id,
                    sku_id: content.sku_id,
                  })
                }
              >
                <img src="/assets/images/close.svg" />
              </div>
            )}
            {content.media_type === "VIDEO" ? (
              <video autoPlay muted loop>
                <source src={content.media_url.replace("m3u8", "mp4")} type="video/mp4" />
              </video>
            ) : (
              <img src={content.media_url} />
            )}
          </S.ContentImage>
        ))}
        {selectedImages.map((image, index) => (
          <S.ContentImage key={index}>
            {isPostgres && (
              <div
                className="cross"
                onClick={() => {
                  let temp = [...selectedImages];
                  temp.splice(index, 1);
                  setSelectedImages(temp);
                }}
              >
                <img src="/assets/images/close.svg" />
              </div>
            )}
            <img src={image} />
          </S.ContentImage>
        ))}
        {selectedVideos.map((video, index) => (
          <S.ContentImage key={index}>
            {isPostgres && (
              <div
                className="cross"
                onClick={() => {
                  let temp = [...selectedVideos];
                  temp.splice(index, 1);
                  setSelectedVideos(temp);
                }}
              >
                <img src="/assets/images/close.svg" />
              </div>
            )}
            <video autoPlay muted loop>
              <source src={video.replace("m3u8", "mp4")} type="video/mp4" />
            </video>
          </S.ContentImage>
        ))}
        {from === "add" && isPostgres && (
          <S.ContentImage
            onClick={() => {
              contentType?.value
                ? addImageRef.current.click()
                : toast.warn("Please select a content type first!");
            }}
          >
            <img src="/assets/images/upload.svg" />
          </S.ContentImage>
        )}
      </S.Flex>
      {isPostgres && (
        <S.Text
          style={{
            margin: "10px 0 10px 5px",
          }}
        >
          Product Images coming from Brand Side
        </S.Text>
      )}
      <S.Flex>
        {productImages?.product?.images?.map((image, index) => (
          <S.ContentImage key={index}>
            <div className="cross" onClick={() => handleDelete(image?.id)}>
              <img src="/assets/images/close.svg" />
            </div>
            <img src={image?.url} />
          </S.ContentImage>
        ))}
      </S.Flex>
      <S.InputFile
        ref={addImageRef}
        type="file"
        multiple
        onChange={(e) => {
          let images = [];
          let videos = [];
          Array.from(e.target.files).map((file) => {
            if (file.type.includes("image")) {
              images.push(file);
            } else if (file.type.includes("video")) {
              videos.push(file);
            }
          });
          handleSelectImages(images, productData);
          handleSelectVideos(videos, productData);
        }}
      />
    </S.ModalContent>
  );
};

const CONTENT_TYPES = [
  {
    id: "SEARCH_IMAGE",
    name: "Search Image",
  },
  {
    id: "LEFT_IMAGE",
    name: "Left Image",
  },
  {
    id: "RIGHT_IMAGE",
    name: "Right Image",
  },
  {
    id: "FRONT_IMAGE",
    name: "Front Image",
  },
  {
    id: "BACK_IMAGE",
    name: "Back Image",
  },
  {
    id: "INFLUENCER_SQUARE_IMAGE",
    name: "Influencer Square Image",
  },
  {
    id: "INFLUENCER_VERTICAL_IMAGE",
    name: "Influencer Vertical Image",
  },
  {
    id: "REEL",
    name: "Reel",
  },
  {
    id: "IG_VIDEO",
    name: "Ig Video",
  },
  {
    id: "IG_TV_VIDEO",
    name: "Ig Tv Video",
  },
  {
    id: "STORY",
    name: "Story",
  },
  {
    id: "SIZE_CHART",
    name: "Size Chart",
  },
];

export default AddContentModal;
