import { gql } from "@apollo/client";

export const UPDATE_STATUS = gql`
  mutation influencerStatus ($status: InfluencerStatusEnum!, $id: ID!){
    influencerUpdate (input : {
      id: $id, status: $status
    }) {
      user {
        id
        influencer {
          id
          status
        }
      }
    }
  }
`