import * as S from "./styles";

const Transaction = ({ mode, amount, date, transactionDetails }) => {
  //converting date string to object
  let dateString = new Date(date).toDateString();
  //date string without the year
  dateString = dateString.substring(0, dateString.length - 5);

  return (
    <>
      <S.Transaction>
        <div>
          <S.TData>{mode}</S.TData>
          <S.TData>UTR Number</S.TData>
          <S.TData>{transactionDetails}</S.TData>
        </div>
        <div style={{ marginLeft: "auto" }}>
          <S.TCost>&#8377; {amount}</S.TCost>
          <br />
          <S.TDate>{dateString}</S.TDate>
        </div>
      </S.Transaction>
      <hr />
    </>
  );
};

export default Transaction;
