import * as S from "./styles";

export const Details = (props) => {
  console.log(props, "detaisl props");
  return (
    <S.OrderDetails>
      <S.ListItem>
        <label>OrderID:</label>
        <p>{props?.data?.orderIdOriginal ?? "--"}</p>
      </S.ListItem>
      <S.ListItem>
        <label>Timestamp:</label>
        <p>{props?.data?.created ?? "--"}</p>
      </S.ListItem>
      <S.ListItem>
        <label>Coupon:</label>
        <p>{props?.data?.voucher?.name ?? "--"}</p>
      </S.ListItem>
      <S.ListItem>
        <label>Customer details:</label>
        <p>
          {props?.data?.shippingAddress
            ? `${props?.data?.shippingAddress?.firstName} | ${props?.data?.shippingAddress?.streetAddress1}, ${props?.data?.shippingAddress?.city}, ${props?.data?.shippingAddress?.postalCode}, ${props?.data?.shippingAddress?.phone}`
            : "--"}
        </p>
      </S.ListItem>
      {props?.data?.fulfillments?.length && props?.data?.fulfillments[0]?.shippingFulfillment && (
        <>
          <S.ListItem>
            <label>Shipping ID:</label>
            <p>
              {props?.data?.fulfillments[0]?.shippingFulfillment?.shippingId ??
                "--"}
            </p>
          </S.ListItem>
          <S.ListItem>
            <label>Shipping Provider:</label>
            <p>
              {props?.data?.fulfillments[0]?.shippingFulfillment?.shippingProvider ?? "--"}
            </p>
          </S.ListItem>
        </>
      )}
    </S.OrderDetails>
  );
};

export default Details;
