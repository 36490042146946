import * as S from "./styles";

import Button from "../../../components/Button";
import CreateBrand from "../CreateBrand";
import { DownloadCsv } from "../DownloadCsv";
import Dropdown from "../../../components/Dropdown";
import ReactModal from "react-modal";
import Search from "../../CustomerSupport/Search";
import { useState } from "react";

const Filters = (props) => {
  const [search, setSearch] = useState("");
  const [createBrand, setCreateBrand] = useState(false);
  const [csvModal, setCsvModal] = useState(false);

  return (
    <S.Div>
      <S.Flex>
        <div>
          <label>Brand Barter</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={boolOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "barter")}
            defaultValue={props.defaultValue.barter}
            isClearable
          />
        </div>
        <div>
          <label>Too Many Orders</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={boolOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "orders")}
            defaultValue={props.defaultValue.orders}
            isClearable
          />
        </div>
        <div>
          <label>Commission</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            value="value"
            label="label"
            data={new Array(100).fill().map((_, index) => {
              return {
                label: (index + 1).toFixed(1),
                value: (index + 1).toFixed(1),
              };
            })}
            onChange={(e) => props.onChange(e, "commission")}
            isClearable
            defaultValue={props.defaultValue.commission}
          />
        </div>
        <div>
          <label>Status</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            value="value"
            label="label"
            data={statusOptions}
            onChange={(e) => props.onChange(e, "status")}
            isMulti
            isClearable
            defaultValue={props.defaultValue.status}
          />
        </div>
        <div>
          <label>Sort Direction</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            data={sortDirections}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "direction")}
            defaultValue={props.defaultValue.direction}
          />
        </div>
        <div>
          <label>Sort By</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", maxWidth: "200px" }}
            data={sortOptions}
            value="value"
            label="label"
            onChange={(e) => props.onChange(e, "field")}
            placeholder="Sort By"
            defaultValue={props.defaultValue.field}
          />
        </div>
        <div>
          <label>Importance</label>
          <Dropdown
            style={{ minWidth: "150px", margin: "5px", width: "fit-content" }}
            value="value"
            label="label"
            data={brandImportanceOptions}
            onChange={(e) => props.onChange(e, "importance")}
            isMulti
            isClearable
            defaultValue={props.defaultValue.importance}
          />
        </div>
        <div>
          <label>Search Brand</label>
          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              margin: "5px",
            }}
          >
            <S.SearchInput
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <div
              onClick={() => props.onChange(search, "search")}
              style={{ position: "absolute", right: "15px" }}
            >
              <Search />
            </div>
          </div>
        </div>
        <Button
          onClick={() => setCreateBrand(true)}
          style={{
            margin: "16px 5px 0px",
            width: "fit-content",
            padding: "4px 10px",
          }}
        >
          Create Brand
        </Button>
      </S.Flex>
      <ReactModal
        isOpen={createBrand}
        onRequestClose={() => setCreateBrand(false)}
        style={S.Modal}
      >
        <CreateBrand close={() => setCreateBrand(false)} />
      </ReactModal>
      <ReactModal
        isOpen={csvModal}
        onRequestClose={() => setCsvModal(false)}
        style={{
          ...S.Modal,
          content: { ...S.Modal.content, maxWidth: "400px" },
        }}
      >
        <DownloadCsv setModal={setCsvModal} />
      </ReactModal>
    </S.Div>
  );
};

const sortDirections = [
  {
    value: "DESC",
    label: "Desc",
  },
  {
    label: "Asc",
    value: "ASC",
  },
];

export const statusOptions = [
  { value: "ACTIVE", label: "Active" },
  { value: "INACTIVE", label: "Inactive" },
  { value: "ACTIVE_ONLY_FOR_BARTER", label: "Active Only for Barter" },
];

export const brandImportanceOptions = [
  { value: "SUPER_IMPORTANT", label: "Super Important" },
  { value: "IMPORTANT", label: "Important" },
  { value: "NICE_TO_HAVE_HIGH_SALES", label: "Nice to have high sales" },
  {
    value: "NICE_TO_HAVE_UNIQUE_CATALOG",
    label: "Nice to have unique catalogs",
  },
  { value: "SOURCING_ONLY", label: "Sourcing only" },
  { value: "OTHERS", label: "Others" },
];

const sortOptions = [
  { label: "Brand Name", value: "BRAND_NAME" },
  { label: "Created At", value: "CREATED_AT" },
  { label: "Updated At", value: "UPDATED_AT" },
  { label: "Last Product", value: "LAST_PRODUCT" },
  { label: "Recent Brand Barter", value: "RECENT_BRAND_BARTER" },
];

export const boolOptions = [
  { label: "True", value: true },
  { label: "False", value: false },
];
export const postpaytypeOptions = [
  { label: "Shipping", value: "shipping" },
  { label: "Delivered", value: "delivered" },
];

export default Filters;
