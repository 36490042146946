import * as S from "./styles";

function PageButton({ onClick, active, children }) {
  return (
    <S.Button active={active} onClick={onClick}>
      {children}
    </S.Button>
  );
}

export default PageButton;
