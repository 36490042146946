import { useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";
import Dropdown from "../../components/Dropdown";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { UPDATE_INVENTORY } from "./mutations";
import { GET_FILTERED_PRODUCTS } from "./queries";
import * as S from "./styles";

const EditInventory = ({ variants, zaamoVariants, setShowModal }) => {
  //selecting the size
  const [selectedValue, setSelectedValue] = useState("");
  //the quantity of the size selected
  const [inputValue, setInputValue] = useState("");
  //the original quantity before updation
  const [checker, setChecker] = useState("");
  //array without the variant with trackInventory as false
  const [dropwdownData, setDropwdownData] = useState([]);
  //id of the variant(size)
  const [id, setId] = useState("");
  //updating the inventory
  const [setInventory, { data, error }] = useMutation(UPDATE_INVENTORY, {
    refetchQueries: "active",
  });

  //setting the nonEmptyArray for dropdown
  useEffect(() => {
    const nonEmptyArray = variants.filter((_, index) => zaamoVariants[index]?.trackInventory);
    setDropwdownData(nonEmptyArray);
    //eslint-disable-next-line
  }, []);

  //for not allowing the e key in textInput
  useEffect(() => {
    const getKey = (e) => {
      if (e.key === "e") e.preventDefault();
    };
    document.getElementById("quantity").addEventListener("keydown", getKey);
  }, []);

  useEffect(() => {
    //if success refetch the products
    if (data?.variantInventoryUpdate?.success) {
      setShowModal(false);
      toast.success("Inventory updated successfully");
    }
    //eslint-disable-next-line
  }, [data]);

  //updating the inputValue on selectiong size
  useEffect(() => {
    if (selectedValue !== "") {
      const value = zaamoVariants.find((item) => item?.name === selectedValue);
      setChecker(value?.quantityAvailable);
      setInputValue(value?.quantityAvailable);
      setId(value?.id);
    }
  }, [selectedValue, zaamoVariants]);

  if (error) {
    return <ErrorPage />;
  }

  return (
    <div>
      <h3 style={{ marginBottom: "20px" }}>Edit Inventory</h3>
      <Dropdown
        value="value"
        data={dropwdownData.map((val) => {
          return { label: val.name, value: val.name };
        })}
        label="label"
        placeholder="Select Size"
        onChange={(e) => setSelectedValue(e.value)}
      />
      <input
        id="quantity"
        style={S.QuantityInput}
        value={inputValue}
        placeholder="Quantity"
        type="number"
        onChange={(e) => setInputValue(e.target.value === "" ? "" : parseInt(e.target.value))}
      />
      <button
        onClick={() => {
          setInventory({
            variables: {
              id: id,
              stock: inputValue,
            },
          });
        }}
        disabled={checker === inputValue}
        style={{
          marginLeft: "auto",
          display: "flex",
          padding: "10px",
        }}
      >
        Update
      </button>
    </div>
  );
};

export default EditInventory;
