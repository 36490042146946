import { gql } from "@apollo/client";

export const CREATE_EMAIL = gql`
  mutation CreateEmail($brandId: ID!, $email: String!, $state: BrandEmailStateEnum) {
    brandEmailCreate(input: { brand: $brandId, email: $email, state: $state }) {
      brandEmail {
        id
        brandId {
          id
        }
        brandEmail
        state
      }
    }
  }
`;

export const UPDATE_EMAIL = gql`
  mutation UpdateEmail($emailId: ID!, $email: String!, $state: BrandEmailStateEnum) {
    brandEmailUpdate(input: { brandEmail: $emailId, email: $email, state: $state }) {
      brandEmail {
        id
        brandEmail
        state
        brandId {
          id
        }
      }
    }
  }
`;

export const DELETE_EMAIL = gql`
  mutation DeleteEmail($emailId: ID!) {
    brandEmailDelete(input: { brandEmail: $emailId }) {
      success
    }
  }
`;
