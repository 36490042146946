import { useLazyQuery, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import PacmanLoader from "../../components/PackmanLoader";
import { FETCH_VOUCHER } from "./queries";
import * as S from "../CouponCreate/styles";
import ViewOrEdit from "../CouponCreate/ViewOrEdit";
import moment from "moment";
import Layout from "../../components/Layout";
import { UPDATE_COUPON } from "./mutations";
import Button from "../../components/Button";
import { toast } from "react-toastify";
import { client } from "../../apollo-client";

export default function EditCoupon() {
  const [searchParams] = useSearchParams();
  const [coupon, setCoupon] = useState(null);
  const [initial, setInitial] = useState(null);
  // const [searchString, setSearchString] = useState("");
  const [fetchCoupon, { data, loading, error }] = useLazyQuery(FETCH_VOUCHER);
  const [updateCoupon, { error: updErr }] = useMutation(UPDATE_COUPON);

  useEffect(() => {
    const voucher = searchParams.get("id");
    if (voucher) {
      fetchCoupon({ variables: { id: voucher } });
    }
    // eslint-disable-next-line
  }, [searchParams]);

  useEffect(() => {
    setCoupon({
      ...data?.voucher,
      title: data?.voucher?.metadata.filter((data) => data.key === "title")[0].value,
      conditions: data?.voucher?.metadata.filter((data) => data.key === "conditions")[0].value,
    });
    setInitial({
      ...data?.voucher,
      title: data?.voucher?.metadata.filter((data) => data.key === "title")[0].value,
      conditions: data?.voucher?.metadata.filter((data) => data.key === "conditions")[0].value,
    });
  }, [data]);

  const handleUpdate = async () => {
    const metaData = coupon?.metadata
      ?.filter((item) => item?.key !== "title" && item?.key !== "conditions")
      .map((item) => ({
        key: item?.key,
        value: item?.value,
      }));
    const res = await updateCoupon({
      variables: {
        ...coupon,
        metadata: [
          ...metaData,
          { key: "title", value: coupon?.title },
          {
            key: "conditions",
            value: coupon?.conditions,
          },
        ],
      },
    });
    if (res?.data?.voucherUpdate?.voucher?.id) {
      toast.success("Coupon Updated!");
      client.refetchQueries({ include: [FETCH_VOUCHER] });
    } else toast.error("Something went wrong!");
  };

  if (loading) return <PacmanLoader />;
  if (error || updErr) return <ErrorPage />;

  return (
    <Layout desktop>
      <div style={{ width: "50%", margin: "20px auto" }}>
        <h2 style={{ margin: "20px 0px" }}>Edit Coupon</h2>
        {/* {!searchParams.get("id") && (
          <S.FlexDiv>
            <ViewOrEdit
              label="Search Voucher"
              value={searchString}
              onChange={(e) => setSearchString(e.target.value)}
            />
            <Button
              onClick={() =>
                fetchCoupon({
                  variables: { id: searchString },
                })
              }
              style={{ width: "fit-content", marginTop: 28 }}
            >
              Search
            </Button>
          </S.FlexDiv>
        )} */}
        {coupon?.name && (
          <>
            <S.FlexDiv>
              <ViewOrEdit
                label="Voucher Title"
                value={coupon.title}
                onChange={(e) => setCoupon((prev) => ({ ...prev, title: e.target.value }))}
              />
              <ViewOrEdit
                label="Voucher Conditions"
                value={coupon.conditions}
                onChange={(e) => setCoupon((prev) => ({ ...prev, conditions: e.target.value }))}
              />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Coupon Name" value={coupon.name} read />
              <ViewOrEdit label="Coupon Code" value={coupon.code} read />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Type" value={coupon.type} read />
              <ViewOrEdit label="Discount Value Type" value={coupon.discountValueType} read />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Discount Value" value={coupon.discountValue} read />
              <ViewOrEdit label="Max Discount Value" value={coupon.maxDiscount?.amount} read />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="User" value={coupon?.user?.email} read />
              <ViewOrEdit label="Usage Limit" value={coupon.usageLimit ?? "COTD"} read />
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Start Date" value={moment(coupon.startDate).format("LLLL")} read />
              <ViewOrEdit
                label="End Date"
                onChange={(e) => setCoupon({ ...coupon, endDate: e.target.value })}
                value={moment(coupon?.endDate).format("YYYY-MM-DDTHH:mm:ss")}
                type="datetime-local"
              />
            </S.FlexDiv>
            <S.FlexDiv></S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Apply once per Order" value={coupon.applyOncePerOrder} read />
              <ViewOrEdit
                label="Apply once per Customer"
                value={coupon.applyOncePerCustomer}
                read
              />
            </S.FlexDiv>
            <S.FlexDiv>
              {!isNaN(coupon?.minSpent?.amount) && (
                <ViewOrEdit label="Min Amount Spent" value={coupon?.minSpent.amount} read />
              )}
              {coupon?.minCheckoutItemsQuantity && !isNaN(coupon?.minCheckoutItemsQuantity) && (
                <ViewOrEdit
                  label="Min Checkout Items"
                  value={coupon.minCheckoutItemsQuantity}
                  read
                />
              )}
            </S.FlexDiv>
            <S.FlexDiv>
              <ViewOrEdit label="Shipping" value={coupon.isShipping} read />
            </S.FlexDiv>
            <Button
              disabled={
                (coupon?.title === initial?.title &&
                  coupon?.conditions === initial?.conditions &&
                  coupon?.endDate === initial?.endDate) ||
                coupon?.title === "" ||
                coupon?.conditions === ""
              }
              onClick={handleUpdate}
              style={{ marginTop: "10px" }}
            >
              Update
            </Button>
          </>
        )}
      </div>
    </Layout>
  );
}
