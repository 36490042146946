import { useMutation } from "@apollo/client";
import { useMemo, useState } from "react";
import { toast } from "react-toastify";
import { client } from "../../../apollo-client";
import Button from "../../../components/Button";
import Dropdown from "../../../components/Dropdown";
import { nextActionEnum, statusEnum } from "../../StoreAnalytics/StoreManagerActions";
import * as S from "../EditStore/styles";
import { GET_STORES } from "../queries";
import { UPDATE_ACTIONS } from "./mutations";

export default function EditActions({ storeData, close }) {
  const status = storeData?.status ?? "";
  const nextActions = storeData?.nextActions ?? "";
  const [store, setStore] = useState({
    id: storeData?.id,
    status: status ? { label: status, value: status } : null,
    nextActions: nextActions ? { label: nextActions, value: nextActions } : null,
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const storeMemo = useMemo(() => store, []);
  const [updateActions, { error }] = useMutation(UPDATE_ACTIONS);

  const handleUpdate = async () => {
    if (!store?.status?.value || !store?.nextActions?.value)
      return toast.warning("Please select status and next actions");
    const res = await updateActions({
      variables: {
        ...store,
        status: store?.status?.value,
        nextActions: store?.nextActions?.value,
      },
    });
    if (res.data?.storeManagerActionUpdate) {
      toast.success("Actions Updated!");
      client.refetchQueries({
        include: [GET_STORES],
      });
    } else toast.error("Error updating actions");
    close();
  };

  const onChange = (e, type) => {
    setStore({ ...store, [type]: e });
  };

  if (error) return <p>Something went wrong!</p>;

  return (
    <div>
      <S.Label>Edit Store Actions</S.Label>
      <S.Flex>
        <div style={{ width: "49%" }}>
          <S.TextLabel>Store Status</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={statusEnum}
            onChange={(e) => onChange(e, "status")}
            value="value"
            label="label"
            defaultValue={store.status}
            isClearable
          />
        </div>
        <div style={{ width: "49%", marginLeft: "10px" }}>
          <S.TextLabel>Store Next Actions</S.TextLabel>
          <Dropdown
            style={{ marginTop: "5px" }}
            data={nextActionEnum}
            onChange={(e) => onChange(e, "nextActions")}
            value="value"
            label="label"
            defaultValue={store.nextActions}
            isClearable
          />
        </div>
      </S.Flex>
      <Button
        disabled={JSON.stringify(store) === JSON.stringify(storeMemo)}
        onClick={handleUpdate}
        style={{ margin: "10px 0px" }}
      >
        Save
      </Button>
    </div>
  );
}
