import { gql } from "@apollo/client";

export const UPDATE_COUPON = gql`
  mutation UpdateCoupon(
    $id: ID!
    $name: String
    $code: String
    $type: VoucherTypeEnum!
    $discountValueType: DiscountValueTypeEnum
    $discountValue: PositiveDecimal
    $startDate: DateTime
    $endDate: DateTime
    $applyOncePerOrder: Boolean
    $applyOncePerCustomer: Boolean
    $usageLimit: Int
    $maxDiscountValue: PositiveDecimal
    $metadata: [MetadataInput]
  ) {
    voucherUpdate(
      id: $id
      input: {
        name: $name
        type: $type
        code: $code
        discountValueType: $discountValueType
        discountValue: $discountValue
        startDate: $startDate
        endDate: $endDate
        applyOncePerOrder: $applyOncePerOrder
        applyOncePerCustomer: $applyOncePerCustomer
        usageLimit: $usageLimit
        maxDiscountValue: $maxDiscountValue
        metadata: $metadata
      }
    ) {
      voucher {
        id
        name
        code
      }
    }
  }
`;
