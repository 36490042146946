import { useEffect, useState } from "react";

export default function useImageUrlValidator(url) {
  const [isImageUrlValid, setIsImageUrlValid] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      let img = new Image();
      img.src = url;
      img.onload = function () {
        if (this.width > 0) {
          setIsImageUrlValid(true);
          setLoading(false);
        }
      };
      img.onerror = function () {
        setIsImageUrlValid(false);
        setLoading(false);
      };
    })();
  }, [url]);

  return { isImageUrlValid, loading };
}
