import { gql } from "@apollo/client";

export const CREATE_FRESHDESK_TICKET = gql`
  mutation CreateFreshDeskTicket($id: ID!, $note: String!) {
    createOrderFreshdeskTicket(id: $id, note: $note) {
      orderErrors {
        message
      }
    }
  }
`;
