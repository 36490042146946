import React from "react";
import styled, { css, keyframes } from "styled-components";
const BounceAnimation = keyframes`
  0% { margin-bottom: 0; }
  50% { margin-bottom: 15px }
  100% { margin-bottom: 0 }
`;

function LoadingDots({ small, customHeight, dotStyle, wrapperStyle }) {
  return (
    <DotWrapper style={wrapperStyle} small={small} customHeight={customHeight}>
      <Dot style={dotStyle} delay="0s" />
      <Dot style={dotStyle} delay=".1s" />
      <Dot style={dotStyle} delay=".2s" />
    </DotWrapper>
  );
}

const Dot = styled.div`
  background-color: black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  margin: 0 5px; /* Animation */
  animation: ${BounceAnimation} 0.5s linear infinite;
  animation-delay: ${(props) => props.delay};
`;
const DotWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ${Dot} {
    ${(props) => {
      if (props.small) {
        return css`
          height: 5px;
          width: 5px;
          margin: 1px;
          background-color: white;
        `;
      }
    }}
  }
`;

export default LoadingDots;
